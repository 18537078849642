/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import styled from '@emotion/styled';
import anime from 'animejs';
import React, { useEffect } from 'react';

const RouterResultsContainer = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RouterIconContainer = styled.div`
    width: 560px;
    height: 560px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: clip;

    #router-results-background {
        z-index: -1;
    }
`;

const routerDuration = 1000;
const baseDuration = 1500;

const getDuration = (percentage: number) => (1 - percentage) * (baseDuration - routerDuration) + baseDuration;

interface RouterResultsBackgroundProps {
    speedPercentage: number;
    dotColor?: string;
}

export const RouterResultsBackground = ({ speedPercentage, dotColor }: RouterResultsBackgroundProps) => {
    const actualDuration =
        getDuration(speedPercentage) === baseDuration ? baseDuration - 1 : getDuration(speedPercentage);

    useEffect(() => {
        const leftPath = anime.path('#left-motion-path');
        const rightPath = anime.path('#right-motion-path');

        const tl = anime.timeline({
            loop: true,
        });

        tl.add({
            targets: '#left-dot',
            translateX: leftPath('x'),
            translateY: leftPath('y'),
            rotate: leftPath('angle'),
            opacity: ['0%', '100%', '100%', '0%', '0%'],
            easing: 'easeInOutSine',
            duration: routerDuration,
        }).add({
            targets: '#left-dot',
            translateX: leftPath('x'),
            translateY: leftPath('y'),
            rotate: leftPath('angle'),
            opacity: ['0%', '0%'],
            easing: 'easeInOutSine',
            duration: actualDuration - routerDuration,
        });

        const t2 = anime.timeline({
            loop: true,
        });

        t2.add({
            targets: '#right-dot',
            translateX: rightPath('x'),
            translateY: rightPath('y'),
            rotate: rightPath('angle'),
            opacity: ['0%', '100%', '100%', '0%', '0%'],
            easing: 'easeInOutSine',
            duration: actualDuration,
            loop: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <RouterResultsContainer>
            <RouterIconContainer>
                <svg
                    width="560.16602"
                    height="560.16602"
                    aria-label="router results image"
                    role="img"
                    viewBox="0 0 560.16602 560.16602"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    id="router-results-background"
                >
                    <circle opacity="1" cx="280.08301" cy="280.08301" r="280.08301" fill="#f9f9fa" />
                    <circle opacity="1" cx="280.08301" cy="279.76001" r="204.627" fill="#f4f4f6" />
                    <circle opacity="0.5" cx="280.08203" cy="279.76102" r="126.662" fill="#e6e6eb" />
                    <circle cx="280.08301" cy="279.76102" r="73.482903" fill="#e6e6eb" />
                    <circle cx="280.08203" cy="279.76102" r="53.427101" fill="#ffffff" />
                    <path
                        d="m 250.74001,305.90001 c -2.414,-1.085 -3.238,-4.186 -3.055,-6.493 -0.027,-1.574 1.529,-10.237 2.079,-11.765 0.55,-1.527 0.841,-2.933 5.211,-3.085 7.686,-0.268 26.002,-0.414 39.036,0.034 5.888,0.203 12.055,0.674 13.72,0.842 3.579,0.361 3.529,2.781 4.003,4.461 0.473,1.681 0.915,10.039 1.007,11.582 0.092,1.543 -0.505,4.996 -4.492,5.286 -1.818,0.132 -8.132,0.182 -16.96,0.365 -8.827,0.183 -37.097,0.325 -40.549,-1.227 z"
                        stroke="#000000"
                        strokeWidth="3.17727"
                        id="path1001"
                    />
                    <path
                        d="m 250.63501,305.96901 c 1.529,1.223 17.118,2.751 29.728,2.674 12.609,-0.076 14.061,-0.611 28.275,-1.911"
                        stroke="#000000"
                        strokeWidth="3.17727"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        id="path1003"
                    />
                    <path
                        d="m 253.67501,260.12701 c 7.836,10.68 14.665,22.401 15.578,23.96 m 38.806,-24.842 c -5.365,8.372 -14.25,22.199 -17.047,24.842"
                        stroke="#000000"
                        strokeWidth="3.17727"
                        strokeLinecap="round"
                        id="path1005"
                    />
                    <path
                        d="m 257.34401,294.48001 c -0.874,0.076 -1.521,0.846 -1.445,1.721 0.076,0.874 0.846,1.521 1.72,1.445 z m 7.595,3.162 c 0.873,-0.096 1.502,-0.881 1.406,-1.753 -0.096,-0.872 -0.881,-1.501 -1.753,-1.405 z m 4.936,-2.938 c -0.878,0 -1.589,0.711 -1.589,1.589 0,0.877 0.711,1.588 1.589,1.588 z m 7.852,3.253 c 0.876,0.042 1.621,-0.634 1.663,-1.511 0.042,-0.876 -0.635,-1.62 -1.511,-1.662 z m 5.277,-3.175 c -0.877,0 -1.588,0.711 -1.588,1.588 0,0.878 0.711,1.589 1.588,1.589 z m 7.125,3.101 c 0.877,-0.025 1.567,-0.756 1.543,-1.633 -0.025,-0.877 -0.756,-1.568 -1.633,-1.543 z m -32.51,-0.237 c 1.019,-0.089 2.208,-0.039 3.513,0.016 1.242,0.052 2.621,0.111 3.807,-0.02 l -0.347,-3.158 c -0.899,0.099 -2.02,0.058 -3.327,0.003 -1.245,-0.052 -2.656,-0.116 -3.921,-0.007 z m 12.256,0.235 c 3.282,0 5.984,-0.013 7.852,0.076 l 0.152,-3.173 c -1.966,-0.095 -4.776,-0.08 -8.004,-0.08 z m 13.129,0.078 c 2.101,0 4.432,0 7.125,-0.076 l -0.09,-3.176 c -2.643,0.074 -4.933,0.075 -7.035,0.075 z"
                        fill="#000000"
                        id="path1007"
                    />
                    <path
                        d="m 298.17701,296.66301 c -0.261,-0.493 -0.274,-1.69 0.997,-2.221 0.953,-0.398 1.792,0.454 2.053,0.947 0.261,0.492 0.144,1.443 -0.872,1.868 -1.017,0.425 -1.917,-0.102 -2.178,-0.594 z"
                        stroke="#000000"
                        strokeWidth="3.17727"
                        id="path1009"
                    />
                    <path
                        d="m 278.35301,272.35901 c -0.77,-1.89 1.07,-4.512 3.739,-4.759 3.736,-0.345 5.394,4.404 2.427,6.64 -2.967,2.236 -5.989,-0.426 -6.166,-1.881 z"
                        stroke="#000000"
                        strokeWidth="3.17727"
                        id="path1011"
                    />
                    <path
                        d="m 262.51201,259.39801 c -0.614,0.602 -0.596,1.6 0.04,2.229 0.636,0.629 1.65,0.651 2.263,0.049 z m 8.744,-4.42 0.765,1.414 z m 10.236,-2.383 -0.06,-1.577 z m 15.342,8.285 c 0.528,0.718 1.531,0.902 2.24,0.41 0.709,-0.492 0.855,-1.473 0.327,-2.192 z m -32.019,0.796 c 1.294,-1.269 3.605,-3.499 7.206,-5.284 l -1.529,-2.828 c -4.045,2.005 -6.631,4.511 -7.98,5.834 z m 7.206,-5.284 c 2.014,-0.998 5.472,-2.231 9.531,-2.221 l -0.12,-3.153 c -4.687,-0.011 -8.629,1.4 -10.94,2.546 z m 9.531,-2.221 c 6.377,0.016 12.36,2.734 15.282,6.709 l 2.567,-1.782 c -3.708,-5.043 -10.83,-8.062 -17.969,-8.08 z"
                        fill="#000000"
                        id="path1013"
                    />
                    <path
                        d="m 269.34601,263.26801 c -0.627,0.588 -0.633,1.589 -0.013,2.236 0.619,0.647 1.629,0.694 2.255,0.107 z m 11.277,-2.993 -0.075,-1.583 z m 11.143,5.181 c 0.667,0.599 1.674,0.568 2.25,-0.069 0.575,-0.636 0.502,-1.638 -0.165,-2.237 z m -20.178,0.155 c 1.492,-1.4 5.245,-3.735 9.11,-3.754 l -0.15,-3.165 c -4.926,0.025 -9.382,2.868 -11.202,4.576 z m 9.11,-3.754 c 3.558,-0.018 5.548,0.259 6.996,0.798 1.414,0.525 2.452,1.346 4.072,2.801 l 2.085,-2.306 c -1.644,-1.476 -3.09,-2.688 -5.149,-3.453 -2.024,-0.752 -4.495,-1.023 -8.154,-1.005 z"
                        fill="#000000"
                        id="path1015"
                    />
                    <path
                        d="m 227.11601,275.81801 h -33 c -4.4186,0 -8.0003,3.582 -8.0003,8 v 39"
                        stroke="#000000"
                        strokeLinecap="round"
                        strokeDasharray="1, 4"
                        id="left-motion-path"
                    />
                    <path
                        d="m 333.45901,275.81801 h 33 c 4.419,0 8,3.582 8,8 v 116.423"
                        stroke="#000000"
                        strokeLinecap="round"
                        strokeDasharray="1, 4"
                        id="right-motion-path"
                    />
                    <circle id="left-dot" r="8" fill={dotColor ?? '#00BF63'} />
                    <circle id="right-dot" r="8" fill={dotColor ?? '#00BF63'} />
                </svg>
            </RouterIconContainer>
        </RouterResultsContainer>
    );
};
