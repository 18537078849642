/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-useless-escape */
import { Answer } from '@home-mgmt-shared/flow-ui';
import { DownloadSpeedResult, IScanResults } from '@home-mgmt-shared/multi-point-scan';
import {
    lowBandwidthRecommendations,
    Recommendation,
    weakSignalRecommendations,
} from '@home-mgmt-shared/recommendation';
import { BehaviorSubject, Observable } from 'rxjs';
import { IMultiPointScanResult } from '../models';

export type MultiPointScanResults = Record<string, IMultiPointScanResult>;

const resultsSubject = new BehaviorSubject<MultiPointScanResults>({});
export const results$: Observable<MultiPointScanResults> = resultsSubject.asObservable();

export const PERCENTAGE_THRESHOLD = 0.2;

export const storeMultiPointFlowResults = (scanName: string, flowResults: Record<string, Answer[]>) => {
    const existingScanResults = { ...resultsSubject.value[scanName] } ?? {};
    const newResults = { ...resultsSubject.value };
    newResults[scanName] = { ...existingScanResults, flowResults, scanName };
    resultsSubject.next(newResults);
};

export const storeMultiPointScanResults = (scanName: string, scanResults: IScanResults) => {
    let downloadSpeed = Number(scanResults.downloadSpeed);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(downloadSpeed)) {
        downloadSpeed = 0;
    }
    const speedResults: DownloadSpeedResult = { downloadSpeed };
    const existingScanResults = { ...resultsSubject.value[scanName] } ?? {};
    const newResults = { ...resultsSubject.value };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    newResults[scanName] = { ...existingScanResults, speedResults, scanName };
    resultsSubject.next(newResults);
};

export const getRec = (baseRecs: Recommendation[], id: string): Recommendation | undefined =>
    baseRecs.find((obj) => obj.id === id);

export const getRecommendations = (
    routerResults: IMultiPointScanResult,
    roomResults: IMultiPointScanResult,
): Recommendation[] => {
    let baseRecs: Recommendation[];
    const recs: Recommendation[] = [];

    const flowResults = roomResults?.flowResults;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const routerSpeed: number = routerResults?.speedResults?.downloadSpeed ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const roomSpeed: number = roomResults?.speedResults?.downloadSpeed ?? 0;

    if (routerSpeed !== 0 && roomSpeed / routerSpeed < PERCENTAGE_THRESHOLD) {
        // Figure out what a "bad result" is
        baseRecs = weakSignalRecommendations;

        // If we want different logic per weakSignal/lowBandwidth we can add the if statements here
    } else {
        baseRecs = lowBandwidthRecommendations;
    }

    if (
        flowResults?.howOldIsYourRouter?.[0].option?.text === 'More than 5 years' ||
        flowResults?.howOldIsYourRouter?.[0].option?.text === 'Between 4 and 5 years'
    ) {
        const newRec = getRec(baseRecs, 'upgradeRouter');
        if (newRec) recs.push(newRec);
    }
    if (flowResults?.howOldIsYourRouter?.[0].option?.text === 'Between 3 and 4 years') {
        const newRec = getRec(baseRecs, 'considerUpgrade');
        if (newRec) recs.push(newRec);
    }
    if (flowResults?.howBigIsYourHome?.[0]?.option?.text === 'More than 2,500 square feet') {
        const newRec = getRec(baseRecs, 'meshNetwork');
        if (newRec) recs.push(newRec);
    } else {
        const newRec = getRec(baseRecs, 'signalExtender');
        if (newRec) recs.push(newRec);
    }
    if (flowResults?.howOldIsYourRouter?.[0].option?.text !== 'More than 5 years') {
        const newRec = getRec(baseRecs, 'switchBands');
        if (newRec) recs.push(newRec);
    }
    if (
        flowResults?.whenIsWifiSlow?.[0].option?.text === "It's always slow" ||
        flowResults?.whenIsWifiSlow?.[0].option?.text === 'Around the same time every day' ||
        flowResults?.whenIsWifiSlow?.[0].option?.text === 'When several people are using it'
    ) {
        const newRec = getRec(baseRecs, 'idealSpeed');
        if (newRec) recs.push(newRec);
    }

    const hardwireDevices = getRec(baseRecs, 'hardwireDevices');
    if (hardwireDevices) recs.push(hardwireDevices);
    const repositionRouter = getRec(baseRecs, 'repositionRouter');
    if (repositionRouter) recs.push(repositionRouter);
    const updateRouter = getRec(baseRecs, 'updateRouter');
    if (updateRouter) recs.push(updateRouter);
    const restartRouter = getRec(baseRecs, 'restartRouter');
    if (restartRouter) recs.push(restartRouter);

    return recs;
};

export const getRoomName = (roomResults: IMultiPointScanResult): string => {
    const question = roomResults?.flowResults?.whatRoomDoYouUseWifiMost?.[0];
    if (question) {
        if (question.responseMeta !== undefined) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return `${question.responseMeta.text}`;
        }
        if (question.option !== undefined) {
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            return `${question.option.text}`;
        }
    }
    return 'Router';
};
