import styled from '@emotion/styled';
import { ChevronRight, FilledButton } from '@home-mgmt-shared/common-ui';
import React from 'react';

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 14.68rem;
    height: calc(100% - 3rem - 2px);
    margin-right: 1rem;
    border: 1px solid #e6e6eb;
    border-radius: 8px;
    padding: 1.5rem;
    padding-top: 1rem;
`;

const IconContainer = styled.div`
    width: auto;
    height: 9rem;
    max-width: 9rem;
    max-height: 9rem;
    margin-bottom: 0.875rem;
`;

const Headline = styled.div`
    font-size: 1.25rem;
    line-height: ${(props) => props.theme.components?.overviewSpeedChart?.headingLineHeight ?? '150%'};
    font-weight: 700;
    margin-bottom: 0.25rem;
`;

const Subtext = styled.div`
    font-size: 1rem;
    line-height: ${(props) => props.theme.components?.overviewSpeedChart?.subHeadingLineHeight ?? '150%'};
    font-weight: 400;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 150%;
`;

const FlowButton = styled(FilledButton)`
    width: auto;
    height: auto;
    padding: 0.25rem 1rem;
    margin: 0;
    margin-top: auto;
    background: ${(props) => props.theme.components?.overviewSpeedChart?.buttonColor ?? '#000'};
`;

const ButtonIcon = styled.div`
    width: 1.17875rem;
    height: 1.17875rem;
    max-width: 1.17875rem;
    max-height: 1.17875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
`;

interface OverviewDataCardProps {
    cardIcon: JSX.Element;
    headline: string;
    subText: string;
    buttonText: string;
    buttonAnalyticName?: string;
    onClick?: () => void;
}

export const OverviewDataCard = ({
    cardIcon,
    headline,
    subText,
    buttonText,
    buttonAnalyticName,
    onClick,
}: OverviewDataCardProps) => (
    <CardContainer>
        <IconContainer>{cardIcon}</IconContainer>
        <Headline>{headline}</Headline>
        <Subtext>{subText}</Subtext>
        <FlowButton
            className="overview-data-card-button"
            onClick={onClick}
            analyticEventName={buttonAnalyticName ?? 'overview-data-card-button'}
        >
            <ButtonRow>
                {buttonText}
                <ButtonIcon>
                    <ChevronRight />
                </ButtonIcon>
            </ButtonRow>
        </FlowButton>
    </CardContainer>
);
