import * as React from 'react';

export const Redo = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 24 21"
        fill="none"
        role="img"
        aria-label="reload"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.45 4.344L19.867 7.08C18.604 3.008 14.8.039 10.315.039c-5.514 0-10 4.486-10 10 0 5.515 4.486 10 10 10v-2c-4.41 0-8-3.59-8-8 0-4.411 3.59-8 8-8 3.77 0 6.93 2.623 7.772 6.136L14.62 6.173l-1 1.733 6.063 3.5 3.5-6.062-1.733-1z"
            fill="#000"
        />
    </svg>
);
