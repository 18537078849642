import styled from '@emotion/styled';
import { AnalyticEvents } from '@home-mgmt-shared/analytics';
import { Accordion } from '@home-mgmt-shared/common-ui';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import React, { ReactNode, useCallback } from 'react';
import { RecommendationsItem } from './RecommendationsItem';
import { TipFeedback } from './TipFeedback';
import { Recommendation } from './utils';

const RecommendationsHeightWrapper = styled.div`
    font-family: ${(props) => props.theme?.font?.type};
    font-size: 1rem;
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0;

    p {
        margin-top: 1rem;
    }
`;

const RecommendationsContentContainer = styled.div`
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const AccordionContainer = styled.div`
    width: 100%;
`;

const AccordionItemContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const AccordionDivider = styled.div`
    margin-top: 1.25rem;
    margin-bottom: 0.25rem;
    height: 1px;
    width: 100%;
    background: #000;
    opacity: 0.1;
`;

const Header = styled.div`
    font-family: ${(props) => props.theme?.font?.type};
    font-size: 1.5rem;
    font-weight: ${(props) => props.theme.components?.resultsPage?.recommendation?.header?.fontWeight || 'normal'};
    text-align: left;
    color: ${(props) => props.theme?.button?.defaultTextColor};
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #e6e6e6;
    padding-left: 0.5rem;
`;

const Subheader = styled.div`
    font-family: ${(props) => props.theme?.font?.type};
    font-size: 0.75rem;
    font-weight: bold;
    text-align: left;
    color: ${(props) => props.theme?.button?.defaultTextColor};
    margin-top: 1.25rem;
    margin-bottom: -1rem;
    margin-left: 4rem;
    padding-left: 3px;
`;

const RecommendationIcon = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1rem;
    margin-top: 30px;
`;

const AccordionLine = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const FirstLineContainer = styled.div`
    min-width: 0;
    margin-top: 1rem;
    font-family: ${(props) => props.theme?.font?.type};
    font-size: 1rem;
    flex: auto;
    text-align: left;
    margin-left: 3px;
    line-height: 150%;
`;

const MAX_LINE_LENGTH = 30;

const getFirstLine = (content: string): ReactNode => {
    if (content.length > MAX_LINE_LENGTH) {
        const firstLineTrunc = content.substring(0, MAX_LINE_LENGTH);
        const firstLine = firstLineTrunc.substring(0, firstLineTrunc.lastIndexOf(' '));

        return <FirstLineContainer className="first-line">{firstLine}...</FirstLineContainer>;
    }
    return <FirstLineContainer className="first-line">{content}...</FirstLineContainer>;
};

interface RecommendationProps {
    recommendations: Recommendation[];
    header?: string;
    subheader?: string;
    elementsToDisplay?: number;
    showFirstLine?: boolean;
}

export const Recommendations = ({
    recommendations,
    header,
    subheader,
    elementsToDisplay,
    showFirstLine,
}: RecommendationProps) => {
    const numElements =
        elementsToDisplay && elementsToDisplay < recommendations.length ? elementsToDisplay : recommendations.length;

    const recsToDisplay = recommendations.filter((_, i) => i < numElements);

    const analyticsCallback = useCallback((eventName: string, props: Record<string, unknown>): void => {
        analytics.dispatch(eventName, NSEventType.Click, props);
    }, []);

    return (
        <RecommendationsHeightWrapper>
            <RecommendationsContentContainer>
                {header !== undefined && <Header>{header}</Header>}
                {subheader !== undefined && <Subheader>{subheader}</Subheader>}
                <AccordionContainer>
                    {recsToDisplay.map((recommendation, index) => (
                        <div key={recommendation.header}>
                            {index !== 0 && <AccordionDivider />}
                            <AccordionItemContainer>
                                {recommendation.icon && <RecommendationIcon>{recommendation.icon}</RecommendationIcon>}
                                <AccordionLine>
                                    <Accordion
                                        header={recommendation.header}
                                        analyticEventName={`${AnalyticEvents.CONNECTION_EXPLANATION_ACCORDION}`}
                                        analyticsCb={analyticsCallback}
                                        headerNormalWeight={false}
                                        headerLarge={showFirstLine}
                                        firstLine={showFirstLine ? getFirstLine(recommendation.content) : undefined}
                                    >
                                        <RecommendationsItem content={recommendation.content} />
                                        {recommendation.tipFeedback && (
                                            <TipFeedback
                                                analyticEventName={`${AnalyticEvents.CONNECTION_EXPLANATION_ACCORDION_FEEDBACK}`}
                                                analyticExtras={{
                                                    header: recommendation.header,
                                                    content: recommendation.content,
                                                }}
                                            />
                                        )}
                                    </Accordion>
                                </AccordionLine>
                            </AccordionItemContainer>
                        </div>
                    ))}
                </AccordionContainer>
            </RecommendationsContentContainer>
        </RecommendationsHeightWrapper>
    );
};
