/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import styled from '@emotion/styled';
import { WebscanEvents } from '@home-mgmt-shared/analytics';
import { OutlineButton } from '@home-mgmt-shared/common-ui';
import {
    useSession as useWixiSession,
    useSpeedTestDownloadSpeed,
    useSpeedTestStatus,
} from '@home-mgmt-shared/web-scan';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import {
    SpeedTestStatus,
    SpeedtestType,
    WixiCore,
    WixiEnrollment,
    WixiSnap,
    WixiSnapConfig,
} from '@soluto-private/wixi-web-sdk';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { IScanResults, MultiPointScanState } from './models';
import { RoomIntroPage } from './RoomIntroPage';
import { RoomScanningPage } from './RoomScanningPage';
import { defaultRoomMapping, determineSpeed, formatSpeed, ordinalToWord } from './utils';

interface RoomScanProps {
    customEnrollment?: WixiEnrollment;
    onTestComplete?: () => void;
    onTestCanceled?: () => void;
    onStepForward?: () => void;
    onStepBackward?: () => void;
    onScanComplete?: (results: IScanResults) => void;
    onScanStart?: () => void;
    IntroImage?: ReactNode;
    currentRoomIndex: number;
    numRooms: number;
    roomName: string;
    minimumSpeed?: number;
    initialDownloadSpeed?: number;
}

const SpeedTestContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: auto;
    width: 100%;
`;

const SpeedTestProgressContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 23rem;
`;

const OutlineButtonContainer = styled.div`
    display: flex;
    margin-bottom: 1rem;
    width: 100%;
`;

export const RoomScan = ({
    customEnrollment,
    onTestComplete,
    onTestCanceled,
    onStepForward,
    onStepBackward,
    onScanComplete,
    onScanStart,
    IntroImage,
    currentRoomIndex,
    numRooms,
    roomName,
    minimumSpeed,
    initialDownloadSpeed,
}: RoomScanProps) => {
    const [multiPointState, setMultiPointState] = useState(
        initialDownloadSpeed ? MultiPointScanState.FINISHED : MultiPointScanState.NOT_STARTED,
    );
    const wixiSession = useWixiSession();
    const downloadSpeed = useSpeedTestDownloadSpeed();
    const [snap, setSnap] = useState<WixiSnap>();
    const userId = customEnrollment?.clientId;

    const onSnapStarted = useCallback(() => {
        if (!initialDownloadSpeed) setMultiPointState(MultiPointScanState.STARTED);
    }, [initialDownloadSpeed]);

    useEffect(() => {
        const snapConfig: WixiSnapConfig = {
            wixiHealthConfig: {
                speedtestType: SpeedtestType.DOWNLOAD,
            },
        };
        const wixiSnap = new WixiSnap(snapConfig);
        wixiSnap.onSnapStart = onSnapStarted;
        setSnap(wixiSnap);
    }, [onSnapStarted]);

    const speedTestStatus = useSpeedTestStatus();
    const [scanStatus, setScanStatus] = useState<SpeedTestStatus>(speedTestStatus);
    const scanCompleted = scanStatus === SpeedTestStatus.completed;

    useEffect(() => {
        setScanStatus(speedTestStatus);
    }, [speedTestStatus]);

    useEffect(() => {
        if (wixiSession.goId) {
            analytics.updateProperties({
                userId: userId || WixiCore.clientId,
                scanId: wixiSession.goId,
                wixiClientId: WixiCore.clientId,
            });
            analytics.dispatch(WebscanEvents.ScanStarted, NSEventType.Click, {
                userId: userId || WixiCore.clientId,
                scanId: wixiSession.goId,
                wixiClientId: WixiCore.clientId,
                roomName,
            });
        }
    }, [roomName, userId, wixiSession.goId]);

    useEffect(() => {
        if (scanCompleted) {
            setMultiPointState(MultiPointScanState.FINISHED);
            onTestComplete?.();
        }
    }, [onTestComplete, scanCompleted]);

    const completeScan = useCallback(() => {
        if (scanCompleted || initialDownloadSpeed) {
            const officialDownloadSpeed = determineSpeed(parseInt(downloadSpeed, 10), minimumSpeed);
            const results: IScanResults = {
                clientId: WixiCore.clientId,
                goId: wixiSession.goId || '',
                downloadSpeed: initialDownloadSpeed?.toString() ?? officialDownloadSpeed.toString(),
            };
            onScanComplete?.(results);
        }
    }, [scanCompleted, downloadSpeed, minimumSpeed, wixiSession.goId, onScanComplete, initialDownloadSpeed]);

    const startSpeedTest = useCallback(async () => {
        setMultiPointState(MultiPointScanState.INITIALIZING);
        onStepForward?.();
        await snap?.snap();
        onScanStart?.();
    }, [onScanStart, onStepForward, snap]);

    const cancelSpeedTest = useCallback(() => {
        if (wixiSession.goId) {
            analytics.dispatch(WebscanEvents.ScanCanceled, NSEventType.Click, {
                userId: userId || WixiCore.clientId,
                scanId: wixiSession.goId,
                wixiClientId: WixiCore.clientId,
            });
        }
        snap?.cancel();
        setMultiPointState(MultiPointScanState.NOT_STARTED);
        onStepBackward?.();
        onTestCanceled?.();
    }, [onStepBackward, onTestCanceled, snap, userId, wixiSession.goId]);

    const notStarted = multiPointState === MultiPointScanState.NOT_STARTED;
    const started = multiPointState !== MultiPointScanState.NOT_STARTED;
    const inProgress = multiPointState === MultiPointScanState.STARTED;

    const headerText =
        currentRoomIndex === 1
            ? `Go to${
                  defaultRoomMapping.has(roomName.toLowerCase()) ? ' the' : ''
              } ${roomName.toLowerCase()}. We'll test the Wi-Fi speed there first.`
            : `Let's run one final speed test next to your router.`;

    return (
        <SpeedTestContainer>
            {notStarted && (
                <RoomIntroPage
                    speedTestCounter={`Speed test ${currentRoomIndex} of ${numRooms}`}
                    header={headerText}
                    image={IntroImage}
                    confirmationText={
                        roomName.toLowerCase() !== 'router'
                            ? `Are you in ${
                                  defaultRoomMapping.has(roomName.toLowerCase()) ? ' the' : ''
                              } ${roomName.toLowerCase()}?`
                            : `Are you next to your router?`
                    }
                    startSpeedTest={startSpeedTest}
                    buttonText={`Yes, start the ${ordinalToWord(currentRoomIndex) ?? ''} speed test`}
                    roomName={roomName}
                />
            )}

            {started && (
                <SpeedTestProgressContainer>
                    <RoomScanningPage
                        speedTestCounter={`Speed test ${currentRoomIndex} of ${numRooms}`}
                        roomName={roomName}
                        downloadSpeed={initialDownloadSpeed ?? formatSpeed(downloadSpeed)}
                        scanState={multiPointState}
                        minimumSpeed={determineSpeed(initialDownloadSpeed ?? parseInt(downloadSpeed, 10), minimumSpeed)}
                        onTestComplete={completeScan}
                    />
                    {inProgress && (
                        <OutlineButtonContainer>
                            <OutlineButton
                                analyticEventName={WebscanEvents.ScanCanceled}
                                analyticsExtras={{ roomName }}
                                data-test-cy="speed-test-cancel-button"
                                onClick={cancelSpeedTest}
                                aria-label="button to cancel speed test"
                                tabIndex={0}
                                borderwidth="1px"
                            >
                                Cancel
                            </OutlineButton>
                        </OutlineButtonContainer>
                    )}
                </SpeedTestProgressContainer>
            )}
        </SpeedTestContainer>
    );
};
