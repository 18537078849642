import styled from '@emotion/styled';
import { CheckboxButton } from '@home-mgmt-shared/common-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { Answer, AnswerOption, BaseAnswerProps } from '../models';

interface AnswerGridButtonProps {
    option: AnswerOption;
    onAnswerSelect: (option: AnswerOption) => void;
    selected: boolean;
    activityId: string;
}

const AnswerMultiSelectButton = ({ option, onAnswerSelect, selected, activityId }: AnswerGridButtonProps) => {
    const onSelection = useCallback(() => {
        onAnswerSelect(option);
    }, [onAnswerSelect, option]);

    if (!option.text) {
        return null;
    }

    return (
        <CheckboxButton
            selected={selected}
            analyticEventName={`option_${option.text}`}
            analyticsExtras={{ activityId }}
            key={option.text}
            label={option.text}
            onClick={onSelection}
        />
    );
};

const MultiSelectListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    margin: auto;
`;

export const MultiSelectAnswerList = ({
    answerOptions,
    onAnswerSelection,
    initialState,
    activityId,
}: BaseAnswerProps) => {
    const [selectedOptions, setSelectedOptions] = useState<AnswerOption[]>([]);

    useEffect(() => {
        if (initialState?.length) {
            const options = initialState.map((a) => a.option);
            setSelectedOptions(options);
        }
    }, [initialState]);

    const onSelection = useCallback(
        (option: AnswerOption) => {
            let updatedSelections: AnswerOption[] = [];
            if (selectedOptions.includes(option)) {
                updatedSelections = selectedOptions.filter((o) => o.text !== option.text);
            } else {
                updatedSelections = [...selectedOptions, option];
            }

            setSelectedOptions(updatedSelections);

            const answers: Answer[] = updatedSelections.map((selection) => ({ option: selection }));
            onAnswerSelection(answers);
        },
        [onAnswerSelection, selectedOptions],
    );

    return (
        <>
            <MultiSelectListContainer>
                {answerOptions.map((option) => {
                    if (!option.text) {
                        return null;
                    }
                    const isSelected = selectedOptions.includes(option);
                    return (
                        <AnswerMultiSelectButton
                            data-test-cy={`question-answer-response-${option.text ?? 'undef'}`}
                            key={option.text}
                            selected={isSelected}
                            option={option}
                            onAnswerSelect={onSelection}
                            activityId={activityId}
                        />
                    );
                })}
            </MultiSelectListContainer>
        </>
    );
};
