import styled from '@emotion/styled';
import {
  BandwidthCalculatorConfig,
  BandwidthCalculatorView,
} from '@home-mgmt-shared/bandwidth-calculator-page';
import { BandwidthHomeIntro } from '@home-mgmt-shared/common-ui';
import { bandwidthCalculatorFlow } from '@home-mgmt-shared/flows';
import React from 'react';
import { SpinningWand } from '../components/CommonComponents/SpinningWand';
import { MxQuestionAnswerIcons } from '../img/att/mx-question-answer-icons';
import { QuestionAnswerIcons } from '../img/att/question-answer-icons';
import { RouterSpaceship } from '../img/att/question-answer-icons/RouterSpaceship';
import Spinner from '../img/att/question-answer-icons/Spinner';
import { BasePage } from './BasePage';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: ${(props) => props.theme.font?.type};
  font-size: 16px;
  background: #fff;

  .speed-results-circle-labels {
    margin-top: -2rem;
  }

  .speed-results-anim-placeholder {
    margin-top: -7.75rem;
  }

  .speed-results-anim-placeholder2 {
    margin-top: -8.65rem;
  }

  .speed-results-anim-placeholder3 {
    margin-top: -2rem;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
`;

const getIntroComponent = (props: { startSpeedTest: () => Promise<void> }) =>
  IS_MX_APP ? (
    <BandwidthHomeIntro startSpeedTest={props.startSpeedTest} />
  ) : (
    <BandwidthHomeIntro
      IntroImage={RouterSpaceship}
      startSpeedTest={props.startSpeedTest}
    />
  );
export const BandwidthCalculatorPage = () => {
  const config: BandwidthCalculatorConfig = {
    flow: {
      imageMap: IS_MX_APP ? MxQuestionAnswerIcons : QuestionAnswerIcons,
      linkButtonUnderlined: true,
    },
    calculatingConfig: {
      LoadingAnimation: IS_MX_APP ? SpinningWand : Spinner,
    },
    webscan: {
      IntroComponent: ({ startSpeedTest }) =>
        getIntroComponent({ startSpeedTest }),
    },
    results: {
      hideResultsHeaderMoodIcon: true,
    },
    progressBar: true,
  };

  return (
    <BasePage>
      <PageContainer>
        <ContentContainer>
          <BandwidthCalculatorView
            flow={bandwidthCalculatorFlow}
            config={config}
          />
        </ContentContainer>
      </PageContainer>
    </BasePage>
  );
};
