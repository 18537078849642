import * as React from 'react';

export const SpeedGauge = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="Speed gauge"
    >
        <path
            clipRule="evenodd"
            d="M12.214 15.924a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13.274 13.363l4.95-4.949M2.714 14.924h2M21.714 14.924h-2M20.99 10.79l-1.847.764M15.849 5.647l-.765 1.848M12.214 4.924v2M8.58 5.647l.764 1.848M5.497 7.706L6.91 9.121M3.438 10.79l1.846.764"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            clipRule="evenodd"
            d="M12.214 2.924c-6.351 0-11.5 5.149-11.5 11.5v3.5a1 1 0 001 1h21a1 1 0 001-1v-3.5c0-6.351-5.149-11.5-11.5-11.5z"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
