import React from 'react';

interface LeftArrowProps {
    width?: string;
    height?: string;
}

export const LeftArrow = ({ width, height }: LeftArrowProps) => (
    <svg
        width={width ?? '8'}
        height={height ?? '13'}
        viewBox="0 0 8 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="left arrow"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.43728 2.04379L2.43719 6.63379L7.43728 11.2238L6.02728 12.6338L0.0272827 6.63379L6.02728 0.633789L7.43728 2.04379Z"
            fill="black"
        />
    </svg>
);
