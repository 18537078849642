import { usePageAnalytics, WebscanEvents } from '@home-mgmt-shared/analytics';
import { CallButton } from '@home-mgmt-shared/common-ui';
import { ContactExpert } from '@home-mgmt-shared/expert-cta';
import React from 'react';

const ContactSupport = () => {
  usePageAnalytics('Web Scan Results');

  return (
    <ContactExpert
      description="Our Wi-Fi experts are available to discuss your results and troubleshoot any issues.
"
    >
      <CallButton
        phoneNumber="877-229-5280"
        analyticEventName={WebscanEvents.ScanConnectWithExpert}
      />
    </ContactExpert>
  );
};

export default ContactSupport;
