import styled from '@emotion/styled';
import { IStreamingQualityGuideline } from '@home-mgmt-shared/common-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { OverviewWifiManagement } from '../components';
import { OverviewConfig } from '../models';

const OverviewViewContainer = styled.div`
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

export enum OverviewPageState {
    WIFI_MANAGEMENT = 'wifi_management',
}

const pageToTitle: Record<OverviewPageState, string> = {
    [OverviewPageState.WIFI_MANAGEMENT]: 'Network Overview',
};

const usePageState = (defaultState: OverviewPageState) => {
    const [currentState, setCurrentState] = useState<OverviewPageState>(defaultState);
    const [prevState, setPrevState] = useState<OverviewPageState>(defaultState);

    const setPageState = (newPageState: OverviewPageState) => {
        setCurrentState((prevPageState) => {
            setPrevState(prevPageState);
            return newPageState;
        });
    };

    return { currentState, prevState, setPageState };
};

interface OverviewViewProps {
    onPageChange?: (page: OverviewPageState) => void;
    backWasClicked: boolean;
    overviewConfig: OverviewConfig;
    streamingGuidelines?: IStreamingQualityGuideline[];
}

export const OverviewView = ({
    onPageChange,
    backWasClicked,
    overviewConfig,
    streamingGuidelines,
}: OverviewViewProps) => {
    const { currentState, prevState, setPageState } = usePageState(OverviewPageState.WIFI_MANAGEMENT);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [back, setBack] = useState<boolean>(backWasClicked ?? false);

    const totalSteps = 1;

    useEffect(() => {
        onPageChange?.(currentState);
        window.scrollTo(0, 0);

        if (currentState === OverviewPageState.WIFI_MANAGEMENT) {
            setCurrentStep(0);
        }
    }, [currentState, currentStep, onPageChange, totalSteps]);

    const onBackClicked = useCallback(() => {
        setPageState(prevState);
    }, [prevState, setPageState]);

    useEffect(() => {
        if (backWasClicked !== back) {
            setBack(!back);
            onBackClicked();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backWasClicked]);

    const pageTitle = pageToTitle[currentState];

    return (
        <OverviewViewContainer>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>

            {currentState === OverviewPageState.WIFI_MANAGEMENT && (
                <OverviewWifiManagement
                    BinaryFeedback={overviewConfig.BinaryFeedback}
                    streamingGuidelines={streamingGuidelines}
                />
            )}
        </OverviewViewContainer>
    );
};
