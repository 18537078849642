import React from 'react';
import styled from '@emotion/styled';
import { SpeedResultsCopy } from './utils';
import { SpeedIndicator } from './SpeedIndicator';

export enum SpeedResultStatus {
    SLOW = 'slow',
    OKAY = 'okay',
    DECENT = 'decent',
    GOOD = 'good',
}

type SpeedResultsProps = {
    speed: number;
    idealSpeed: number;
    speedResultsCopy: SpeedResultsCopy;
    dialColor: string | undefined;
    isSpeedSufficient: boolean;
};

const SpeedResultContainer = styled.div`
    max-width: 365px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: ${(props) => props.theme.font?.type};
`;

export const SpeedResults = ({
    speed,
    idealSpeed,
    dialColor,
    isSpeedSufficient,
    speedResultsCopy,
}: SpeedResultsProps) => (
    <SpeedResultContainer>
        <SpeedIndicator
            speed={speed}
            idealSpeed={idealSpeed}
            dialColor={dialColor}
            isSpeedSufficient={isSpeedSufficient}
            speedResultsCopy={speedResultsCopy}
        />
    </SpeedResultContainer>
);
