/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import React, { ReactNode, useCallback, useState } from 'react';
import { CloseArrow, OpenArrow } from '../Icons';

type AccordionProps = {
    header: string;
    children: ReactNode;
    analyticEventName: string;
    shouldPinHeader?: boolean;
    contentPreview?: ReactNode;
    openedHeader?: string;
    analyticsCb?: (eventName: string, props: Record<string, unknown>) => void;
    headerNormalWeight?: boolean;
    headerLarge?: boolean;
    firstLine?: ReactNode;
};

type AccordionDescriptionProps = {
    open?: boolean;
    showFirstLine?: boolean;
    firstLineEnabled?: boolean;
};

type HeaderProps = {
    normalWeight?: boolean;
    headerLarge?: boolean;
};

const HeaderContainer = styled.button`
    display: flex;
    margin-left: 3px;
    padding: 5px 0px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border: none;
    width: 100%;
    background: none;
`;

const Header = styled.div<HeaderProps>`
    font-family: ${(props) => props.theme?.font?.type};
    font-size: ${(props) => (props.headerLarge ? '1.25rem' : '1rem')};
    font-weight: ${(props) => (props.normalWeight ? 'normal' : 'bold')};
    flex: auto;
    line-height: 150%;
    text-align: left;
    margin-bottom: 0px;
    text-transform: none;
    width: 100%;
`;

const AccordionIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: right;
    padding-right: 15px;
    flex: 1;
    margin-left: 15px;
    margin-top: 10px;
    align-self: flex-start;
`;

const AccordionContainer = styled.div`
    overflow: hidden;
    margin-top: 20px;
    width: 100%;
`;

const AccordionDescriptionContainer = styled.div<AccordionDescriptionProps>`
    margin-top: ${(props) => (props.firstLineEnabled ? '-1rem' : '0px')};
    transition: max-height 0.4s ease-in-out;
    height: auto;
    overflow: hidden;
    max-height: ${(props) => (props.open ? '900px' : props.showFirstLine ? '3rem' : '0px')};
`;

interface AccordionLabelProps {
    onClick: () => void;
    headerTitle: string;
    headerNormalWeight?: boolean;
    headerLarge?: boolean;
    isOpen: boolean;
    openedHeader: string;
}
const AccordionLabel = ({
    onClick,
    headerTitle,
    headerNormalWeight,
    headerLarge,
    isOpen,
    openedHeader,
}: AccordionLabelProps) => {
    const title = isOpen ? openedHeader : headerTitle;
    return (
        <HeaderContainer data-test-cy={`accordion-${title}`} onClick={onClick}>
            <Header
                data-test-cy={`accordion-header-${title}`}
                normalWeight={headerNormalWeight}
                headerLarge={headerLarge}
            >
                {title}
            </Header>
            <AccordionIcon>{isOpen ? <OpenArrow /> : <CloseArrow />}</AccordionIcon>
        </HeaderContainer>
    );
};

export const Accordion = ({
    header,
    children,
    analyticEventName,
    shouldPinHeader = true,
    contentPreview,
    openedHeader = header,
    analyticsCb,
    headerNormalWeight,
    headerLarge,
    firstLine,
}: AccordionProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [showFirstLine, setShowFirstLine] = useState<boolean>(true);

    const toggleAccordion = useCallback(() => {
        setIsOpen(!isOpen);
        if (isOpen) {
            setTimeout(() => {
                setShowFirstLine(isOpen);
            }, 350);
        } else {
            setShowFirstLine(isOpen);
        }
        analyticsCb?.(analyticEventName, { isOpen: !isOpen, header });
    }, [analyticEventName, analyticsCb, isOpen, header]);

    // if true, then the header will be at the top of the accordion and content will expand downwards
    // if false, then the header will be at the bottom of the accordion and content will expand upwards
    if (shouldPinHeader) {
        return (
            <AccordionContainer>
                <AccordionLabel
                    onClick={toggleAccordion}
                    headerLarge={headerLarge}
                    headerNormalWeight={headerNormalWeight}
                    headerTitle={header}
                    openedHeader={openedHeader}
                    isOpen={isOpen}
                />
                <AccordionDescriptionContainer
                    open={isOpen}
                    showFirstLine={firstLine !== undefined && showFirstLine}
                    firstLineEnabled={firstLine !== undefined}
                >
                    {firstLine !== undefined && showFirstLine ? firstLine : children}
                </AccordionDescriptionContainer>
            </AccordionContainer>
        );
    }

    return (
        <AccordionContainer>
            {contentPreview}
            <AccordionDescriptionContainer open={isOpen}>{children}</AccordionDescriptionContainer>
            {children && (
                <AccordionLabel
                    onClick={toggleAccordion}
                    headerLarge={headerLarge}
                    headerNormalWeight={headerNormalWeight}
                    headerTitle={header}
                    openedHeader={openedHeader}
                    isOpen={isOpen}
                />
            )}
        </AccordionContainer>
    );
};
