import styled from '@emotion/styled';
import React from 'react';
import { LeftArrow } from '../Icons';

const BackButtonContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 2.5rem;
`;

const StyledBackButton = styled.button`
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    background: transparent;
    border: none;
    margin-bottom: 0.625rem;
`;

const LeftArrowStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 1rem 0px 0.5rem;
    cursor: pointer;
`;

const BackButtonText = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    cursor: pointer;
`;

interface BackButtonProps {
    backButtonCallback: () => void;
}

export const BackButton = ({ backButtonCallback }: BackButtonProps) => (
    <BackButtonContainer>
        <StyledBackButton onClick={backButtonCallback}>
            <LeftArrowStyled>
                <LeftArrow />
            </LeftArrowStyled>
            <BackButtonText>Back</BackButtonText>
        </StyledBackButton>
    </BackButtonContainer>
);
