/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import * as React from 'react';
import styled from '@emotion/styled';

const SpinningWandSvg = require('./SpinningWandSvg.svg');

const SpinningWandContainer = styled.img`
    flex-grow: 0;
    flex-shrink: 0;
    height: calc(100vw / 2.5);
    min-height: 330px;
    max-height: 600px;
    width: auto;
    @media all and (max-width: 599px) {
        min-height: calc(100vw / 1.5);
        margin-top: 0;
        margin-bottom: 0;
    }
`;

export const SpinningWand = () => <SpinningWandContainer src={SpinningWandSvg} />;
