import * as React from 'react';

interface FlagIconProps {
    fillColor?: string;
    flagColor?: string;
    width?: string;
    height?: string;
}

export const FlagIcon = ({ fillColor = '#F0F0F5', flagColor = '#000', width, height }: FlagIconProps) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="flag icon"
    >
        <circle cx="13.4792" cy="13.666" r="13.1996" fill={fillColor} />
        <path
            d="M20.4337 11.0529L10.6437 7.5565C10.4297 7.47958 10.1913 7.51175 10.006 7.64321C9.81996 7.77468 9.70947 7.98726 9.70947 8.21452V21.5009H11.108V15.7003L20.4337 12.3697C20.712 12.2704 20.898 12.0067 20.898 11.7116C20.898 11.4165 20.712 11.1522 20.4337 11.0529Z"
            fill={flagColor}
        />
    </svg>
);
