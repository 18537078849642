import React from 'react';

function AttFreeUser() {
  return (
    <>
      <div className="free-user mt-5 row">
        <div className="col-xs-12 col-sm-12 col-md-6 order-lg-1 text-holder">
          <p className="heading">
            You must be an AT&T Protect Advantage customer to view your scan
            results.
          </p>
          <p className="text1">
            Learn more about Protect Advantage and check your eligibility. Tap
            below to read more.
          </p>
          <div>
            <a className="btn" href="https://protectadvantage.att.com/faqs">
              Read the FAQs
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default AttFreeUser;
