import { SpeedTestIntro } from '@home-mgmt-shared/common-ui';
import {
  GenericScanConfig,
  GenericScanView,
} from '@home-mgmt-shared/generic-scan-page';
import React from 'react';
import { ContactSupport } from '../../contactSupport';
import { BasePage } from '../../pages/BasePage';

const genericScanConfig: GenericScanConfig = {
  results: {
    checkSpeedAgainButtonDesktopColumn: 'right',
    expertCTADesktopColumn: 'left',
    resultsHeader: 'Wi-Fi checkup results',
    resultsSubHeader: 'Your speed',
    ctaOverride: <ContactSupport />,
  },
  webscan: {
    IntroComponent: ({ startSpeedTest }) => (
      <SpeedTestIntro
        introHeader="Your speed plays a vital role in your streaming experience."
        introSubheader="Let's see what speeds you're getting..."
        startSpeedTest={startSpeedTest}
      />
    ),
    scanningPage: {
      showHeader: true,
    },
  },
};

export const ScanPage = () => (
  <BasePage>
    <GenericScanView config={genericScanConfig} />
  </BasePage>
);

export default ScanPage;
