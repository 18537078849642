import { SelectableButton } from '@home-mgmt-shared/common-ui';
import React, { useCallback } from 'react';
import { Answer, AnswerOption } from '../models';

interface AnswerListButtonProps {
    option: AnswerOption;
    onAnswerSelect: (answer: Answer) => void;
    selected: boolean;
    activityId: string;
}

export const AnswerListButton = ({ option, onAnswerSelect, selected, activityId }: AnswerListButtonProps) => {
    const onSelection = useCallback(
        (answer?: Answer) => {
            const a = answer ?? { option };
            onAnswerSelect(a);
        },
        [onAnswerSelect, option],
    );

    if (!option.text) {
        return null;
    }

    return (
        <SelectableButton
            data-test-cy={`question-answer-response-${option.text ?? 'undef'}`}
            analyticsExtras={{ activityId }}
            analyticEventName={`option_${option.text}`}
            selected={selected}
            onClick={onSelection}
        >
            {option.text}
        </SelectableButton>
    );
};
