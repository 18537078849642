import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { getColorFromSpeeds } from '../api';
import { SpeedPoint, SpeedType } from '../models';

type PointHighlightProps = {
    border?: string;
    marginLeft: number;
    marginTop: number;
    marginBottom: number;
};

const PointHighlight = styled.div<PointHighlightProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-start;
    align-self: flex-start;
    z-index: 20;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 9999px;
    margin-left: ${(props) => props.marginLeft}px;
    margin-top: -${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
    pointer-events: none;

    ${(props) =>
        props.border &&
        `
        border: 4px solid ${props.border};
    `}

    transition: margin-left 0.375s, margin-top 0.375s, margin-bottom 0.375s, border 0.375s;
`;

type PointEmphasisDotProps = {
    speedType: SpeedType;
    color?: string;
};

const PointEmphasisDot = styled.div<PointEmphasisDotProps>`
    width: 1rem;
    height: 1rem;
    background: ${(props) =>
        props.speedType === SpeedType.DOWNLOAD
            ? props.theme.components?.overviewSpeedChart?.downloadSpeedColor
            : props.theme.components?.overviewSpeedChart?.uploadSpeedColor};
    border-radius: 9999px;
    z-index: 20;
    pointer-events: none;

    ${(props) =>
        props.color &&
        `
        background: ${props.color};
    `}
`;

interface SpeedChartPointHighlightProps {
    data: SpeedPoint[];
    chartMin: number;
    chartMax: number;
    chartWidth: number;
    chartHeight: number;
    speedType: SpeedType;
    emptyDataSetColor?: string;
}

export const SpeedChartPointHighlight = ({
    data,
    chartMin,
    chartMax,
    chartWidth,
    chartHeight,
    speedType,
    emptyDataSetColor,
}: SpeedChartPointHighlightProps) => {
    const theme: Theme = useTheme();

    // The position of the point can be either 0 for one data point, 1 for two
    // data points, or 2 for three data points, or 3 for four or five data points.
    const xPos = Math.min(3, data.length - 1);
    const yPos = data[data.length - 1].speed;

    // We only want a border if there is more than one data point
    const border = data.length > 1 ? getColorFromSpeeds(data, theme) : undefined;

    // If the border exists, add 8 pixels for the 4px padding and 4px border
    const pointSize = border ? 24 : 16;

    // The chart points are always separated by a 1/4 of the chart's total width

    // The chart is actually the (width of the container + (width of container / 15))

    // There's an offset in the start of the points so the leftmost point is out
    // of view by (chartWidth / 10) so we adjust for that as well

    // This component is positioned by the top left corner, so adjust by half
    // the size of this component and half the size of the chart's points (8px / 2)
    const marginLeft = xPos * ((chartWidth + chartWidth / 15) / 4) + chartWidth / 10 - pointSize / 2 - 4;

    // For the y position of the point we get the position as a percentage of the
    // total height of chart by getting the distance from the min to the yPos,
    // then by dividing that distance by the height of the chart in speed units

    // Then we multiply that percentage by the pixel height of the chart (chartHeight)

    // There is a 30px margin on the bottom of the chart, so we add that offset
    // and also add the half component size and half chart point offset
    const marginTop = ((yPos - chartMin) / (chartMax - chartMin)) * chartHeight + 30 + pointSize / 2 + 4;

    // This is the offset to counteract the negative margin above, minus the
    // unnecessary margin and component offsets since this starts below the component
    const marginBottom = ((yPos - chartMin) / (chartMax - chartMin)) * chartHeight;

    return (
        <PointHighlight
            // Override the color of the border if we are showing the empty state
            border={emptyDataSetColor ?? border}
            marginLeft={marginLeft}
            marginTop={marginTop}
            marginBottom={marginBottom}
        >
            {/* Override the color of the border if we are showing the empty state */}
            <PointEmphasisDot speedType={speedType} color={emptyDataSetColor} />
        </PointHighlight>
    );
};
