import { WixiCore, WixiPartner } from '@soluto-private/wixi-web-sdk';

export const wixiPartnerConfig: WixiPartner = {
  partnerSecret: process.env.WIXI_PARTNER_SECRET ?? '',
  partnerAppId: process.env.WIXI_PARTNER_APP_ID ?? '',
  partnerId: process.env.WIXI_PARTNER_ID ?? '',
  partnerAppSecret: process.env.WIXI_PARTNER_APP_SECRET ?? '',
};

export const initializeWixiSdk = (): void => {
  WixiCore.initialize({
    wixiPartner: wixiPartnerConfig,
  });
};
