/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import styled from '@emotion/styled';
import * as React from 'react';
import wand from '../../img/att/mx-question-answer-icons/wand.gif';

const SpinningWandContainer = styled.img`
  flex-grow: 0;
  flex-shrink: 0;
  height: calc(100vw / 2.5);
  min-height: 120px;
  max-height: 180px;
  width: auto;
  @media all and (max-width: 599px) {
    min-height: calc(100vw / 3);
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const SpinningWand = () => <SpinningWandContainer src={wand} />;
