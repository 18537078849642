import * as C from '../constants';
import { getQuickTips } from '../services';

export const fetchDataBegin = () => ({
  type: C.FETCH_QUICK_TIPS_BEGIN,
});

export const fetchDataSuccess = (data) => ({
  type: C.FETCH_QUICK_TIPS_SUCCESS,
  payload: {
    data,
  },
});

export const fetchDataFailure = (error) => ({
  type: C.FETCH_QUICK_TIPS_FAILURE,
  payload: { error },
});

export function fetchQuickTipsUsingAxios() {
  return (dispatch) => {
    dispatch(fetchDataBegin());
    getQuickTips()
      .then((response) => {
        dispatch(fetchDataSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchDataFailure(error));
      });
  };
}
