import React from 'react';

interface PowerProps {
    strokeColor?: string;
}

export const Power = ({ strokeColor = 'black' }: PowerProps = {}) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="power"
    >
        <path
            d="M15.9383 5.59082C19.8793 7.23163 22.1166 11.4239 21.2858 15.6113C20.455 19.7986 16.7866 22.8192 12.5177 22.8311C8.2487 22.843 4.56359 19.8429 3.70949 15.6602C2.85539 11.4775 5.06929 7.27285 9.00114 5.61013"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M12.4902 2.83105V11.7955" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
