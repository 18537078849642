import { useRxApi } from '@home-mgmt-shared/common-hooks';
import { HomeWifi, IntroPage, isMobile, isTablet, UserBrowser } from '@home-mgmt-shared/common-ui';
import { ProgressBar } from '@home-mgmt-shared/flow-ui';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import { getRecommendations, getRoomName, MultiPointScanResults, results$ } from '../apis';
import { MultiRoomDesktop, MultiRoomScanRecommendations, MultiRoomScanResults, Scans } from '../components';
import { MultiPointScanConfig } from '../models';

export enum MultiScanPageState {
    INTRO = 'intro',
    SCANS = 'scans',
    RESULTS = 'results',
    RECOMMENDATIONS = 'recommendations',
}

const pageToTitle: Record<MultiScanPageState, string> = {
    [MultiScanPageState.INTRO]: 'Multi Room Scan',
    [MultiScanPageState.SCANS]: 'Multi Room Scans Page',
    [MultiScanPageState.RESULTS]: 'Multi Room Scan - Results',
    [MultiScanPageState.RECOMMENDATIONS]: 'Multi Room Scan - Recommendations',
};

const usePageState = (defaultState: MultiScanPageState) => {
    const [currentState, setCurrentState] = useState<MultiScanPageState>(defaultState);
    const [prevState, setPrevState] = useState<MultiScanPageState>(defaultState);

    const setPageState = (newPageState: MultiScanPageState) => {
        setCurrentState((prevPageState) => {
            setPrevState(prevPageState);
            return newPageState;
        });
    };

    return { currentState, prevState, setPageState };
};

const appendQueryParamToUrl = (currentUrl: string, queryParamName: string, queryParamValue: string): string => {
    let newUrl = currentUrl;
    if (!(newUrl.indexOf(queryParamName) >= 0)) {
        if (!(newUrl.indexOf('?') >= 0)) {
            newUrl = `${newUrl}?${queryParamName}=${queryParamValue}`;
        } else {
            newUrl = `${newUrl}&${queryParamName}=${queryParamValue}`;
        }
    }

    return newUrl;
};

const getSetupUrl = (): string => {
    let setupUrl = window.location.href;
    setupUrl = appendQueryParamToUrl(setupUrl, 'userBrowserId', UserBrowser.Id);
    setupUrl = appendQueryParamToUrl(setupUrl, 'flow', 'desktopmobile');

    return setupUrl;
};

interface MultiRoomScanViewProps {
    config: MultiPointScanConfig;
    onPageChange?: (page: MultiScanPageState, scanInProgress: boolean) => void;
    backWasClicked: boolean;
    defaultExperienceRoute: string;
}

export const MultiRoomScanView = ({
    config,
    onPageChange,
    backWasClicked,
    defaultExperienceRoute,
}: MultiRoomScanViewProps) => {
    const { currentState, setPageState } = usePageState(MultiScanPageState.INTRO);
    const [back, setBack] = useState<boolean>(backWasClicked ?? false);
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [currentScanStep, setCurrentScanStep] = useState<number>(0);
    const [scanInProgress, setScanInProgress] = useState<boolean>(false);
    const [overrideMobileCheck, setOverrideMobileCheck] = useState<boolean>(false);
    const multiPointScanResults = useRxApi<MultiPointScanResults, unknown>(results$, {
        initialValue: {},
    });

    const setupUrl = getSetupUrl();
    const history = useHistory();
    const location = useLocation();

    const totalSteps = useCallback(() => {
        let steps = 0;
        config.points.forEach((point) => {
            const activities = point.flowConfig?.flow?.activities;
            steps += activities?.length ?? 0; // For Q&A flow
            steps += 2; // For Scan page
        });
        return steps;
    }, [config.points]);

    useEffect(() => {
        onPageChange?.(currentState, scanInProgress);
        window.scrollTo(0, 0);

        if (currentState === MultiScanPageState.INTRO) {
            setCurrentStep(1);
            setCurrentScanStep(0);
        }
        if (currentState === MultiScanPageState.RESULTS || currentState === MultiScanPageState.RECOMMENDATIONS) {
            setCurrentStep(totalSteps());
        }
        if (currentState === MultiScanPageState.SCANS) {
            setCurrentStep(currentScanStep);
        }
    }, [currentScanStep, currentState, onPageChange, scanInProgress, totalSteps]);

    const onBackClicked = () => {
        if (currentState === MultiScanPageState.RESULTS) {
            setPageState(MultiScanPageState.SCANS);
        }
        if (currentState === MultiScanPageState.RECOMMENDATIONS) {
            setPageState(MultiScanPageState.RESULTS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    useEffect(() => {
        if (backWasClicked !== back) {
            setBack(!back);
            onBackClicked();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backWasClicked]);

    const pageTitle = pageToTitle[currentState];

    const onAllScansComplete = useCallback(() => {
        setPageState(MultiScanPageState.RESULTS);
    }, [setPageState]);

    const onScanStateChange = (isScanInProgress: boolean) => {
        setScanInProgress(isScanInProgress);
    };

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>

            {currentState === MultiScanPageState.SCANS && <ProgressBar progress={currentStep / totalSteps()} />}

            {currentState === MultiScanPageState.INTRO && (isMobile() || isTablet() || overrideMobileCheck) && (
                <IntroPage
                    introImage={<HomeWifi />}
                    nextButtonOnClick={() => {
                        setPageState(MultiScanPageState.SCANS);
                    }}
                />
            )}

            {currentState === MultiScanPageState.INTRO && ((!isMobile() && !isTablet()) || overrideMobileCheck) && (
                <MultiRoomDesktop
                    onBasicSpeedtest={() => {
                        history.push(defaultExperienceRoute + location.search);
                        analytics.dispatch('NoMobileAccessButton', NSEventType.Click);
                    }}
                    onContinue={() => {
                        setOverrideMobileCheck(true);
                        setPageState(MultiScanPageState.SCANS);
                    }}
                    setupUrl={setupUrl}
                />
            )}

            {currentState === MultiScanPageState.SCANS && (
                <Scans
                    scanPoints={config.points}
                    onAllScansComplete={onAllScansComplete}
                    backWasClicked={backWasClicked}
                    onPreviousPageState={() => {
                        setPageState(MultiScanPageState.INTRO);
                        setCurrentStep(1);
                    }}
                    onStepChange={setCurrentScanStep}
                    isBackFromResults={currentScanStep !== 0}
                    onScanStateChange={onScanStateChange}
                />
            )}

            {currentState === MultiScanPageState.RESULTS && (
                <MultiRoomScanResults
                    onPageChange={() => {
                        setPageState(MultiScanPageState.RECOMMENDATIONS); // This should be RECOMMENDATIONS when we make that page
                    }}
                    routerSpeedResults={
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        multiPointScanResults?.result['Router Scan']?.speedResults ?? {
                            downloadSpeed: 0,
                        }
                    }
                    roomSpeedResults={
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        multiPointScanResults?.result['Trouble Room Scan']?.speedResults ?? {
                            downloadSpeed: 0,
                        }
                    }
                    roomName={`${getRoomName(multiPointScanResults?.result['Trouble Room Scan'])}`}
                    recommendations={getRecommendations(
                        multiPointScanResults?.result['Router Scan'],
                        multiPointScanResults?.result['Trouble Room Scan'],
                    )}
                />
            )}

            {currentState === MultiScanPageState.RECOMMENDATIONS && (
                <MultiRoomScanRecommendations
                    recommendations={getRecommendations(
                        multiPointScanResults?.result['Router Scan'],
                        multiPointScanResults?.result['Trouble Room Scan'],
                    )}
                    routerSpeedResults={
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        multiPointScanResults?.result['Router Scan']?.speedResults ?? {
                            downloadSpeed: 0,
                        }
                    }
                    roomSpeedResults={
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        multiPointScanResults?.result['Trouble Room Scan']?.speedResults ?? {
                            downloadSpeed: 0,
                        }
                    }
                />
            )}
        </>
    );
};
