import styled from '@emotion/styled';
import React, { ReactNode, useState } from 'react';
import { useTweekValue } from 'react-tweek';
import { MultiRoomDesktopIntro } from './MultiRoomDesktopIntro';
import { MultiRoomDesktopQRCode } from './MultiRoomDesktopQRCode';

const MultiRoomDesktopIntroContainer = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
`;

interface MultiRoomDesktopProps {
    header?: string | ReactNode;
    tagline?: string | ReactNode;
    buttonText?: string;
    setupUrl: string;
    onBasicSpeedtest: () => void;
    onContinue: () => void;
}

export const MultiRoomDesktop = ({
    header,
    tagline,
    buttonText,
    setupUrl,
    onBasicSpeedtest,
    onContinue,
}: MultiRoomDesktopProps) => {
    const [showShortLink, setShowShortLink] = useState(false);

    const showReminderLink: boolean = useTweekValue<boolean>('network_scan/multi_point/show_reminder_link', false);
    const [showRemindLink, setShowRemindLink] = useState(showReminderLink);

    const shortLink: string = useTweekValue<string>(
        'network_scan/multi_point/shortlink',
        `${window.location.host}${window.location.pathname}`,
    );

    return (
        <MultiRoomDesktopIntroContainer>
            {!showShortLink ? (
                <MultiRoomDesktopIntro
                    showRemindLink={showRemindLink}
                    setShowRemindLink={(newValue: boolean) => setShowRemindLink(newValue)}
                    setShowShortLink={(newValue: boolean) => setShowShortLink(newValue)}
                    onContinue={onContinue}
                />
            ) : (
                <MultiRoomDesktopQRCode
                    header={header}
                    tagline={tagline}
                    buttonText={buttonText}
                    setupUrl={setupUrl}
                    shortLink={shortLink}
                    onBasicSpeedtest={onBasicSpeedtest}
                />
            )}
        </MultiRoomDesktopIntroContainer>
    );
};
