import styled from '@emotion/styled';
import React from 'react';

interface QuestionProps {
    question: string;
    questionSubtext?: string;
}

const QuestionText = styled.h1`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1.5rem;
    font-weight: ${(props) => props.theme.components?.questionAnswer?.questionFontWeight || 'normal'};
    color: ${(props) => props.theme.button?.defaultTextColor};
    margin-bottom: 1.5rem;
`;

const QuestionSubtext = styled.div`
    margin-top: 0.5rem;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    color: ${(props) => props.theme.text?.subText};
`;

export const Question = ({ question, questionSubtext }: QuestionProps) => (
    <>
        <QuestionText>{question}</QuestionText>
        <QuestionSubtext>{questionSubtext}</QuestionSubtext>
    </>
);
