import { Bell, Cable, Calculator, CellSignal, Gear, Hierarchy, Power, RouterSignal, TripPins } from './icons';
import { Recommendation } from './utils';

// Upgrade your router (if it’s >4 years old)
// Consider upgrading your router (if it’s 3-4 years old)
// Get a signal extender (if home is anything other than >2500 sq feet)
// Get a mesh network (if home is >2500 sq feet)
// Try switching bands (if router IS NOT > 5 years old because those routers may not have dual band)
// Calculate your ideal speed (If “It’s always slow” OR “Slow at a consistent time of day” OR “Slow when several people are using it”)
// Hardwire devices to router
// Reposition your router
// Check for router updates
// Restart your router
// Upgrade internet plan

export const weakSignalRecommendations: Recommendation[] = [
    {
        icon: RouterSignal(),
        header: 'Upgrade your router',
        content: `Since your router is over 4 years old, upgrading it will likely solve many of your problems.

Wi-Fi and router technology is always getting better with big improvements happening every 3-4 years. A new router will likely provide better coverage, better speeds, and better security.

If you rent your router from your internet service provider (ISP), call them to see if you’re eligible for your upgrade.

If you’re buying a new one, look for one that supports Wi-Fi 5 or 6, and make sure it’s compatible with your ISP.`,
        id: 'upgradeRouter',
        tipFeedback: true,
    },
    {
        icon: RouterSignal(),
        header: 'Upgrade your router',
        content: `Since your router is 3-4 years old, you should consider upgrading it. That may solve many of your problems.

Wi-Fi and router technology is always getting better with big improvements happening every 3-4 years. A new router will likely provide better coverage, better speeds, and better security.

If you rent your router from your internet service provider (ISP), call them to see if you’re eligible for your upgrade.

If you’re buying a new one, look for one that supports Wi-Fi 5 or 6, and make sure it’s compatible with your ISP.`,
        id: 'considerUpgrade',
        tipFeedback: true,
    },
    {
        icon: CellSignal(),
        header: 'Try a Wi-Fi range extender',
        content: `Since you’re experiencing coverage issues, consider buying a Wi-Fi range extender.

Most extenders wirelessly connect to your router, then broadcast a new Wi-Fi signal into the hard-to-reach areas of your home.

Because the extender needs to “talk” to the router, the extender needs to be placed in an area where the Wi-Fi signal is still strong. Halfway in between your router and the dead zone is a generally a good bet.`,
        id: 'signalExtender',
        tipFeedback: true,
    },
    {
        icon: Hierarchy(),
        header: 'Try a mesh network',
        content: `Since your house is over 2500 square feet, consider investing in a mesh network.

A mesh network is a collection of router-like devices (access points) that you place throughout your home to blanket it in a single Wi-Fi network.

Mesh networks create a much larger coverage area than traditional single-router networks. They’re ideal if you live in a large home with multiple stories.
        `,
        id: 'meshNetwork',
        tipFeedback: true,
    },
    {
        icon: Gear(),
        header: 'Try switching bands',
        content: `Your router is new enough that it likely has dual-band technology. That means it broadcasts Wi-Fi signals on two frequency bands at the same time – 5 GHz and 2.4 GHz.

The 5 GHz band has faster speeds but a shorter range. Try to put devices that use a lot of data (TVs, laptops, etc.) on this band. 

The 2.4 GHz band has slower speeds but a longer range. Device that use less data (thermostats, smart speakers, etc.) or devices that are far away from your router will do better on 2.4 GHz.

To move a device to a different band, navigate to its Wi-Fi settings. You’ll likely see two versions of your Wi-Fi network name available. The one with “5” or “5G” on the end is the 5 GHz band and the other is the 2.4 GHz band.

As always, if you’re having trouble, give one of our Wi-Fi experts a call!`,
        id: 'switchBands',
        tipFeedback: true,
    },
    {
        icon: Cable(),
        header: 'Connect with a cable',
        content: `If you want to speed up your Wi-Fi, reduce the number of devices that connect wirelessly to your internet.

Use ethernet cables to connect nearby devices directly to your router.

Devices that use a lot of data — like laptops, smart TVs, and gaming consoles — are great candidates for wired connections. 

Wired connections are also significantly faster and more stable than wireless ones.
        `,
        id: 'hardwireDevices',
        tipFeedback: true,
    },
    {
        icon: TripPins(),
        header: 'Reposition your router',
        content: `To maximize your router’s coverage:
- Move it as close as possible to the center of your home.
- Place it out in the open. Cabinet or closet doors can dramatically dampen the signal.
- Don’t place it near thick walls or metal appliances, like refrigerators or microwaves. These can obstruct or interfere with the signal.
- Lift it off the ground. Routers cast signal in all directions, including downwards. Some materials that make up floors — like concrete – will block that downward signal.`,
        id: 'repositionRouter',
        tipFeedback: true,
    },
    {
        icon: Bell(),
        header: 'Check for router updates',
        content: `If you use a router from your internet service provider (ISP), you can skip this tip. Your ISP will automatically keep your router up-to-date.

If you bought your own router, you should occasionally go to the manufacturer’s website to check for available firmware updates. 

Manufacturers release firmware updates to fix security vulnerabilities and bugs. If your router isn’t behaving right, a firmware update may do the trick!`,
        id: 'updateRouter',
        tipFeedback: true,
    },
    {
        icon: Power(),
        header: 'Restart your router',
        content: `Restarting your router is a good short-term fix for many issues. 

Just like your computer, your router can occasionally “freeze”. Restarting it clears its memory and refreshes its connection with your internet service provider.

We recommend restarting your router every 30-60 days to keep things running smoothly.`,
        id: 'restartRouter',
        tipFeedback: true,
    },
];

export const lowBandwidthRecommendations: Recommendation[] = [
    {
        icon: RouterSignal(),
        header: 'Upgrade your router',
        content: `Since your router is over 4 years old, upgrading it will likely solve many of your problems.

Wi-Fi and router technology is always getting better with big improvements happening every 3-4 years. A new router will likely provide better coverage, better speeds, and better security.

If you rent your router from your internet service provider (ISP), call them to see if you’re eligible for your upgrade.

If you’re buying a new one, look for one that supports Wi-Fi 5 or 6, and make sure it’s compatible with your ISP.`,
        id: 'upgradeRouter',
        tipFeedback: true,
    },
    {
        icon: RouterSignal(),
        header: 'Upgrade your router',
        content: `Since your router is 3-4 years old, you should consider upgrading it. That may solve many of your problems.

Wi-Fi and router technology is always getting better with big improvements happening every 3-4 years. A new router will likely provide better coverage, better speeds, and better security.

If you rent your router from your internet service provider (ISP), call them to see if you’re eligible for your upgrade.

If you’re buying a new one, look for one that supports Wi-Fi 5 or 6, and make sure it’s compatible with your ISP.`,
        id: 'considerUpgrade',
        tipFeedback: true,
    },
    {
        icon: Calculator(),
        header: 'Calculate your ideal speed',
        content: `It’s possible that your household needs faster speeds than your current internet plan supports.

Use our speed calculator to estimate how much speed your household really needs.

In general, it may be time to upgrade your plan if your Wi-Fi...
- is always slow
- gets too slow when several people are using it

Upgrading your plan unlocks faster speeds and makes it possible for your home network to support more devices simultaneously. It’s like adding new lanes to a highway. `,
        id: 'idealSpeed',
        tipFeedback: true,
    },
    {
        icon: Cable(),
        header: 'Connect with a cable',
        content: `If you want to speed up your Wi-Fi, reduce the number of devices that connect wirelessly to your internet.

Use ethernet cables to connect nearby devices directly to your router.

Devices that use a lot of data — like laptops, smart TVs, and gaming consoles — are great candidates for wired connections. 

Wired connections are also significantly faster and more stable than wireless ones.
        `,
        id: 'hardwireDevices',
        tipFeedback: true,
    },
    {
        icon: Bell(),
        header: 'Check for router updates',
        content: `If you use a router from your internet service provider (ISP), you can skip this tip. Your ISP will automatically keep your router up-to-date.

If you bought your own router, you should occasionally go to the manufacturer’s website to check for available firmware updates. 

Manufacturers release firmware updates to fix security vulnerabilities and bugs. If your router isn’t behaving right, a firmware update may do the trick!`,
        id: 'updateRouter',
        tipFeedback: true,
    },
    {
        icon: Power(),
        header: 'Restart your router',
        content: `Restarting your router is a good short-term fix for many issues. 

Just like your computer, your router can occasionally “freeze”. Restarting it clears its memory and refreshes its connection with your internet service provider.

We recommend restarting your router every 30-60 days to keep things running smoothly.`,
        id: 'restartRouter',
        tipFeedback: true,
    },
];
