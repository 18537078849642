export const LOCAL_STORAGE_PROPERTY = 'ns_flow_data';

export type FlowData = {
    scanId: string;
    downloadSpeed: number;
    uploadSpeed: number;
    timestamp: string;
    idealSpeed?: number;
    results?: Record<string, unknown>;
};
