export const FETCH_HOMEGRAPHS_DATA_BEGIN = 'FETCH_HOMEGRAPHS_DATA_BEGIN';
export const FETCH_HOMEGRAPHS_DATA_SUCCESS = 'FETCH_HOMEGRAPHS_DATA_SUCCESS';
export const FETCH_HOMEGRAPHS_DATA_FAILURE = 'FETCH_HOMEGRAPHS_DATA_FAILURE';
export const FETCH_SESSION_DATA_FREE_USER = 'FETCH_SESSION_DATA_FREE_USER';
export const FETCH_SESSION_DATA_BEGIN = 'FETCH_SESSION_DATA_BEGIN';
export const FETCH_SESSION_DATA_SUCCESS = 'FETCH_SESSION_DATA_SUCCESS';
export const FETCH_SESSION_DATA_FAILURE = 'FETCH_SESSION_DATA_FAILURE';
export const DEFAULT_NO_OF_DAYS = 7;
// quicktips actions
export const FETCH_QUICK_TIPS_BEGIN = 'FETCH_QUICK_TIPS_BEGIN';
export const FETCH_QUICK_TIPS_SUCCESS = 'FETCH_QUICK_TIPS_SUCCESS';
export const FETCH_QUICK_TIPS_FAILURE = 'FETCH_QUICK_TIPS_FAILURE';
// Analytics events
export const USER_SCROLLED_TO_THE_BOTTOM =
  'User scrolled to the bottom of the page';
export const ATT_KEY = 'att';
export const PATH_VALUE_AS_FALSE = 'false';
export const callToActionNumber = '+1-877-229-5280';
export const PARTNER = 'att';
export const DOWNLOAD_HEADER = 'Download speed';
export const UPLOAD_HEADER = 'Upload speed';
export const UNITS_MBPS = 'Mbps';
export const NETWORK_CHECKUP_HEADER = 'Wi-Fi checkup results';
export const NETWORK_CHECKUP_SUBHEADER = 'Your speeds';
export const STREAMING_STATUS_HEADER = 'Your connection to popular sites';
export const STREAMING_SUBHEADER =
  'We checked the speed of your connection to these sites.';
export const EXPERT_PHONE_NUMBER = '1 (877)-229-5280';

// Session Storage Keys
export const SESSION_STORAGE_APPLICATION_ID_KEY = 'application-id';
export const NATIONAL_AVG_DEFAULT_SPEED = 35;
export const COMPARE_CTA_TEXT = 'How do I compare?';
export const COMPARE_HEADER_MSG =
  'The U.S. has the 8th fastest internet speed in the world.';

export const DEFAULT_STREAMING_GUIDELINES = [
  { capability: 'Email', downloadReq: 1, uploadReq: 0 },
  { capability: 'Browse the web', downloadReq: 3, uploadReq: 0 },
  { capability: 'Game online', downloadReq: 5, uploadReq: 0 },
  { capability: 'Video chat', downloadReq: 1, uploadReq: 1 },
  { capability: 'Video conference', downloadReq: 5, uploadReq: 2 },
  {
    capability: 'Stream HD video on a single device',
    downloadReq: 5,
    uploadReq: 0,
  },
  {
    capability: 'Stream HD video on multiple devices',
    downloadReq: 10,
    uploadReq: 0,
  },
  {
    capability: 'Stream 4k video on a single device',
    downloadReq: 25,
    uploadReq: 0,
  },
];
