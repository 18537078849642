import styled from '@emotion/styled';
import { getDefaultFeedbackMeta, shuffle } from '@home-mgmt-shared/common-services';
import {
    FeedbackComponent,
    FeedbackTagLists,
    GoldStar,
    GrayStar,
    IStreamingQualityGuideline,
} from '@home-mgmt-shared/common-ui';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import React, { useCallback } from 'react';
import { useTweekValue } from 'react-tweek';
import { BandwidthCalculatorStatus, IBandwidthCalculatorResults, ResultsConfig } from '../models';
import { BandwidthCalculatorResultsCompleteView } from './BandwidthCalculatorResultsCompleteView';
import { BandwidthCalculatorResultsIncompleteView } from './BandwidthCalculatorResultsIncompleteView';

const ResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
`;

const FeedbackComponentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: visible;
    max-width: 745px;
    width: 100vw;
    align-self: center;
    margin-top: 1rem;
`;

interface BandwidthCalculatorResultsViewProps {
    results: IBandwidthCalculatorResults;
    streamingGuidelines: IStreamingQualityGuideline[];
    skippedGuidelines: IStreamingQualityGuideline[];
    onSeeMoreRecomendations: () => void;
    onCheckSpeedsAgain: () => void;
    config?: ResultsConfig;
    scanId: string;
}

export const BandwidthCalculatorResultsView = ({
    results,
    streamingGuidelines,
    skippedGuidelines,
    onSeeMoreRecomendations,
    onCheckSpeedsAgain,
    config = { hideResultsHeaderMoodIcon: false, emphasizeResults: false },
    scanId,
}: BandwidthCalculatorResultsViewProps) => {
    const downloadSpeed = results.speedResults?.downloadSpeed ?? 0;
    const uploadSpeed = results.speedResults?.uploadSpeed ?? 0;
    const idealDownloadSpeed = results.idealSpeeds?.downloadSpeed ?? 0;
    const feedbackTagsUnsorted: FeedbackTagLists = useTweekValue(
        'network_scan/feedback_survey/bandwidth_survey',
        getDefaultFeedbackMeta(),
    );

    const feedbackTags: FeedbackTagLists = {
        maxScoreTagList: shuffle(feedbackTagsUnsorted.maxScoreTagList),
        lowScoreTagList: shuffle(feedbackTagsUnsorted.lowScoreTagList),
    };

    const feedbackCallback = useCallback((eventName: string, props: Record<string, unknown>) => {
        analytics.dispatch(eventName, NSEventType.Click, props);
    }, []);

    const onlyShowPartialResults =
        results.overallStatus === BandwidthCalculatorStatus.INCOMPLETE ||
        results.overallStatus === BandwidthCalculatorStatus.ISP_BLOCKED;

    return (
        <ResultsContainer>
            {onlyShowPartialResults && (
                <BandwidthCalculatorResultsIncompleteView
                    downloadSpeed={downloadSpeed}
                    uploadSpeed={uploadSpeed}
                    streamingGuidelines={skippedGuidelines}
                    onCheckSpeedsAgain={onCheckSpeedsAgain}
                    scanId={scanId}
                />
            )}
            {!onlyShowPartialResults && (
                <BandwidthCalculatorResultsCompleteView
                    downloadSpeed={downloadSpeed}
                    uploadSpeed={uploadSpeed}
                    idealDownloadSpeed={idealDownloadSpeed}
                    streamingGuidelines={streamingGuidelines}
                    onCheckSpeedsAgain={onCheckSpeedsAgain}
                    onSeeMoreRecomendations={onSeeMoreRecomendations}
                    results={results}
                    hideIcon={config.hideResultsHeaderMoodIcon}
                    emphasizeResult={config.emphasizeResults}
                    UpsellComponent={config?.upsellComponent}
                    scanId={scanId}
                />
            )}

            <FeedbackComponentContainer>
                <FeedbackComponent
                    starRatingInfo={{ iconFilled: GoldStar(), iconEmpty: GrayStar() }}
                    tagLists={feedbackTags}
                    analyticsCb={feedbackCallback}
                />
            </FeedbackComponentContainer>
        </ResultsContainer>
    );
};
