import React, { useCallback } from 'react';

interface IMGProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  onImgClick?: () => void;
  size?: number;
  width?: string;
  height?: string;
  enableClick?: boolean;
  src: string;
}
export const IMG = ({
  size,
  width,
  height,
  enableClick,
  src,
  onImgClick,
  ...rest
}: IMGProps) => {
  if (size) {
    width = `${1.5 * size}px`;
    height = `${1.5 * size}px`;
  }
  const onClick = useCallback(() => {
    if (enableClick) {
      onImgClick?.();
    }
  }, [enableClick, onImgClick]);

  return (
    <img src={src} onClick={onClick} width={width} height={height} {...rest} />
  );
};
