import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BrowserView, MobileView } from 'react-device-detect';
import { withAnalytics } from 'react-shisell';
import { formatPhoneNumber } from '../../../utils/utils';
import * as C from '../../../constants';
import { IMG } from '../../CommonComponents/IMG';
import banner1 from '../../../img/att/marketing/banner1.png';
import banner3 from '../../../img/att/marketing/banner3.png';
import banner4 from '../../../img/att/marketing/banner4.png';
import banner5 from '../../../img/att/marketing/banner5.png';
import BrowsingIcon from '../../../img/att/marketing/browsing.svg';
import GamingIcon from '../../../img/att/marketing/gaming.svg';
import VideoIcon from '../../../img/att/marketing/video.svg';
import StreamingIcon from '../../../img/att/marketing/streaming.svg';
import UltraIcon from '../../../img/att/marketing/ultra.svg';

function AttLogin({ obj, analytics }) {
  useEffect(() => {
    analytics.dispatcher
      .createScoped('StreamingSupport')
      .withExtra('context', (obj && obj.cid) || '')
      .withExtra('program', (obj && obj.program) || '')
      .withExtra('AccountIdFromQueryParameter', (obj && obj.accountId) || '')
      .withExtra('CarrierName', 'att')
      .dispatch('Streaming Support Marketing Page Rendered');
  }, []);

  function onCallToActionClick() {
    analytics.dispatcher
      .createScoped('StreamingSupport')
      .withExtra('context', (obj && obj.cid) || '')
      .withExtra('program', (obj && obj.program) || '')
      .withExtra('AccountIdFromQueryParameter', (obj && obj.accountId) || '')
      .withExtra('CarrierName', 'att')
      .dispatch('User Clicked on Call To Action button');
  }
  return (
    <div className="att-login">
      <div className="row section-1">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 order-lg-6">
          <div
            className="bg-img"
            style={{ backgroundImage: `url(${banner1})` }}
            aria-hidden="true"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 order-lg-1 text-holder">
          <div className="wrapper">
            <p className="heading">STREAMING SUPPORT</p>
            <p className="text1">Video streaming issues? We can help.</p>
            <p className="text2">
              Get set up, minimize buffering, and remove dead zones. Talk with a
              ProTech to get started.
            </p>
            <BrowserView>
              <p className="btn">
                Call {formatPhoneNumber(C.callToActionNumber)}
              </p>
            </BrowserView>
            <MobileView>
              <a
                className="btn call-to-action-phone-number"
                onClick={onCallToActionClick}
                href={`tel:${C.callToActionNumber}`}
              >
                Call {formatPhoneNumber(C.callToActionNumber)}
              </a>
            </MobileView>
            <p className="call-text">
              You can also contact a ProTech through the AT&T ProTech App
            </p>
          </div>
        </div>
      </div>

      <div className="row section-2">
        <div className="col">
          <div className="wrapper">
            <p className="text1">
              Optimize your internet performance for better experiences
            </p>

            <div className="quality-wrapper">
              <div className="holder" role="text" aria-label="Browsing">
                <div className="text-center" aria-hidden="true">
                  <div className="image-wrapper">
                    <BrowsingIcon />
                  </div>
                  <p className="text">Browsing</p>
                </div>
              </div>
              <div className="holder" role="text" aria-label="Gaming">
                <div className="text-center" aria-hidden="true">
                  <div className="image-wrapper">
                    <GamingIcon />
                  </div>
                  <p className="text">Gaming</p>
                </div>
              </div>
              <div className="holder" role="text" aria-label="Video Chat">
                <div className="text-center" aria-hidden="true">
                  <div className="image-wrapper">
                    <VideoIcon />
                  </div>
                  <p className="text">Video Chat</p>
                </div>
              </div>
              <div className="holder" role="text" aria-label="Streaming">
                <div className="text-center" aria-hidden="true">
                  <div className="image-wrapper">
                    <StreamingIcon />
                  </div>
                  <p className="text">Streaming</p>
                </div>
              </div>
              <div className="holder" role="text" aria-label="Ultra HD">
                <div className="text-center" aria-hidden="true">
                  <div className="image-wrapper">
                    <UltraIcon />
                  </div>
                  <p className="text">Ultra HD</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row section-3">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 img-wrapper">
          <div
            className="bg-img"
            style={{ backgroundImage: `url(${banner3})` }}
            aria-hidden="true"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 text-holder">
          <div className="wrapper">
            <p className="heading">OPTIMIZE VIEWING</p>
            <p className="text1">Get set up to stream</p>
            <p className="text2">
              We’ll get your AT&T and other streaming services set up on the
              streaming devices in your home—we can help you log in to your
              account, download apps, and customize your settings.
            </p>
          </div>
        </div>
      </div>

      <div className="row section-4">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 order-lg-6 img-wrapper">
          <IMG src={banner4} className="img-fluid" alt="" aria-hidden="true" />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 order-lg-1 text-holder">
          <div className="wrapper">
            <p className="heading">NETWORK ASSESSMENT</p>
            <p className="text1">Maximize streaming speed</p>
            <p className="text2">
              Your ProTech will evaluate what you need to optimize your
              streaming experience, including Wi-Fi performance and settings,
              and address any issues on the spot.
            </p>
          </div>
        </div>
      </div>

      <div className="row section-5">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
          <div
            className="bg-img"
            style={{ backgroundImage: `url(${banner5})` }}
            aria-hidden="true"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 text-holder">
          <div className="wrapper">
            <p className="heading">PROTECH SUPPORT</p>
            <p className="text1">Here to fix your streaming problems</p>
            <p className="text2">
              When you have issues streaming or playing your favorite shows,
              speak with a ProTech who can help.
            </p>
            <BrowserView>
              <p className="btn">
                Call {formatPhoneNumber(C.callToActionNumber)}
              </p>
            </BrowserView>
            <MobileView>
              <a
                className="btn call-to-action-phone-number"
                onClick={onCallToActionClick}
                href={`tel:${C.callToActionNumber}`}
              >
                Call {formatPhoneNumber(C.callToActionNumber)}
              </a>
            </MobileView>
            <p className="call-text">
              You can also contact a ProTech through the AT&T ProTech App
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

AttLogin.propTypes = {
  analytics: PropTypes.any,
  obj: PropTypes.object,
};

const attLogin = withAnalytics(AttLogin);
export default attLogin;
