import * as React from 'react';

export const LoadingSpinner = ({ fillColor = '#000' }) => (
    <svg
        className="prefix__lds-microsoft"
        viewBox="0 0 100 100"
        role="img"
        aria-label="loading spinner"
        preserveAspectRatio="xMidYMid"
        style={{
            backgroundImage: 'none',
            backgroundPosition: 'initial initial',
            backgroundRepeat: 'initial initial',
        }}
        width="100%"
        height="100%"
    >
        <g>
            <circle cx={73.801} cy={68.263} fill={fillColor} r={3}>
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="spline"
                    values="0 50 50;360 50 50"
                    keySplines="0.5 0 0.5 1"
                    repeatCount="indefinite"
                    dur="1.5s"
                    begin="0s"
                />
            </circle>
            <circle cx={68.263} cy={73.801} fill={fillColor} r={3}>
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="spline"
                    values="0 50 50;360 50 50"
                    keySplines="0.5 0 0.5 1"
                    repeatCount="indefinite"
                    dur="1.5s"
                    begin="-0.062s"
                />
            </circle>
            <circle cx={61.481} cy={77.716} fill={fillColor} r={3}>
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="spline"
                    values="0 50 50;360 50 50"
                    keySplines="0.5 0 0.5 1"
                    repeatCount="indefinite"
                    dur="1.5s"
                    begin="-0.125s"
                />
            </circle>
            <circle cx={53.916} cy={79.743} fill={fillColor} r={3}>
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="spline"
                    values="0 50 50;360 50 50"
                    keySplines="0.5 0 0.5 1"
                    repeatCount="indefinite"
                    dur="1.5s"
                    begin="-0.187s"
                />
            </circle>
            <circle cx={46.084} cy={79.743} fill={fillColor} r={3}>
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="spline"
                    values="0 50 50;360 50 50"
                    keySplines="0.5 0 0.5 1"
                    repeatCount="indefinite"
                    dur="1.5s"
                    begin="-0.25s"
                />
            </circle>
            <circle cx={38.519} cy={77.716} fill={fillColor} r={3}>
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="spline"
                    values="0 50 50;360 50 50"
                    keySplines="0.5 0 0.5 1"
                    repeatCount="indefinite"
                    dur="1.5s"
                    begin="-0.312s"
                />
            </circle>
            <circle cx={31.737} cy={73.801} fill={fillColor} r={3}>
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="spline"
                    values="0 50 50;360 50 50"
                    keySplines="0.5 0 0.5 1"
                    repeatCount="indefinite"
                    dur="1.5s"
                    begin="-0.375s"
                />
            </circle>
            <circle cx={26.199} cy={68.263} fill={fillColor} r={3}>
                <animateTransform
                    attributeName="transform"
                    type="rotate"
                    calcMode="spline"
                    values="0 50 50;360 50 50"
                    keySplines="0.5 0 0.5 1"
                    repeatCount="indefinite"
                    dur="1.5s"
                    begin="-0.437s"
                />
            </circle>
            <animateTransform
                attributeName="transform"
                type="rotate"
                calcMode="spline"
                values="0 50 50;0 50 50"
                keySplines="0.5 0 0.5 1"
                repeatCount="indefinite"
                dur="1.5s"
            />
        </g>
    </svg>
);
