import styled from '@emotion/styled';
import React, { useCallback, useState } from 'react';
import { Answer, AnswerOption } from '../models';
import { AnswerListButton } from './AnswerListButton';

type BorderProps = {
    selected?: boolean;
};

const InputBox = styled.input<BorderProps>`
    display: flex;
    background: none;
    border-radius: 8px;
    color: ${(props) => (props.selected ? props.theme.button?.selectedColor : props.theme.button?.defaultTextColor)};
    padding: 1.2rem 1.1rem;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    margin-top: 15px;
    border: 2px solid ${(props) => (props.selected ? props.theme.button?.selectedColor : '#E6E6EB')};
    min-height: 1.625rem;
    width: calc(100% - 2.2rem - 4px);
    align-items: center;
    transition: transform 0.2s ease-in;
    user-select: text;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    input&:active {
        color: ${(props) => props.theme.button?.selectedColor};
        transform: scale(0.96);
    }
`;

const Directions = styled.div`
    color: #6e767d;
    font-size: 0.875rem;
    padding-left: 1rem;
    margin-top: 0.5rem;
`;

interface InputOnClickProps {
    option: AnswerOption;
    isSelected: boolean;
    onAnswerSelect: (answer: Answer) => void;
    onInputChange: (answer: Answer) => void;
    activityId: string;
}

interface InputOnClickMeta {
    placeholder?: string;
    directions?: string;
}

export const InputOnClick = ({ option, isSelected, onAnswerSelect, activityId, onInputChange }: InputOnClickProps) => {
    const [inputValue, setInputValue] = useState('');
    const onAnswerListButtonSelected = useCallback(
        (selectedAnswer: Answer) => {
            // TODO: Remove this
            if (isSelected) {
                setInputValue('');
                const newAnswer = { option: selectedAnswer.option };

                onAnswerSelect(newAnswer);

                return;
            }
            setInputValue('');

            onAnswerSelect(selectedAnswer);
        },
        [isSelected, onAnswerSelect],
    );

    const inputChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const text = event.target.value;
            setInputValue(text);
            const newAnswer = { option, responseMeta: { text } };
            onInputChange(newAnswer);
        },
        [onInputChange, option],
    );

    const meta = (option.componentMeta ?? {}) as InputOnClickMeta;

    return (
        <>
            <AnswerListButton
                key={option.text}
                option={option}
                selected={isSelected}
                onAnswerSelect={onAnswerListButtonSelected}
                activityId={activityId}
            />

            {isSelected && (
                <div>
                    <InputBox
                        id={`input-button-${option.text ?? ''}`}
                        name={option.text}
                        type="text"
                        onChange={inputChange}
                        value={inputValue}
                        placeholder={meta.placeholder}
                        maxLength={20}
                    />
                    {meta.directions && <Directions>{meta.directions}</Directions>}
                </div>
            )}
        </>
    );
};
