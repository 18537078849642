import React from 'react';
import { useTweekValue } from 'react-tweek';
import { CTABlock, ICTAData } from '@home-mgmt-shared/common-ui';
import { clickEvent } from '../../utils/analytics/utils';
import { SESSION_STORAGE_APPLICATION_ID_KEY } from '../../constants';

const DEFAULT_WEBSCAN_DATA: ICTAData = {
  title: '',
  description: '',
  buttonText: '',
  link: '',
};

const urlWithAppendedApplicationId = (
  url: string,
  applicationId: string
): string => {
  if (url.includes('?')) {
    return `${url}&applicationId=${applicationId}`;
  }
  return `${url}?applicationId=${applicationId}`;
};

const WebscanCTA = () => {
  const webscanTweekData = useTweekValue(
    'network_scan/att_dashboard/webscan_cta',
    DEFAULT_WEBSCAN_DATA
  );

  const applicationId: string =
    sessionStorage.getItem(SESSION_STORAGE_APPLICATION_ID_KEY) ?? '';

  return (
    <CTABlock
      title={webscanTweekData.title}
      description={webscanTweekData.description}
      buttonText={webscanTweekData.buttonText}
      link={urlWithAppendedApplicationId(webscanTweekData.link, applicationId)}
      withExpertImage={!!webscanTweekData.withExpertImage}
      analytics={webscanTweekData.analytics}
      analyticsCb={clickEvent}
    />
  );
};

export default WebscanCTA;
