import styled from '@emotion/styled';
import React from 'react';
import { BaseButton, IBaseButtonProps } from './BaseButton';

interface LinkButtonProps extends IBaseButtonProps {
    onClick?: () => void;
    disabled?: boolean;
    underlined?: boolean;
}

type UnderlineProps = {
    underlined?: string;
};

const Button = styled(BaseButton)<UnderlineProps>`
    background: none;
    border-radius: 25px;
    color: ${(props) => props.theme?.components?.linkColor || props.theme?.button?.selectedColor};
    padding: 0.9375rem;
    font-family: ${(props) => props.theme?.font?.type};
    font-size: 1rem;
    text-decoration: ${(props) => (props.underlined ? 'underline' : 'none')};
    margin-top: 15px;
    min-height: 46px;
    width: 100%;
    border: none;
    transition: transform 0.2s ease-in;
    user-select: none;
    font-weight: ${(props) => props.theme?.components?.button?.fontWeight || 'normal'};

    button&:active {
        transform: scale(0.96);
    }
`;

export const LinkButton = ({
    onClick,
    children,
    analyticEventName,
    disabled,
    underlined,
    analyticsExtras,
    ...props
}: LinkButtonProps) => (
    <Button
        onClick={onClick}
        analyticsExtras={analyticsExtras}
        analyticEventName={analyticEventName}
        disabled={disabled || false}
        underlined={underlined?.toString()}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        {children}
    </Button>
);
