import styled from '@emotion/styled';
import { MultiPointEvents, usePageAnalytics } from '@home-mgmt-shared/analytics';
import QRCode from 'qrcode.react';
import React, { ReactNode } from 'react';

const MultiRoomDesktopIntroContent = styled.div`
    max-width: 42rem;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const IntroHeader = styled.h1`
    width: 100%;
    font-size: 1.5rem;
    line-height: 135%;
    margin-bottom: 1rem;
`;

const IntroRow = styled.div`
    width: 100%;
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ul {
        padding-left: 1rem;
        margin-bottom: 0;
    }

    li {
        margin-top: 0.35rem;
        line-height: 125%;
    }

    @media all and (max-width: 800px) {
        flex-direction: column;
    }
`;

const IntroRowItem = styled.div`
    align-self: flex-start;
    width: 280px;
    border-radius: 8px;
    border: 1px solid #f0f0f5;
    padding: 1.5rem;
    word-break: break-word;
`;

const QRCodeContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
`;

const IntroRowHeader = styled.div`
    font-size: 1rem;
    font-weight: bold;
`;

const IntroTaglineLink = styled.div`
    font-size: 1rem;
    line-height: 150%;
    margin: 0.5rem 0;
    text-decoration: underline;
    text-align: left;
    align-self: flex-start;
    color: #000;
    cursor: pointer;
`;

const IntroTagline = styled.div`
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 0.5rem;
    text-align: left;
    align-self: flex-start;
`;

const CircleSeparator = styled.div`
    font-size: 1rem;
    font-weight: bold;
    padding: 1.35rem 0.75rem;
    margin: auto 0.75rem;
    background: #f0f0f5;
    border-radius: 9999px;
    white-space: nowrap;
`;

const LinkContainer = styled.div`
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 150%;
    padding: 0.5rem;
    background: #f0f0f5;
    margin-top: 1.25rem;
    margin-bottom: 3.5rem;
    border-radius: 8px;
    text-align: center;
`;

interface MultiRoomDesktopQRCodeProps {
    header?: string | ReactNode;
    tagline?: string | ReactNode;
    buttonText?: string;
    setupUrl: string;
    shortLink: string;
    onBasicSpeedtest: () => void;
}

export const MultiRoomDesktopQRCode = ({
    header,
    tagline,
    buttonText,
    setupUrl,
    shortLink,
    onBasicSpeedtest,
}: MultiRoomDesktopQRCodeProps) => {
    usePageAnalytics(MultiPointEvents.SwitchToMobilePage);
    return (
        <MultiRoomDesktopIntroContent>
            <IntroHeader>{header ?? 'Options for switching to a mobile device'}</IntroHeader>
            <IntroRow>
                <IntroRowItem>
                    <QRCodeContainer>
                        <QRCode value={setupUrl} size={98} aria-label="QR code" role="img" />
                    </QRCodeContainer>
                    <IntroRowHeader>Use this QR code</IntroRowHeader>
                    <ol>
                        <li>Open the camera app on your phone or tablet</li>
                        <li>Hover the camera over the above square</li>
                        <li>Tap the notification that appears</li>
                    </ol>
                </IntroRowItem>
                {shortLink.length > 0 && (
                    <>
                        <CircleSeparator>-OR-</CircleSeparator>
                        <IntroRowItem>
                            <LinkContainer>{shortLink}</LinkContainer>
                            <IntroRowHeader>Visit this link on a mobile device</IntroRowHeader>
                            <ol>
                                <li>Open the browser on your phone, tablet, or laptop</li>
                                <li>Type this link into the address bar</li>
                                <li style={{ color: 'white' }}>{'\n\n'}</li>
                            </ol>
                        </IntroRowItem>{' '}
                    </>
                )}
            </IntroRow>
            <IntroTaglineLink onClick={onBasicSpeedtest}>
                {tagline ?? "Don't have access to your phone?"}
            </IntroTaglineLink>
            <IntroTagline>{buttonText ?? 'You can use our basic speed test tool.'}</IntroTagline>
        </MultiRoomDesktopIntroContent>
    );
};
