import React, { useCallback, useEffect, useState } from 'react';
import deepEqual from 'deep-equal';
import { Answer, BaseAnswerProps } from '../models';
import { AnswerListButton } from './AnswerListButton';
import { InputOnClick } from './InputOnClick';

type AnwserListProps = BaseAnswerProps;

export const AnswerList = ({ answerOptions, onAnswerSelection, initialState, activityId }: AnwserListProps) => {
    const [selectedAnswer, setSelectedAnswer] = useState<Answer | undefined>(undefined);

    useEffect(() => {
        if (initialState?.length) {
            setSelectedAnswer(initialState[0]);
        }
    }, [initialState]);

    const onSelection = useCallback(
        (answer: Answer) => {
            let updatedSelections: Answer[] = [];

            if (deepEqual({ option: selectedAnswer?.option }, { option: answer?.option })) {
                updatedSelections = [];
                setSelectedAnswer(undefined);
            } else {
                updatedSelections = [answer];
                setSelectedAnswer(answer);
            }

            if (answer.option.componentName !== 'InputOnClick') {
                onAnswerSelection(updatedSelections);
            } else {
                onAnswerSelection([]);
            }
        },
        [onAnswerSelection, selectedAnswer],
    );

    const onInputChange = useCallback(
        (answer: Answer) => {
            let updatedSelections: Answer[] = [];

            if (deepEqual(selectedAnswer, answer)) {
                updatedSelections = [];
                setSelectedAnswer(undefined);
            } else {
                updatedSelections = [answer];
                setSelectedAnswer(answer);
            }

            if ((answer?.responseMeta as Record<string, string>).text.length > 0) {
                onAnswerSelection(updatedSelections);
            } else {
                onAnswerSelection([]);
            }
        },
        [onAnswerSelection, selectedAnswer],
    );

    return (
        <div>
            {answerOptions.map((option) => {
                const isSelected = selectedAnswer?.option.text === option.text;

                if (option.componentName === 'InputOnClick') {
                    return (
                        <InputOnClick
                            key={option.text}
                            isSelected={isSelected}
                            option={option}
                            activityId={activityId}
                            onAnswerSelect={onSelection}
                            onInputChange={onInputChange}
                        />
                    );
                }

                return (
                    <AnswerListButton
                        data-test-cy={`question-answer-response-${option.text ?? 'undef'}`}
                        key={option.text}
                        option={option}
                        selected={isSelected}
                        onAnswerSelect={onSelection}
                        activityId={activityId}
                    />
                );
            })}
        </div>
    );
};
