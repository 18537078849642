import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* eslint-disable*/
import QuickTips from '@soluto-asurion/one-service-quick-tips-react';
/* eslint-enable */
import Spinner from '../../CommonComponents/Spinner';
import NoData from './QuickTipsNoData';

function AttQuickTips({
  quickTipsData,
  quickTipsLoading,
  quickTipsError,
  quickTipsTitle,
}) {
  const tips =
    quickTipsData && quickTipsData.data && quickTipsData.data.body
      ? quickTipsData.data.body
      : [];
  const quickTips = {
    title: quickTipsTitle || 'ProTech recommendations',
    tips,
  };
  if (quickTipsLoading) {
    return <Spinner text="Loading..." />;
  }
  if (quickTipsError) {
    return <NoData />;
  }
  return (
    <div className="quickTips">
      {quickTips &&
        quickTips.tips &&
        Object.entries(quickTips.tips).length > 0 && (
          <QuickTips quickTips={quickTips} />
        )}
    </div>
  );
}

AttQuickTips.propTypes = {
  quickTipsData: PropTypes.object,
  quickTipsLoading: PropTypes.any,
  quickTipsError: PropTypes.bool,
  quickTipsTitle: PropTypes.string,
};
const mapStateToProps = (state) => ({
  quickTipsData: state.quickTips.items,
  quickTipsLoading: state.quickTips.loading,
  quickTipsError: state.quickTips.error,
});

export default connect(mapStateToProps)(AttQuickTips);
