import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import homeGraphs from './HomeGraphReducer';
import sessionData from './SessionDataReducer';
import quickTips from './QuickTipsReducer';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    homeGraphs,
    sessionData,
    quickTips,
  });
