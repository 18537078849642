import * as React from 'react';

export const VideoCalls = () => (
  <svg
    width="73"
    height="45"
    viewBox="0 0 73 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Video calling"
    role="img"
  >
    <path
      d="M43.3319 26.1259C43.3319 27.9885 40.4137 29.4166 36.6264 29.4166C34.8879 29.4787 33.1494 29.044 31.5972 28.299C31.473 28.2369 31.411 28.0505 31.5351 27.9264C31.5972 27.8022 31.7835 27.7402 31.8456 27.8644C33.3357 28.6094 35.0121 28.9819 36.6884 28.9198C40.1033 28.9198 42.9593 27.678 42.9593 26.0637C42.9593 25.9395 42.9593 25.8155 42.9593 25.6913V25.505L42.8352 24.76C42.5868 23.2078 42.1522 21.6555 41.5934 20.1653C41.2209 19.3582 40.1654 18.7374 38.7374 18.427C38.6753 18.427 38.5511 18.3028 38.5511 18.2407C38.5511 18.1165 38.6132 18.0544 38.6753 17.9923H38.7374C39.4203 17.5576 39.917 16.8748 40.1033 16.0676C40.1654 15.7572 40.2275 15.4467 40.2275 15.1363C40.2275 13.2737 38.6753 11.7215 36.8126 11.7215C34.95 11.7215 33.3978 13.2737 33.3978 15.1363C33.3978 15.4467 33.4599 15.7572 33.5219 16.0676C33.7703 16.8127 34.2049 17.4957 34.8879 17.9303H34.95C35.0742 17.9924 35.0742 18.1785 35.0121 18.3027C35.0121 18.3648 34.95 18.3649 34.8879 18.3649H34.7016C33.2736 18.6753 32.2181 19.3583 31.8456 20.1034C31.2868 21.5935 30.8522 23.0836 30.6038 24.6358C30.5417 24.76 30.3555 24.7599 30.2313 24.6978C30.1692 24.6978 30.1692 24.6358 30.1692 24.5737C30.4175 22.9594 30.8522 21.4072 31.473 19.9171C31.8456 19.1099 32.839 18.4269 34.2049 18.0544C33.6461 17.5577 33.2115 16.9368 32.9632 16.1918C32.839 15.8813 32.7769 15.5088 32.7769 15.1363C32.7769 12.9632 34.5154 11.2869 36.6264 11.2869C38.7374 11.2869 40.4758 13.0253 40.4758 15.1363C40.4758 15.5088 40.4137 15.8192 40.3516 16.1918C40.1654 16.9368 39.7308 17.6199 39.1099 18.1166C40.4758 18.4891 41.4692 19.172 41.8418 20.0412C42.4626 21.5313 42.8973 23.1457 43.1456 24.76L43.2698 25.505V25.6913C43.3319 25.8155 43.3319 26.0017 43.3319 26.1259Z"
      fill="#009FDB"
      stroke="#009FDB"
      strokeWidth="0.75"
    />
    <path
      d="M40.913 40.9579H32.5884C32.1127 40.72 31.8744 40.0065 32.1123 39.7687C32.1123 39.5308 32.3506 39.5309 32.5884 39.2931H40.913C41.3887 39.2931 41.8642 39.7688 41.8642 40.2445C41.8642 40.7202 41.3887 41.1957 40.913 41.1957V40.9579ZM72.546 39.2931V40.4823C72.546 42.3851 70.8811 44.05 68.9783 44.05H4.52252C2.61975 44.05 0.954834 42.3851 0.954834 40.4823V39.2931C0.954834 37.3903 2.61975 35.7254 4.52252 35.7254H9.99308V6.70806C9.99308 3.61607 12.6092 1 15.7011 1H57.7997C60.8917 1 63.5083 3.61607 63.5083 6.70806V35.7254H68.9783C70.8811 35.7254 72.546 37.3903 72.546 39.2931ZM11.8954 35.7254H62.081V6.70806C62.081 4.56745 60.1784 2.6648 58.0378 2.6648H15.9392C13.7986 2.6648 11.8954 4.56745 11.8954 6.70806V35.7254ZM70.8812 39.2931C70.8812 38.3417 70.1678 37.628 69.2164 37.628H4.76001C3.80863 37.628 3.09521 38.3417 3.09521 39.2931V40.4823C3.09521 41.4337 3.80863 42.1471 4.76001 42.1471H69.2164C70.1678 42.1471 70.8812 41.4337 70.8812 40.4823V39.2931ZM58.2753 31.6818V8.61094C58.2753 7.18387 57.0864 5.9947 55.6593 5.9947H18.0796C16.6525 5.9947 15.4631 7.18387 15.4631 8.61094V22.4061C15.4631 22.8818 15.9391 23.3573 16.4148 23.3573C16.8905 23.3573 17.3659 22.8818 17.3659 22.4061V8.61094C17.3659 8.13525 17.6039 7.89729 18.0796 7.89729H55.6593C56.135 7.89729 56.373 8.13525 56.373 8.61094V31.6818C56.373 32.1575 56.135 32.3955 55.6593 32.3955H18.0796C17.6039 32.3955 17.1284 32.8712 17.1284 33.3469C17.1284 33.8226 17.6039 34.2984 18.0796 34.2984H55.6593C57.0864 34.2984 58.2753 33.1089 58.2753 31.6818Z"
      fill="#009FDB"
      stroke="white"
      strokeWidth="0.5"
    />
  </svg>
);
