import React from 'react';
import styled from '@emotion/styled';

const ProgressBarContainer = styled.div`
    width: 100vw;
    height: 8px;
    background-color: ${(props) => props.theme.progressBar?.backgroundColor ?? '#F0F0F5'};
`;

type ProgressBarFillProps = {
    progress: number;
};

const ProgressBarFill = styled.div<ProgressBarFillProps>`
    width: ${(props) => props.progress}%;
    height: ${(props) => props.theme.progressBar?.height ?? '8px'};
    background: ${(props) => props.theme.progressBar?.primaryColor ?? '#8223d2'};
    transition: width 0.2s ease-in-out;
`;

interface ProgressBarProps {
    progress: number;
}

export const ProgressBar = ({ progress }: ProgressBarProps) => {
    const progressPercentage = Math.max(0.01, Math.min(1, progress)) * 100;
    return (
        <ProgressBarContainer>
            <ProgressBarFill progress={progressPercentage} />
        </ProgressBarContainer>
    );
};
