import styled from '@emotion/styled';
import React from 'react';
import { BaseButton } from './BaseButton';

type IClickableButtonProps = {
    label: string;
    onClick?: () => void;
    analyticEventName?: string;
};

const Button = styled(BaseButton)`
    background: #000000;
    border-radius: 25px;
    color: white;
    font-family: ${(props) => props.theme?.font?.type};
    font-size: 16px;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease-in;
    user-select: none;
    cursor: pointer;

    button&:focus {
        outline: none;
    }

    button&:active {
        transform: scale(0.96);
    }
`;

export const ClickableButton = ({ onClick, label, analyticEventName }: IClickableButtonProps) => (
    <Button onClick={onClick} analyticEventName={analyticEventName}>
        {label}
    </Button>
);
