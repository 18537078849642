import React from 'react';
import styled from '@emotion/styled';

type SpeedResultsHeaderProps = {
    hideIcon?: boolean;
    speedMoodIcon: JSX.Element;
    headerText: string;
    emphasizeResult?: boolean;
    speedColor?: string;
};

const SpeedResultHeaderContainer = styled.div<Pick<SpeedResultsHeaderProps, 'emphasizeResult' | 'speedColor'>>`
    font-family: ${(props) => props.theme.font?.type};
    font-weight: ${(props) => props.theme.components?.resultsPage?.header?.fontWeight || 'normal'};
    font-size: 1.5rem;
    text-align: left;
    line-height: 150%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    margin-bottom: 1rem;

    ${(props) =>
        props.emphasizeResult &&
        `
    .emphasize {
        border-bottom: 3px solid ${props.speedColor ?? '#000'};        
        display: inline-block;
        line-height: 2rem;
    }
    `}
`;

const IconContainer = styled.div`
    height: auto;
    max-width: 4rem;
    padding-top: 0.5rem;
    margin-right: 0.25rem;
`;

export const SpeedResultsHeader = ({
    hideIcon,
    speedMoodIcon,
    headerText,
    emphasizeResult = false,
    speedColor,
}: SpeedResultsHeaderProps) => (
    <SpeedResultHeaderContainer
        speedColor={speedColor}
        emphasizeResult={emphasizeResult}
        data-test-cy="speed-results-header"
    >
        {!hideIcon && <IconContainer>{speedMoodIcon}</IconContainer>}
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: headerText }} />
    </SpeedResultHeaderContainer>
);
