import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { usePageAnalytics } from '@home-mgmt-shared/analytics';
import {
  defaultStreamingGuidelines,
  getDefaultFeedbackTags,
  parseSiteConnectivity,
  shuffle,
} from '@home-mgmt-shared/common-services';
import {
  DownArrow,
  FeedbackComponent,
  FeedbackTagLists,
  GoldStar,
  GrayStar,
  IStreamingQualityGuideline,
  LoadingSpinner,
  SpeedCheckup,
  SpeedDisplay,
  StreamingConnectivity,
  UpArrow,
} from '@home-mgmt-shared/common-ui';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import React, { useEffect } from 'react';
import { useTweekValue } from 'react-tweek';
import WebscanCTA from '../components/ATT/WebscanCTA';
import {
  DOWNLOAD_HEADER,
  NETWORK_CHECKUP_HEADER,
  NETWORK_CHECKUP_SUBHEADER,
  STREAMING_STATUS_HEADER,
  STREAMING_SUBHEADER,
  UNITS_MBPS,
  UPLOAD_HEADER,
} from '../constants';
import { clickEvent } from '../utils/analytics/utils';
import { formatSpeed } from '../utils/utils';

type ScanResultsProps = {
  homeData: any;
};
const Card = styled.div`
  margin-top: 15px;
  margin-bottom: 0px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  width: 100%;
`;
const Title = styled.h1<Theme>`
  font-size: 1.125rem;
  font-family: ${(props) => props.theme?.font?.type};
  text-align: left;
  margin-bottom: 20px;
  text-transform: none;
  font-weight: bold;
`;
const Panel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  background-color: #f7f7fa;
  padding: 20px;
  width: 100%;
  border-radius: 4px;
`;
const HomePageTitle = styled.h1<Theme>`
  font-size: 1.5rem;
  font-family: ${(props) => props.theme?.font?.type};
  text-align: center;
  margin-bottom: 30px;
  margin-top: 0px;
  text-transform: none;
`;
const SpeedDisplayCase = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 0px;
  justify-content: space-between;
`;
const SpeedDisplayDownload = styled.div`
  display: flex;
  align-items: center;
`;
const SpeedDisplayUpload = styled.div`
  display: flex;
  margin-left: 5px;
  align-items: center;
  align-self: center;
`;
const PageContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  display: inline-flex;
  max-width: 500px;
  margin-bottom: 100px;
`;
const CheckupSubHeading = styled.h1<Theme>`
  font-family: ${(props) => props.theme?.font?.type};
  font-size: 1.25rem;
  text-transform: none;
`;
const SubHeading = styled.p<Theme>`
  font-family: ${(props) => props.theme?.font?.type};
  font-size: 1rem;
`;

/**
 * We are measuring how many sessions load with invalid upload / download data.
 * Send up an analytics event of type Home_Network_Event with property speedTestWorked
 */
const sendSpeedQualityAnalyticsEvent = (homeData: any) => {
  const speedTestWorked =
    !isNaN(parseInt(homeData?.internetHealth?.downloadThroughput, 10)) &&
    !isNaN(parseInt(homeData?.internetHealth?.uploadThroughput, 10));
  analytics.dispatch('SpeedResults_Loaded', NSEventType.Event, {
    speedTestWorked,
  });
};

export const AppScanResultsPage = ({ homeData }: ScanResultsProps) => {
  usePageAnalytics('App Scan Results Page');
  useEffect(() => {
    sendSpeedQualityAnalyticsEvent(homeData);
  }, [homeData]);

  const theme = useTheme();
  const feedbackTagsUnsorted: FeedbackTagLists = useTweekValue(
    'network_scan/feedback_survey/feedback_tags',
    getDefaultFeedbackTags()
  );

  const feedbackTags: FeedbackTagLists = {
    maxScoreTagList: shuffle(feedbackTagsUnsorted.maxScoreTagList),
    lowScoreTagList: shuffle(feedbackTagsUnsorted.lowScoreTagList),
  };

  const streamingGuidelines: IStreamingQualityGuideline[] = useTweekValue(
    'network_scan/capabilities/streaming_capabilities',
    defaultStreamingGuidelines
  );

  return (
    <PageContainer>
      <Card>
        <HomePageTitle data-test-cy="scan-results-title">
          {NETWORK_CHECKUP_HEADER}
        </HomePageTitle>
        <CheckupSubHeading>{NETWORK_CHECKUP_SUBHEADER}</CheckupSubHeading>
        {homeData && homeData.internetHealth && (
          <SpeedDisplayCase>
            <SpeedDisplayDownload>
              <SpeedDisplay
                label={DOWNLOAD_HEADER}
                icon={<DownArrow fillColor={theme.special?.secondaryColor} />}
                speed={formatSpeed(homeData.internetHealth.downloadThroughput)}
                units={UNITS_MBPS}
                isLoading={
                  homeData.internetHealth.downloadThroughput === 'NO_VALUE'
                }
                loadingIndicator={LoadingSpinner({
                  fillColor: theme.spinner?.primaryColor,
                })}
              />
            </SpeedDisplayDownload>
            <SpeedDisplayUpload>
              <SpeedDisplay
                label={UPLOAD_HEADER}
                icon={<UpArrow fillColor={theme.special?.secondaryColor} />}
                speed={formatSpeed(homeData.internetHealth.uploadThroughput)}
                units={UNITS_MBPS}
                isLoading={
                  homeData.internetHealth.uploadThroughput === 'NO_VALUE'
                }
                loadingIndicator={LoadingSpinner({
                  fillColor: theme.spinner?.primaryColor,
                })}
              />
            </SpeedDisplayUpload>
          </SpeedDisplayCase>
        )}
        {homeData && homeData.internetHealth && (
          <SpeedCheckup
            downloadSpeed={homeData.internetHealth.downloadThroughput}
            uploadSpeed={homeData.internetHealth.uploadThroughput}
            streamQualityGuidelines={streamingGuidelines}
            isLoading={homeData.internetHealth.uploadThroughput === 'NO_VALUE'}
          />
        )}
      </Card>
      <Panel>
        <WebscanCTA />
      </Panel>
      {homeData && homeData.siteScores && (
        <Card>
          <Title>{STREAMING_STATUS_HEADER}</Title>
          <SubHeading>{STREAMING_SUBHEADER}</SubHeading>
          <StreamingConnectivity
            sites={parseSiteConnectivity(homeData.siteScores)}
            analyticsCb={clickEvent}
          />
        </Card>
      )}
      <Panel>
        <FeedbackComponent
          starRatingInfo={{ iconFilled: GoldStar(), iconEmpty: GrayStar() }}
          tagLists={feedbackTags}
          noBackground={true}
          analyticsCb={clickEvent}
        />
      </Panel>
    </PageContainer>
  );
};
