import { analytics as NetScanAnalytics } from '@soluto-private/ns-analytics';
import * as C from '../constants';
import { getSessionIdsPerClient } from '../services';
import { fetchHomeGraphsDataUsingAxios } from './homeGraphActions';

export const fetchDataBegin = (shouldDisplayLoadingIndicator) => ({
  type: C.FETCH_SESSION_DATA_BEGIN,
  shouldDisplayLoadingIndicator,
});

export const fetchDataSuccess = (data) => ({
  type: C.FETCH_SESSION_DATA_SUCCESS,
  payload: {
    data,
  },
});

export const fetchDataFailure = (error) => ({
  type: C.FETCH_SESSION_DATA_FAILURE,
  payload: { error },
});

export const fetchDataFreeUserFailure = (error) => ({
  type: C.FETCH_SESSION_DATA_FREE_USER,
  payload: { error },
});

export function fetchSessionDataUsingAxios(
  applicationsAry = [],
  shouldDisplayLoadingIndicator = true
) {
  return (dispatch) => {
    dispatch(fetchDataBegin(shouldDisplayLoadingIndicator));
    getSessionIdsPerClient(applicationsAry)
      .then((response) => {
        if (
          response &&
          response.hasOwnProperty('data') &&
          response.data.hasOwnProperty('sessions') &&
          response.data.sessions.length > 0
        ) {
          const homegraphProps = {
            sessionId: response.data.sessions[0].session_id,
            path: response.data.sessions[0].path,
            applicationsArray: applicationsAry,
            table: response.data.sessions[0].table,
          };
          NetScanAnalytics.updateProperties({
            scanId: homegraphProps.sessionId,
          });
          dispatch(
            fetchHomeGraphsDataUsingAxios(
              homegraphProps,
              shouldDisplayLoadingIndicator
            )
          );
        }
        dispatch(fetchDataSuccess(response));
      })
      .catch((error) => {
        const { response } = error;
        if (
          response &&
          response.status === 401 &&
          response.data === 'Unauthorized access'
        ) {
          dispatch(fetchDataFreeUserFailure(response));
          return;
        }
        dispatch(fetchDataFailure(error));
      })
      .catch((error) => {
        dispatch(fetchDataFailure(error));
      });
  };
}
