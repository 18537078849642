import React from 'react';
import styled from '@emotion/styled';

interface IStreamingItemProps {
    siteIcon: JSX.Element | string;
    label: string;
    scoreIcon: JSX.Element;
}

const StreamingScoreIcon = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    margin-right: 0.625rem;
    max-width: 1.3125rem;
`;

const StreamingSiteIcon = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    padding-right: 0.9375rem;
    flex: 1;
    margin-left: 0.9375rem;
`;

const StreamingItemList = styled.div`
    display: flex;
    margin-left: 0.1875rem;
    padding: 5px 0px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 1rem;
`;

const StreamingItemLabel = styled.p`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 16px;
    flex: auto;
    text-align: right;
    margin-bottom: 0;
`;

const SiteImage = styled.div`
    height: 20px;
    width: auto;
`;
export const StreamingItem = ({ siteIcon, scoreIcon, label }: IStreamingItemProps) => (
    <StreamingItemList>
        <StreamingScoreIcon>{scoreIcon}</StreamingScoreIcon>
        <StreamingSiteIcon>
            <SiteImage>{siteIcon}</SiteImage>
        </StreamingSiteIcon>
        <StreamingItemLabel>{label}</StreamingItemLabel>
    </StreamingItemList>
);
