import styled from '@emotion/styled';
import { MultiPointEvents, usePageAnalytics } from '@home-mgmt-shared/analytics';
import { IAuthClient } from '@home-mgmt-shared/auth';
import { getDefaultFeedbackMeta, getDefaultSignalGuidelines, shuffle } from '@home-mgmt-shared/common-services';
import {
    BreakPoints,
    FeedbackComponent,
    FeedbackTagLists,
    FilledButton,
    GoldStar,
    GrayStar,
    IconLink,
    IStreamingQualityGuideline,
    OutlineButton,
    SpeedTestResults,
    Wifi,
} from '@home-mgmt-shared/common-ui';
import { defaultRoomMapping, FactorsCard, RouterResults } from '@home-mgmt-shared/multi-point-scan';
import { Recommendation, Recommendations, weakSignalRecommendations } from '@home-mgmt-shared/recommendation';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import React, { useCallback, useState } from 'react';
import { useTweekValue } from 'react-tweek';
import { PERCENTAGE_THRESHOLD } from '../apis';
import { SignalResultStatus } from '../models';

let AuthClient: IAuthClient;
if (IS_MX_APP) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,global-require
    AuthClient = require('@home-mgmt-shared/auth').AuthClient;
}

const MultiRoomScanResultsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
`;

const MultiRoomScanResultsContent = styled.div`
    width: calc(100% - 2.5rem);
    max-width: 24rem;
    padding: 0 1.25rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
    overflow: hidden;
    box-sizing: unset;

    @media ${BreakPoints.desktop} {
        max-width: 45rem;
    }
`;

const FactorsCardContainer = styled.div`
    margin-top: 2rem;
    width: 100%;
`;

const RecommendationsContainer = styled.div`
    margin-bottom: 2rem;
    z-index: -1;
    width: 100%;
`;

const StyledFilledButton = styled(FilledButton)`
    margin-bottom: 0;
    max-width: 20rem;
`;

const StyledOutlinedButton = styled(OutlineButton)`
    max-width: 20rem;
`;

const FeedbackComponentContainer = styled.div`
    display: flex;
    width: 100%;
    max-width: 45rem;
    justify-content: center;
    align-items: center;
    overflow-x: visible;
    align-self: center;
    margin-top: 2.5rem;
`;

type SpeedResultsContainerProps = {
    open: boolean;
};

const SpeedResultsContainer = styled.div`
    transition: max-height 1s ease-in-out;
    height: auto;
    max-height: ${(props: SpeedResultsContainerProps) => (props.open ? '5000px' : '0px')};
    margin-top: ${(props: SpeedResultsContainerProps) => (props.open ? '2rem' : '0')};
    overflow: scroll;
`;

const SpeedResultsHeader = styled.div`
    font-size: 1.25rem;
    line-height: 150%;
    margin-bottom: 1rem;
`;

const SpeedTestResultsContainer = styled.div`
    margin-top: -20px;
`;

const InsightsHeader = styled.div`
    width: 100%;
    font-size: 1.5rem;
    line-height: 135%;
    border-bottom: 1px solid #e6e6eb;
    padding-bottom: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #e6e6eb;
    margin: 1.5rem 0;
`;

const DividerGap = styled.div`
    width: 100%;
    height: 1px;
    margin: 1.5rem 0;
`;

const GreenUnderline = styled.span`
    font-weight: bold;
    font-family: ${(props) => props.theme.font?.bold};
    text-decoration: underline;
    text-decoration-color: #00bf63;
`;

const YellowUnderline = styled.span`
    font-weight: bold;
    font-family: ${(props) => props.theme.font?.bold};
    text-decoration: underline;
    text-decoration-color: #f9d51c;
`;

const IconLinkWrapper = styled.div`
    margin-top: 1.5rem;
    width: 100%;
    z-index: 999;
`;

const GoodHeader = () => (
    <div>
        <GreenUnderline>Your signal seems OK</GreenUnderline>,&nbsp;but other factors may be slowing your speeds.
    </div>
);

const BadHeader = (room: string) => (
    <div>
        <YellowUnderline>Uh oh.</YellowUnderline>&nbsp;Your Wi-Fi signal may be too weak in{' '}
        {defaultRoomMapping.has(room.toLowerCase()) ? 'the ' : ''} {room} .
    </div>
);

type SpeedResults = {
    downloadSpeed: number;
};

interface MultiRoomScanResultsProps {
    onPageChange: () => void;
    routerSpeedResults?: SpeedResults;
    roomSpeedResults?: SpeedResults;
    roomName?: string;
    recommendations?: Recommendation[];
}

export const MultiRoomScanResults = ({
    onPageChange,
    routerSpeedResults = { downloadSpeed: 0 },
    roomSpeedResults = { downloadSpeed: 0 },
    roomName = 'Room unknown',
    recommendations = weakSignalRecommendations,
}: MultiRoomScanResultsProps) => {
    const [showInsights, setShowInsights] = useState<boolean>(false);
    const feedbackTagsUnsorted: FeedbackTagLists = useTweekValue(
        'network_scan/feedback_survey/bandwidth_survey',
        getDefaultFeedbackMeta(),
    );

    const feedbackTags: FeedbackTagLists = {
        maxScoreTagList: shuffle(feedbackTagsUnsorted.maxScoreTagList),
        lowScoreTagList: shuffle(feedbackTagsUnsorted.lowScoreTagList),
    };

    const feedbackCallback = useCallback((eventName: string, props: Record<string, unknown>) => {
        analytics.dispatch(eventName, NSEventType.Click, props);
    }, []);

    const streamingGuidelines: IStreamingQualityGuideline[] = useTweekValue(
        'network_scan/capabilities/signal_capabilities',
        getDefaultSignalGuidelines(),
    );

    const isLoggedIn = AuthClient ? AuthClient.isLoggedIn : false;

    const room = roomName.toLowerCase();
    const roomUppercase = room.charAt(0).toUpperCase() + room.substring(1);

    const routerDownloadSpeed = Math.trunc(routerSpeedResults.downloadSpeed);

    const roomDownloadSpeed = Math.trunc(roomSpeedResults.downloadSpeed);

    const percentage = routerDownloadSpeed > 0 ? roomDownloadSpeed / routerDownloadSpeed : 0;
    const badResults = percentage <= PERCENTAGE_THRESHOLD;
    const header = !badResults ? GoodHeader() : BadHeader(room);

    let signalResult = badResults ? SignalResultStatus.WEAK : SignalResultStatus.OKAY;
    if (routerSpeedResults.downloadSpeed === roomSpeedResults.downloadSpeed) {
        signalResult = SignalResultStatus.ADJUSTED;
    }

    analytics.updateProperties({ signalResult });
    usePageAnalytics(MultiPointEvents.ResultsPage, { routerSpeedResults, roomSpeedResults, roomName });

    const onClickNext = useCallback(() => {
        onPageChange();
    }, [onPageChange]);

    return (
        <MultiRoomScanResultsContainer>
            <MultiRoomScanResultsContent>
                {isLoggedIn && (
                    <IconLinkWrapper>
                        <IconLink Icon={<Wifi />} msg="Go to Wi-Fi Overview" route="/overview" />
                    </IconLinkWrapper>
                )}
                <RouterResults
                    header={header}
                    routerSpeed={routerDownloadSpeed}
                    roomSpeed={roomDownloadSpeed}
                    room={roomUppercase}
                    dotColor={badResults ? '#F9D51C' : '#00BF63'}
                />
                <FactorsCardContainer>
                    <FactorsCard
                        header={
                            badResults
                                ? 'Things that affect your signal strength'
                                : "If you're still having issues, some of these factors may be the issue:"
                        }
                        factors={
                            badResults
                                ? [
                                      'Distance from router',
                                      'Age of router',
                                      'Size of home',
                                      'Obstacles between router and device',
                                  ]
                                : [
                                      'Age of router',
                                      'Number of people using the network',
                                      'Type of activities and devices online',
                                      'Internet plan and type of internet',
                                  ]
                        }
                    />
                </FactorsCardContainer>
                <RecommendationsContainer>
                    <Recommendations
                        header={badResults ? 'How to improve your signal:' : 'How to improve your speed:'}
                        recommendations={recommendations}
                        elementsToDisplay={3}
                        showFirstLine
                    />
                </RecommendationsContainer>
                <StyledFilledButton analyticEventName="Show more tips" onClick={onClickNext}>
                    Show more tips
                </StyledFilledButton>
                <SpeedResultsContainer tabIndex={0} open={showInsights}>
                    {badResults && <DividerGap />}
                    <InsightsHeader>Wi-Fi Insights</InsightsHeader>
                    <SpeedResultsHeader>Router speed test</SpeedResultsHeader>
                    <SpeedTestResultsContainer>
                        <SpeedTestResults
                            downloadSpeed={routerDownloadSpeed}
                            streamingGuidelines={streamingGuidelines}
                        />
                    </SpeedTestResultsContainer>
                    <Divider />
                    <SpeedResultsHeader>{`${roomUppercase} speed test`}</SpeedResultsHeader>
                    <SpeedTestResultsContainer>
                        <SpeedTestResults downloadSpeed={roomDownloadSpeed} streamingGuidelines={streamingGuidelines} />
                    </SpeedTestResultsContainer>
                </SpeedResultsContainer>
                <StyledOutlinedButton
                    data-test-cy="show-more-insights"
                    analyticEventName=""
                    onClick={() => {
                        setShowInsights(!showInsights);
                    }}
                >
                    {showInsights ? 'Hide these Wi-Fi insights' : 'Show more Wi-Fi insights'}
                </StyledOutlinedButton>
            </MultiRoomScanResultsContent>
            <FeedbackComponentContainer>
                <FeedbackComponent
                    starRatingInfo={{ iconFilled: GoldStar(), iconEmpty: GrayStar() }}
                    tagLists={feedbackTags}
                    analyticsCb={feedbackCallback}
                />
            </FeedbackComponentContainer>
        </MultiRoomScanResultsContainer>
    );
};
