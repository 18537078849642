import * as C from '../constants';
import { getHomeGraphData } from '../services';

export const fetchDataBegin = (shouldDisplayLoadingIndicator) => ({
  type: C.FETCH_HOMEGRAPHS_DATA_BEGIN,
  shouldDisplayLoadingIndicator,
});

export const fetchDataSuccess = (data) => ({
  type: C.FETCH_HOMEGRAPHS_DATA_SUCCESS,
  payload: {
    data,
  },
});

export const fetchDataFailure = (error) => ({
  type: C.FETCH_HOMEGRAPHS_DATA_FAILURE,
  payload: { error },
});

export function fetchHomeGraphsDataUsingAxios(
  props,
  shouldDisplayLoadingIndicator
) {
  return (dispatch) => {
    dispatch(fetchDataBegin(shouldDisplayLoadingIndicator));
    getHomeGraphData(props)
      .then((response) => {
        dispatch(fetchDataSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchDataFailure(error));
      });
  };
}
