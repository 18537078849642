import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { useWindowDimensions } from '@home-mgmt-shared/common-hooks';
import { ThemeTypings, TooltipTriangle } from '@home-mgmt-shared/common-ui';
import { CartesianMarkerProps, DatumValue } from '@nivo/core';
import { ResponsiveLine, Serie } from '@nivo/line';
import React, { useEffect, useState } from 'react';
import { getMarginFromChartPoint, getMarginFromChartPoints, PointMargin } from '../api';
import { SpeedPoint, SpeedType } from '../models';
import { SpeedChartLabels } from './SpeedChartLabels';
import { SpeedChartLegend } from './SpeedChartLegend';
import { SpeedChartPointHighlight } from './SpeedChartPointHighlight';

const SpeedChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
    width: 100%;
    max-width: 35.5rem;
`;

const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 10rem;
    width: 100%;
    margin-top: 2rem;
`;

const ChartBaseline = styled.div`
    width: 100%;
    border: 1px solid #d5d6da;
`;

type MinMaxSpeed = {
    max: number;
    min: number;
};

// Override the NIVO library useTheme and Theme props
declare module '@emotion/react' {
    export type ThemeType = ThemeTypings;
}

const MIN_MAX_MARGIN_AMOUNT = 0.0625;

const getMinMaxSpeed = (data: SpeedPoint[], idealSpeed: number | undefined): MinMaxSpeed => {
    let min: number = idealSpeed ?? data[0].speed;
    let max: number = idealSpeed ?? data[0].speed;

    data.forEach((d: SpeedPoint) => {
        if (d.speed < min) {
            min = d.speed;
        }
        if (d.speed > max) {
            max = d.speed;
        }
    });

    // If we have an ideal speed, try to center the chart more on the ideal
    // speed marker rather than the actual center of the values
    if (idealSpeed) {
        const delta = Math.max(Math.abs(max - idealSpeed), Math.abs(idealSpeed - min));
        return { min: idealSpeed - delta, max: idealSpeed + delta };
    }

    return { min, max };
};

// Add a margin to the top and bottom of the chart from the min and max values
// to match the design where there is padding from the edges of the graph
const getMinMaxSpeedMargin = (minMaxSpeed: MinMaxSpeed): MinMaxSpeed => {
    let distance = minMaxSpeed.max - minMaxSpeed.min;
    // If there is only one point on the chart and no ideal speed, the min and
    // max values will be the same.  Add artificial margin in this case.
    if (distance === 0) {
        distance = 1;
    }

    return {
        max: minMaxSpeed.max + distance * MIN_MAX_MARGIN_AMOUNT,
        min: minMaxSpeed.min - distance * MIN_MAX_MARGIN_AMOUNT,
    };
};

const getLabelOfLength = (length: number) => {
    let label = '';
    for (let i = 0; i < length; i++) {
        label += ' ';
    }
    return label;
};

const getSeriesFromData = (data: SpeedPoint[], color?: string): Serie[] => {
    const newData: { x: DatumValue | null; y: DatumValue | null; date?: DatumValue | null }[] = [];

    data.forEach((d: SpeedPoint, index: number) => {
        // Empty label to allow chart to go off of the left side of the page but
        // not show the label clipping on the bottom axis
        if (index === 0 && data.length < 5) {
            newData.push({ x: '', y: d.speed, date: d.date });
            if (data.length < 5) {
                newData.push({ x: index, y: d.speed, date: d.date });
            }
        } else {
            newData.push({ x: index, y: d.speed, date: d.date });
        }
    });
    // Empty data points to add padding to right side of chart but with no label
    // or value
    for (let i = 0; i < 6 - (data.length + 1); i++) {
        const label = getLabelOfLength(i + 1);
        newData.push({ x: label, y: null });
    }
    if (data.length >= 5) {
        newData.push({ x: '  ', y: null });
    }
    return [
        {
            id: 'currentSpeed',
            colors: color ?? '#4866f9',
            data: newData,
        },
    ];
};

const emptyDataset: SpeedPoint[] = [
    {
        date: '   ',
        speed: 30,
    },
    {
        date: '    ',
        speed: 10,
    },
    {
        date: '     ',
        speed: 50,
    },
];
const emptyDatasetIdealSpeed = 20;

const getMarkers = (
    theme: Theme,
    speedType: SpeedType,
    idealSpeed?: number,
    data?: SpeedPoint[],
): CartesianMarkerProps[] => {
    if (speedType === SpeedType.DOWNLOAD) {
        if (idealSpeed && data) {
            return [
                {
                    axis: 'y',
                    value: idealSpeed,
                    lineStyle: {
                        stroke: theme.components?.overviewSpeedChart?.idealSpeedColor ?? '#37E7A7',
                        strokeWidth: 3,
                        strokeDasharray: 8,
                    },
                },
            ];
        }
        if (data === undefined) {
            return [
                {
                    axis: 'y',
                    value: emptyDatasetIdealSpeed,
                    lineStyle: {
                        stroke: '#D5D6DA',
                        strokeWidth: 3,
                        strokeDasharray: 8,
                    },
                },
            ];
        }
    }
    return [];
};

type MarginProps = {
    marginLeft: number;
    marginTop: number;
    marginBottom: number;
};

const TooltipContainer = styled.div<MarginProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: flex-start;
    align-self: flex-start;
    margin-left: ${(props) => props.marginLeft}px;
    margin-top: -${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
    z-index: 500;
    cursor: pointer;
`;

const Tooltip = styled.div`
    display: flex;
    flex-direction: column;
    color: black;
    background: #e6e6eb;
    border-radius: 4px;
    padding: 0.5rem 1rem;

    animation: animateTooltip 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    @keyframes animateTooltip {
        0% {
            transform: scale(1) translateY(0px);
            opacity: 0;
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        }
        1% {
            transform: scale(0.9) translateY(15px);
            opacity: 0;
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        }
        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
            box-shadow: 0 0 500px rgba(241, 241, 241, 0);
        }
    }
`;

const TooltipItem = styled.div`
    font-size: 0.875rem;
    line-height: 150%;
`;

const TooltipLink = styled.div`
    font-size: 0.875rem;
    line-height: 150%;
    text-decoration: underline;
`;

type TriangleOffset = {
    offset: number;
};

const TooltipTriangleContainer = styled.div<TriangleOffset>`
    justify-self: flex-start;
    align-self: flex-start;
    margin-top: -8px;
    margin-bottom: 42px; // This is an arbitrary number that just happens work by trial and error
    margin-left: ${(props) => props.offset}px;
    width: 9px;
    height: 6px;
`;

const ClickPoint = styled.div<MarginProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: flex-start;
    align-self: flex-start;
    z-index: 20;
    width: 2rem;
    height: 2rem;
    margin-left: ${(props) => props.marginLeft}px;
    margin-top: -${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
    cursor: pointer;
`;

const TOOLTIP_ID = 'network-overview-tooltip-container';
const POINT_ID = 'network-overview-point-container';

interface SpeedChartProps {
    downloadData?: SpeedPoint[];
    uploadData?: SpeedPoint[];
    idealSpeed?: number;
    speedType: SpeedType;
    onShowModal?: (speedPoint: number) => void;
    analyticsCb?: (eventName: string, extraProps: Record<string, unknown>) => void;
}

export const SpeedChart = ({
    downloadData,
    uploadData,
    idealSpeed,
    speedType,
    onShowModal,
    analyticsCb,
}: SpeedChartProps) => {
    const data = speedType === SpeedType.DOWNLOAD ? downloadData : uploadData;
    const minMaxSpeed = data ? getMinMaxSpeed(data, idealSpeed) : { min: 10, max: 50 };
    const minMaxSpeedMargin = getMinMaxSpeedMargin(minMaxSpeed);
    const theme: Theme = useTheme();

    const [currentPoint, setCurrentPoint] = useState<number>();
    const [pointMargins, setPointMargins] = useState<PointMargin[]>();
    const [showTooltip, setShowTooltip] = useState(false);

    const windowDimensions = useWindowDimensions();
    const [viewWidth, setViewWidth] = useState(Math.min(windowDimensions.width, 600));

    useEffect(() => {
        setViewWidth(Math.min(windowDimensions.width, 600 - 32));
    }, [windowDimensions]);

    useEffect(() => {
        if (data) {
            const margins = getMarginFromChartPoints(
                data,
                minMaxSpeedMargin.min,
                minMaxSpeedMargin.max,
                viewWidth,
                130,
            );

            // Delay state change to allow chart animation to finish
            // Otherwise you're changing state DURING a re-render,
            // stopping the animation prematurely
            setTimeout(() => {
                setPointMargins(margins);
                setCurrentPoint(undefined);
                setShowTooltip(false);
            }, 500);
        }
    }, [data, minMaxSpeedMargin.max, minMaxSpeedMargin.min, viewWidth]);

    const getTooltip = () => {
        const index = currentPoint;
        if (index !== undefined && pointMargins && data && index < data.length) {
            const downloadPoint = downloadData?.[index];
            const uploadPoint = uploadData?.[index];

            const point = getMarginFromChartPoint(
                data[index],
                data.length,
                index,
                minMaxSpeedMargin.min,
                minMaxSpeedMargin.max,
                viewWidth,
                130,
            ) ?? { top: 0, left: 0, bottom: 0 };

            // Subtract half the width of the tooltip component to center on the point,
            // then make sure that the tooltip is at LEAST 16px inside the screen so it
            // never ends up off of the screen (left side)
            const addLeftBuffer = Math.max(point.left - 60, 16);
            // This is also to make sure the tooltip does not end up off of the screen on
            // the right side.  Use viewWidth minus the width of the tooltip and 16px margin
            const marginLeft = Math.min(addLeftBuffer, viewWidth - 140 - 16);
            // Add height of the component to position the tooltip above the point;
            const marginTop = point.top + 108;
            const marginBottom = point.bottom;

            // We want the triangle to ALWAYS be horizontally centered on the point, not the tooltip,
            // so find the difference between the tooltip margin and the point margin,
            // and add half of the triangle width to center
            const triangleOffset = point.left - marginLeft + 4;

            return (
                <TooltipContainer
                    marginLeft={marginLeft}
                    tabIndex={0}
                    marginTop={marginTop}
                    marginBottom={marginBottom}
                    id={TOOLTIP_ID}
                    onClick={() => {
                        analyticsCb?.('Speed_Chart_Modal_Opened', { index });
                        setTimeout(() => {
                            onShowModal?.(index);
                        }, 50);
                    }}
                >
                    <Tooltip>
                        <TooltipItem>
                            <strong>{downloadPoint?.date}</strong>
                        </TooltipItem>
                        <TooltipItem>
                            Download: <strong>{downloadPoint?.speed}</strong>
                        </TooltipItem>
                        <TooltipItem>
                            Upload: <strong>{uploadPoint?.speed}</strong>
                        </TooltipItem>
                        <TooltipLink role="button">Show more details</TooltipLink>
                    </Tooltip>
                    <TooltipTriangleContainer offset={triangleOffset}>
                        <TooltipTriangle />
                    </TooltipTriangleContainer>
                </TooltipContainer>
            );
        }

        return <></>;
    };

    const handleClick = () => {
        // If we did not click a tooltip, hide the tooltip if showing
        setCurrentPoint(undefined);
        setShowTooltip(false);
    };

    const onClickedPoint = (index: number) => {
        analyticsCb?.('Speed_Chart_Point_Clicked', { index });
        setTimeout(() => {
            if (data && data.length < 5) {
                setCurrentPoint(index);
            } else {
                setCurrentPoint(index + 1); // Add 1 because we have no click point for the point off screen
            }
            setShowTooltip(true);
        }, 50);
    };

    return (
        <SpeedChartContainer
            onClick={() => handleClick()}
            role="region"
            aria-label="Speed test chart"
            id="speedChartContainer"
        >
            {data && (
                <SpeedChartLegend
                    data={data}
                    idealSpeed={idealSpeed}
                    speedType={speedType}
                    aria-labelledby="speedChartContainer"
                />
            )}
            <ChartContainer aria-labelledby="speedChartContainer">
                <ResponsiveLine
                    data={
                        data !== undefined
                            ? getSeriesFromData(
                                  data,
                                  speedType === SpeedType.UPLOAD
                                      ? theme.components?.overviewSpeedChart?.uploadSpeedColor
                                      : theme.components?.overviewSpeedChart?.downloadSpeedColor,
                              )
                            : getSeriesFromData(emptyDataset, '#A5AAAF')
                    }
                    margin={{ top: 0, right: -(viewWidth / 6.0), bottom: 30, left: -(viewWidth / 6.0) }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: minMaxSpeedMargin.min,
                        max: minMaxSpeedMargin.max,
                        stacked: false,
                        reverse: false,
                    }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    lineWidth={3}
                    axisLeft={null}
                    enableGridX={false}
                    enableGridY={false}
                    pointSize={8}
                    colors={{ datum: 'colors' }}
                    pointColor={{ from: 'colors', modifiers: [] }}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    enableCrosshair={false}
                    isInteractive={false}
                    useMesh
                    animate
                    markers={getMarkers(theme, speedType, idealSpeed, data)}
                />
            </ChartContainer>
            <SpeedChartPointHighlight
                data={data ?? emptyDataset}
                chartMin={minMaxSpeedMargin.min}
                chartMax={minMaxSpeedMargin.max}
                chartWidth={viewWidth}
                chartHeight={130}
                speedType={speedType}
                emptyDataSetColor={data === undefined ? emptyDataset && '#A5AAAF' : undefined}
            />
            {/* We render these click points to give us bounding rects to use for
                detecting clicks on each point, but there is no color and pointer
                events are turned off, so the customer won't see these
            */}
            {pointMargins?.map((point, index) => (
                <ClickPoint
                    role="button"
                    marginLeft={point.left}
                    tabIndex={0}
                    marginTop={point.top}
                    marginBottom={point.bottom}
                    id={`${POINT_ID}-${index}`}
                    key={`${POINT_ID}-${index}`}
                    onClick={() => onClickedPoint(index)}
                />
            ))}
            {showTooltip && getTooltip()}
            <ChartBaseline />
            {data && <SpeedChartLabels data={data} />}
        </SpeedChartContainer>
    );
};
