import styled from '@emotion/styled';
import React from 'react';
import { CustomBullet } from './CustomBullet';

const BulletPointIcon = styled.div`
    height: 1.4rem;
    max-width: 1.4rem;
    min-width: 1.4rem;
    margin-top: 0.1875rem;
`;

const BulletPointText = styled.div`
    font-size: 1.25rem;
    margin-left: 0.9375rem;
    max-width: 17.5rem;
    line-height: 135%;
`;

const BulletPoint = styled.ul`
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    margin-bottom: 1.2rem;
`;

interface IconBulletListProps {
    bullets: CustomBullet[];
}
export const IconBulletList = ({ bullets }: IconBulletListProps) => (
    <>
        {bullets?.map((bullet, index) => (
            <BulletPoint key={index}>
                <BulletPointIcon role="listitem">{bullet.icon}</BulletPointIcon>
                <BulletPointText role="listitem">{bullet.text}</BulletPointText>
            </BulletPoint>
        ))}
    </>
);
