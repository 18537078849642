import styled from '@emotion/styled';
import { DottedLine, NetworkRouter, ScanningPhone } from '@home-mgmt-shared/common-ui';
import * as React from 'react';

const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

const RouterPulse = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    z-index: 10;
    position: relative;
`;

const DottedLineContainer = styled.div`
    display: flex;
    transform: rotate(90deg);
    margin: 32px 0px 45px -10px;
    z-index: 15;
`;

const NetworkRouterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 63px;
    width: 71px;
`;

const NetworkRouterBackground = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 100%;
    height: 75px;
    padding: 12px;
    width: 75px;
    position: relative;
    left: 83px;
`;

const ScanningPhoneContainer = styled.div`
    display: flex;
    margin-left: -10px;
`;

const Pulsing = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border-radius: 9999px;
    background-color: #dfdfe6;
    z-index: -1;
    pointer-events: none;
    animation: sonarWave 2s linear infinite;
    font-family: ${(props) => props.theme?.font?.type};
    left: ${(props) => props.theme?.components?.multiScanProgress?.pulsingLargeLeftPosition || '-17px'};
    @keyframes sonarWave {
        from {
            opacity: 0.4;
        }
        to {
            transform: scale(4);
            opacity: 0;
        }
    }
`;

const PulsingSmall = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    border-radius: 9999px;
    background-color: #c7c7cc;
    z-index: -1;
    pointer-events: none;
    animation: sonarWaveSmall 2s linear infinite;
    left: ${(props) => props.theme?.components?.multiScanProgress?.pulsingSmallLeftPosition || '-107px'};
    @keyframes sonarWaveSmall {
        from {
            opacity: 0.4;
        }
        to {
            transform: scale(2.5);
            opacity: 0;
        }
    }
`;

export const ScanningRouter = () => (
    <Container>
        <RouterPulse>
            <NetworkRouterBackground>
                <NetworkRouterWrapper>
                    <NetworkRouter />
                </NetworkRouterWrapper>
            </NetworkRouterBackground>
            <Pulsing />
            <PulsingSmall />
        </RouterPulse>
        <DottedLineContainer>
            <DottedLine />
        </DottedLineContainer>
        <ScanningPhoneContainer>
            <ScanningPhone />
        </ScanningPhoneContainer>
    </Container>
);
