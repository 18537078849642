import * as React from 'react';

export const DiamondStars = () => (
    <svg width="100%" height="100%" viewBox="0 0 44 62" fill="none">
        <path
            d="M25.004 3.598c.194-.591.326 1.463.4 2.5.073 1.036.245 1.073.376 2.137.361 2.936 1.973 6.109 4.064 8.262 1.744 1.788 6.57 5.118 9.03 5.423l1.43.177c-.993-.123-3.894 1.17-4.799 1.723-3.252 2.016-4.315 3.676-5.701 5.852-2.5 3.925-3.534 7.127-4.024 10.925.43-3.312-3.936-9.762-5.977-12-2.678-2.941-5.69-5.148-9.372-5.605 8.347 1.035 13.53-7.97 14.415-15.115l.158-4.28zM5.996 31.235a7.6 7.6 0 00-.023-.638c-.084 1.4.375 2.806 1.197 3.931.625.858 2.632.525 3.746.662-1.965.53-3.157.762-3.746 1.72-.334.543-.572 2.383-.572 3.687-.37-.935-.574-2.983-1.295-3.686-.72-.703-2.51-1.352-3.5-1.191 4.193-.682 4.2-1.62 4.193-4.485zM16.264 42.623c-.84 2.58-1.26 4.24-2.16 5.32-1.95 2.33-4.05 3.88-4.92 4.44 4.92 0 7.3 3.214 7.3 7.65 0 .02.02.02.02 0 .86-3.78 2.49-8.436 7.33-9.34-1.48-.14-3.62-1.45-4.84-2.3-1.67-1.17-1.88-4.08-2.73-5.77z"
            stroke="#1D1D1B"
            strokeWidth={2.45}
            strokeMiterlimit={1.206}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
