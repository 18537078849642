import * as React from 'react';

export const WebBrowsing = () => (
    <svg width={77} height={45} viewBox="0 0 77 40" fill="none" role="img" aria-label="web browsing">
        <g clipPath="url(#prefix__clip0)" strokeLinejoin="round">
            <path
                d="M22.6 33.696c.09-.736.192-2.32.3-3.055.287-2.017.736-4.247 1.053-6.264 1.054-6.733 2.556-12.078 4.227-18.686.299-1.382 2.19-2.367 4.58-2.663 2.986-.398 6.279-.546 9.057-.688 8.177-.433 13.403-.694 21.599-.76 2.119-.017 3.585-.326 6.172-.231 1.22.047 3.083.196 2.885 1.578M72.545 2.862c-1.742 9.135-3.664 18.377-6.376 27.275a109.016 109.016 0 01-1.035 3.203"
                stroke="#000"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path
                d="M72.072 1.841l2.544 2.065c.467.38.719.96.665 1.56-.084.996-.252 2.562-.521 3.695a779.069 779.069 0 00-2.095 9.153c-.707 3.168-1.024 4.829-1.904 7.96-.826 2.925-2.478 8.56-3.071 10.832M67.594 37.1c.048-.04-2.694.137-3.334.102-2.203.148-9.058.397-12.86.54-7.482.474-11.044.302-13.917.397-.743.035-1.695.249-1.952-.516-.036-.362-1.718-3.322-1.503-3.619.216-.285 1.467-.201 1.766-.22 1.964-.112 3.957.042 5.92-.082 7.663-.196 16.392-.166 23.228-.036M64.87 33.797c.042-.137 2.215 2.42 2.575 3.155"
                stroke="#000"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path
                d="M36.19 38.186c-9.555.398-16.858.232-24.736.41-1.287.006-2.575.017-3.85.07-1.724-.005-2.514-.118-3.777-.664-1.06-.462-3.334-3.138-1.287-3.594.233-.06.473-.107.706-.113 10.722-.303 19.743-.647 31.375-.457M25.192 33.6c.401-2.07.749-4.341 1.096-6.412.311-1.91.587-3.63.916-5.54.497-2.835 1.305-5.647 1.904-8.465.37-1.738 1.334-6.039 1.46-6.015.222-.67.898-1.085 2.79-1.281 7.465-.688 14.032-.79 22.353-1.145 5.412-.036 9.399-.136 11.189-.136 1.293 0 2.19.195 1.79 1.975-.384 1.886-1.707 6.762-2.096 8.524-1.023 4.668-2.185 9.029-3.346 13.84-.437 1.553-.617 2.835-1.227 4.33M8.083 33.785c-.083.006-.018-6.994-.113-9.36-.102-1.187-.12-2.1.299-2.563.563-.63 2.047-.973 2.813-1.08 1.982-.279 3.856-.302 5.652.095 1.556.344 2.987 1.085 2.79 2.266.101 1.382.143 4.372.101 5.327-.114 2.634.102 2.859 0 5.327M7.503 23.932c-.773.107-1.55.136-2.161.688-1.33 1.21-1.353 3.458-1.114 5.267.162.95.216 1.471.76 1.934.48.41 2.12.083 2.688 0M11.98 9.778c-.245 2.314-2.358 3.589-2.753 5.736-.156.86.299 1.768.544 2.569M14.04 4.273c-.048-.035.287.368.323.41.27.32.539.664.742 1.038.605 1.133.922 2.663.88 3.956-.083 2.972-3.771 5.802-1.676 8.465M19.17 6.195c-.024.018.257.362.281.392.234.332.39.7.521 1.085.371 1.11.401 2.397.282 3.554-.3 2.788-2.946 4.348-2.323 7.29"
                stroke="#000"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path
                d="M36.058 10.793c-.102-.006-.545 1.619-.67 2.147-.587 2.503-1.03 5.06-1.57 7.575"
                stroke="#000"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path
                d="M36.04 10.816c.898-.1 2.46-.047 2.987-.1 1.49-.131 3.484-.036 4.975-.101 2.562-.113 4.693-.101 7.267-.101 2.251 0 3.927-.125 6.172 0M57.59 10.514c.174.148-1.292 5.843-1.58 7.593a32.886 32.886 0 01-.526 2.622M55.472 20.96c-.198.308-4.233.124-5.651.071-3.503-.113-6.693.006-10.195-.154-1.683-.071-4.095-.208-5.771-.196"
                stroke="#000"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path
                d="M37.135 20.978c-.113-.018-.575 2.491-.263 2.983.126.202 3.215.107 4.34.173 3.472.213 6.962.201 10.44.278 1.197.024 4.897.166 5.508.06.802-.13.832-2.569.916-3.038.257-1.447.844-5.024.916-5.35.215-.973.508-1.43-.485-1.4-.168.006-1.341-.273-1.659-.208M34.196 27.888c-.006.024.772-.023.814-.023.46-.006.922-.012 1.383 0 1.73.006 3.466.07 5.196.083 4.735.017 9.572.118 14.254.225"
                stroke="#000"
                strokeWidth={2}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
            <path
                d="M62.467 13.75V29l4-2.126 3.5 6.251 2.75-2.251-2.75-6.5 3.908-1.249-11.408-9.375z"
                fill="#fff"
                stroke="#fff"
                strokeWidth={4}
            />
            <path
                d="M65.34 27.21c.241-.124.481-.372.722-.495.12 0 .24-.248.36-.248s.12.248.12.248c.12.247.36.619.601.866.601 1.238 1.202 2.476 1.803 3.59.24.372.36.744.6 1.115l.121.495c.12 0 .24-.247.36-.247.361-.248.722-.496 1.082-.62.48-.247.961-.495 1.442-.866.12-.124.48-.124.601-.372.12-.247-.48-.99-.48-1.238-.602-1.114-1.323-2.228-1.923-3.466-.24-.372-.481-.62-.601-.99 0-.125-.24-.248-.12-.372.12-.248.72-.248.84-.248l.241-.124c.36-.123.841-.247 1.322-.495.24-.124.721-.247.841-.371.12-.248-.72-.99-.841-1.115l-.36-.371-.12-.124c-1.323-1.114-2.885-1.98-4.327-3.095-1.562-1.238-3.004-2.972-4.687-4.086-.841-.495-.841.743-.841 1.238V17.8c.12 2.476.12 4.953.36 7.305.12.867.24 1.981.24 2.848v.247c0 .124 0 .743.241.743.24 0 .601-.495.841-.743.481-.495.962-.866 1.443-1.238 0 .372.12.248.12.248z"
                stroke="#000"
                strokeWidth={1.808}
                strokeMiterlimit={10}
                strokeLinecap="round"
            />
        </g>
        <defs>
            <clipPath id="prefix__clip0">
                <path fill="#fff" transform="translate(.978 .602)" d="M0 0h75.046v38.795H0z" />
            </clipPath>
        </defs>
    </svg>
);
