import * as React from 'react';

export const Email = () => (
    <svg width={49} height={45} viewBox="0 0 49 48" fill="none" role="img" aria-label="email">
        <path
            d="M4.882 43.333c-.146.26-.075-.589-.075-.843-.038-.621-.076-1.248-.108-1.87-.108-1.615-.222-3.192-.292-4.808-.254-5.176.113-10.368.373-14.993M40.175 20.338c.178 1.14.286 1.697.362 2.393.178 1.2.362 2.697.48 4.734.12 4.851.292 10.136.06 14.431-.146.919-.124 2.167-.53 3.047-.67.006-1.615.108-1.982.146-1.8.146-3.631.184-5.468.222-6.603.145-13.173.291-19.775.259-1.762 0-3.485.108-5.209-.038-1.983-.183-3.09.022-3.274-1.956"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.964 26.773l.186-2.22c-.038-.698-.11-1.357-.148-2.054-.11-2.534.148-5.024-.038-7.52-.039-.406-.225-2.167.224-2.459.636-.265.998-.146 1.787-.146 1.601.124 2.714.324 4.282.508 5.322.697 10.83 1.724 16.23 1.724 1.749 0 3.465-.076 5.175 0 .11 0 1.452-.076 1.524.108.224.621-.296 2.128-.411 2.717-.373 2.237-.894 6.585-1.113 8.86M4.84 20.457c.955-.719 2.897-1.821 4.021-2.4"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.18 12.914c.697-.513 2.15-1.275 2.917-1.68.957-.514 2.567-1.502 3.745-1.432.988.038 2.064 1.167 2.798 1.794 1.432 1.172 2.27 1.848 3.772 2.874M40.132 20.073c.075 0-1.584-1.47-2.172-1.653M25.37 21.482c0 .07-.185-.07-.26-.11-.185-.069-.375-.069-.56-.069-.595 0-1.195 0-1.72.323-1.83 1.074-2.95 2.58-2.69 4.728.15 1.252 1.53 1.396 2.465.68 1.304-.963 2.464-2.683 2.69-4.293.034-.324.224-1.15.034-1.467 0-.034-.075.249-.075.249-.04.178-.075.357-.11.536-.075.537-.11 1.109-.184 1.645-.04.393-.076.75-.11 1.149-.04 1.218.56 4.08 2.54 3.007.634-.358 1.044-1.039 1.379-1.61 1.01-1.686 1.455-3.654.04-5.264-1.344-1.541-4.294-2.292-6.268-2.003-3.584.502-5.599 3.798-5.339 7.088.185 2.36 1.495 4.975 4.22 5.154.634.035 1.229-.11 1.83-.288"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M40.142 23.304c-1.869.918-3.998 3.852-5.76 5.354-.918.805-1.906 1.54-2.863 2.313-2.312 1.837-4.182 3.63-6.932 4.733-1.026.405-1.718.93-2.858.637-.475-.108-1.361-.713-1.767-.967-1.431-.88-2.712-1.837-4.036-2.863-1.507-1.14-3.01-2.237-4.441-3.448-2.35-1.945-4.403-3.89-6.349-6.202M5.017 43.814c-.108.146.416-.373.524-.519.292-.367.622-.697.957-.988.88-.843 1.799-1.616 2.717-2.383 1.762-1.502 3.34-3.231 5.209-4.625 1.14-.843 1.02-1.122 2.047-2.117"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M28.218 33.688c1.21.697 2.09 1.465 3.23 2.313 2.167 1.616 4.296 3.302 6.274 5.139.989.918 2.166 1.87 2.826 3.047M7.184 9.359c.038.038-2.35-3.263-2.934-4.22M33.453 10.537c-.183.038.108-.33.222-.476.221-.405.475-.772.735-1.14.734-1.172 1.47-2.35 2.128-3.56M19.962 7.247c-.094.163-.174-.3-.207-.481-.155-.435-.26-.87-.362-1.307-.386-1.33-.776-2.662-1.24-3.959M41.601 15.454c-.183.108.368-.26.59-.368.329-.184.658-.33.988-.475.956-.438 1.87-.919 2.642-1.578"
            stroke="#000"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
