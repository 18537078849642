import styled from '@emotion/styled';
import { iconRecommendationsList, Recommendations } from '@home-mgmt-shared/recommendation';
import { getDefaultFeedbackMeta, shuffle } from '@home-mgmt-shared/common-services';
import { FeedbackTagLists, FeedbackComponent, GoldStar, GrayStar } from '@home-mgmt-shared/common-ui';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import React, { useCallback, useEffect } from 'react';
import { useTweekValue } from 'react-tweek';

const RecommendationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 365px;
    margin: -2rem auto 0 auto;
`;

const RecomendationsItem = styled.div`
    margin-bottom: 1rem;
`;

const FeedbackComponentContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: visible;
    max-width: 745px;
    width: 100vw;
    align-self: center;
    margin-top: 1rem;
`;

export const BandwidthCalculatorResultsRecommendations = () => {
    const feedbackTagsUnsorted: FeedbackTagLists = useTweekValue(
        'network_scan/feedback_survey/bandwidth_survey',
        getDefaultFeedbackMeta(),
    );

    const feedbackTags: FeedbackTagLists = {
        maxScoreTagList: shuffle(feedbackTagsUnsorted.maxScoreTagList),
        lowScoreTagList: shuffle(feedbackTagsUnsorted.lowScoreTagList),
    };

    const feedbackCallback = useCallback((eventName: string, props: Record<string, unknown>) => {
        analytics.dispatch(eventName, NSEventType.Click, props);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <RecommendationsContainer>
            <RecomendationsItem>
                <Recommendations
                    header="How to increase your speed"
                    recommendations={iconRecommendationsList}
                    showFirstLine
                />
            </RecomendationsItem>
            <FeedbackComponentContainer>
                <FeedbackComponent
                    starRatingInfo={{ iconFilled: GoldStar(), iconEmpty: GrayStar() }}
                    tagLists={feedbackTags}
                    analyticsCb={feedbackCallback}
                />
            </FeedbackComponentContainer>
        </RecommendationsContainer>
    );
};
