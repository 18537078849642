import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

interface CheckboxProps {
    selected: boolean;
    checkedIcon: ReactNode;
}

const Checked = styled.div`
    height: 1.25rem;
    width: 1.25rem;
    top: 5px;
    left: 4px;
    position: relative;
    border-radius: 4px;
    background-color: ${(props) =>
        props.theme.components?.questionAnswerGrid?.answerAccent || props.theme.button?.selectedColor};
    display: flex;
    justify-content: center;
    align-items: center;
    @-moz-document url-prefix() {
        left: 2px;
    }
    @supports (-webkit-appearance: attachment) {
        left: 3px;
    }
    @supports (-webkit-touch-callout: none) {
        left: -7px;
    }
`;

const Unchecked = styled.div`
    height: 1.125rem;
    width: 1.125rem;
    top: 5px;
    left: 4px;
    position: relative;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid
        ${(props) => props.theme.components?.questionAnswerGrid?.answerAccent || props.theme.button?.selectedColor};

    @-moz-document url-prefix() {
        left: 2px;
    }
    @supports (-webkit-touch-callout: none) {
        left: -6px;
    }
`;

export const Checkbox = ({ selected, checkedIcon }: CheckboxProps) =>
    selected ? <Checked>{checkedIcon}</Checked> : <Unchecked />;
