import { UserBrowser } from '@home-mgmt-shared/common-ui';
import {
  initFullstory,
  setIdentityForFullStory,
} from '@home-mgmt-shared/fullstory';
import { TweekProvider } from '@home-mgmt-shared/tweek-helpers';
import React, { useEffect } from 'react';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { PARTNER } from '../constants';
import { RoutePrefix } from '../models';
import { isProduction } from '../utils';
import Account from '../utils/analytics/AccountId';
import { initializeAnalytics } from '../utils/analytics/analyticsInitializer';
import Campaign from '../utils/analytics/Campaign';
import { initializeLogger } from '../utils/initializeLogger';
import Routes from './Routes';

const AppComponent = ({ prefix }: RoutePrefix) => {
  const location = useLocation();
  initializeAnalytics(location.search);
  const params = new URLSearchParams(location.search);
  const isTest = params.get('isTest');

  useEffect(() => {
    initFullstory(!isProduction() || !!isTest);
    if (!isTest && isProduction()) {
      setIdentityForFullStory(UserBrowser.Id, {
        cid_str: Campaign.getCampaignId(),
        partner_str: PARTNER,
        accountId_str: Account.getAccountId(),
      });
    }
  }, [isTest]);

  useEffect(() => {
    Campaign.setCampaignId();
    Account.setAccountId();

    initializeLogger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const base: string = prefix && prefix ? prefix.replace('/', '') : '';

  return (
    <TweekProvider>
      <BrowserRouter basename={base}>
        <Routes />
      </BrowserRouter>
    </TweekProvider>
  );
};

export const App = AppComponent;
