import styled from '@emotion/styled';
import {
    FilledButton,
    SelectableButton,
    QuestionAnswerIcons,
    BreakPoints,
    GirlOnPhoneWithRouter,
    GuyOnPhone,
} from '@home-mgmt-shared/common-ui';
import {
    Answer,
    QuestionAnswerFlow,
    Question,
    AnswerList,
    AnswerGrid,
    MultiSelectAnswerList,
    QuestionAnswerRef,
} from '@home-mgmt-shared/flow-ui';
import { IScanResults, RoomScan } from '@home-mgmt-shared/multi-point-scan';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useRxApi } from '@home-mgmt-shared/common-hooks';
import { usePageAnalytics, MultiPointEvents } from '@home-mgmt-shared/analytics';
import { ScanPoint } from '../models';
import {
    storeMultiPointScanResults,
    storeMultiPointFlowResults,
    results$,
    MultiPointScanResults,
    getRoomName,
} from '../apis';

const ScanContainer = styled.div`
    margin-right: 20px;
    margin-left: 20px;
`;

const ContentContainer = styled.div`
    max-width: 23rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;

    @media ${BreakPoints.mobileBig} {
        max-width: 30rem;
    }

    @media ${BreakPoints.desktop} {
        max-width: 745px;
    }
`;

interface ScansProps {
    scanPoints: ScanPoint[];
    onAllScansComplete: () => void;
    onPreviousPageState?: () => void;
    onStepChange?: (_: number) => void;
    backWasClicked?: boolean;
    isBackFromResults?: boolean;
    onScanStateChange?: (isScanInProgress: boolean) => void;
}

enum ScanState {
    FLOW,
    SCAN,
}

const getAnalyticPageName = (scanState: ScanState): string | undefined => {
    if (scanState === ScanState.FLOW) {
        return MultiPointEvents.QuestionnairePage;
    }

    return undefined;
};

const getSpecificScanResult = (scanPoints: ScanPoint[], currentPointIndex: number): string => {
    const scanPoint = scanPoints[currentPointIndex];
    return scanPoint.name;
};

export const Scans = ({
    scanPoints,
    onAllScansComplete,
    onPreviousPageState,
    onStepChange,
    backWasClicked,
    isBackFromResults,
    onScanStateChange,
}: ScansProps) => {
    const multiPointScanResults = useRxApi<MultiPointScanResults, unknown>(results$, {
        initialValue: {},
    });

    const [currentPointIndex, setCurrentPointIndex] = useState(isBackFromResults ? scanPoints.length - 1 : 0);
    const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
    const [currentScanStateIndex, setCurrentScanStateIndex] = useState(isBackFromResults ? 2 : 0);
    const [scanState, setScanState] = useState(isBackFromResults ? ScanState.SCAN : ScanState.FLOW);
    const [back, setBack] = useState<boolean>(backWasClicked ?? false);
    const [scanStart, setScanStarted] = useState<boolean>(false);
    const [scanCompleted, setScanCompleted] = useState<boolean>(isBackFromResults ?? false);
    const [prevAnswerList, setPrevAnswerList] = useState<Answer[]>();
    const [showScanResults, setShowScanResults] = useState<boolean>(isBackFromResults ?? false);

    usePageAnalytics(getAnalyticPageName(scanState));

    const questionAnswerRef = useRef<QuestionAnswerRef>(null);

    const onBackClicked = useCallback(() => {
        if (scanState === ScanState.SCAN && !scanStart) {
            if (scanCompleted && currentPointIndex > 0) {
                // If going back from a scan to a scan, init scan index, decrement point index, and add back previous
                // activity index to correct the progress bar in case previous scan has flow
                setCurrentScanStateIndex(2);
                setCurrentPointIndex(currentPointIndex - 1);
                setCurrentActivityIndex(
                    (scanPoints[currentPointIndex - 1].flowConfig?.flow?.activities.length ?? 1) - 1,
                );
                setShowScanResults(true);
            } else {
                // If going back from a scan to a flow, set state, add back previous activity index, and reset the scan index
                setScanState(ScanState.FLOW);
                setCurrentActivityIndex((scanPoints[currentPointIndex].flowConfig?.flow?.activities.length ?? 1) - 1);
                setCurrentScanStateIndex(0);
                setShowScanResults(false);
            }
        }

        if (scanState === ScanState.FLOW) {
            questionAnswerRef?.current?.previous();
        }
    }, [currentPointIndex, scanCompleted, scanPoints, scanStart, scanState]);

    const scanStarted = () => {
        setScanStarted(true);
        setScanCompleted(false);
        onScanStateChange?.(true);
    };

    useEffect(() => {
        if (backWasClicked !== back) {
            setBack(!back);
            onBackClicked();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backWasClicked]);

    const onScanComplete = useCallback(
        (results: IScanResults) => {
            setCurrentActivityIndex(0);
            const scanName = scanPoints[currentPointIndex].name;
            storeMultiPointScanResults(scanName, results);
            if (currentPointIndex + 1 < scanPoints.length) {
                setCurrentPointIndex(currentPointIndex + 1);

                if (scanPoints[currentPointIndex + 1]?.flowConfig) {
                    setScanState(ScanState.FLOW);
                } else {
                    setScanState(ScanState.SCAN);
                }
                return;
            }

            onAllScansComplete();
        },
        [currentPointIndex, onAllScansComplete, scanPoints],
    );

    const onTestComplete = () => {
        setCurrentScanStateIndex(2);
        setScanStarted(false);
        setScanCompleted(true);
        onScanStateChange?.(false);
    };

    const onTestCanceled = () => {
        setScanStarted(false);
        setScanCompleted(true);
        onScanStateChange?.(false);
    };

    const scanPoint = scanPoints[currentPointIndex];
    const activities = scanPoint.flowConfig?.flow?.activities;
    const flowImageMap = scanPoint.flowConfig?.imageMap || QuestionAnswerIcons;

    useEffect(() => {
        let index = 1;
        if (currentPointIndex > 0) {
            for (let i = 0; i < currentPointIndex; i++) {
                index += (scanPoints[i].flowConfig?.flow?.activities.length ?? 1) - 1; // Q&A steps
                index += 2; // Scan states
            }
        }
        index += currentActivityIndex;
        index += currentScanStateIndex;
        onStepChange?.(index);
    }, [currentActivityIndex, currentPointIndex, currentScanStateIndex, onStepChange, scanPoints]);

    const componentMap = {
        Question,
        AnswerList,
        AnswerGrid,
        MultiSelectAnswerList,
        FilledButton,
        SelectableButton,
    };

    useEffect(() => {
        if (scanState === ScanState.SCAN && !scanStart && !showScanResults) {
            setCurrentScanStateIndex(1);
        }
    }, [scanStart, scanState, showScanResults]);

    useEffect(() => {
        if (!scanStart && !scanCompleted) {
            onScanStateChange?.(false);
        }
    }, [onScanStateChange, scanCompleted, scanStart]);

    if (scanState === ScanState.FLOW && scanPoint.flowConfig?.flow === undefined) {
        setScanState(ScanState.SCAN);
    }

    const onFlowComplete = useCallback(
        (finalState: Record<string, Answer[]>) => {
            const scanName = scanPoints[currentPointIndex].name;
            storeMultiPointFlowResults(scanName, finalState);
            setScanState(ScanState.SCAN);
            setCurrentScanStateIndex(1);
            setShowScanResults(false);
        },
        [currentPointIndex, scanPoints],
    );

    const onFlowStateChange = useCallback(
        (flowState: Record<string, Answer[]>) => {
            const scanName = scanPoints[currentPointIndex].name;
            storeMultiPointFlowResults(scanName, flowState);
            setCurrentActivityIndex(1);
        },
        [currentPointIndex, scanPoints],
    );

    return (
        <ScanContainer>
            {scanState === ScanState.FLOW && activities && (
                <ContentContainer>
                    <QuestionAnswerFlow
                        ref={questionAnswerRef}
                        imageMap={flowImageMap}
                        componentMap={componentMap}
                        activities={activities}
                        onFlowStateChange={onFlowStateChange}
                        onFlowComplete={onFlowComplete}
                        onStepChange={setCurrentActivityIndex}
                        linkButtonUnderlined={undefined}
                        flowAnalyticEventPrefix="mp_flow_"
                        onPreviousPageState={onPreviousPageState}
                        hidePreviousButton={scanPoint.flowConfig?.customBackButton}
                        initialFlowState={multiPointScanResults?.result[scanPoint.name]?.flowResults}
                        initialActivityIndex={
                            Object.keys(multiPointScanResults?.result[scanPoint.name]?.flowResults ?? { _: '' })
                                .length - 1
                        }
                        initialSelectedAnswerList={prevAnswerList}
                        onCurrentAnswerListChange={setPrevAnswerList}
                    />
                </ContentContainer>
            )}

            {scanState === ScanState.SCAN && (
                <div>
                    <RoomScan
                        key={currentPointIndex}
                        IntroImage={currentPointIndex + 1 === 1 ? <GuyOnPhone /> : <GirlOnPhoneWithRouter />}
                        roomName={getRoomName(multiPointScanResults?.result[scanPoint.name])}
                        currentRoomIndex={currentPointIndex + 1}
                        numRooms={scanPoints.length}
                        minimumSpeed={
                            getRoomName(multiPointScanResults?.result[scanPoint.name]) === 'Router'
                                ? multiPointScanResults?.result[
                                      getSpecificScanResult(scanPoints, currentPointIndex - 1)
                                  ]?.speedResults?.downloadSpeed
                                : undefined
                        }
                        onScanStart={scanStarted}
                        onStepBackward={() => {
                            setScanStarted(false);
                        }}
                        onScanComplete={onScanComplete}
                        onTestComplete={onTestComplete}
                        onTestCanceled={onTestCanceled}
                        initialDownloadSpeed={
                            showScanResults
                                ? multiPointScanResults?.result[scanPoint.name]?.speedResults?.downloadSpeed
                                : undefined
                        }
                    />
                </div>
            )}
        </ScanContainer>
    );
};
