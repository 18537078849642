import * as React from 'react';

export const AttTv = () => (
    <svg width={70} height={20} viewBox="0 0 70 20" fill="none" role="img" aria-label="att tv logo">
        <path
            d="M46.6 0h.142c.001 1.51-.004 3.02 0 4.53 3.523.005 7.045-.008 10.568.004.732 2.245 1.471 4.487 2.198 6.732.212.654.398 1.318.67 1.952 1.004-2.877 1.935-5.779 2.863-8.679 1.985-.016 3.97-.014 5.957-.002A2978.254 2978.254 0 0162.812 20H57.32c-.369-.638-.575-1.344-.857-2.016-1.214-3.034-2.394-6.08-3.65-9.098-2.015.004-4.03-.012-6.045.023.011 1.986-.17 3.987.08 5.962.014.557.596.837 1.107.894 1.376.104 2.758.04 4.137.053-.546 1.397-1.122 2.784-1.667 4.182H46.06c-1.05-.216-2.092-.544-2.935-1.195-1.308-.957-1.958-2.562-1.897-4.102.005-4.431-.032-8.864.028-13.294C43.044.959 44.823.484 46.6 0z"
            fill="#019EDB"
        />
        <path
            opacity={0.98}
            d="M22.239 4.696c1.396-.565 3.141-.601 4.404.29 1.008.664 1.296 2.045.778 3.073-.495.844-1.37 1.381-2.229 1.856.548.6 1.129 1.174 1.718 1.738.408-.618.751-1.27 1.123-1.909.796-.001 1.592.001 2.389.015-.598 1.135-1.274 2.232-1.976 3.312.895.909 1.815 1.796 2.692 2.72-1 .002-2.01.099-3.003-.008-.462-.262-.774-.696-1.125-1.073-.744.479-1.48 1.057-2.389 1.21-.845.136-1.734.223-2.571-.002-1.016-.222-1.912-.891-2.395-1.76-.423-1.03-.303-2.298.502-3.135.42-.573 1.148-.829 1.623-1.34-.52-.768-1.255-1.515-1.174-2.502-.093-1.043.662-2.05 1.633-2.485zm1.839 1.406c-.478.048-1.07.323-1.09.831-.09.688.404 1.266.906 1.7.67-.218 1.29-.785 1.399-1.466.052-.616-.547-1.157-1.215-1.065zm-1.131 5.077c-.42.296-.76.709-.932 1.18-.196.673.23 1.467.958 1.655.883.304 1.834-.04 2.49-.615-.634-.693-1.291-1.367-1.946-2.043-.145-.132-.368-.34-.57-.177zM4.449 4.538c.924-.01 1.848-.01 2.773-.001 1.46 3.766 2.962 7.517 4.452 11.27-.994.007-1.988.005-2.981-.001-.239-.74-.49-1.477-.748-2.212-1.415-.007-2.83-.005-4.245-.007-.278.727-.532 1.46-.773 2.199-.975.02-1.95.032-2.927.041v-.058c.241-.584.5-1.16.723-1.752 1.205-3.173 2.506-6.312 3.726-9.48zm-.038 6.872c.922.066 1.849.034 2.77-.013-.4-1.371-.883-2.72-1.359-4.068-.54 1.338-.952 2.717-1.411 4.081zM9.586 4.53h9.85c.003.77.004 1.538 0 2.306-1.174.007-2.349-.004-3.523.003-.004 2.99.002 5.981-.002 8.972-.904.002-1.807.002-2.71 0-.004-2.99.001-5.98-.002-8.97-1.206-.016-2.416.035-3.62-.044.002-.756 0-1.51.007-2.266z"
            fill="#000"
        />
        <path
            opacity={0.98}
            d="M29.022 4.53h9.769c0 .74.02 1.48-.016 2.22-1.155.192-2.346.06-3.516.087-.002 2.991.003 5.983 0 8.974-.905.002-1.808.002-2.711 0-.004-2.99.002-5.981-.002-8.972-1.175-.007-2.35.004-3.524-.003-.003-.768-.003-1.537 0-2.305z"
            fill="#000"
        />
    </svg>
);
