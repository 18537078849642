import styled from '@emotion/styled';
import * as React from 'react';
import { GirlOnPhone } from './GirlOnPhone';
import { NetworkRouter } from './NetworkRouter';

const Container = styled.div`
    display: flex;
`;
const GirlOnPhoneWrapper = styled.div`
    max-width: 200px;
    align-self: flex-start;
    width: 100%;
`;

const NetworkRouterWrapper = styled.div`
    margin-top: 50px;
    margin-left: 45px;
    min-width: 70px;
    max-width: 70px;

    @media all and (max-width: 300px) {
        min-width: 50px;
        max-width: 50px;
    }
`;

export const GirlOnPhoneWithRouter = () => (
    <Container aria-label="Woman on phone with router" role="img">
        <GirlOnPhoneWrapper>
            <GirlOnPhone />
        </GirlOnPhoneWrapper>
        <NetworkRouterWrapper>
            <NetworkRouter />
        </NetworkRouterWrapper>
    </Container>
);
