import * as React from 'react';

export const DottedLine = () => (
    <svg
        width={89}
        height={3}
        viewBox="0 0 89 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="dotted line"
    >
        <path
            stroke="#000"
            strokeWidth={1.823}
            strokeLinecap="round"
            strokeDasharray="0.91 7.29"
            d="M1.798 1.724h85.759"
        />
    </svg>
);
