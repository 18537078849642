import {
    AmazonLogo,
    AttTv,
    FacebookLogo,
    GoogleLogo,
    GreenCheckmark,
    HboMax,
    HuluLogo,
    ISiteConnectivity,
    NetflixLogo,
    RedAlertCircle,
    YellowAlertTriangle,
    YoutubeLogo,
} from '@home-mgmt-shared/common-ui';

enum SupportedConnectivitySites {
    AMAZON = 'www.amazon.com',
    FACEBOOK = 'www.facebook.com',
    GOOGLE = 'www.google.com',
    HULU = 'www.hulu.com',
    NETFLIX = 'www.netflix.com',
    YOUTUBE = 'www.youtube.com',
    HBO_GO = 'www.hbo.com',
    HBO = 'play.hbomax.com',
    ATT_WATCH_TV = 'www.attwatchtv.com',
    ATT_TV = 'att.tv',
}

enum SitePerformanceStrings {
    NO_CONNECTION = 'No connection',
    POOR_CONNECTION = 'Poor connection',
    FAST_CONNECTION = 'Fast connection',
}

export interface SiteScoring {
    siteScoreIcon: JSX.Element;
    siteLabel: SitePerformanceStrings;
}
const getScorePerfMetrics = (siteScore: number): SiteScoring => {
    if (siteScore >= 2) {
        return { siteScoreIcon: GreenCheckmark(), siteLabel: SitePerformanceStrings.FAST_CONNECTION };
    }
    if (siteScore === 1) {
        return { siteScoreIcon: YellowAlertTriangle(), siteLabel: SitePerformanceStrings.POOR_CONNECTION };
    }
    return { siteScoreIcon: RedAlertCircle(), siteLabel: SitePerformanceStrings.NO_CONNECTION };
};

const getSiteIcon = (siteName: string): JSX.Element | string => {
    switch (siteName) {
        case SupportedConnectivitySites.AMAZON:
            return AmazonLogo();
        case SupportedConnectivitySites.FACEBOOK:
            return FacebookLogo();
        case SupportedConnectivitySites.GOOGLE:
            return GoogleLogo();
        case SupportedConnectivitySites.HULU:
            return HuluLogo();
        case SupportedConnectivitySites.NETFLIX:
            return NetflixLogo();
        case SupportedConnectivitySites.YOUTUBE:
            return YoutubeLogo();
        case SupportedConnectivitySites.HBO_GO:
            return HboMax();
        case SupportedConnectivitySites.HBO:
            return HboMax();
        case SupportedConnectivitySites.ATT_WATCH_TV:
            return AttTv();
        case SupportedConnectivitySites.ATT_TV:
            return AttTv();
        default:
            return siteName;
    }
};

const createSiteItem = (siteList: Record<string, number>, siteName: string): ISiteConnectivity => {
    const scorePerfMetrics: SiteScoring = getScorePerfMetrics(siteList[siteName]);
    return {
        siteIcon: getSiteIcon(siteName),
        scoreIcon: scorePerfMetrics.siteScoreIcon,
        label: scorePerfMetrics.siteLabel,
    };
};

export const parseSiteConnectivity = (sites: Record<string, number>): ISiteConnectivity[] => {
    const siteConnectivityList: ISiteConnectivity[] = [];
    Object.values(SupportedConnectivitySites).forEach((site) => {
        if (Object.prototype.hasOwnProperty.call(sites, site)) {
            siteConnectivityList.push(createSiteItem(sites, site));
        }
    });
    return siteConnectivityList;
};
