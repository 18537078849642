import * as React from 'react';

export const NetworkRouter = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 71 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Router"
        role="img"
    >
        <path
            d="M5.096 57.462c-2.476-1.113-3.322-4.295-3.133-6.66-.028-1.614 1.568-10.5 2.132-12.067.564-1.567.862-3.008 5.344-3.164 7.884-.276 26.669-.425 40.038.035 6.039.207 12.364.69 14.072.863 3.67.37 3.62 2.852 4.105 4.576.485 1.724.939 10.296 1.033 11.878.094 1.583-.518 5.124-4.608 5.422-1.863.135-8.34.187-17.394.374-9.053.188-38.05.334-41.589-1.258z"
            stroke="#000"
            strokeWidth={3.259}
        />
        <path
            d="M4.988 57.532c1.568 1.254 17.557 2.821 30.49 2.743 12.933-.078 14.422-.627 29-1.96"
            stroke="#000"
            strokeWidth={3.259}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.106 10.514C16.143 21.47 23.148 33.49 24.084 35.09m39.8-25.48c-5.502 8.588-14.615 22.769-17.483 25.48"
            stroke="#000"
            strokeWidth={3.259}
            strokeLinecap="round"
        />
        <path
            d="M11.87 45.749a1.63 1.63 0 10.282 3.246l-.282-3.246zm7.79 3.242a1.63 1.63 0 10-.357-3.239l.357 3.24zm5.061-3.013a1.63 1.63 0 100 3.259v-3.259zm8.054 3.337a1.63 1.63 0 10.156-3.255l-.156 3.255zm5.413-3.257a1.63 1.63 0 100 3.258v-3.258zm7.307 3.18a1.63 1.63 0 00-.092-3.257l.092 3.257zm-33.343-.243c1.045-.09 2.264-.04 3.602.017 1.274.053 2.689.113 3.906-.02l-.357-3.24c-.922.102-2.072.06-3.412.004-1.277-.054-2.724-.12-4.021-.007l.282 3.246zm12.57.242c3.365 0 6.137-.014 8.053.078l.156-3.255c-2.017-.097-4.899-.082-8.21-.082v3.259zm13.466.08c2.154 0 4.545 0 7.307-.079l-.092-3.257c-2.711.076-5.06.077-7.215.077v3.258z"
            fill="#000"
        />
        <path
            d="M53.749 47.987c-.268-.505-.282-1.733 1.022-2.277.977-.408 1.838.466 2.106.97.268.506.148 1.481-.895 1.917-1.043.435-1.966-.105-2.233-.61zM33.415 23.06c-.79-1.938 1.098-4.627 3.835-4.88 3.832-.355 5.532 4.516 2.49 6.81-3.044 2.292-6.144-.438-6.325-1.93z"
            stroke="#000"
            strokeWidth={3.259}
        />
        <path
            d="M17.168 9.766c-.63.618-.61 1.641.042 2.287.652.645 1.692.667 2.321.05l-2.363-2.337zm8.97-4.533l.784 1.45-.785-1.45zM36.634 2.79l.062 1.617-.062-1.617zm15.736 8.497c.541.737 1.57.925 2.297.42.727-.504.877-1.51.336-2.247l-2.633 1.827zm-32.84.817c1.327-1.301 3.697-3.588 7.39-5.42l-1.568-2.9c-4.148 2.057-6.8 4.626-8.185 5.983l2.363 2.337zm7.39-5.42c2.066-1.024 5.612-2.288 9.776-2.277l-.123-3.234c-4.807-.012-8.85 1.436-11.221 2.61l1.569 2.901zm9.776-2.277c6.54.016 12.676 2.804 15.674 6.88l2.633-1.827C51.2 4.287 43.895 1.19 36.574 1.172l.123 3.234zM24.178 13.736c-.642.603-.648 1.63-.013 2.293.635.663 1.67.712 2.313.11l-2.3-2.403zm11.567-3.07l.077 1.623-.077-1.623zm11.429 5.314c.683.614 1.716.583 2.307-.07.59-.653.515-1.68-.169-2.295l-2.138 2.365zm-20.696.159c1.53-1.436 5.38-3.83 9.344-3.85l-.154-3.246c-5.053.025-9.623 2.942-11.49 4.693l2.3 2.403zm9.344-3.85c3.65-.019 5.69.266 7.175.818 1.45.539 2.514 1.38 4.177 2.873l2.138-2.365c-1.686-1.514-3.17-2.757-5.281-3.542-2.076-.771-4.61-1.05-8.363-1.03l.154 3.246z"
            fill="#000"
        />
    </svg>
);
