import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { getColorFromSpeeds, getPercentChange } from '../api';
import { SpeedPoint, SpeedType } from '../models';

const ChartLegendContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: flex-start;
    margin-left: 1rem;
    margin-top: 1rem;
`;

type LabelIconProps = {
    color: string;
};

const LabelIcon = styled.div`
    width: 12.95px;
    height: 12.95px;
    background: ${(props: LabelIconProps) => props.color};
    border-radius: 50%;
    margin-right: 0.5rem;
`;

const ChartLegendItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const ChartLegendItemText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
`;

const Speed = styled.div`
    color: ${(props) => props.theme.components?.overviewSpeedChart?.textColor ?? '#000'};
    font-size: 1.25rem;
    font-weight: 700;
`;

const Units = styled.div`
    color: ${(props) => props.theme.components?.overviewSpeedChart?.textColor ?? '#000'};
    font-size: 0.875rem;
    line-height: 120%;
    font-weight: 700;
`;

const Label = styled.div`
    color: ${(props) => props.theme.components?.overviewSpeedChart?.textColor ?? '#000'};
    font-size: 0.875rem;
    line-height: 120%;
    font-weight: 300;
    margin-right: 0.5rem;
`;

const PercentChip = styled.div<LabelIconProps>`
    color: ${(props) => props.theme.components?.overviewSpeedChart?.textColor ?? '#000'};
    font-size: 0.75rem;
    font-weight: 400;
    background: ${(props) => props.color ?? '#37e7a7'};
    padding: 0.25rem;
    border-radius: 2px;
    margin-top: 0.25rem;
`;

const Filler = styled.div`
    color: white;
    font-size: 0.75rem;
    font-weight: 400;
    background: none;
    padding: 0.25rem;
    border-radius: 2px;
    margin-top: 0.25rem;
    user-select: none;
`;

const LabelFiller = styled.div`
    height: 20px;
`;

interface SpeedChartLegendProps {
    data: SpeedPoint[];
    idealSpeed?: number;
    speedType?: SpeedType;
}

export const SpeedChartLegend = ({ data, idealSpeed, speedType }: SpeedChartLegendProps): JSX.Element => {
    const theme: Theme = useTheme();

    if (data.length <= 0) {
        return <></>;
    }
    const currentSpeed = data[data.length - 1].speed;
    let percentChange = getPercentChange(data);
    const isDecrease = percentChange && percentChange < 0;
    if (percentChange && percentChange < 0) {
        percentChange *= -1;
    }
    const chipColor = getColorFromSpeeds(data, theme);

    return (
        <ChartLegendContainer>
            <ChartLegendItem>
                <LabelIcon
                    color={
                        (speedType === SpeedType.UPLOAD
                            ? theme.components?.overviewSpeedChart?.uploadSpeedColor ?? '#8223D2'
                            : theme.components?.overviewSpeedChart?.downloadSpeedColor) ?? '#4A68F9'
                    }
                />
                <ChartLegendItemText>
                    <Speed>{currentSpeed}&nbsp;</Speed>
                    <Units>Mbps&nbsp;</Units>
                    <Label>current</Label>
                </ChartLegendItemText>
                {percentChange ? (
                    <PercentChip color={chipColor ?? '#37E7A7'}>
                        {isDecrease ? 'Decreased' : 'Increased'}&nbsp;{percentChange.toFixed(0)}%
                    </PercentChip>
                ) : (
                    <Filler>filler</Filler>
                )}
            </ChartLegendItem>
            {idealSpeed && speedType === SpeedType.DOWNLOAD ? (
                <ChartLegendItem>
                    <LabelIcon color={theme.components?.overviewSpeedChart?.idealSpeedColor ?? '#37E7A7'} />
                    <ChartLegendItemText>
                        <Speed>{idealSpeed}&nbsp;</Speed>
                        <Units>Mbps&nbsp;</Units>
                        <Label>ideal</Label>
                    </ChartLegendItemText>
                </ChartLegendItem>
            ) : (
                <LabelFiller />
            )}
        </ChartLegendContainer>
    );
};
