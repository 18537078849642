import * as React from 'react';

export const Clock = (strokeColor = 'black') => (
    <svg
        width={25}
        height={24}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="clock"
    >
        <path
            clipRule="evenodd"
            d="M12.078 23.501c6.351 0 11.5-5.149 11.5-11.5s-5.149-11.5-11.5-11.5-11.5 5.149-11.5 11.5 5.149 11.5 11.5 11.5z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M18.078 18.001l-4.943-4.942" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path
            clipRule="evenodd"
            d="M12.078 13.501a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M12.078 6.501v4" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
