import * as React from 'react';

export const MiniLaptop = (strokeColor = 'black') => (
    <svg
        width={24}
        height={18}
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="laptop"
    >
        <path
            clipRule="evenodd"
            d="M8.5 13.5c0 1.1 1.567 2 3.5 2s3.5-.9 3.5-2h5V2.167c0-.92-.746-1.667-1.667-1.667H5.167C4.247.5 3.5 1.246 3.5 2.167V13.5h5z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            clipRule="evenodd"
            d="M15.5 13.5c0 1.1-1.567 2-3.5 2s-3.5-.9-3.5-2h-8c.588 2.35 2.7 4 5.123 4h12.754a5.281 5.281 0 005.123-4h-8z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
