import * as React from 'react';

interface SpeedIconSadProps {
    fillColor?: string;
}

export const SpeedIconSad = ({ fillColor = '#000' }: SpeedIconSadProps) => (
    <svg
        width="50"
        height="50"
        viewBox="0 0 65 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="sad speed icon"
    >
        <path
            d="M15.6296 45.6242C17.3226 51.0953 26.1943 55.3746 32.1062 55.3746C44.5322 55.3746 55.6172 44.7968 55.6172 32.3708C55.6172 19.9448 45.1085 9.18359 32.6825 9.18359C20.2565 9.18359 8.6027 19.1184 8.6027 31.5444C8.6027 36.4521 11.1052 44.766 14.0705 48.7642M38.3188 15.5488L37.2476 18.8952M46.9648 22.8027L43.6947 24.363M48.9248 33.919L45.9688 33.397M27.0307 15.5488L28.5154 18.9768M29.6577 29.4228C25.893 27.0078 23.1848 25.9094 18.3849 22.8027C23.1848 25.9094 25.9745 28.3951 29.7393 30.8102M25.5955 45.4671C25.5955 42.1546 37.2476 36.4046 40.908 45.4671M32.6748 28.3619C31.0188 28.3619 29.6748 29.3968 29.6748 31.0528C29.6748 32.7088 31.0188 34.0528 32.6748 34.0528C34.3308 34.0528 35.6748 32.7088 35.6748 31.0528C35.6748 29.3968 34.3308 28.3619 32.6748 28.3619Z"
            stroke={fillColor}
            strokeWidth="2.45477"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M43.9845 52.0111C43.9845 52.0111 39.7284 54.7612 30.6919 54.7613C21.6553 54.7614 15.893 46.6415 15.893 46.6415"
            stroke={fillColor}
            strokeWidth="2.45477"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.5587 37.074C20.3675 38.1734 20.9041 33.388 20.1365 30.1299C18.9805 30.4023 17.5611 31.3192 16.4091 32.6721C15.2097 34.0806 14.5668 36.499 16.5587 37.074Z"
            stroke={fillColor}
            strokeWidth="2"
            strokeLinejoin="round"
        />
    </svg>
);
