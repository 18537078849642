import React from 'react';

interface CableProps {
    strokeColor?: string;
}

export const Cable = ({ strokeColor = 'black' }: CableProps = {}) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="cable"
    >
        <path d="M4.53125 5.97461V7.97461" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M19.5312 16.9746V18.9746" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.53125 4.97461C0.53125 4.42232 0.978965 3.97461 1.53125 3.97461H7.53125C8.08353 3.97461 8.53125 4.42232 8.53125 4.97461V8.97461C8.53125 9.52689 8.08353 9.97461 7.53125 9.97461H1.53125C0.978965 9.97461 0.53125 9.52689 0.53125 8.97461V4.97461Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.03125 0.974609H7.03125C7.30739 0.974609 7.53125 1.19847 7.53125 1.47461V3.97461H1.53125V1.47461C1.53125 1.19847 1.75511 0.974609 2.03125 0.974609Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.5312 19.9746C23.5312 20.5269 23.0835 20.9746 22.5312 20.9746H16.5312C15.979 20.9746 15.5312 20.5269 15.5312 19.9746V15.9746C15.5312 15.4223 15.979 14.9746 16.5312 14.9746H22.5312C23.0835 14.9746 23.5312 15.4223 23.5312 15.9746V19.9746Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.0312 23.9746H17.0312C16.7551 23.9746 16.5312 23.7508 16.5312 23.4746V20.9746H22.5312V23.4746C22.5312 23.7508 22.3074 23.9746 22.0312 23.9746Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.53125 9.97461H5.53125V13.9746H3.53125V9.97461Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5312 10.9746H20.5312V14.9746H18.5312V10.9746Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M4.53125 13.9746V19.9746C4.53125 22.1837 6.32211 23.9746 8.53125 23.9746C10.7404 23.9746 12.5312 22.1837 12.5312 19.9746V4.47461C12.5312 2.54161 14.0983 0.974609 16.0312 0.974609C17.9642 0.974609 19.5312 2.54161 19.5312 4.47461V10.9746"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
