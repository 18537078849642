import { Theme } from '@emotion/react';

export const webScanTheme: Theme = {
  font: {
    type: 'ATTAleckSans_Rg',
    bold: 'ATTAleckSans_Bd',
    accentColor: '',
  },
  accentColors: {
    primary: '#0057B8',
    secondary: '#009FDB',
    tertiary: '#6E767D',
  },
  button: {
    primaryColor: '#0057B8',
    selectedColor: '#0057B8',
    disabledColor: '#6E767D',
    disabledTextColor: '#000000',
    defaultTextColor: '#000000',
  },
  special: {
    primaryColor: '#0057B8',
    secondaryColor: '#00A2FB',
  },
  spinner: {
    primaryColor: '#0057B8',
  },
  progressBar: {
    primaryColor: '#0057B8',
  },
  text: {
    subText: '#6E767D',
  },
  components: {
    multiScanProgress: {
      pulsingLargeLeftPosition: '-15px',
      pulsingSmallLeftPosition: '-107px',
      progressFillColor: '#0057B8',
    },
    resultsPage: {
      multiScanChipColor: '#0057B8',
    },
  },
};
