import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import React, { ReactNode } from 'react';
import { TooltipIcon } from '../Icons';

const InformationAlertContainer = styled.div`
    width: calc(100% - 1.5rem);
    padding: 0.45rem 0.75rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    background: #f0f0f5;
    border-radius: 4px;
`;

const InformationAlertTextContainer = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 0.875rem;
    text-align: left;
    color: ${(props) => props.theme.button?.defaultTextColor};
    line-height: 130%;
`;

const TooltipIconContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: auto;
    height: 100%;
`;

interface InformationAlertProps {
    children: ReactNode;
    tooltip?: () => void;
}

export const InformationAlert = ({ children, tooltip }: InformationAlertProps) => {
    const theme = useTheme();

    return (
        <InformationAlertContainer>
            <InformationAlertTextContainer>{children}</InformationAlertTextContainer>
            {tooltip && (
                <TooltipIconContainer>
                    <TooltipIcon fillColor={theme.button?.primaryColor} />
                </TooltipIconContainer>
            )}
        </InformationAlertContainer>
    );
};
