import React from 'react';

export const ArrowCurveDown = () => (
    <svg
        width="80"
        height="74"
        viewBox="0 0 80 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="arrow curved down"
    >
        <g clipPath="url(#clip0)">
            <path
                d="M54.2548 57.2918C53.9774 57.6312 48.2866 65.6279 47.5364 64.8784C45.1858 62.5298 43.4743 58.2934 42.6308 55.1457"
                stroke="black"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M28.5868 7.78898C62.2355 26.0597 48.3171 60.0691 48.1665 61.6829C48.1285 62.0902 47.3228 63.843 47.8969 62.6851"
                stroke="black"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <rect
                    width="62"
                    height="51"
                    fill="white"
                    transform="matrix(-0.895953 -0.444149 -0.444149 0.895953 79.0947 28.0322)"
                />
            </clipPath>
        </defs>
    </svg>
);
