import styled from '@emotion/styled';
import {
    Close,
    DownloadUploadSpeeds,
    FilledButton,
    IStreamingQualityGuideline,
    SpeedCheckup,
} from '@home-mgmt-shared/common-ui';
import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';
import React from 'react';

const SpeedPointModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.5;
    transition: all 0.3s;
    z-index: 1000;
`;

const SpeedPointModal = styled.div`
    left: -0.25rem;
    margin: 0 1rem;
    position: absolute;
    margin-top: 1rem;
    width: 35.5rem;
    max-width: calc(100% - 3.5rem);
    border-radius: 1rem;
    background: white;
    z-index: 1001;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    justify-content: flex-start;
    align-items: flex-start;

    @media all and (min-width: 35.5rem) {
        left: unset;
        width: max-content;
    }

    animation: animateModal 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    @keyframes animateModal {
        0% {
            transform: scale(1) translateY(0px);
            opacity: 0;
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        }
        1% {
            transform: scale(1) translateY(4rem);
            opacity: 0.5;
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        }
        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
            box-shadow: 0 0 500px rgba(241, 241, 241, 0);
        }
    }
`;

const SpeedPointModalHeader = styled.div`
    font-size: 1.5rem;
    line-height: 150%;
    color: #000;
`;

const SpeedsContainer = styled.div`
    @media all and (max-width: 35.5rem) {
        width: calc(100% - 2rem);
    }
`;

const SpeedPointModalDate = styled.div`
    font-size: 0.875rem;
    line-height: 150%;
    color: #6e767d;
`;

const SpeedPointModalButton = styled(FilledButton)`
    width: 6rem;
    height: 3rem;
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 0;
`;

const SpeedPointModalCloseContainer = styled.div`
    position: absolute;
    margin-right: 1rem;
    width: calc(100% - 1rem - 13px);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const SpeedPointModalClose = styled.div`
    cursor: pointer;
`;

interface OverviewSpeedPointModalProps {
    date: string;
    downloadSpeed: number;
    uploadSpeed: number;
    streamingGuidelines?: IStreamingQualityGuideline[];
    onCloseModal: () => void;
}

export const OverviewSpeedPointModal = ({
    date,
    downloadSpeed,
    uploadSpeed,
    streamingGuidelines,
    onCloseModal,
}: OverviewSpeedPointModalProps) => (
    <>
        <SpeedPointModal>
            <SpeedPointModalCloseContainer>
                <SpeedPointModalClose onClick={onCloseModal}>
                    <Close />
                </SpeedPointModalClose>
            </SpeedPointModalCloseContainer>
            <SpeedPointModalHeader>Speed test details</SpeedPointModalHeader>
            <SpeedPointModalDate>{date}</SpeedPointModalDate>
            <SpeedsContainer>
                <DownloadUploadSpeeds
                    downloadSpeed={downloadSpeed}
                    uploadSpeed={uploadSpeed}
                    status={SpeedTestStatus.completed}
                />
            </SpeedsContainer>
            {streamingGuidelines && (
                <SpeedCheckup
                    downloadSpeed={downloadSpeed}
                    uploadSpeed={uploadSpeed}
                    streamQualityGuidelines={streamingGuidelines}
                />
            )}
            <SpeedPointModalButton onClick={onCloseModal}>Close</SpeedPointModalButton>
        </SpeedPointModal>
        <SpeedPointModalBackground />
    </>
);
