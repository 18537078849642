import React from 'react';
import styled from '@emotion/styled';
import { FilledButton } from '../Buttons';

const StartButton = styled(FilledButton)`
    background-color: ${(props) => props.theme.button?.primaryColor};
    color: #ffffff;
    font-family: ${(props) => props.theme.font?.type};
    transition: transform 0.2s ease-in;
    user-select: none;
    max-width: 170px;
    div&:active {
        transform: scale(0.96);
    }

    display: flex;
    flex-direction: column;
    padding: 14px 30px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 14px;
    margin-top: -135px;
    margin-bottom: 100px;
    @media all and (max-width: 450px) {
        margin-top: -160px;
    }
`;

interface SpeedTestStartButtonProps {
    startSpeedTest: () => void;
}

const SpeedTestStartButton = ({ startSpeedTest }: SpeedTestStartButtonProps) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <StartButton
        data-test-cy="speed-test-home-start-button"
        onClick={startSpeedTest}
        tabIndex={0}
        aria-label="start speed test button to test internet download and upload speeds"
        role="button"
    >
        Start speed test
    </StartButton>
);

export default SpeedTestStartButton;
