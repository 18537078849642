import React from 'react';
import styled from '@emotion/styled';

interface ISpeedDisplayProps {
    label: string;
    icon: JSX.Element;
    speed: string;
    units: string;
    loadingIndicator?: JSX.Element;
    isLoading?: boolean;
}

const Header = styled.h1`
    font-family: ${(props) => props.theme.font?.type};
    font-weight: bold;
    font-size: 14px;
    margin: 0px;
    display: inline-block;
`;

const LoadingWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    flex-wrap: nowrap;
    margin: 0px;
    max-width: 100px;
`;

const Speed = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 36px;
    color: ${(props) => props.theme.font?.accentColor};
`;

const SpeedDisplayContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    flex-wrap: nowrap;
    margin: 0px;
`;

const SpeedDisplayIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5px;
`;

const SpeedDisplayValue = styled.div`
    display: flex;
    margin-left: 3px;
    padding: 5px 0px;
    align-items: center;
`;

const SpeedDisplayComponent = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0;
    justify-content: flex-start;
`;
const Units = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 14px;
    color: #000000;
    display: inline-flex;
    margin-top: 15px;
    margin-left: 5px;
`;
export const SpeedDisplay = ({ label, icon, speed, units, loadingIndicator, isLoading }: ISpeedDisplayProps) => (
    <SpeedDisplayComponent>
        <Header>{label}</Header>
        {!isLoading ? (
            <SpeedDisplayContainer>
                <SpeedDisplayIcon>{icon}</SpeedDisplayIcon>
                <SpeedDisplayValue>
                    <Speed data-test-cy={`${label}`}>{speed}</Speed>
                </SpeedDisplayValue>
                <Units>{units}</Units>
            </SpeedDisplayContainer>
        ) : (
            <LoadingWrapper>{loadingIndicator}</LoadingWrapper>
        )}
    </SpeedDisplayComponent>
);
