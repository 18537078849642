import styled from '@emotion/styled';
import { IScanResults, WebScan } from '@home-mgmt-shared/web-scan';
import React, { useEffect } from 'react';

const InlineSpeedTestContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #d5d6da;
`;

const HiddenIntroContainer = styled.div`
    width: 100%;
    height: 0;
    overflow: hidden;
`;

interface HiddenIntroProps {
    startSpeedTest: () => void;
}

const HiddenIntro = ({ startSpeedTest }: HiddenIntroProps) => {
    useEffect(() => {
        startSpeedTest();
    }, [startSpeedTest]);

    return <HiddenIntroContainer />;
};

interface OverviewInlineSpeedTestProps {
    onScanComplete?: (results: IScanResults) => void;
    onScanCancel?: () => void;
}

export const OverviewInlineSpeedTest = ({ onScanComplete, onScanCancel }: OverviewInlineSpeedTestProps) => (
    <InlineSpeedTestContainer>
        <WebScan
            onScanComplete={onScanComplete}
            onScanCancel={onScanCancel}
            IntroPageComponent={({ startSpeedTest }) => <HiddenIntro startSpeedTest={startSpeedTest} />}
        />
    </InlineSpeedTestContainer>
);
