import React from 'react';

interface GearProps {
    strokeColor?: string;
}

export const Gear = ({ strokeColor = 'black' }: GearProps = {}) => (
    <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="gear"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8897 8.15625C20.6618 8.63527 20.6476 9.18844 20.8506 9.67852C21.0536 10.1686 21.4548 10.5497 21.9547 10.7273L23.2197 11.1773C23.9677 11.4428 24.4675 12.1505 24.4675 12.9443C24.4675 13.738 23.9677 14.4458 23.2197 14.7113L21.9547 15.1613C21.4548 15.3388 21.0536 15.7199 20.8506 16.21C20.6476 16.7001 20.6618 17.2532 20.8897 17.7323L21.4677 18.9433C21.8101 19.6603 21.6634 20.5153 21.1015 21.0771C20.5397 21.639 19.6847 21.7857 18.9677 21.4433L17.7547 20.8673C17.2756 20.6394 16.7225 20.6252 16.2324 20.8282C15.7423 21.0312 15.3612 21.4324 15.1837 21.9323L14.7337 23.1973C14.4677 23.9446 13.7604 24.4437 12.9672 24.4437C12.174 24.4437 11.4666 23.9446 11.2007 23.1973L10.7507 21.9323C10.5729 21.4323 10.1916 21.0311 9.70136 20.8281C9.21112 20.6251 8.65782 20.6393 8.17866 20.8673L6.96766 21.4433C6.2506 21.787 5.39465 21.6409 4.83235 21.0786C4.27005 20.5163 4.12386 19.6603 4.46766 18.9433L5.04366 17.7303C5.27155 17.2512 5.28575 16.6981 5.08274 16.208C4.87973 15.7179 4.47853 15.3368 3.97866 15.1593L2.71366 14.7093C1.96563 14.4438 1.46582 13.736 1.46582 12.9423C1.46582 12.1485 1.96563 11.4408 2.71366 11.1753L3.97866 10.7253C4.47799 10.5476 4.87877 10.1669 5.08173 9.67729C5.2847 9.1877 5.27086 8.63507 5.04366 8.15625L4.46766 6.94325C4.12386 6.2262 4.27005 5.37025 4.83235 4.80795C5.39465 4.24565 6.2506 4.09946 6.96766 4.44325L8.18066 5.01925C8.65936 5.24656 9.21189 5.26069 9.70158 5.05815C10.1913 4.8556 10.5724 4.4553 10.7507 3.95625L11.2007 2.69125C11.4666 1.94395 12.174 1.44482 12.9672 1.44482C13.7604 1.44482 14.4677 1.94395 14.7337 2.69125L15.1837 3.95625C15.3612 4.45612 15.7423 4.85733 16.2324 5.06034C16.7225 5.26335 17.2756 5.24915 17.7547 5.02125L18.9677 4.44325C19.6847 4.10081 20.5397 4.24751 21.1015 4.80937C21.6634 5.37123 21.8101 6.22624 21.4677 6.94325L20.8897 8.15625Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9668 17.4443C15.4521 17.4443 17.4668 15.4296 17.4668 12.9443C17.4668 10.4591 15.4521 8.44434 12.9668 8.44434C10.4815 8.44434 8.4668 10.4591 8.4668 12.9443C8.4668 15.4296 10.4815 17.4443 12.9668 17.4443Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
