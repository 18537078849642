import axios from 'axios';
import * as service from './serviceUrls';
import * as c from '../constants';
import { sortArrofObjByDate } from '../utils/utils';

const headers = {
  headers: {
    'Content-Type': 'text/plain',
  },
};

const getHomeGraphData = (props) => {
  const postPayload = {
    authToken:
      (props && props.hasOwnProperty('authToken') && props.authToken) ||
      c.ATT_KEY,
    tool:
      (props && props.hasOwnProperty('hostArray') && props.hostArray[0]) ||
      c.ATT_KEY,
    carrier:
      (props && props.hasOwnProperty('hostArray') && props.hostArray[1]) ||
      c.ATT_KEY,
    sessionId: props && props.hasOwnProperty('sessionId') && props.sessionId,
    nrOfDays: c.DEFAULT_NO_OF_DAYS,
    table: props && props.hasOwnProperty('table') && props.table,
    applicationsary:
      props && props.hasOwnProperty('path') && props.applicationsArray,
  };

  if (process.env.DEVELOP) {
    return axios
      .get('../json/homegraphv2.json', headers)
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
  return axios
    .post(`${service.getHomeGraphsUrl}`, postPayload, headers)
    .then(function (response) {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const getSessionIdsPerClient = (applicationsArray) => {
  const postPayload = {
    authToken: c.ATT_KEY,
    tool: c.ATT_KEY,
    carrier: c.ATT_KEY,
    nrOfDays: c.DEFAULT_NO_OF_DAYS,
    path: c.PATH_VALUE_AS_FALSE,
    applicationsary: applicationsArray,
  };

  return axios
    .post(`${service.getListSessionsUrl}`, postPayload, headers)
    .then(function (response) {
      if (
        response &&
        response.hasOwnProperty('data') &&
        response.data.hasOwnProperty('sessions')
      ) {
        response.data.sessions =
          response.data.sessions.length > 0 &&
          sortArrofObjByDate(response.data.sessions, 'created_at');
      }
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

const getQuickTips = () => {
  if (process.env.DEVELOP) {
    return axios
      .get('../json/QuickTips.json', headers)
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
  return axios
    .get(`${service.getQuickTipsUrl}`, headers)
    .then(function (response) {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

export { getHomeGraphData, getSessionIdsPerClient, getQuickTips };
