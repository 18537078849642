import { Answer } from '@home-mgmt-shared/flow-ui';
import { IScanResults, SpeedTestResult } from '@home-mgmt-shared/web-scan';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBandwidthCalculatorResults, BandwidthCalculatorStatus } from '../models';

export const resultsSubject = new BehaviorSubject<IBandwidthCalculatorResults>({
    overallStatus: BandwidthCalculatorStatus.INCOMPLETE,
});

export const results$: Observable<IBandwidthCalculatorResults> = resultsSubject.asObservable();

export const setResultsStatus = (status: BandwidthCalculatorStatus) => {
    resultsSubject.next({ ...resultsSubject.value, overallStatus: status });
};
export const resetResults = () => {
    resultsSubject.next({
        overallStatus: BandwidthCalculatorStatus.INCOMPLETE,
    });
};

const calculateIdealSpeedsFromFlow = (flowState: Record<string, Answer[]>) => {
    let overallStatus: BandwidthCalculatorStatus = BandwidthCalculatorStatus.INCOMPLETE;

    let idealDownload = 0;
    Object.keys(flowState).forEach((questionKey: string) => {
        const answers = flowState[questionKey];
        answers.forEach((answer: Answer) => {
            idealDownload += answer.option.weight ?? 0;
        });
    });

    const actualDownloadSpeed = resultsSubject.value.speedResults?.downloadSpeed;

    if (actualDownloadSpeed && idealDownload > 0) {
        const percentage = actualDownloadSpeed / idealDownload;
        if (percentage < 0.2) {
            overallStatus = BandwidthCalculatorStatus.SLOW;
        } else if (percentage < 0.8) {
            overallStatus = BandwidthCalculatorStatus.OKAY;
        } else if (percentage < 1) {
            overallStatus = BandwidthCalculatorStatus.DECENT;
        } else {
            overallStatus = BandwidthCalculatorStatus.GOOD;
        }
    }

    const idealSpeeds: SpeedTestResult = { downloadSpeed: idealDownload, uploadSpeed: 0 };
    resultsSubject.next({ ...resultsSubject.value, idealSpeeds, overallStatus });
};

export const storeFlowResults = (flowState: Record<string, Answer[]>, numActivities: number) => {
    const flowDone = Object.keys(flowState).length === numActivities;
    if (flowDone) {
        resultsSubject.next({ ...resultsSubject.value, flowResults: flowState });
        calculateIdealSpeedsFromFlow(flowState);
    } else {
        resultsSubject.next({
            ...resultsSubject.value,
            flowResults: flowState,
            overallStatus: BandwidthCalculatorStatus.INCOMPLETE,
        });
    }
};

export const storeScanResults = (scanResults: IScanResults) => {
    let uploadSpeed = Number(scanResults.uploadSpeed);
    let downloadSpeed = Number(scanResults.downloadSpeed);

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(uploadSpeed)) {
        uploadSpeed = 0;
    }

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(downloadSpeed)) {
        downloadSpeed = 0;
    }

    const speedResults: SpeedTestResult = { downloadSpeed, uploadSpeed };
    resultsSubject.next({ ...resultsSubject.value, speedResults });
};
