import { useEffect } from 'react';
import { FlowData, LOCAL_STORAGE_PROPERTY } from '../models';

interface SaveResultsProps {
    flowData?: FlowData;
}

export const useSaveResults = ({ flowData }: SaveResultsProps) => {
    useEffect(() => {
        if (flowData) {
            let flowDataArray: FlowData[] = [];

            // Not logged in yet. Store results, then login, and then go to overview
            const savedFlowDataJSON = localStorage.getItem(LOCAL_STORAGE_PROPERTY);
            if (savedFlowDataJSON) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                flowDataArray = JSON.parse(savedFlowDataJSON);
            }

            if (flowDataArray.find((data) => data.scanId === flowData.scanId) === undefined) {
                flowDataArray.push(flowData);
            }

            localStorage.setItem(LOCAL_STORAGE_PROPERTY, JSON.stringify(flowDataArray));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};
