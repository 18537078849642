import { IStreamingQualityGuideline } from '@home-mgmt-shared/common-ui';
import { Answer } from '@home-mgmt-shared/flow-ui';

export const filterStreamingCapabiltiesFromResults = (
    capabilities: IStreamingQualityGuideline[],
    flowResults: Record<string, Answer[]> = {},
): IStreamingQualityGuideline[] => {
    const selectedOnlineActivities = flowResults.onlineActivities ?? [];

    const filteredCapabilities: IStreamingQualityGuideline[] = selectedOnlineActivities.flatMap((activity) =>
        capabilities.filter((c) => c.key === activity.option.optionMeta?.capabilityKey),
    );

    filteredCapabilities.sort((a, b) => a.downloadReq - b.downloadReq);
    return filteredCapabilities;
};
