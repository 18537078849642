import styled from '@emotion/styled';
import { MultiPointEvents, usePageAnalytics } from '@home-mgmt-shared/analytics';
import React, { useState } from 'react';
import { useTweekValue } from 'react-tweek';
import { FilledButton, LinkButton } from '../Buttons';
import { SpeedTestLegal } from '../SpeedTest';
import { ReminderModal } from './ReminderModal';

type IntroPageProps = {
    header?: string;
    // subtitle?: string;
    introImage?: JSX.Element;
    nextButtonText?: string;
    nextButtonOnClick?: () => void;
};

const IntroPageContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: ${(props) => props.theme.font?.type};
    margin: 2rem 0;
`;

const MaxWidth = styled.div`
    max-width: 23rem; // Make sure to set the margins the same as the flow questions
`;

const ImageAndCaption = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 1.5rem;
    line-height: 135%;
    margin-bottom: 2.5rem;
    text-align: center;
`;

const ImageContainer = styled.div`
    max-height: 300px;
    max-width: 300px;
    margin-left: 35px;
`;

const SpeedPointModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 0.5;
    transition: all 0.3s;
    z-index: 1000;
`;

const WifiWarning = styled.div`
    margin-bottom: 1.5625rem;
    font-size: 1rem;
    font-family: ${(props) => props.theme.font?.type};
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    text-align: center;
    line-height: 150%;
`;

const Header = styled.div`
    font-size: 2rem;
    font-family: ${(props) => props.theme.font?.type};
    text-align: left;
    margin-left: 1rem;
    margin-bottom: 2rem;
    line-height: 120%;
`;

const FilledButtonContainer = styled.div`
    display: flex;
    margin-bottom: 1.25rem;
`;

const LegalContainer = styled.div`
    margin-left: 1rem;
`;
export const IntroPage = ({
    introImage,
    header = 'See if a weak Wi-Fi signal is limiting your speed',
    // subtitle = "Let's compare Wi-Fi speed in two different areas of your home.",
    nextButtonText = 'Get started',
    nextButtonOnClick,
}: IntroPageProps) => {
    const showReminderLink: boolean = useTweekValue<boolean>('network_scan/multi_point/show_reminder_link', false);
    usePageAnalytics(MultiPointEvents.LandingPage);
    const [modalIsActive, setModalIsActive] = useState<boolean>(false);
    return (
        <IntroPageContainer>
            <MaxWidth>
                <ImageAndCaption>
                    <Header> {header} </Header>
                    {/* <Subtitle> {subtitle} </Subtitle> */}
                    <ImageContainer>{introImage}</ImageContainer>
                </ImageAndCaption>
                <WifiWarning>Make sure you&apos;re connected to your home Wi-Fi before you get started.</WifiWarning>
                {nextButtonText !== undefined && nextButtonOnClick !== undefined && (
                    <FilledButtonContainer>
                        <FilledButton
                            style={{ marginTop: '0' }}
                            analyticEventName={nextButtonText}
                            onClick={nextButtonOnClick}
                        >
                            {nextButtonText}
                        </FilledButton>
                    </FilledButtonContainer>
                )}
                {showReminderLink && (
                    <LinkButton
                        onClick={() => setModalIsActive(true)}
                        underlined
                        analyticEventName="Remind Me (False door)"
                    >
                        Remind me
                    </LinkButton>
                )}
                {modalIsActive && <SpeedPointModalBackground />}
                {modalIsActive && <ReminderModal onCloseClicked={() => setModalIsActive(false)} />}
                <LegalContainer>
                    <SpeedTestLegal textAlign="left" />
                </LegalContainer>
            </MaxWidth>
        </IntroPageContainer>
    );
};
