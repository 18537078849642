import React from 'react';

interface HierarchyProps {
    strokeColor?: string;
}

export const Hierarchy = ({ strokeColor = 'black' }: HierarchyProps = {}) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="hierarchy"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5654 15.3184C15.7746 15.3184 17.5654 13.5275 17.5654 11.3184C17.5654 9.10922 15.7746 7.31836 13.5654 7.31836C11.3563 7.31836 9.56543 9.10922 9.56543 11.3184C9.56543 13.5275 11.3563 15.3184 13.5654 15.3184Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.06641 7.81836C2.89483 7.81836 3.56641 7.14679 3.56641 6.31836C3.56641 5.48993 2.89483 4.81836 2.06641 4.81836C1.23798 4.81836 0.566406 5.48993 0.566406 6.31836C0.566406 7.14679 1.23798 7.81836 2.06641 7.81836Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.0664 4.31836C22.8948 4.31836 23.5664 3.64679 23.5664 2.81836C23.5664 1.98993 22.8948 1.31836 22.0664 1.31836C21.238 1.31836 20.5664 1.98993 20.5664 2.81836C20.5664 3.64679 21.238 4.31836 22.0664 4.31836Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.06641 24.3184C2.89483 24.3184 3.56641 23.6468 3.56641 22.8184C3.56641 21.9899 2.89483 21.3184 2.06641 21.3184C1.23798 21.3184 0.566406 21.9899 0.566406 22.8184C0.566406 23.6468 1.23798 24.3184 2.06641 24.3184Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5654 24.3184C14.3939 24.3184 15.0654 23.6468 15.0654 22.8184C15.0654 21.9899 14.3939 21.3184 13.5654 21.3184C12.737 21.3184 12.0654 21.9899 12.0654 22.8184C12.0654 23.6468 12.737 24.3184 13.5654 24.3184Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M3.12598 21.7572L10.746 14.1562" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.3994 8.49539L21.0044 3.87939" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.41602 6.96826L9.88802 9.74326" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.6287 14.8475L17.0107 13.3525" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.5654 21.3184V15.3184" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.0664 16.8184C22.8948 16.8184 23.5664 16.1468 23.5664 15.3184C23.5664 14.4899 22.8948 13.8184 22.0664 13.8184C21.238 13.8184 20.5664 14.4899 20.5664 15.3184C20.5664 16.1468 21.238 16.8184 22.0664 16.8184Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
