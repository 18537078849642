/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import { clickEventCb, OverviewEvents, usePageAnalytics } from '@home-mgmt-shared/analytics';
import {
    CloseArrow,
    FilledButton,
    IStreamingQualityGuideline,
    LaptopSpeedRocket,
    OpenArrow,
    SmartphoneProblem,
} from '@home-mgmt-shared/common-ui';
import { InlinePopup } from '@home-mgmt-shared/inline-popup';
import { logger } from '@home-mgmt-shared/logger';
import { iconRecommendationsList, Recommendations } from '@home-mgmt-shared/recommendation';
import { FlowData, LOCAL_STORAGE_PROPERTY } from '@home-mgmt-shared/save-results';
import { SpeedChart, SpeedType } from '@home-mgmt-shared/speed-chart';
import { IScanResults, WebScanState } from '@home-mgmt-shared/web-scan';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import { Profile, ProfileMetadata, ScanResponse, WixiCustomer } from '@soluto-private/wixi-web-sdk';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getNewScansFromFlowData, getSpeedPointsFromScanData, isSpeedIdeal } from '../apis';
import { OverviewDataCard } from './OverviewDataCard';
import { OverviewErrorState } from './OverviewErrorState';
import { OverviewInlineSpeedTest } from './OverviewInlineSpeedTest';
import { OverviewLoadingState } from './OverviewLoadingState';
import { OverviewSpeedBanner } from './OverviewSpeedBanner';
import { OverviewSpeedPointModal } from './OverviewSpeedPointModal';

const OverviewWifiManagementContainer = styled.div`
    max-width: 37.5rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
`;

const HeaderContainer = styled.div`
    width: calc(100% - 2rem);
    max-width: 33.5rem;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    font-size: 1.5rem;
    line-height: 150%;
    font-weight: 400;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
`;

const UpdateSpeedButton = styled(FilledButton)`
    height: auto;
    width: auto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    padding: 0.25rem 1rem;
    background: ${(props) => props.theme.components?.overviewSpeedChart?.buttonColor ?? '#000'};
`;

const TabContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 3rem;
    width: 100%;
    max-width: 35.5rem;
    align-self: center;
    border-bottom: 2px solid ${(props) => props.theme.components?.overviewSpeedChart?.tabColor ?? '#f3f3f3'};
`;

type IsEnabled = {
    isEnabled: boolean;
};

type IsSelected = {
    isSelected: boolean;
};

const TabItem = styled.button<IsEnabled & IsSelected>`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1rem;
    width: 50%;
    text-decoration: none;
    cursor: pointer;
    color: ${(props) => (props.isEnabled ? '#000000' : '#A5AAAF')};
    font-family: ${(props) => props.theme.font?.type};
    font-weight: ${(props) => (props.isSelected && props.isEnabled ? 'bold' : 'normal')};
    border: none;
    border-bottom: 4px solid ${(props) => (props.isSelected && props.isEnabled ? '#000000' : '#fff')};
    background: none;
`;

const StartSpeedTestButton = styled(FilledButton)`
    height: auto;
    width: auto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    padding: 0.25rem 1rem;
    background: ${(props) => props.theme.components?.overviewSpeedChart?.buttonColor ?? '#000'};
    z-index: 20;
`;

const NewSpeedTestContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 1rem;
    margin-top: 2rem;
    margin-bottom: -3rem;
`;

const NewSpeedTestHeader = styled.div`
    font-size: 1rem;
    font-weight: bold;
    line-height: 150%;
`;

const NewSpeedTestDesc = styled.div`
    font-size: 1rem;
    font-weight: normal;
    line-height: 150%;
    margin-bottom: 0.5rem;
`;

const BinaryFeedbackWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const SpeedChartTestWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    min-height: 17.44925rem;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ResultsItem = styled.div`
    margin: 1rem;
    width: calc(100% - 2rem);
`;

const SeeMoreButtonContainer = styled.div`
    margin: 1rem;
    width: calc(100% - 2rem);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SeeMoreTipsButton = styled(FilledButton)`
    max-width: 22rem;
    margin: 0;
    margin-bottom: 1rem;
`;

const ButtonIcon = styled.div`
    width: 1.17875rem;
    height: 1.17875rem;
    max-width: 1.17875rem;
    max-height: 1.17875rem;
    margin-left: 10px;
    position: relative;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: #d5d6da;

    @media all and (min-width: 35.5rem) {
        visibility: hidden;
    }
`;

const ResultsItemHeader = styled.div`
    font-size: 1.5rem;
    text-align: left;
`;

const DataCardItemContainer = styled.div`
    width: 100%;
    overflow-x: scroll;
`;

const DataCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: calc((17.18rem * 2) + 1rem);
    height: calc(24rem + 2px);
    margin-left: 1rem;
    margin-bottom: 1rem;
`;

const InlinePopupContainer = styled.div`
    border-radius: 8px;
    margin: 1rem;
    width: calc(100% - 2rem);
`;

interface OverviewWifiManagementProps {
    BinaryFeedback?: ReactNode;
    streamingGuidelines?: IStreamingQualityGuideline[];
}

export const OverviewWifiManagement = ({ BinaryFeedback, streamingGuidelines }: OverviewWifiManagementProps) => {
    usePageAnalytics(OverviewEvents.WifiManagementPage);
    const [scanData, setScanData] = useState<ScanResponse[]>([]);
    const [speedType, setSpeedType] = useState<SpeedType>(SpeedType.DOWNLOAD);
    const [speedTestState, setSpeedTestState] = useState<WebScanState>(WebScanState.NOT_STARTED);
    const [isCurrentSpeedIdeal, setIsCurrentSpeedIdeal] = useState<boolean | undefined>(false);
    const [isModalActive, setIsModalActive] = useState(false);
    const [hasErrorOccurred, setHasErrorOccurred] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentSpeedPoint, setCurrentSpeedPoint] = useState<number>(0);

    const [showIdealSpeedPrompt, setShowIdealSpeedPrompt] = useState(true);
    const [hidePrompt, setHidePrompt] = useState(false);
    const [profile, setProfile] = useState<Profile>();

    const currentDownloadPoints = getSpeedPointsFromScanData(scanData, SpeedType.DOWNLOAD);
    const currentUploadPoints = getSpeedPointsFromScanData(scanData, SpeedType.UPLOAD);
    const currentSpeedPoints = speedType ? currentDownloadPoints : currentUploadPoints;

    const [isShowingAllRecs, setIsShowingAllRecs] = useState(false);
    const buttonText = isShowingAllRecs ? 'Show less tips' : 'Show more tips';

    const history = useHistory();

    const onSeeMoreTipsClicked = useCallback(() => {
        setIsShowingAllRecs(!isShowingAllRecs);
    }, [isShowingAllRecs]);

    const getProfile = async () => {
        try {
            const wixiCustomer = new WixiCustomer();
            const newProfile: Profile = await wixiCustomer.getProfile();

            return newProfile;
        } catch (e) {
            // Could not retrieve idealSpeed from profile
            logger.error('Could not retrieve profile', e);
        }
        return profile;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const updateProfile = async (meta: ProfileMetadata, goId: string) => {
        try {
            const wixiCustomer = new WixiCustomer();
            const newProfile: Profile = {
                meta,
            };
            const profileRes: Profile = await wixiCustomer.updateProfile(newProfile, goId);
            return profileRes;
        } catch (e) {
            // Flow data does not exist or profile API could not be hit
            logger.error('Could not update profile data', e);
        }
        return profile;
    };

    const handleProfile = useCallback(async () => {
        let currentProfile = await getProfile();
        try {
            const flowDataString = localStorage.getItem(LOCAL_STORAGE_PROPERTY);
            if (flowDataString) {
                const flowDataArray: FlowData[] = JSON.parse(flowDataString) as FlowData[];
                if (flowDataArray) {
                    for (const flowData of flowDataArray) {
                        const { idealSpeed, scanId: goId } = flowData;
                        if (idealSpeed) {
                            const profileMeta: ProfileMetadata = { idealSpeed: idealSpeed.toString() };
                            currentProfile = await updateProfile(profileMeta, goId);
                            setProfile(currentProfile);
                        }
                    }
                }
            }
        } catch (e) {
            // Flow data does not exist or profile API could not be hit
            logger.error('Could not get profile data', e);
        } finally {
            setProfile(currentProfile);
        }
        return currentProfile;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (speedType === SpeedType.DOWNLOAD && currentSpeedPoints && profile?.meta?.idealSpeed !== undefined) {
            setIsCurrentSpeedIdeal(
                isSpeedIdeal(currentSpeedPoints[currentSpeedPoints.length - 1].speed, Number(profile?.meta.idealSpeed)),
            );
        }
    }, [currentSpeedPoints, profile, speedType]);

    const getScans = useCallback(async () => {
        try {
            const wixiCustomer = new WixiCustomer();
            const results: ScanResponse[] = await wixiCustomer.getScans({
                limit: 5,
                showOnlyCompletedSpeedtests: true,
            });
            if (results) {
                results.reverse();

                const flowDataString = localStorage.getItem(LOCAL_STORAGE_PROPERTY);
                if (flowDataString) {
                    const newScans = getNewScansFromFlowData(results, flowDataString);
                    setScanData(newScans);
                    return newScans;
                }

                setScanData(results);
                return results;
            }
        } catch (e) {
            logger.error('Could not getScans', e);
            // eslint-disable-next-line
            const error: string | undefined = e?.toString();
            if (error && !error.includes('Received token was not valid')) {
                setHasErrorOccurred(true);
            }
        }
        return [];
    }, []);

    const loadData = useCallback(async () => {
        setLoading(true);
        const newProfile = await handleProfile();
        const newScanData = await getScans();
        setLoading(false);

        analytics.updateProperties({
            hasIdealSpeed: newProfile?.meta?.idealSpeed !== undefined,
            isSpeedChartEmpty: newScanData.length === 0,
        });
        analytics.dispatch(OverviewEvents.DataLoaded, NSEventType.View);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        loadData();
    }, [loadData]);

    const startSpeedTest = useCallback(() => {
        setSpeedTestState(WebScanState.STARTED);
    }, []);

    const onShowModal = (speedPoint: number) => {
        if (!isModalActive) {
            setCurrentSpeedPoint(speedPoint);
            setIsModalActive(true);
        }
    };

    const onCloseModal = () => {
        if (isModalActive) {
            setIsModalActive(false);
        }
    };

    const sliceScanData = useCallback(() => {
        if (scanData && scanData.length > 5) {
            setScanData(scanData.slice(scanData.length - 5, scanData.length));
        }
    }, [scanData]);

    const onScanComplete = (results: IScanResults) => {
        setSpeedTestState(WebScanState.FINISHED);

        const date = new Date(Date.now()).toString();

        const newScan: ScanResponse = {
            internetHealth: {
                downloadThroughput: results.downloadSpeed.toString(),
                uploadThroughput: results.uploadSpeed.toString(),
            },
            timestamp: date,
        };

        if (scanData) {
            scanData.push(newScan);
        } else {
            setScanData([newScan]);
        }

        sliceScanData();

        setSpeedTestState(WebScanState.NOT_STARTED);
    };

    useEffect(() => {
        sliceScanData();
        sessionStorage.setItem('cid', 'overview');
        sessionStorage.setItem('campaignId', 'overview');
    });

    return (
        <OverviewWifiManagementContainer>
            {isModalActive && currentSpeedPoints && currentDownloadPoints && currentUploadPoints && (
                <OverviewSpeedPointModal
                    date={currentSpeedPoints[currentSpeedPoint].date}
                    downloadSpeed={currentDownloadPoints[currentSpeedPoint].speed}
                    uploadSpeed={currentUploadPoints[currentSpeedPoint].speed}
                    streamingGuidelines={streamingGuidelines}
                    onCloseModal={onCloseModal}
                />
            )}
            <HeaderContainer>
                Wi-Fi Speed
                {scanData.length > 0 && speedTestState !== WebScanState.STARTED && (
                    <UpdateSpeedButton onClick={startSpeedTest} analyticEventName="Update_Speed_Now">
                        Update speed now
                    </UpdateSpeedButton>
                )}
            </HeaderContainer>
            {scanData.length > 0 &&
                profile?.meta?.idealSpeed &&
                speedTestState !== WebScanState.STARTED &&
                !hasErrorOccurred && (
                    <OverviewSpeedBanner
                        isSpeedIdeal={isCurrentSpeedIdeal}
                        idealContent={
                            <span>
                                <strong>Looks good.</strong>&nbsp;Your speed should be fast enough for your activities.
                            </span>
                        }
                        nonIdealContent={
                            <span>
                                <strong>Uh oh.</strong>&nbsp;Your speed might not be fast enough for your activities.
                            </span>
                        }
                    />
                )}
            <TabContainer>
                <TabItem
                    role="button"
                    isSelected={scanData?.length > 0 && speedType === SpeedType.DOWNLOAD}
                    isEnabled={
                        scanData?.length > 0 &&
                        (speedTestState === WebScanState.NOT_STARTED || speedTestState === WebScanState.FINISHED)
                    }
                    onClick={() => {
                        if (
                            scanData?.length > 0 &&
                            (speedTestState === WebScanState.NOT_STARTED || speedTestState === WebScanState.FINISHED)
                        ) {
                            setSpeedType(SpeedType.DOWNLOAD);
                            analytics.dispatch(OverviewEvents.SpeedTabClicked, NSEventType.Click, {
                                tabName: SpeedType.DOWNLOAD,
                            });
                        }
                    }}
                >
                    Download
                </TabItem>
                <TabItem
                    isSelected={scanData?.length > 0 && speedType === SpeedType.UPLOAD}
                    isEnabled={
                        scanData?.length > 0 &&
                        (speedTestState === WebScanState.NOT_STARTED || speedTestState === WebScanState.FINISHED)
                    }
                    onClick={() => {
                        if (
                            scanData?.length > 0 &&
                            (speedTestState === WebScanState.NOT_STARTED || speedTestState === WebScanState.FINISHED)
                        )
                            setSpeedType(SpeedType.UPLOAD);
                        analytics.dispatch(OverviewEvents.SpeedTabClicked, NSEventType.Click, {
                            tabName: SpeedType.UPLOAD,
                        });
                    }}
                >
                    Upload
                </TabItem>
            </TabContainer>
            {scanData.length > 0 && !profile?.meta?.idealSpeed && showIdealSpeedPrompt && !hidePrompt && !loading && (
                <InlinePopupContainer>
                    <InlinePopup
                        headerText="Do you have the speed you need?"
                        messageText="Determine the Wi-Fi speed you need to do your typical online activities."
                        buttonText="Find your ideal speed"
                        buttonAnalyticName="Find_Ideal_Speed_Popup"
                        onButtonClick={() => {
                            analytics.updateProperties({ cid: 'overview' });
                            history.push('/bandwidth');
                        }}
                        onClose={() => {
                            setShowIdealSpeedPrompt(false);
                            setHidePrompt(true);
                            analytics.dispatch(OverviewEvents.PopupClosed, NSEventType.Click, {
                                popupName: 'Find_Ideal_Speed_Popup',
                            });
                        }}
                    />
                </InlinePopupContainer>
            )}
            {!hasErrorOccurred && !loading && (
                <SpeedChartTestWrapper>
                    {scanData.length === 0 && speedTestState !== WebScanState.STARTED && (
                        <NewSpeedTestContainer>
                            <NewSpeedTestHeader>New here?</NewSpeedTestHeader>
                            <NewSpeedTestDesc>Get started by running a quick speed test!</NewSpeedTestDesc>
                            <StartSpeedTestButton analyticEventName="Start_New_Speed_Test" onClick={startSpeedTest}>
                                Start a speed test
                            </StartSpeedTestButton>
                        </NewSpeedTestContainer>
                    )}
                    {speedTestState !== WebScanState.STARTED ? (
                        <SpeedChart
                            downloadData={currentDownloadPoints}
                            uploadData={currentUploadPoints}
                            idealSpeed={profile?.meta?.idealSpeed ? parseFloat(profile.meta.idealSpeed) : undefined}
                            speedType={speedType}
                            onShowModal={onShowModal}
                            analyticsCb={clickEventCb}
                        />
                    ) : (
                        <OverviewInlineSpeedTest
                            onScanComplete={onScanComplete}
                            onScanCancel={() => setSpeedTestState(WebScanState.NOT_STARTED)}
                        />
                    )}
                </SpeedChartTestWrapper>
            )}
            {hasErrorOccurred && !loading && (
                <SpeedChartTestWrapper>
                    <OverviewErrorState
                        onClick={() => {
                            setHasErrorOccurred(false);
                        }}
                        errorMessage="Something went wrong."
                        buttonText="Try again"
                        key="error_state"
                    />
                </SpeedChartTestWrapper>
            )}

            {!hasErrorOccurred && loading && (
                <SpeedChartTestWrapper>
                    <OverviewLoadingState />
                </SpeedChartTestWrapper>
            )}

            {!hasErrorOccurred && scanData.length > 0 && (
                <>
                    <BinaryFeedbackWrapper>{BinaryFeedback}</BinaryFeedbackWrapper>
                    <Divider />
                </>
            )}
            {scanData.length > 0 && (
                <>
                    <ResultsItem>
                        <Recommendations
                            header="How to improve your speed"
                            recommendations={iconRecommendationsList}
                            elementsToDisplay={isShowingAllRecs ? undefined : 3}
                            showFirstLine
                        />
                    </ResultsItem>
                    <SeeMoreButtonContainer>
                        <SeeMoreTipsButton
                            onClick={onSeeMoreTipsClicked}
                            analyticEventName="Toggle tips"
                            analyticsExtras={{ status: buttonText }}
                        >
                            <ButtonRow>
                                {buttonText}
                                <ButtonIcon>
                                    {isShowingAllRecs ? (
                                        <OpenArrow fillColor="#fff" />
                                    ) : (
                                        <CloseArrow fillColor="#fff" />
                                    )}
                                </ButtonIcon>
                            </ButtonRow>
                        </SeeMoreTipsButton>
                    </SeeMoreButtonContainer>
                    <Divider />
                </>
            )}
            <ResultsItem>
                <ResultsItemHeader>Wi-Fi Troubleshooting Tools</ResultsItemHeader>
            </ResultsItem>
            <DataCardItemContainer>
                <DataCardContainer>
                    <OverviewDataCard
                        cardIcon={<SmartphoneProblem />}
                        headline="Check for Wi-Fi dead zones"
                        subText="If your Wi-Fi is slow in one area of your home, weak signal could be limiting your speed."
                        buttonText="Check for dead zones"
                        buttonAnalyticName="Check_For_Dead_Zones"
                        onClick={() => {
                            analytics.updateProperties({ cid: 'overview' });
                            history.push('/signal');
                        }}
                    />
                    <OverviewDataCard
                        cardIcon={<LaptopSpeedRocket />}
                        headline="Are you getting the speeds you need?"
                        subText="Compare your current speed to your ideal speed."
                        buttonText="Find your ideal speed"
                        buttonAnalyticName="Find_Your_Ideal_Speed"
                        onClick={() => {
                            analytics.updateProperties({ cid: 'overview' });
                            history.push('/bandwidth');
                        }}
                    />
                </DataCardContainer>
            </DataCardItemContainer>
        </OverviewWifiManagementContainer>
    );
};
