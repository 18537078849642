import styled from '@emotion/styled';
import { MXLoadingSpinner } from '@home-mgmt-shared/common-ui';
import React from 'react';

const OverviewLoadingStateContainer = styled.div`
    width: 100%;
    height: 17.44925rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #d5d6da;
`;

const LoadingContainer = styled.div`
    display: flex;
    height: 7rem;
    width: 7rem;
    justify-content: center;
    align-items: center;
`;
export const OverviewLoadingState = () => (
    <OverviewLoadingStateContainer>
        <LoadingContainer>
            <MXLoadingSpinner />
        </LoadingContainer>
    </OverviewLoadingStateContainer>
);
