import styled from '@emotion/styled';
import React from 'react';
import { FilledButton } from '../Buttons';

const ReminderModalContainer = styled.div`
    left: -0.25rem;
    top: 30%;
    margin: 0 1rem;
    position: absolute;
    margin-top: 1rem;
    width: 35.5rem;
    max-width: calc(100% - 3.5rem);
    border-radius: 1rem;
    background: white;
    z-index: 1001;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    justify-content: flex-start;
    align-items: flex-start;

    @media all and (min-width: 35.5rem) {
        left: unset;
        width: max-content;
    }

    animation: animateModal 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    @keyframes animateModal {
        0% {
            transform: scale(1) translateY(0px);
            opacity: 0;
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        }
        1% {
            transform: scale(1) translateY(4rem);
            opacity: 0.5;
            box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        }
        100% {
            transform: scale(1) translateY(0px);
            opacity: 1;
            box-shadow: 0 0 500px rgba(241, 241, 241, 0);
        }
    }
`;

const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin-bottom: 1.5rem;
`;

const SubHeader = styled.div`
    font-size: 1rem;
    line-height: 150%;
`;

const ButtonContainer = styled.div`
    width: 100px;
`;

interface ReminderModalProps {
    onCloseClicked?: () => void;
}

export const ReminderModal = ({ onCloseClicked }: ReminderModalProps) => (
    <ReminderModalContainer>
        <Header>Thanks for letting us know!</Header>
        <SubHeader>
            You caught us. We’re still working on this. Now that we know you’d like a reminder, we’ll let you know when
            it’s ready.
        </SubHeader>
        <ButtonContainer>
            <FilledButton onClick={onCloseClicked}>Close</FilledButton>
        </ButtonContainer>
    </ReminderModalContainer>
);
