import { Theme, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { AnalyticEvents } from '@home-mgmt-shared/analytics';
import { IAuthClient } from '@home-mgmt-shared/auth';
import {
    BreakPoints,
    FilledButton,
    IconLink,
    IStreamingQualityGuideline,
    LinkButton,
    Redo,
    SpeedIconHappy,
    SpeedIconSad,
    SpeedTestResults,
    Wifi,
} from '@home-mgmt-shared/common-ui';
import { CTAStatus, ExpertCTA, getDefaultCTAInfo, IExpertCTAInfo } from '@home-mgmt-shared/expert-cta';
import { iconRecommendationsList, Recommendations } from '@home-mgmt-shared/recommendation';
import { SaveResults, useSaveResults } from '@home-mgmt-shared/save-results';
import { SpeedResults, SpeedResultsAlert, SpeedResultsHeader } from '@home-mgmt-shared/speed-results';
import { TweekifiedComponent } from '@home-mgmt-shared/tweek-helpers';
import React, { ReactNode } from 'react';
import { useTweekValue } from 'react-tweek';
import { BandwidthCalculatorStatus, IBandwidthCalculatorResults } from '../models';

let AuthClient: IAuthClient;
if (IS_MX_APP) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,global-require
    AuthClient = require('@home-mgmt-shared/auth').AuthClient;
}

const ResultsItem = styled.div`
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
`;

const SeeMoreTipsBtn = styled(FilledButton)`
    margin-bottom: 10px;
`;

const SectionTitle = styled.h1`
    font-size: 1.375rem;
    margin-bottom: 10px;
    max-width: 325px;
    width: 100%;
`;

const SectionDivider = styled.div`
    margin-bottom: 0.25rem;
    height: 1px;
    width: 100%;
    background: #000;
    opacity: 0.1;
`;

const IconLinkWrapper = styled.div`
    margin-bottom: 1.5rem;
    z-index: 999;
`;

const CenteredResultsItem = styled(ResultsItem)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ContactExpertContainer = styled(ResultsItem)`
    align-items: center;
    margin-top: 2rem;
`;

const DesktopSpeedContainer = styled.div`
    @media ${BreakPoints.desktop} {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0.5rem;
        width: 100%;
    }
`;

const CheckSpeedAgainContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
`;

const RedoIconContainer = styled.div`
    max-height: 1rem;
    max-width: 1.5rem;
    margin-right: 0.5rem;
`;

const RedoTextContainer = styled.div`
    font-size: 1rem;
`;

const DesktopSpeedColumnRight = styled.div`
    @media ${BreakPoints.desktop} {
        display: flex;
        flex-direction: column;
        margin-left: 2rem;
        max-width: 20rem;
    }
`;

const DesktopSpeedColumnLeft = styled.div`
    @media ${BreakPoints.desktop} {
        display: flex;
        flex-direction: column;
        margin-right: 2rem;
        max-width: 20rem;
    }
`;

interface BandwidthCalculatorResultsCompleteViewProps {
    downloadSpeed: number;
    uploadSpeed: number;
    idealDownloadSpeed: number;
    streamingGuidelines: IStreamingQualityGuideline[];
    results: IBandwidthCalculatorResults;
    onCheckSpeedsAgain: () => void;
    onSeeMoreRecomendations: () => void;
    hideIcon?: boolean;
    emphasizeResult?: boolean;
    UpsellComponent?: ReactNode;
    scanId: string;
}

export const BandwidthCalculatorResultsCompleteView = ({
    downloadSpeed,
    uploadSpeed,
    idealDownloadSpeed,
    streamingGuidelines,
    results,
    onCheckSpeedsAgain,
    onSeeMoreRecomendations,
    hideIcon,
    emphasizeResult,
    UpsellComponent,
    scanId,
}: BandwidthCalculatorResultsCompleteViewProps) => {
    const theme: Theme = useTheme();

    const expertCTAInfo: IExpertCTAInfo = useTweekValue('network_scan/expert_cta', getDefaultCTAInfo());

    const speedResultsCopy = {
        goodSpeed: `<span class="emphasize">All good!</span> You likely have the Wi-Fi speed you need.`,
        decentSpeed: '<span class="emphasize">Not bad!</span> You’re just shy of your ideal speed.',
        okaySpeed: '<span class="emphasize">Uh oh!</span> You may not have the Wi-Fi speed you need.',
        slowSpeed: '<span class="emphasize">Uh oh!</span> You may not have the Wi-Fi speed you need.',
        innerCircleLabel: 'Current download speed',
    };

    const isLoggedIn = AuthClient ? AuthClient.isLoggedIn : false;

    let headerText = speedResultsCopy.slowSpeed;
    let speedMoodIcon = <SpeedIconSad fillColor={theme.button?.defaultTextColor} />;
    let dialColor = theme.speedResults?.slowColor;

    let isSpeedSufficient = false;

    switch (results.overallStatus) {
        case BandwidthCalculatorStatus.GOOD:
            headerText = speedResultsCopy.goodSpeed;
            speedMoodIcon = <SpeedIconHappy fillColor={theme.button?.defaultTextColor} />;
            dialColor = theme.speedResults?.goodColor;
            isSpeedSufficient = true;
            break;
        case BandwidthCalculatorStatus.DECENT:
            headerText = speedResultsCopy.decentSpeed;
            speedMoodIcon = <SpeedIconHappy fillColor={theme.button?.defaultTextColor} />;
            dialColor = theme.speedResults?.decentColor;
            break;
        case BandwidthCalculatorStatus.OKAY:
            headerText = speedResultsCopy.okaySpeed;
            dialColor = theme.speedResults?.okayColor;
            break;
        case BandwidthCalculatorStatus.SLOW:
        default:
            break;
    }

    useSaveResults({
        flowData: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
            scanId,
            downloadSpeed,
            uploadSpeed,
            timestamp: new Date(Date.now()).toUTCString(),
            idealSpeed: idealDownloadSpeed,
            results: { ...results },
        },
    });

    return (
        <>
            {isLoggedIn && (
                <IconLinkWrapper>
                    <IconLink Icon={<Wifi />} msg="Go to Wi-Fi Overview" route="/overview" />
                </IconLinkWrapper>
            )}

            <SpeedResultsHeader
                speedColor={dialColor}
                emphasizeResult={emphasizeResult}
                hideIcon={hideIcon}
                headerText={headerText}
                speedMoodIcon={speedMoodIcon}
            />
            {!isSpeedSufficient && (
                <SpeedResultsAlert
                    prefix="The ideal Wi-Fi speed for your household is"
                    idealSpeed={idealDownloadSpeed}
                    suffix=""
                />
            )}
            <DesktopSpeedContainer>
                <DesktopSpeedColumnLeft>
                    <CenteredResultsItem>
                        <SpeedResults
                            speed={downloadSpeed}
                            idealSpeed={idealDownloadSpeed}
                            speedResultsCopy={speedResultsCopy}
                            dialColor={dialColor}
                            isSpeedSufficient={isSpeedSufficient}
                        />
                    </CenteredResultsItem>

                    <ResultsItem>
                        <SpeedTestResults
                            downloadSpeed={downloadSpeed}
                            uploadSpeed={uploadSpeed}
                            streamingGuidelines={streamingGuidelines}
                        />
                    </ResultsItem>
                    {results.overallStatus === BandwidthCalculatorStatus.GOOD && UpsellComponent && (
                        <>
                            <SectionTitle>Upgrade your home protection</SectionTitle>
                            <SectionDivider />
                            <ResultsItem>{UpsellComponent}</ResultsItem>
                        </>
                    )}

                    {IS_MX_APP && <SaveResults />}
                </DesktopSpeedColumnLeft>
                <DesktopSpeedColumnRight>
                    <ResultsItem>
                        <Recommendations
                            header="How to increase your speed"
                            recommendations={iconRecommendationsList}
                            elementsToDisplay={3}
                            showFirstLine
                        />
                    </ResultsItem>
                    <ResultsItem>
                        <SeeMoreTipsBtn
                            onClick={onSeeMoreRecomendations}
                            analyticEventName={AnalyticEvents.CONNECTION_EXPLANATION_ACCORDION_SEE_MORE}
                        >
                            See more tips
                        </SeeMoreTipsBtn>
                        <LinkButton
                            onClick={onCheckSpeedsAgain}
                            underlined
                            analyticEventName={AnalyticEvents.SCAN_AGAIN}
                        >
                            <CheckSpeedAgainContainer>
                                <RedoIconContainer>
                                    <Redo />
                                </RedoIconContainer>
                                <RedoTextContainer>Check speed again</RedoTextContainer>
                            </CheckSpeedAgainContainer>
                        </LinkButton>
                    </ResultsItem>
                    <ContactExpertContainer>
                        <TweekifiedComponent tweekKey="network_scan/expert_cta_enabled">
                            <ExpertCTA
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                                options={expertCTAInfo[results.overallStatus as CTAStatus]}
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
                                analyticEventName={expertCTAInfo[results.overallStatus as CTAStatus].analyticName}
                            />
                        </TweekifiedComponent>
                    </ContactExpertContainer>
                </DesktopSpeedColumnRight>
            </DesktopSpeedContainer>
        </>
    );
};
