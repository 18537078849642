import styled from '@emotion/styled';
import React, { ReactNode, useCallback, useState } from 'react';

const animationDuration = 2.5; // seconds

const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: flex-start;
    padding-left: 0.7906rem;
    padding-top: 0.49rem;
    padding-bottom: 0.90625rem;
    width: calc(100% - 2rem - 0.7906rem);
    margin: 1rem;

    font-family: ${(props) => props.theme?.font?.type};
    background: ${(props) => props.theme?.binaryFeedback?.backgroundColor};
    transition: opacity ${animationDuration}s linear;
    transition-delay: 0.5s;
    height: fit-content;
    opacity: ${(props: { isSubmitted: boolean }) => (props.isSubmitted ? '0%' : '100%')};
    display: ${(props: { isAnimationFinished: boolean; isSubmitted: boolean }) =>
        props.isAnimationFinished ? 'none' : 'flex'};
    overflow: hidden;
    border-radius: 4px;
`;

const AnswerContainer = styled.div`
    display: flex;
    text-align: left;
    justify-content: flex-start;
    align-items: flex-start;
    width: 55%;
    height: 2rem;
    visibility: ${(props: { isSubmitted: boolean }) => (props.isSubmitted ? 'hidden' : 'visible')};
`;

const IconWrapper = styled.div`
    width: auto;
    height: 100%;
`;

const Label = styled.div`
    font-size: 1rem;
    line-height: 150%;
    margin-bottom: 0.4788rem;
`;

type FeedbackThumbsProps = {
    greetingText: string;
    farewellText: string;
    positiveFeedbackIcon: ReactNode;
    negativeFeedbackIcon: ReactNode;
    analyticsCb: (eventName: string, props: Record<string, unknown>) => void;
};

export const BinaryFeedback = ({
    greetingText,
    farewellText,
    positiveFeedbackIcon,
    negativeFeedbackIcon,
    analyticsCb,
}: FeedbackThumbsProps) => {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isAnimationFinished, setIsAnimationFinished] = useState<boolean>(false);
    const onFeedbackSelected = useCallback(
        (selected: string) => {
            analyticsCb?.('Binary_Feedback_Submitted', { rating: selected });
            setIsSubmitted(true);
            setTimeout(() => setIsAnimationFinished(true), animationDuration * 1000);
        },
        [analyticsCb],
    );
    return (
        <Container
            className="binary-feedback-container"
            isSubmitted={isSubmitted}
            isAnimationFinished={isAnimationFinished}
        >
            <Label>{isSubmitted ? farewellText : greetingText}</Label>
            <AnswerContainer isSubmitted={isSubmitted}>
                <IconWrapper onClick={() => onFeedbackSelected('positive')}>{positiveFeedbackIcon}</IconWrapper>
                <IconWrapper onClick={() => onFeedbackSelected('negative')}>{negativeFeedbackIcon}</IconWrapper>
            </AnswerContainer>
        </Container>
    );
};
