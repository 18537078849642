import React from 'react';

interface CellSignalProps {
    strokeColor?: string;
}

export const CellSignal = ({ strokeColor = 'black' }: CellSignalProps = {}) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="cell signal"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.566406 16.4902C0.566406 16.2141 0.790264 15.9902 1.06641 15.9902H3.06641C3.34255 15.9902 3.56641 16.2141 3.56641 16.4902V21.4902C3.56641 21.7664 3.34255 21.9902 3.06641 21.9902H1.06641C0.790264 21.9902 0.566406 21.7664 0.566406 21.4902V16.4902Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.56641 13.4902C5.56641 13.2141 5.79026 12.9902 6.06641 12.9902H8.06641C8.34255 12.9902 8.56641 13.2141 8.56641 13.4902V21.4902C8.56641 21.7664 8.34255 21.9902 8.06641 21.9902H6.06641C5.79026 21.9902 5.56641 21.7664 5.56641 21.4902V13.4902Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5664 10.4902C10.5664 10.2141 10.7903 9.99023 11.0664 9.99023H13.0664C13.3425 9.99023 13.5664 10.2141 13.5664 10.4902V21.4902C13.5664 21.7664 13.3425 21.9902 13.0664 21.9902H11.0664C10.7903 21.9902 10.5664 21.7664 10.5664 21.4902V10.4902Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5664 7.49023C15.5664 7.21409 15.7903 6.99023 16.0664 6.99023H18.0664C18.3425 6.99023 18.5664 7.21409 18.5664 7.49023V21.4902C18.5664 21.7664 18.3425 21.9902 18.0664 21.9902H16.0664C15.7903 21.9902 15.5664 21.7664 15.5664 21.4902V7.49023Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.5664 4.49023C20.5664 4.21409 20.7903 3.99023 21.0664 3.99023H23.0664C23.3425 3.99023 23.5664 4.21409 23.5664 4.49023V21.4902C23.5664 21.7664 23.3425 21.9902 23.0664 21.9902H21.0664C20.7903 21.9902 20.5664 21.7664 20.5664 21.4902V4.49023Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
