import * as React from 'react';

export const MXLoadingSpinner = ({ fillColor = '#000' }) => (
    <svg
        id="prefix__e79h3lcw1fl51"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 75 75"
        shapeRendering="geometricPrecision"
        role="img"
        aria-label="loading spinner"
        textRendering="geometricPrecision"
        width="100%"
        height="100%"
    >
        <style>
            {
                '@keyframes e79h3lcw1fl53_s_do{0%{stroke-dashoffset:199.5}to{stroke-dashoffset:0}}@keyframes e79h3lcw1fl54_to__to{0%{transform:translate(53.188379px,39.450436px)}6.666667%{transform:translate(42.106909px,39.604344px)}13.333333%{transform:translate(48.109372px,31.447152px)}20%{transform:translate(58.883024px,33.140154px)}26.666667%{transform:translate(59.652569px,45.760718px)}33.333333%{transform:translate(52.111014px,54.841367px)}40%{transform:translate(39.644361px,59.920374px)}46.666667%{transform:translate(27.639435px,57.150006px)}53.333333%{transform:translate(17.327512px,47.145901px)}60%{transform:translate(13.47978px,35.140976px)}66.666667%{transform:translate(16.865785px,22.828232px)}73.333333%{transform:translate(27.485526px,14.51713px)}80%{transform:translate(40.106088px,12.670219px)}86.666667%{transform:translate(51.495376px,17.133588px)}93.333333%{transform:translate(58.267386px,27.445511px)}to{transform:translate(54.419653px,38.680889px)}}@keyframes e79h3lcw1fl54_tr__tr{0%{transform:rotate(143deg)}6.666667%{transform:rotate(241deg)}13.333333%{transform:rotate(711deg)}20%{transform:rotate(786deg)}26.666667%{transform:rotate(851deg)}33.333333%{transform:rotate(870deg)}40%{transform:rotate(887deg)}46.666667%{transform:rotate(931deg)}53.333333%{transform:rotate(966deg)}60%{transform:rotate(990deg)}66.666667%{transform:rotate(1025deg)}73.333333%{transform:rotate(1060deg)}80%{transform:rotate(1090deg)}86.666667%{transform:rotate(1120deg)}93.333333%{transform:rotate(1162deg)}to{transform:rotate(1223deg)}}'
            }
        </style>
        <path
            d="M20.232 52.515c-6.166-7.584-9.9-18.487-4.926-28.193 4.71-9.19 14.955-12.719 23.697-12.425 7.582.254 17.407 5.436 19.898 14.436.618 2.234.307 7.515-2.454 11.127-6.022 7.877-18.43 5.652-15.022-1.697 2.274-4.904 14.692-8.988 18.189-3.112 6.902 11.598-3.186 19.612-4.96 21.12-3.577 3.044-8.231 5.231-12.742 6.109-11.27 2-19.15-4.077-21.68-7.365z"
            fill="none"
            stroke={fillColor}
            strokeWidth={2.5}
            strokeLinecap="round"
            strokeDashoffset={199.5}
            strokeDasharray={100}
            style={{
                animation: 'e79h3lcw1fl53_s_do 1500ms linear infinite normal forwards',
            }}
            id="prefix__e79h3lcw1fl52"
        />
        <g
            style={{
                animation: 'e79h3lcw1fl54_to__to 1500ms linear infinite normal forwards',
            }}
        >
            <g
                transform="rotate(143 19.994 28.624)"
                style={{
                    animation: 'e79h3lcw1fl54_tr__tr 1500ms linear infinite normal forwards',
                }}
            >
                <g id="prefix__e79h3lcw1fl54" transform="translate(2.138 -.636)">
                    <path
                        id="prefix__e79h3lcw1fl55"
                        d="M-5.4 6.9c-.3 0-.7-.1-.9-.4-.5-.5-.5-1.3 0-1.8.6-.6 1.3-1.4 2-2.2 1-1.2 2-2.4 3.1-3.3-1.4-.7-2.8-1.5-4.1-2.6-.5-.5-.6-1.2-.1-1.8.5-.5 1.2-.6 1.8-.1 1.4 1.2 2.9 2 4.6 2.9h.1c.4.1 1.3.4 1.3 1.4 0 .3 0 1-.9 1.4-1.3.7-2.6 2.3-3.9 3.8l-2.1 2.4c-.3.2-.6.3-.9.3z"
                        fill={fillColor}
                        stroke="none"
                        strokeWidth={1}
                    />
                </g>
            </g>
        </g>
    </svg>
);
