import React from 'react';

interface RouterSignalProps {
    strokeColor?: string;
}

export const RouterSignal = ({ strokeColor = 'black' }: RouterSignalProps = {}) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="router signal"
    >
        <path
            d="M14.4834 5.65576C15.069 6.24151 15.069 7.19101 14.4834 7.77676"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.6045 3.53369C17.4484 4.37761 17.9225 5.52221 17.9225 6.71569C17.9225 7.90918 17.4484 9.05378 16.6045 9.89769"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.6045 3.53369C17.4484 4.37761 17.9225 5.52221 17.9225 6.71569C17.9225 7.90918 17.4484 9.05378 16.6045 9.89769"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.7227 1.4126C20.1293 2.81914 20.9196 4.72688 20.9196 6.7161C20.9196 8.70532 20.1293 10.6131 18.7227 12.0196"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M18.7227 1.4126C20.1293 2.81914 20.9196 4.72688 20.9196 6.7161C20.9196 8.70532 20.1293 10.6131 18.7227 12.0196"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10.362 5.65576C9.77646 6.24151 9.77646 7.19101 10.362 7.77676"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.24089 3.53369C7.39696 4.37761 6.92285 5.52221 6.92285 6.71569C6.92285 7.90918 7.39696 9.05378 8.24089 9.89769"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.24089 3.53369C7.39696 4.37761 6.92285 5.52221 6.92285 6.71569C6.92285 7.90918 7.39696 9.05378 8.24089 9.89769"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.12268 1.4126C4.71603 2.81914 3.92578 4.72688 3.92578 6.7161C3.92578 8.70532 4.71603 10.6131 6.12268 12.0196"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6.12268 1.4126C4.71603 2.81914 3.92578 4.72688 3.92578 6.7161C3.92578 8.70532 4.71603 10.6131 6.12268 12.0196"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.9229 19.4126C23.9229 20.5172 23.0274 21.4126 21.9229 21.4126H2.92285C1.81828 21.4126 0.922852 20.5172 0.922852 19.4126V17.4126C0.922852 16.308 1.81828 15.4126 2.92285 15.4126H21.9229C23.0274 15.4126 23.9229 16.308 23.9229 17.4126V19.4126Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5.17285 18.1626C5.03478 18.1626 4.92285 18.2745 4.92285 18.4126C4.92285 18.5507 5.03478 18.6626 5.17285 18.6626C5.31092 18.6626 5.42285 18.5507 5.42285 18.4126C5.42285 18.2745 5.31092 18.1626 5.17285 18.1626V18.1626"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.67285 18.1626C8.53478 18.1626 8.42285 18.2745 8.42285 18.4126C8.42285 18.5507 8.53478 18.6626 8.67285 18.6626C8.81092 18.6626 8.92285 18.5507 8.92285 18.4126C8.92285 18.2745 8.81092 18.1626 8.67285 18.1626V18.1626"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M12.4229 15.4126V10.4126" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.42285 21.4126L2.92285 23.4126" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.4229 21.4126L21.9229 23.4126" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
