import styled from '@emotion/styled';
import React from 'react';

const AccordionDescriptions = styled.p`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 16px;
    overflow: hidden;
`;

export const StreamingAccordionInfo = () => {
    const NO_CONNECTION_EXPLANATION =
        ' often means that a particular site is having an outage. Outages can be fixed in minutes and generally don’t last longer than a few hours. Wait and try again later.';
    const NO_CONNECTION = '"No connection"';
    const POOR_CONNECTION_EXPLANATION =
        ' to a particular site means that you may experience loading or buffering issues. This could be caused by your home Wi-Fi, that particular site, or how your ISP manages traffic to that site. Talk to one of our Wi-Fi experts if you’re experiencing issues.';
    const POOR_CONNECTION = '"Poor connection"';
    const FAST_CONNECTION_EXPLANATION = ' means you should not have any issues streaming on these sites.';
    const FAST_CONNECTION = '"Fast connection"';
    return (
        <div>
            <AccordionDescriptions>
                <strong>{NO_CONNECTION}</strong> {NO_CONNECTION_EXPLANATION}
            </AccordionDescriptions>
            <AccordionDescriptions>
                <strong>{POOR_CONNECTION}</strong> {POOR_CONNECTION_EXPLANATION}
            </AccordionDescriptions>
            <AccordionDescriptions>
                <strong>{FAST_CONNECTION}</strong> {FAST_CONNECTION_EXPLANATION}
            </AccordionDescriptions>
        </div>
    );
};
