import styled from '@emotion/styled';
import { BandwidthCalculatorEvents, usePageAnalytics, WebscanEvents } from '@home-mgmt-shared/analytics';
import { useRxApi } from '@home-mgmt-shared/common-hooks';
import { defaultStreamingGuidelines, retrieveItem } from '@home-mgmt-shared/common-services';
import {
    BackButton,
    BreakPoints,
    FilledButton,
    IStreamingQualityGuideline,
    SelectableButton,
} from '@home-mgmt-shared/common-ui';
import {
    Answer,
    AnswerGrid,
    AnswerList,
    IQuestionAnswerFlow,
    ProgressBar,
    Question,
    QuestionAnswerFlow,
    QuestionAnswerRef,
} from '@home-mgmt-shared/flow-ui';
import { IScanResults, WebScan } from '@home-mgmt-shared/web-scan';
import { analytics, NSEventType } from '@soluto-private/ns-analytics';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTweekValue } from 'react-tweek';
import { resetResults, results$, storeFlowResults, storeScanResults } from '../apis';
import {
    BandwidthCalculatorResultsRecommendations,
    BandwidthCalculatorResultsView,
    CalculatingResultsView,
} from '../components';
import { BandwidthCalculatorConfig, BandwidthCalculatorStatus, IBandwidthCalculatorResults } from '../models';
import { filterStreamingCapabiltiesFromResults } from '../utils';

let updateMxHeader: (
    isMx: boolean,
    view: string,
    title: string,
    backTitle?: string,
    onBack?: () => void,
    menu?: boolean,
) => void;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (IS_MX_APP) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-var-requires,global-require
    updateMxHeader = require('@home-mgmt-shared/mx-header').updateMxHeader;
}

const ContentContainer = styled.div`
    max-width: 23rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    padding: 0 1rem;

    @media ${BreakPoints.mobileBig} {
        max-width: 30rem;
    }

    @media ${BreakPoints.desktop} {
        max-width: 745px;
    }
`;

export enum PageState {
    SCAN = 'scan',
    FLOW = 'flow',
    RESULTS = 'results',
    RECOMMENDATIONS = 'recommendations',
    CALCULATING = 'calculating',
}

interface BandwidthCalculatorViewProps {
    flow: IQuestionAnswerFlow;
    onPageChange?: (page: PageState) => void;
    config: BandwidthCalculatorConfig;
}

const usePageState = (defaultState: PageState) => {
    const [currentState, setCurrentState] = useState<PageState>(defaultState);
    const [prevState, setPrevState] = useState<PageState>(defaultState);

    const setPageState = (newPageState: PageState) => {
        setCurrentState((prevPageState) => {
            setPrevState(prevPageState);
            return newPageState;
        });
    };

    return { currentState, prevState, setPageState };
};

const USER_ID = 'userId';

const pageToAnalyticName: Record<PageState, string> = {
    [PageState.SCAN]: 'Bandwidth Calculator Page',
    [PageState.FLOW]: 'Bandwidth Calculator Questionnaire Page',
    [PageState.RESULTS]: 'Bandwidth Calculator Results Page',
    [PageState.RECOMMENDATIONS]: 'Bandwidth Calculator Recommendations Page',
    [PageState.CALCULATING]: 'Bandwidth Calculator Calculating Page',
};

const pageToTitle: Record<PageState, string> = {
    [PageState.SCAN]: 'Bandwidth Calculator',
    [PageState.FLOW]: 'Bandwidth Calculator',
    [PageState.RESULTS]: 'Bandwidth Calculator Results',
    [PageState.RECOMMENDATIONS]: 'Bandwidth Calculator Recommendations',
    [PageState.CALCULATING]: 'Bandwidth Calculator',
};

const getAnalyticPageName = (currentPage: PageState, webScanResults: IBandwidthCalculatorResults): string => {
    if (currentPage === PageState.RESULTS && webScanResults.overallStatus === BandwidthCalculatorStatus.INCOMPLETE) {
        return 'Bandwidth Calculator Generic Results Page';
    }
    if (currentPage === PageState.RESULTS && webScanResults.overallStatus === BandwidthCalculatorStatus.ISP_BLOCKED) {
        return 'Bandwidth Calculator Isp Blocked Page';
    }

    return pageToAnalyticName[currentPage];
};

export const BandwidthCalculatorView = ({ flow, onPageChange, config }: BandwidthCalculatorViewProps) => {
    const { currentState, setPageState } = usePageState(PageState.SCAN);
    const webScanResults = useRxApi<IBandwidthCalculatorResults, unknown>(results$, {
        initialValue: { overallStatus: BandwidthCalculatorStatus.INCOMPLETE },
    });

    const streamingGuidelines: IStreamingQualityGuideline[] = useTweekValue(
        'network_scan/capabilities/streaming_capabilities',
        defaultStreamingGuidelines,
    );
    const userId = retrieveItem(USER_ID);
    const customEnrollment = userId ? { clientId: userId } : undefined;
    const bandwithCalculatorResult = webScanResults.result.overallStatus.toString();
    const idealDownloadSpeed = webScanResults.result.idealSpeeds?.downloadSpeed;
    const actualSpeeds = webScanResults.result.speedResults;
    const [scanId, setScanId] = useState<string>();

    usePageAnalytics(getAnalyticPageName(currentState, webScanResults.result), {
        bandwithCalculatorResult,
        idealDownloadSpeed,
        downloadSpeed: actualSpeeds?.downloadSpeed,
        uploadSpeed: actualSpeeds?.uploadSpeed,
    });

    useEffect(() => {
        analytics.updateProperties({
            bandwithCalculatorResult,
            idealDownloadSpeed,
            downloadSpeed: actualSpeeds?.downloadSpeed,
            uploadSpeed: actualSpeeds?.uploadSpeed,
        });
    }, [actualSpeeds?.downloadSpeed, actualSpeeds?.uploadSpeed, bandwithCalculatorResult, idealDownloadSpeed]);

    useEffect(() => {
        onPageChange?.(currentState);
        window.scrollTo(0, 0);
    }, [currentState, onPageChange]);

    const onScanAgain = useCallback(() => {
        setPageState(PageState.SCAN);
    }, [setPageState]);

    const onScanComplete = useCallback(
        (results: IScanResults) => {
            storeScanResults(results);
            setScanId(results.goId);

            analytics.dispatch(WebscanEvents.ScanCompleted, NSEventType.Event, {
                userId: results.clientId,
                scanId: results.goId,
                uploadSpeed: results.uploadSpeed,
                downloadSpeed: results.downloadSpeed,
            });

            if (!webScanResults.result.flowResults) {
                setPageState(PageState.FLOW);
            } else {
                setPageState(PageState.RESULTS);
            }
        },
        [setPageState, webScanResults.result.flowResults],
    );

    const onScanStart = useCallback(() => {
        setPageState(PageState.SCAN);
    }, [setPageState]);

    const componentMap = {
        Question,
        AnswerList,
        AnswerGrid,
        FilledButton,
        SelectableButton,
    };

    const { activities } = flow;

    const totalSteps = useCallback(
        () => flow.activities.length + 1, // + 1 for the calculating step
        [flow.activities.length],
    );

    const [currentStep, setCurrentStep] = useState<number>(1);

    const onFlowStateChange = useCallback(
        (flowState: Record<string, Answer[]>) => {
            storeFlowResults(flowState, flow.activities.length);
        },
        [flow.activities.length],
    );

    const onFlowComplete = useCallback(
        (finalState: Record<string, Answer[]>) => {
            storeFlowResults(finalState, flow.activities.length);
            setCurrentStep(totalSteps());
            setPageState(PageState.CALCULATING);
        },
        [flow.activities.length, setPageState, totalSteps],
    );

    const onFlowSkip = useCallback(() => {
        setPageState(PageState.RESULTS);
    }, [setPageState]);

    const onSeeMoreRecommendations = useCallback(() => {
        setPageState(PageState.RECOMMENDATIONS);
    }, [setPageState]);

    const onRecommendationsBackClicked = useCallback(() => {
        setPageState(PageState.RESULTS);
    }, [setPageState]);

    const onResultsBackClicked = useCallback(() => {
        setPageState(PageState.FLOW);
    }, [setPageState]);

    useEffect(() => {
        if (IS_MX_APP) {
            if (currentState === PageState.RECOMMENDATIONS) {
                updateMxHeader(IS_MX_APP, 'service-view', 'WiFi Management', 'Back', onRecommendationsBackClicked);
            } else if (currentState === PageState.RESULTS) {
                updateMxHeader(IS_MX_APP, 'service-view', 'WiFi Management', 'Back', onResultsBackClicked);
            } else {
                updateMxHeader(IS_MX_APP, 'service-view', '');
            }
        }
    }, [currentState, onRecommendationsBackClicked, onResultsBackClicked]);

    const onCalculatingFinished = useCallback(() => {
        setPageState(PageState.RESULTS);
    }, [setPageState]);

    const pageTitle = pageToTitle[currentState];

    const questionAnswerRef = useRef<QuestionAnswerRef>(null);
    const onFlowBackClicked = useCallback(() => {
        if (PageState.FLOW && currentStep === 0) {
            resetResults();
            setPageState(PageState.SCAN);
        }
        analytics.dispatch(BandwidthCalculatorEvents.BandwidthCalcBackButton, NSEventType.Click, { currentStep });
        questionAnswerRef?.current?.previous?.();
    }, [currentStep, setPageState]);

    const filteredStreamingCapabilities = filterStreamingCapabiltiesFromResults(
        streamingGuidelines,
        webScanResults.result.flowResults,
    );

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {(currentState === PageState.FLOW || currentState === PageState.CALCULATING) && config?.progressBar && (
                <div>
                    {currentState !== PageState.CALCULATING && config.flow.customBackButton && (
                        <BackButton backButtonCallback={onFlowBackClicked} />
                    )}
                    <ProgressBar progress={(currentStep + 1) / totalSteps()} />
                </div>
            )}
            {currentState === PageState.RECOMMENDATIONS && !IS_MX_APP && (
                <BackButton backButtonCallback={onRecommendationsBackClicked} />
            )}
            {currentState === PageState.RESULTS && !IS_MX_APP && (
                <BackButton backButtonCallback={onResultsBackClicked} />
            )}
            {currentState === PageState.CALCULATING && (
                <CalculatingResultsView
                    onCalculatingFinished={onCalculatingFinished}
                    LoadingAnimation={config.calculatingConfig?.LoadingAnimation}
                />
            )}

            {currentState !== PageState.CALCULATING && currentState === PageState.SCAN && (
                <WebScan
                    IntroImage={config.webscan?.IntroImage}
                    onScanComplete={onScanComplete}
                    onScanStart={onScanStart}
                    customEnrollment={customEnrollment}
                    IntroPageComponent={config.webscan?.IntroComponent}
                    showHeader={config.webscan?.scanningPage?.showHeader}
                    headerMsg={config.webscan?.scanningPage?.headerMsg}
                />
            )}

            {currentState !== PageState.CALCULATING && currentState !== PageState.SCAN && (
                <ContentContainer>
                    {currentState === PageState.FLOW && (
                        <QuestionAnswerFlow
                            ref={questionAnswerRef}
                            flowAnalyticEventPrefix="bc_flow_"
                            imageMap={config.flow.imageMap}
                            componentMap={componentMap}
                            activities={activities}
                            onFlowStateChange={onFlowStateChange}
                            onFlowComplete={onFlowComplete}
                            onFlowSkip={onFlowSkip}
                            onStepChange={setCurrentStep}
                            linkButtonUnderlined={config.flow.linkButtonUnderlined}
                            numberProgress={config.flow.numberProgress}
                            hidePreviousButton={config.flow.customBackButton}
                        />
                    )}

                    {currentState === PageState.RESULTS && scanId && (
                        <BandwidthCalculatorResultsView
                            results={webScanResults.result}
                            streamingGuidelines={filteredStreamingCapabilities}
                            skippedGuidelines={streamingGuidelines}
                            onCheckSpeedsAgain={onScanAgain}
                            onSeeMoreRecomendations={onSeeMoreRecommendations}
                            config={config.results}
                            scanId={scanId}
                        />
                    )}
                    {currentState === PageState.RECOMMENDATIONS && <BandwidthCalculatorResultsRecommendations />}
                </ContentContainer>
            )}
        </>
    );
};
