import * as React from 'react';

export const RedAlertCircle = () => (
    <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" role="img" aria-label="red alert circle">
        <path d="M11 6.97v7h2v-7h-2zM13.25 16.97a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z" fill="#DB1B1B" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.97c-5.514 0-10-4.486-10-10 0-5.515 4.486-10 10-10s10 4.485 10 10c0 5.514-4.486 10-10 10zm0-18c-4.411 0-8 3.588-8 8 0 4.41 3.589 8 8 8s8-3.59 8-8c0-4.412-3.589-8-8-8z"
            fill="#DB1B1B"
        />
    </svg>
);
