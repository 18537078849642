import styled from '@emotion/styled';
import React from 'react';
import SpinnerSvg from '../spinner.svg';

const SpinnerContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: -2rem;
  margin-bottom: 2rem;

  .reload-svg {
    animation: spin 4s linear infinite;
    -webkit-animation: spin 4s linear infinite;

    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const Spinner = () => (
  <SpinnerContainer>
    <SpinnerSvg
      alt="reload"
      className="reload-svg"
      height="100px"
      aria-label="loading spinner"
      role="img"
    />
  </SpinnerContainer>
);

export default Spinner;
