import styled from '@emotion/styled';
import React from 'react';
import { BreakPoints } from '../Breakpoints';
import { Checkbox } from '../Checkbox';
import { Checkmark } from '../Icons';
import { BaseButton, IBaseButtonProps } from './BaseButton';

interface IconButtonProps extends IBaseButtonProps {
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
    Icon?: () => JSX.Element;
    selected?: boolean;
}

type BorderProps = Pick<IconButtonProps, 'selected'>;

const Button = styled(BaseButton)<BorderProps>`
    background: none;
    border-radius: 8px;
    color: ${(props) =>
        props.selected
            ? props.theme?.components?.questionAnswerGrid?.answerAccent || props.theme?.button?.selectedColor
            : props.theme?.button?.defaultTextColor};
    font-family: ${(props) => props.theme?.font?.type};
    font-size: 1rem;
    border: 2px solid
        ${(props) =>
            props.selected
                ? props.theme?.components?.questionAnswerGrid?.answerAccent || props.theme?.button?.selectedColor
                : '#E6E6EB'};
    height: 6.25rem;
    width: 100%;
    transition: transform 0.2s ease-in;
    user-select: none;

    button&:active {
        color: ${(props) =>
            props.theme?.components?.questionAnswerGrid?.answerAccent || props.theme?.button?.selectedColor};
        transform: scale(0.96);
    }

    @media ${BreakPoints.mobileBig} {
        width: 100%;
    }

    @media (hover: hover) {
        button&:hover {
            color: ${(props) =>
                props.theme.components?.questionAnswerGrid?.answerAccent || props.theme.button?.selectedColor};
            border: 2px solid
                ${(props) =>
                    props.theme.components?.questionAnswerGrid?.answerAccent || props.theme.button?.selectedColor};
        }
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    top: -10px;
`;

export const IconButton = ({
    onClick,
    label,
    analyticEventName,
    disabled,
    Icon,
    selected,
    analyticsExtras,
    ...props
}: IconButtonProps) => (
    <Button
        onClick={onClick}
        analyticsExtras={analyticsExtras}
        analyticEventName={analyticEventName}
        disabled={disabled || false}
        selected={selected || false}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        <Checkbox checkedIcon={<Checkmark />} selected={selected || false} />
        <ButtonContainer>
            {Icon && (
                <div>
                    <Icon />
                </div>
            )}
            {label && <div>{label}</div>}
        </ButtonContainer>
    </Button>
);
