import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { RouterResultsBackground } from './RouterResultsBackground';

const RouterResultsContainer = styled.div`
    width: 100%;
`;

const BackgroundContainer = styled.div`
    margin-top: -5rem;
`;

const RouterResultsHeader = styled.div`
    font-size: 1.5rem;
    line-height: 2.25rem;
    height: 0;
    margin: 0 1rem;
    margin-top: -28.5rem;
    margin-bottom: 28.5rem;
    overflow-y: visible;
`;

const RouterSpeedContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: -14.5rem;
`;

const RoomSpeedContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

const SpeedHeader = styled.div`
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5rem;
    text-align: left;
`;

const RouterSpeed = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    text-align: center;
    margin-right: 11rem;
`;

const RoomSpeed = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: auto;
    text-align: center;
    margin-top: -2.5rem;
    margin-left: 12rem;
    padding: 0.5rem;
`;

const Speed = styled.div`
    font-size: 3rem;
`;

const Units = styled.div`
    color: #000000;
    font-size: 1rem;
    margin-bottom: 0.35rem;
    margin-left: 0.15rem;
`;

const SpeedChip = styled.div`
    font-size: 1rem;
    color: #fff;
    background: ${(props) => props.theme.components?.resultsPage?.multiScanChipColor || '#000'};
    padding: 0.25rem;
    border-radius: 3px;
    margin: auto 0.35rem;
`;

interface RouterResultsProps {
    header: string | ReactNode;
    routerSpeed: number;
    roomSpeed: number;
    room: string;
    dotColor: string;
}

export const RouterResults = ({ header, routerSpeed, roomSpeed, room, dotColor }: RouterResultsProps) => {
    const speedPercentage = roomSpeed > 0 && routerSpeed > 0 ? Math.min(1, roomSpeed / routerSpeed) : 0;
    const speedPercentageFormatted = `${((1 - speedPercentage) * 100).toFixed(0)}%`;

    const showSpeedChip = speedPercentage > 0 && routerSpeed > roomSpeed;

    return (
        <RouterResultsContainer>
            <BackgroundContainer>
                <RouterResultsBackground speedPercentage={speedPercentage} dotColor={dotColor} />
            </BackgroundContainer>
            <RouterResultsHeader>{header}</RouterResultsHeader>
            <RouterSpeedContainer>
                <RouterSpeed>
                    <SpeedHeader>Router speed</SpeedHeader>
                    <Speed>{routerSpeed}</Speed>
                    <Units>Mbps</Units>
                </RouterSpeed>
            </RouterSpeedContainer>
            <RoomSpeedContainer>
                <RoomSpeed>
                    <SpeedHeader>{room} speed</SpeedHeader>
                    <Speed>{roomSpeed}</Speed>
                    <Units>Mbps</Units>
                    {showSpeedChip && <SpeedChip>{`${speedPercentageFormatted} slower`}</SpeedChip>}
                </RoomSpeed>
            </RoomSpeedContainer>
        </RouterResultsContainer>
    );
};
