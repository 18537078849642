import styled from '@emotion/styled';
import { FilledButton, Hazard } from '@home-mgmt-shared/common-ui';
import React from 'react';

const OverviewErrorStateContainer = styled.div`
    width: 100%;
    height: 17.44925rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-bottom: 1px solid #d5d6da;
`;
const OverviewErrorStateContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`;

const ErrorMessage = styled.div`
    font-size: 1.25rem;
    line-height: 150%;
    text-align: center;
    margin-top: 1.75rem;
    margin-bottom: 1rem;
`;

const ErrorButton = styled(FilledButton)`
    width: 7.5rem;
    height: 2rem;
    margin: 0;
`;

interface OverviewErrorStateProps {
    onClick?: () => void;
    errorMessage: string;
    buttonText: string;
}

export const OverviewErrorState = ({ onClick, errorMessage, buttonText }: OverviewErrorStateProps) => (
    <OverviewErrorStateContainer>
        <OverviewErrorStateContent>
            <Hazard />
            <ErrorMessage>{errorMessage}</ErrorMessage>
            <ErrorButton onClick={onClick} analyticEventName="Error_Try_Again">
                {buttonText}
            </ErrorButton>
        </OverviewErrorStateContent>
    </OverviewErrorStateContainer>
);
