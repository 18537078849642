import React from 'react';

interface TooltipIconProps {
    fillColor?: string;
}

export const TooltipIcon = ({ fillColor = '#000' }: TooltipIconProps) => (
    <svg
        width="18"
        height="18"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="tooltip icon"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.40698 9.4126C4.40698 6.38229 6.87618 3.9126 9.90698 3.9126C12.9378 3.9126 15.407 6.38229 15.407 9.4126C15.407 12.4441 12.938 14.9126 9.90698 14.9126C6.87592 14.9126 4.40698 12.4441 4.40698 9.4126ZM9.90698 1.9126C5.77148 1.9126 2.40698 5.27785 2.40698 9.4126C2.40698 13.5488 5.77148 16.9126 9.90698 16.9126C14.0425 16.9126 17.407 13.5488 17.407 9.4126C17.407 5.27785 14.0425 1.9126 9.90698 1.9126ZM9.90698 5.47583C10.4245 5.47583 10.8445 5.89583 10.8445 6.41333C10.8445 6.93158 10.4245 7.35083 9.90698 7.35083C9.38948 7.35083 8.96948 6.93083 8.96948 6.41258C8.96948 5.89508 9.38948 5.47583 9.90698 5.47583ZM12.157 11.6626V13.1626H7.65698V11.6626H9.15698V9.41258H8.40698V7.91258H9.90698C10.3217 7.91258 10.657 8.24858 10.657 8.66258V11.6626H12.157Z"
            fill={fillColor}
        />
    </svg>
);
