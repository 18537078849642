import { multiScanRouterScanFlow } from '@home-mgmt-shared/flows';
import {
  MultiPointScanConfig,
  MultiRoomScanView,
  MultiScanPageState,
} from '@home-mgmt-shared/multi-point-scan-page';
import React, { useCallback, useState } from 'react';
import { QuestionAnswerIcons } from '../img/att/question-answer-icons';
import { RoutePaths } from '../utils';
import { BasePage } from './BasePage';

const assets = {
  imageMap: QuestionAnswerIcons,
};

const multiPointScanConfig: MultiPointScanConfig = {
  points: [
    {
      flowConfig: {
        flow: multiScanRouterScanFlow,
        imageMap: assets.imageMap,
      },
      name: 'Trouble Room Scan',
    },
    {
      flowConfig: { imageMap: assets.imageMap },
      name: 'Router Scan',
    },
  ],
};

interface BackButtonConfig {
  enabledPages: MultiScanPageState[];
}

const backButtonConfig: BackButtonConfig = {
  enabledPages: [MultiScanPageState.RECOMMENDATIONS],
};

export const MultiScanPage = () => {
  const [back, setBack] = useState<boolean>(false);
  const [showBack, setShowBack] = useState<boolean>(false);
  const onPageChange = useCallback(
    (page: MultiScanPageState, scanInProgress: boolean) => {
      if (backButtonConfig.enabledPages.includes(page) && !scanInProgress) {
        setShowBack(true);
      } else {
        setShowBack(false);
      }
    },
    []
  );
  const onPreviousPage = useCallback(() => {
    setBack(!back);
  }, [back]);

  return (
    <BasePage backButtonCallback={showBack ? onPreviousPage : undefined}>
      <MultiRoomScanView
        config={multiPointScanConfig}
        onPageChange={onPageChange}
        backWasClicked={back}
        defaultExperienceRoute={RoutePaths.SCAN_PAGE}
      />
    </BasePage>
  );
};
