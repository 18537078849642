import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line import/extensions
import 'bootstrap/dist/js/bootstrap.js';
import React from 'react';
import ReactDOM from 'react-dom';
import configureStore, { history } from './store/configureStore';
import Root from './components/Root';
import './styles/main.scss';
import './styles/fonts.scss';
import './i18n/i18n';

require('./ATT_favicon.png');

const store = configureStore({});

ReactDOM.render(
  <Root store={store} history={history} />,
  document.getElementById('app')
);
