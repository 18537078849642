import queryString from './queryString';

const SESSION_STORAGE_KEY_NAME = 'campaign-identifier';

const setCidInStore = (cid: string) => {
  sessionStorage.setItem(SESSION_STORAGE_KEY_NAME, cid);
};

const getCidFromStore = () => sessionStorage.getItem(SESSION_STORAGE_KEY_NAME);
export default class Campaign {
  public static getCampaignId(): string {
    const cidFromQueryString = (
      queryString.get().cid || ''
    ).toLocaleLowerCase();
    if (cidFromQueryString) {
      setCidInStore(cidFromQueryString);
      return cidFromQueryString;
    }
    const cidFromStore = getCidFromStore();
    if (cidFromStore) {
      return cidFromStore;
    }

    return 'none';
  }

  public static setCampaignId(): void {
    const campaignId: string = queryString.get().cid;
    if (campaignId) setCidInStore(campaignId.toLocaleLowerCase());
  }
}
