import * as React from 'react';

export const TargetCircle = () => (
    <svg
        width={25}
        height={25}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="target-circle"
    >
        <path d="M10.714 12.924l5.5-5.5" stroke="#000" strokeLinecap="round" strokeLinejoin="round" />
        <path
            clipRule="evenodd"
            d="M16.8 3.338a2 2 0 00-.586 1.414v2.672h2.672a2 2 0 001.414-.586l2.414-2.414h-3.5v-3.5L16.8 3.338zM10.714 17.924a5 5 0 100-10 5 5 0 000 10z"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M19.714 12.924a9 9 0 11-9-9M5.108 19.965l-3.394 3.959M16.32 19.965l3.394 3.959"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
