export enum MultiPointEvents {
    CanYouMovePage = 'Multi_Point_Can_You_Move_Page',
    SwitchToMobilePage = 'Multi_Point_Switch_To_Mobile_Page',
    LandingPage = 'Multi_Point_Landing_Page',
    QuestionnairePage = 'Multi_Point_Questionnaire_Page',
    RoomPrescanPage = 'Multi_Point_Room_Prescan_Page',
    RouterPrescanPage = 'Multi_Point_Router_Prescan_Page',
    ScanningPage = 'Multi_Point_Scanning_Page',
    ResultsPage = 'Multi_Point_Results_Page',
}
