import { Theme } from '@emotion/react';

export const appScanTheme: Theme = {
  font: {
    type: 'ATTAleckSans_Rg',
    bold: 'ATTAleckSans_Bd',
    accentColor: '#0057B8',
  },
  accentColors: {
    primary: '#0057B8',
    secondary: '#009FDB',
    tertiary: '#6E767D',
  },
  button: {
    primaryColor: '#0057B8',
    selectedColor: '#0057B8',
    disabledColor: '#6E767D',
    disabledTextColor: '#000000',
    defaultTextColor: '#000000',
  },
  special: {
    primaryColor: '#00A2FB',
    secondaryColor: '#0057B8',
  },
  spinner: {
    primaryColor: '#0057B8',
  },
};
