import styled from '@emotion/styled';
import React from 'react';
import { BaseButton, IBaseButtonProps } from './BaseButton';

interface SelectableButtonProps extends IBaseButtonProps {
    onClick?: () => void;
    disabled?: boolean;
    selected?: boolean;
}

type BorderProps = Pick<SelectableButtonProps, 'selected'>;

const Button = styled(BaseButton)<BorderProps>`
    display: flex;
    background: none;
    border-radius: 8px;
    color: ${(props) =>
        props.selected
            ? props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor
            : props.theme.button?.defaultTextColor};
    padding: 1.062rem 1.1rem;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    margin-top: 15px;
    border: 2px solid
        ${(props) =>
            props.selected
                ? props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor
                : '#E6E6EB'};
    min-height: 46px;
    width: 100%;
    align-items: center;
    transition: transform 0.2s ease-in;
    user-select: none;

    button&:active {
        color: ${(props) => props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor};
        transform: scale(0.96);
    }

    @media (hover: hover) {
        button&:hover {
            color: ${(props) =>
                props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor};
            border: 2px solid
                ${(props) => props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor};
        }
    }
`;

const Unselected = styled.div`
    height: 1.375rem;
    width: 1.375rem;
    border-radius: 1.375rem;
    border: 1px solid
        ${(props) => props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.primaryColor};
`;

const Selected = styled.div`
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 0.75rem;
    background-color: ${(props) =>
        props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.primaryColor};
`;

const SelectedContainer = styled.div`
    border: 2px solid
        ${(props) => props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.primaryColor};
    height: 1.25rem;
    width: 1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1.25rem;
`;

const SelectedWrapper = styled.div`
    padding-right: 1rem;
`;

type SelectedIndicatorProps = {
    selected: boolean;
};

const SelectedIndicator = ({ selected }: SelectedIndicatorProps) => (
    <SelectedWrapper>
        {selected ? (
            <SelectedContainer>
                <Selected />
            </SelectedContainer>
        ) : (
            <Unselected />
        )}
    </SelectedWrapper>
);
export const SelectableButton = ({
    onClick,
    children,
    analyticEventName,
    disabled,
    selected,
    analyticsExtras,
    ...props
}: SelectableButtonProps) => (
    <Button
        onClick={onClick}
        analyticsExtras={analyticsExtras}
        analyticEventName={analyticEventName}
        disabled={disabled || false}
        selected={selected || false}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        <SelectedIndicator selected={selected || false} />
        {children}
    </Button>
);
