import * as C from '../constants';

const initialState = {
  items: [],
  loading: false,
  error: null,
};

export default function HomeGraphReducer(state = initialState, action) {
  switch (action.type) {
    case C.FETCH_HOMEGRAPHS_DATA_BEGIN:
      return {
        ...state,
        error: null,
        loading: action.shouldDisplayLoadingIndicator,
      };
    case C.FETCH_HOMEGRAPHS_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload.data,
      };

    case C.FETCH_HOMEGRAPHS_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response
          ? action.payload.error.response.status
          : true,
        items: [],
      };

    default:
      return state;
  }
}
