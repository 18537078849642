import React from 'react';

interface BellProps {
    strokeColor?: string;
}

export const Bell = ({ strokeColor = 'black' }: BellProps = {}) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="bell"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.49219 12.2998C4.49219 7.88153 8.07391 4.2998 12.4922 4.2998C16.9105 4.2998 20.4922 7.88153 20.4922 12.2998V18.7998C20.4922 19.6282 21.1638 20.2998 21.9922 20.2998H2.99219C3.82061 20.2998 4.49219 19.6282 4.49219 18.7998V12.2998Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M12.4922 1.30078V4.30078" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M14.942 22.2998C14.7054 23.465 13.6809 24.3023 12.492 24.3023C11.3031 24.3023 10.2786 23.465 10.042 22.2998"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.9922 7.76855C16.5235 8.52831 17.4921 10.0901 17.4922 11.7996"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
