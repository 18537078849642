import * as React from 'react';

export const QuestionCircle = () => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="question-circle"
    >
        <path
            d="M8.464 9.92a3.752 3.752 0 114.562 3.657 1.007 1.007 0 00-.812.975v1.113M12.214 18.17a.25.25 0 110 .5.25.25 0 010-.5"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            clipRule="evenodd"
            d="M12.214 23.678c6.351 0 11.5-5.149 11.5-11.5s-5.149-11.5-11.5-11.5-11.5 5.149-11.5 11.5 5.149 11.5 11.5 11.5z"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
