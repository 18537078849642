import styled from '@emotion/styled';
import { SpinningWand } from '@home-mgmt-shared/common-ui';
import React, { useEffect } from 'react';

interface CalculatingResultsViewProps {
    onCalculatingFinished: () => void;
    delay?: number;
    LoadingAnimation?: () => JSX.Element;
}

const CalculatingPageContainer = styled.div`
    display: flex;
    width: 100%;
    height: calc(100vh - 75px);
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
`;

const CalculatingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 365px;
`;

const CalculatingWand = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CalculatingDescription = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 2rem;
    color: ${(props) => props.theme.font?.accentColor};
    margin: 0 1.3125rem;
    text-align: center;
    @media all and (max-width: 425px) {
        text-align: center;
        font-size: 1.5rem;
    }
`;

export const CalculatingResultsView = ({
    onCalculatingFinished,
    delay = 5000,
    LoadingAnimation,
}: CalculatingResultsViewProps) => {
    useEffect(() => {
        setTimeout(() => {
            onCalculatingFinished();
        }, delay);
    }, [delay, onCalculatingFinished]);

    return (
        <CalculatingPageContainer>
            <CalculatingContainer>
                <CalculatingWand>{LoadingAnimation ? LoadingAnimation() : <SpinningWand />}</CalculatingWand>
                <CalculatingDescription>Calculating your ideal Wi-Fi speed...</CalculatingDescription>
            </CalculatingContainer>
        </CalculatingPageContainer>
    );
};
