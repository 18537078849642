import { IAuthClient } from '@home-mgmt-shared/auth';
import { WixiCore, WixiCoreConfig, WixiPartner } from '@soluto-private/wixi-web-sdk';

let AuthClient: IAuthClient;
if (IS_MX_APP) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-var-requires
    AuthClient = require('@home-mgmt-shared/auth').AuthClient;
}

export const initializeWixiSdk = (config: WixiPartner): void => {
    WixiCore.initialize({
        wixiPartner: {
            partnerSecret: config.partnerSecret,
            partnerAppId: config.partnerAppId,
            partnerId: config.partnerId,
            partnerAppSecret: config.partnerAppSecret,
        },
    });
};

export const initializeWixiSdkWithAuth = async (config: WixiPartner): Promise<void> => {
    let wixiConfig: WixiCoreConfig = {
        wixiPartner: {
            partnerSecret: config.partnerSecret,
            partnerAppId: config.partnerAppId,
            partnerId: config.partnerId,
            partnerAppSecret: config.partnerAppSecret,
        },
    };
    if (AuthClient.isLoggedIn) {
        wixiConfig = {
            ...wixiConfig,
            wixiAuth: {
                getAuthToken: () => Promise.resolve(AuthClient.idToken ?? ''),
            },
        };
    }

    WixiCore.initialize(wixiConfig);

    try {
        await WixiCore.enroll();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('An error occurred during wixi enrollment');
    }
};
