import * as React from 'react';

export const OpenArrow = ({ fillColor = '#000' }) => (
    <svg width={13} height={8} viewBox="0 0 13 8" fill="none" role="img" aria-label="open arrow">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.018 7.773l-4.59-5-4.59 5-1.41-1.41 6-6 6 6-1.41 1.41z"
            fill={fillColor}
        />
    </svg>
);
