import * as React from 'react';

export const Wifi = ({ fill }: { fill?: string }) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="wifi"
    >
        <path
            d="M12.325 22.792c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3c0 1.655-1.346 3-3 3zm0-4c-.55 0-1 .45-1 1 0 .551.45 1 1 1 .551 0 1-.449 1-1 0-.55-.449-1-1-1zM16.567 15.55a5.952 5.952 0 00-4.242-1.758 5.952 5.952 0 00-4.242 1.758L6.67 14.136a7.942 7.942 0 015.656-2.344c2.136 0 4.146.832 5.656 2.344l-1.414 1.414zM22.225 9.892a13.912 13.912 0 00-9.9-4.1c-3.74 0-7.256 1.455-9.9 4.1L1.011 8.478a15.896 15.896 0 0111.314-4.686c4.273 0 8.291 1.664 11.315 4.686l-1.415 1.414z"
            fill={fill || '#000'}
        />
        <path
            d="M19.395 12.72c-3.898-3.898-10.242-3.898-14.14 0L3.84 11.306c4.678-4.678 12.29-4.678 16.97 0l-1.415 1.414z"
            fill={fill || '#000'}
        />
    </svg>
);
