import styled from '@emotion/styled';
import React from 'react';
import ReactMarkdown from 'react-markdown';

const AccordionItem = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    flex: auto;
    text-align: left;
    margin-left: 3px;
    padding-right: 2rem;
    line-height: 150%;
`;

interface RecommendationsItemProps {
    content: string;
}

export const RecommendationsItem = ({ content }: RecommendationsItemProps) => (
    <AccordionItem className="accordion-item">
        <ReactMarkdown className="accordion-item-md">{content}</ReactMarkdown>
    </AccordionItem>
);
