import { Email } from './Email';
import { Gaming } from './Gaming';
import { StreamingVideo } from './StreamingVideo';
import { VideoCalls } from './VideoCalls';
import { WebBrowsing } from './WebBrowsing';
import { Streaming4k } from './Streaming4k';

export const QuestionAnswerIcons = {
    gaming: Gaming,
    email: Email,
    streamingVideo: StreamingVideo,
    videoCalls: VideoCalls,
    webBrowsing: WebBrowsing,
    streaming4k: Streaming4k,
};

export * from './SpinningWand';
export * from './Checkmark';
