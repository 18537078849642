import React from 'react';

interface CalculatorProps {
    strokeColor?: string;
}

export const Calculator = ({ strokeColor = 'black' }: CalculatorProps = {}) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="calculator"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.99609 3.7373C3.99609 2.35659 5.11538 1.2373 6.49609 1.2373H19.4961C20.8768 1.2373 21.9961 2.35659 21.9961 3.7373V21.7373C21.9961 23.118 20.8768 24.2373 19.4961 24.2373H6.49609C5.11538 24.2373 3.99609 23.118 3.99609 21.7373V3.7373Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M3.99609 16.2373H21.9961" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.99609 9.2373H21.9961" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.9961 9.2373V24.2373" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.49609 13.2373H9.49609" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.9961 13.2373H18.9961" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.9961 21.2373H18.9961" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M15.9961 19.2373H18.9961" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.99609 11.7373V14.7373" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.49609 18.7373L9.49609 21.7373" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.49609 18.7373L6.49609 21.7373" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.9971 6.2373V4.2373" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
