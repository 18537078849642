export const sortArrofObjByDate = (arr, key) =>
  arr.sort(function (a, b) {
    return (
      Date.parse(b[key].replace(/ /g, 'T')) -
      Date.parse(a[key].replace(/ /g, 'T'))
    );
  });

export const searchStringToObj = (string) => {
  if (typeof string !== 'string' || string.length < 1) {
    return null;
  }
  const search = string.substring(1);
  return JSON.parse(
    `{"${search.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
    function (key, value) {
      return key === '' ? value : decodeURIComponent(value);
    }
  );
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const formatSpeed = (speed: string): string =>
  Math.round(parseInt(speed, 10)).toString();

export const isProduction = (): boolean => process.env.BUILD_ENV === 'prod';
