import { Answer } from '@home-mgmt-shared/flow-ui';
import { DownloadSpeedResult } from '@home-mgmt-shared/multi-point-scan';

export interface IMultiPointScanResult {
    scanName: string;
    speedResults?: DownloadSpeedResult;
    flowResults?: Record<string, Answer[]>;
}

export enum SignalResultStatus {
    WEAK = 'weak',
    OKAY = 'okay',
    ADJUSTED = 'adjusted',
}
