import { updateTweekContext } from '@home-mgmt-shared/tweek-helpers';
import { analytics } from '@soluto-private/ns-analytics';
import { UserBrowser } from '@home-mgmt-shared/common-ui';
import { PARTNER } from '../../constants';
import Campaign from './Campaign';
import { isProduction } from '../utils';
import AccountId from './AccountId';

export const initializeAnalytics = (queryParams: string): void => {
  const params = new URLSearchParams(queryParams);
  const isTest = params.get('isTest');
  analytics.setCustomParamValidator(() => true);
  updateTweekContext({
    partner: PARTNER,
    '@@id': UserBrowser.Id,
    hostname: window.location.hostname,
    program: params.get('program'),
  });
  analytics.updateProperties({
    partner: PARTNER,
    launchedFrom: window.location.href,
    browserId: UserBrowser.Id,
    campaignId: Campaign.getCampaignId(),
    program: params.get('program'),
    flow: params.get('flow'),
    appName: 'streaming-support',
    isTest: !isProduction() || !!isTest,
    env: process.env.BUILD_ENV,
    accountId: AccountId.getAccountId() || params.get('accountId'),
  });
};
