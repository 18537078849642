import styled from '@emotion/styled';
import { BaseButton } from '@home-mgmt-shared/common-ui';
import React, { useState } from 'react';
import { useTweekValue } from 'react-tweek';

const TipFeedbackContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
`;

const Label = styled.div`
    color: ${(props) => props.theme.button?.defaultTextColor};
    font-family: ${(props) => props.theme.font?.type};
    font-size: 0.9rem;
    font-weight: bold;
    text-align: left;
    margin-right: 15px;
    margin-left: 3px;
`;

const Button = styled(BaseButton)`
    background: ${(props) => props.theme.button?.primaryColor};
    border-radius: 25px;
    color: white;
    padding: 3px 3px 4px 3px;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 16px;
    margin-right: 15px;
    border: none;
    width: 70px;
    transition: transform 0.2s ease-in;
    user-select: none;

    @-moz-document url-prefix() {
        padding: 4px 3px 3px 3px;
    }

    button&:active {
        transform: scale(0.96);
    }
`;

interface ITipFeedback {
    label: string;
    yesLabel: string;
    noLabel: string;
}

const getDefaultTipLabels = (): ITipFeedback => ({
    label: 'Was this tip helpful?',
    yesLabel: 'Thanks!',
    noLabel: 'Sorry that this tip wasn’t helpful! Be sure to try a few of the others',
});

type TipButtonProps = {
    analyticEventName: string;
    analyticExtras: Record<string, unknown>;
    onClick?: () => void;
    onTipSelected?: (feedbackValue: boolean) => void;
};

export const TipFeedback = ({ analyticEventName, analyticExtras, onClick, onTipSelected }: TipButtonProps) => {
    const [submitted, setSubmitted] = useState(false);
    const [isYes, setIsYes] = useState(false);

    const submitTrue = () => {
        onClick?.();
        onTipSelected?.(true);
        setIsYes(true);
        setSubmitted(true);
    };
    const submitFalse = () => {
        onClick?.();
        onTipSelected?.(false);
        setIsYes(false);
        setSubmitted(true);
    };

    const labels: ITipFeedback = useTweekValue<ITipFeedback>('network_scan/tip_feedback_labels', getDefaultTipLabels());

    return (
        <TipFeedbackContainer>
            {!submitted && (
                <>
                    <Label>{labels.label}</Label>
                    <Button
                        analyticEventName={analyticEventName}
                        analyticsExtras={{ ...analyticExtras, feedbackValue: 'Yes' }}
                        onClick={submitTrue}
                    >
                        Yes
                    </Button>
                    <Button
                        analyticEventName={analyticEventName}
                        analyticsExtras={{ ...analyticExtras, feedbackValue: 'No' }}
                        onClick={submitFalse}
                    >
                        No
                    </Button>
                </>
            )}
            {submitted && isYes && <Label>{labels.yesLabel}</Label>}
            {submitted && !isYes && <Label>{labels.noLabel}</Label>}
        </TipFeedbackContainer>
    );
};
