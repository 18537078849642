import queryString from 'query-string';

function get(): any {
  return queryString.parse(location.search);
}

function stringify(obj: any): string {
  return queryString.stringify(obj);
}

export default {
  get,
  stringify,
};
