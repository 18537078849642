import React from 'react';
import PropTypes from 'prop-types';
import SpinnerSvg from '../../img/att/spinner.svg';

export default function Spinner({ height, text }) {
  const spinnerProps = {
    height: height || '100px',
  };

  return (
    <div className="dashboard-spinner d-block">
      <div className="dashboard-spinner-wrapper">
        {text && <p className="text">{text}</p>}
        <SpinnerSvg alt="reload" className="reload-svg" {...spinnerProps} />
      </div>
    </div>
  );
}
Spinner.propTypes = {
  height: PropTypes.any,
  text: PropTypes.string,
};
