import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AttHeader, {
  forceLogin,
  USER_SSO_STATUS,
} from '@soluto-asurion/one-service-header-react';
import AttFooter from '@soluto-asurion/one-service-footer-react';
import {
  ATTMessaging,
  startMessagingSession,
  State,
  updateState,
} from '@soluto-asurion/one-service-messaging-react';
import { withAnalytics } from 'react-shisell';
import styled from '@emotion/styled';
import {
  analytics as NetScanAnalytics,
  NSEventType,
} from '@soluto-private/ns-analytics';
import Spinner from '../CommonComponents/Spinner';
import AttFreeUser from './AttFreeUser';
import AttQuickTips from './AttQuickTips';
import AttNoData from './AttNoData';
import AttLogin from './AttLogin';
import { fetchHomeGraphsDataUsingAxios } from '../../actions/homeGraphActions';
import { fetchSessionDataUsingAxios } from '../../actions/sessionDataActions';
import { fetchQuickTipsUsingAxios } from '../../actions/quickTipsActions';
import { searchStringToObj } from '../../utils/utils';
import * as c from '../../constants';
import { AppScanResultsPage } from '../../pages';
import { SESSION_STORAGE_APPLICATION_ID_KEY } from '../../constants';

const AppScanResultsPageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function AttDashboard({
  dispatch,
  location,
  homegraphsLoading,
  homegraphsData,
  homeGraphsError,
  sessionError,
  sessionDataLoading,
  analytics,
  isFreeUser,
}) {
  const obj = searchStringToObj(decodeURIComponent(location.search)) || {};

  const [userStatus, setUserStatus] = useState(
    USER_SSO_STATUS.STATUS_CHECK_IN_PROGRESS
  );
  const { applicationId } = obj;
  const [userInfoAppAryErrorMsg, setUserInfoAppAryErrorMsg] = useState('');
  const [isUserStatusUpdated, setIsUserStatusUpdated] = useState(false);
  const [isPollingFinished, setIsPollingFinished] = useState(false);

  useEffect(() => {
    if (process.env.DEVELOP) {
      // defining this object for local development only
      const homeGraphObj = {
        clientId: process.env.Client_Id,
        authToken: '123',
        hostArray: ['test'],
      };
      dispatch(fetchHomeGraphsDataUsingAxios(homeGraphObj));
    }
    dispatch(fetchQuickTipsUsingAxios());
    analytics.dispatcher
      .createScoped('StreamingSupport')
      .withExtra('context', obj.cid || '')
      .withExtra('program', obj.program || '')
      .withExtra('AccountIdFromQueryParameter', obj.accountId || '')
      .withExtra('CarrierName', 'att')
      .dispatch('Streaming Support Page Rendered');
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleScroll() {
    const windowHeight =
      'innerHeight' in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const { body } = document;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight) {
      analytics.dispatcher
        .createScoped('StreamingSupport')
        .withExtra('context', obj.cid || '')
        .withExtra('program', obj.program || '')
        .withExtra('AccountIdFromQueryParameter', obj.accountId || '')
        .withExtra('CarrierName', 'att')
        .dispatch(c.USER_SCROLLED_TO_THE_BOTTOM);
    }
  }

  function fetchSessionData(
    sessionApplicationId,
    shouldDisplayLoadingIndicator
  ) {
    if (!isFreeUser) {
      dispatch(
        fetchSessionDataUsingAxios(
          sessionApplicationId,
          shouldDisplayLoadingIndicator
        )
      );
    }
  }

  /* we will handle the logic to set the application id here */
  function getApplicationIdfromStatus(userInfo) {
    if (userInfo && userInfo.applications && userInfo.applications.length > 0) {
      dispatch(fetchSessionDataUsingAxios(userInfo.applications));
    } else {
      setUserInfoAppAryErrorMsg('Application Array is missing in the UserInfo');
    }
  }

  const onFinishedPolling = (intervalHandler: NodeJS.Timeout) => {
    clearInterval(intervalHandler);
    setIsPollingFinished(true);
  };

  function handleSignInFunctionality(status) {
    if (status && status.userStatus && status.userInfo) {
      // pass user Status to getApplicationIdfromStatus
      if (
        status.userStatus === USER_SSO_STATUS.SIGNED_IN &&
        !isUserStatusUpdated
      ) {
        setIsUserStatusUpdated(true);
        updateState(State.AuthenticatedUser);
        analytics.dispatcher
          .createScoped('StreamingSupport')
          .withExtra('context', obj.cid || '')
          .withExtra('program', obj.program || '')
          .withExtra('AccountIdFromQueryParameter', obj.accountId || '')
          .withExtra('CarrierName', 'att')
          .dispatch('User Signed In');
        getApplicationIdfromStatus(status.userInfo);
      } else if (status.userStatus === USER_SSO_STATUS.SIGNED_OUT) {
        if (applicationId) {
          setIsPollingFinished(false);
          fetchSessionData([{ applicationId }], true);
          const fetchRepeat = setInterval(
            () => fetchSessionData([{ applicationId }], false),
            10000
          );
          setTimeout(() => onFinishedPolling(fetchRepeat), 50000);
        }
        updateState(State.Anonymous);
        setIsUserStatusUpdated(false);
      }
      setUserStatus(status.userStatus);
    }
  }

  function shouldRenderSignedOutPage(pUserStatus, pApplicationId) {
    return !pApplicationId && pUserStatus === USER_SSO_STATUS.SIGNED_OUT;
  }

  useEffect(() => {
    if (isPollingFinished) {
      const pollingSucceeded =
        !isNaN(
          parseInt(homegraphsData?.internetHealth?.downloadThroughput, 10)
        ) &&
        !isNaN(parseInt(homegraphsData?.internetHealth?.uploadThroughput, 10));
      NetScanAnalytics.dispatch('Finished_Polling', NSEventType.Event, {
        pollingSucceeded,
      });
    }
  }, [
    homegraphsData?.internetHealth?.downloadThroughput,
    homegraphsData?.internetHealth?.uploadThroughput,
    isPollingFinished,
  ]);

  if (!process.env.DEVELOP) {
    if (shouldRenderSignedOutPage(userStatus, applicationId)) {
      return (
        <div role="banner" className="att-dashboard">
          <AttHeader
            partner="att"
            userStatus={handleSignInFunctionality}
            initiateMessaging={() => startMessagingSession('', forceLogin)}
            isProduction={process.env.NODE_ENV !== 'development'}
            appName="streaming-support"
          />
          <ATTMessaging environment={process.env.NODE_ENV} />
          <AttLogin obj={obj} />
          <div className="att-background att-quick-tips-section">
            <AttQuickTips quickTipsTitle="ProTech recommendations" />
          </div>
          <AttFooter
            partner="att"
            initiateMessaging={() => startMessagingSession('', forceLogin)}
          />
        </div>
      );
    }
    if (isFreeUser) {
      return (
        <div className="att-dashboard parent-container">
          <AttHeader
            partner="att"
            userStatus={handleSignInFunctionality}
            initiateMessaging={() => startMessagingSession('', forceLogin)}
            isProduction={process.env.NODE_ENV !== 'development'}
            appName="streaming-support"
          />
          <ATTMessaging environment={process.env.NODE_ENV} />
          <div className="container mb-5">
            <AttFreeUser />
          </div>
          <AttFooter
            partner="att"
            initiateMessaging={() => startMessagingSession('', forceLogin)}
          />
        </div>
      );
    }
    if (
      sessionDataLoading ||
      homegraphsLoading ||
      userStatus === USER_SSO_STATUS.STATUS_CHECK_IN_PROGRESS
    ) {
      return (
        <div className="att-dashboard parent-container">
          <AttHeader
            partner="att"
            userStatus={handleSignInFunctionality}
            initiateMessaging={() => startMessagingSession('', forceLogin)}
            isProduction={process.env.NODE_ENV !== 'development'}
            appName="streaming-support"
          />
          <ATTMessaging environment={process.env.NODE_ENV} />
          <Spinner text="Loading..." />
          <AttFooter
            partner="att"
            initiateMessaging={() => startMessagingSession('', forceLogin)}
          />
        </div>
      );
    }
    if (sessionError || homeGraphsError || userInfoAppAryErrorMsg.length > 0) {
      analytics.dispatcher
        .createScoped('StreamingSupport')
        .withExtra('context', obj.cid || '')
        .withExtra('program', obj.program || '')
        .withExtra('AccountIdFromQueryParameter', obj.accountId || '')
        .withExtra('CarrierName', 'att')
        .dispatch('Error while fetching the results');

      return (
        <div className="att-dashboard parent-container">
          <AttHeader
            partner="att"
            userStatus={handleSignInFunctionality}
            initiateMessaging={() => startMessagingSession('', forceLogin)}
            isProduction={process.env.NODE_ENV !== 'development'}
            appName="streaming-support"
          />
          <ATTMessaging environment={process.env.NODE_ENV} />
          <div className="container mb-5">
            <AttNoData />
          </div>
          <div className="att-background att-quick-tips-section">
            <AttQuickTips />
          </div>
          <AttFooter
            partner="att"
            initiateMessaging={() => startMessagingSession('', forceLogin)}
          />
        </div>
      );
    }
  }
  analytics.dispatcher
    .createScoped('StreamingSupport')
    .withExtra('Homegraph Data', homegraphsData)
    .withExtra('context', obj.cid || '')
    .withExtra('program', obj.program || '')
    .withExtra('AccountIdFromQueryParameter', obj.accountId || '')
    .withExtra('CarrierName', 'att')
    .dispatch('Successfully fetched the results');
  NetScanAnalytics.updateProperties({ userId: applicationId });

  sessionStorage.setItem(SESSION_STORAGE_APPLICATION_ID_KEY, applicationId);

  return (
    <div className="att-dashboard parent-container">
      <AttHeader
        partner="att"
        userStatus={handleSignInFunctionality}
        initiateMessaging={() => startMessagingSession('', forceLogin)}
        isProduction={process.env.NODE_ENV !== 'development'}
        appName="streaming-support"
      />
      <ATTMessaging environment={process.env.NODE_ENV} />
      {homegraphsData &&
      Object.entries(homegraphsData).length > 0 &&
      !(
        homegraphsData.internetHealth.downloadThroughput === 'NO_VALUE' &&
        isPollingFinished
      ) ? (
        <AppScanResultsPageContainer>
          <AppScanResultsPage homeData={homegraphsData} />
        </AppScanResultsPageContainer>
      ) : (
        <>
          <div className="container mb-5">
            <AttNoData />
          </div>
          <div className="att-background att-quick-tips-section">
            <AttQuickTips />
          </div>
        </>
      )}
      <AttFooter
        partner="att"
        initiateMessaging={() => startMessagingSession('', forceLogin)}
      />
    </div>
  );
}

AttDashboard.propTypes = {
  homegraphsData: PropTypes.object,
  homeGraphsError: PropTypes.any,
  homegraphsLoading: PropTypes.bool,
  sessionError: PropTypes.any,
  sessionDataLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  analytics: PropTypes.any,
  isFreeUser: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  homegraphsData: state.homeGraphs.items.data,
  homeGraphsError: state.homeGraphs.error,
  homegraphsLoading: state.homeGraphs.loading,
  sessionError: state.sessionData.error,
  sessionDataLoading: state.sessionData.loading,
  isFreeUser: state.sessionData.isFreeUser,
});

const attDashboard = withAnalytics(AttDashboard);

export default connect(mapStateToProps)(attDashboard);
