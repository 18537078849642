import styled from '@emotion/styled';
import { ArrowCurveDown } from '@home-mgmt-shared/common-ui';
import React from 'react';

const FactorsCardContainer = styled.div`
    margin: 1rem 0;
    padding: 1.5rem;
    background: #f0f0f5;
    border-radius: 4px;
`;

const FactorsCardHeader = styled.div`
    font-weight: bold;
    font-family: ${(props) => props.theme.font?.bold};
    font-size: 1rem;
    padding-bottom: 0.5rem;
    line-height: 135%;
`;

const FactorsCardText = styled.ul`
    font-size: 1rem;
    line-height: 135%;
    padding-inline-start: 1.25rem;
    margin: 0;
`;

const ArrowContainer = styled.div`
    width: 40px;
    height: 0;
    overflow: visible;
    margin-left: auto;
`;

interface FactorsCardProps {
    header: string;
    factors: string[];
}

export const FactorsCard = ({ header, factors }: FactorsCardProps) => (
    <FactorsCardContainer>
        <FactorsCardHeader>{header}</FactorsCardHeader>
        <FactorsCardText>
            {factors.map((factor) => (
                <li key={factor}>{factor}</li>
            ))}
        </FactorsCardText>
        <ArrowContainer>
            <ArrowCurveDown />
        </ArrowContainer>
    </FactorsCardContainer>
);
