import * as React from 'react';

interface SpeedIconHappyProps {
    fillColor?: string;
}

export const SpeedIconHappy = ({ fillColor = '#000' }: SpeedIconHappyProps) => (
    <svg
        height="50"
        width="50"
        viewBox="0 0 65 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="happy speed icon"
    >
        <path
            d="M49.6584 45.6242C47.9654 51.0953 39.0937 55.3746 33.1818 55.3746C20.7558 55.3746 9.67078 44.7968 9.67078 32.3708C9.67078 19.9448 20.1795 9.18359 32.6055 9.18359C45.0315 9.18359 56.6853 19.1184 56.6853 31.5444C56.6853 36.4521 54.1828 44.766 51.2174 48.7642M26.9691 15.5488L28.0404 18.8952M18.3231 22.8027L21.5932 24.363M16.3632 33.919L19.3192 33.397M38.2573 15.5488L36.7726 18.9768M35.6302 29.4228C39.395 27.0078 42.1032 25.9094 46.9031 22.8027C42.1032 25.9094 39.3134 28.3951 35.5487 30.8102M32.6132 28.3619C34.2692 28.3619 35.6132 29.3968 35.6132 31.0528C35.6132 32.7088 34.2692 34.0528 32.6132 34.0528C30.9572 34.0528 29.6132 32.7088 29.6132 31.0528C29.6132 29.3968 30.9572 28.3619 32.6132 28.3619Z"
            stroke={fillColor}
            strokeWidth="2.45477"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M49.3192 32.919L46.3632 32.397"
            stroke={fillColor}
            strokeWidth="2.45477"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21.3034 52.0111C21.3034 52.0111 25.5596 54.7612 34.5961 54.7613C43.6326 54.7614 49.395 46.6415 49.395 46.6415"
            stroke={fillColor}
            strokeWidth="2.45477"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M39.6942 40.5977C39.6942 43.9102 28.0421 49.6602 24.3817 40.5977"
            stroke={fillColor}
            strokeWidth="2.45477"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
