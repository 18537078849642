import queryString from './queryString';

const SESSION_STORAGE_KEY_NAME = 'account-identifier';

const setAccountIdInStore = (accountId: string) => {
  sessionStorage.setItem(SESSION_STORAGE_KEY_NAME, accountId);
};

const getAccountIdFromStore = () =>
  sessionStorage.getItem(SESSION_STORAGE_KEY_NAME);
export default class Account {
  public static getAccountId(): string {
    const accountIdFromQueryString = (
      queryString.get().accountId || ''
    ).toLocaleLowerCase();
    if (accountIdFromQueryString) {
      setAccountIdInStore(accountIdFromQueryString);
      return accountIdFromQueryString;
    }
    const accountIdFromStore = getAccountIdFromStore();
    if (accountIdFromStore) {
      return accountIdFromStore;
    }

    return 'none';
  }

  public static setAccountId(): void {
    const { accountId } = queryString.get();
    if (accountId) setAccountIdInStore(accountId.toLocaleLowerCase());
  }
}
