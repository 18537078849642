import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { IMG } from '../../CommonComponents/IMG';
import NoDataImg from '../../../img/att/no-data.png';
import { formatPhoneNumber } from '../../../utils/utils';
import * as C from '../../../constants';

function AttNoData() {
  return (
    <>
      <div className="no-data mt-5 row">
        <div className="col-xs-12 col-sm-12 col-md-6 img-container order-lg-6">
          <IMG
            src={NoDataImg}
            alt="Speedometer with Browsing and Ultra HD icons"
            aria-label="Speedometer with Browsing and Ultra HD icons"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 order-lg-1 text-holder">
          <p className="heading">No data found</p>
          <p className="text1">
            To receive insights on your home network, please make sure you have
            run a scan with the ProTech app. Contact a ProTech expert for
            assistance.
          </p>
          <BrowserView>
            <p className="btn">
              Call {formatPhoneNumber(C.callToActionNumber)}
            </p>
          </BrowserView>
          <MobileView>
            <a className="btn" href={`tel:${C.callToActionNumber}`}>
              Call {formatPhoneNumber(C.callToActionNumber)}
            </a>
          </MobileView>
          <p className="call-text">
            You can also contact a ProTech through the AT&T ProTech app
          </p>
        </div>
      </div>
    </>
  );
}

export default AttNoData;
