import * as React from 'react';

export const CloseArrow = ({ fillColor = '#000' }) => (
    <svg width={13} height={8} viewBox="0 0 13 8" fill="none" role="img" aria-label="close arrow">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.838.589l4.59 5 4.59-5 1.41 1.41-6 6-6-6 1.41-1.41z"
            fill={fillColor}
        />
    </svg>
);
