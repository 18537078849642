import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { BreakPoints } from '../Breakpoints';
import { Checkmark } from '../Icons';
import { BaseButton, IBaseButtonProps } from './BaseButton';

interface CheckboxButtonProps extends IBaseButtonProps {
    label?: string;
    onClick?: () => void;
    disabled?: boolean;
    selected?: boolean;
}

type BorderProps = Pick<CheckboxButtonProps, 'selected'>;

interface CheckboxProps {
    selected: boolean;
    checkedIcon: ReactNode;
}

const Checked = styled.div`
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 4px;
    background-color: ${(props) =>
        props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Unchecked = styled.div`
    height: 1.375rem;
    width: 1.375rem;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid
        ${(props) => props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor};
`;

export const Checkbox = ({ selected, checkedIcon }: CheckboxProps) =>
    selected ? <Checked>{checkedIcon}</Checked> : <Unchecked />;

const Button = styled(BaseButton)<BorderProps>`
    background: none;
    border-radius: 8px;
    color: ${(props) =>
        props.selected
            ? props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor
            : props.theme.button?.defaultTextColor};
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    border: 2px solid
        ${(props) =>
            props.selected
                ? props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor
                : '#E6E6EB'};
    height: 100%;
    width: 100%;
    margin-top: 15px;
    transition: transform 0.2s ease-in;
    user-select: none;
    padding: 1.062rem 1.1rem;

    button&:active {
        color: ${(props) => props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor};
        transform: scale(0.96);
    }

    @media ${BreakPoints.mobileBig} {
        width: 100%;
    }

    @media (hover: hover) {
        button&:hover {
            color: ${(props) =>
                props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor};
            border: 2px solid
                ${(props) => props.theme.components?.questionAnswer?.answerAccent || props.theme.button?.selectedColor};
        }
    }
`;

const ButtonLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Label = styled.div`
    margin-left: 1rem;
`;

export const CheckboxButton = ({
    onClick,
    label,
    analyticEventName,
    disabled,
    selected,
    analyticsExtras,
    ...props
}: CheckboxButtonProps) => (
    <Button
        onClick={onClick}
        analyticsExtras={analyticsExtras}
        analyticEventName={analyticEventName}
        disabled={disabled || false}
        selected={selected || false}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    >
        <ButtonLabelContainer data-test-cy={`checkbox_${label ?? 'unknown'}`}>
            <Checkbox selected={selected || false} checkedIcon={<Checkmark />} />
            <Label>{label}</Label>
        </ButtonLabelContainer>
    </Button>
);
