import styled from '@emotion/styled';
import React from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import image from './GuyWalkingOnPhone.png';

const ImageContainer = styled.img`
    width: 100%;
    height: auto;
`;

export const GuyWalkingOnPhone = () => <ImageContainer src={image} alt="Man walking using phone" />;
