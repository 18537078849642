import { Cable, CellSignal, Clock, MiniLaptop, Power, TripPins } from './icons';

export interface Recommendation {
    header: string;
    content: string;
    tipFeedback?: boolean;
    icon?: JSX.Element;
    id?: string;
}

export const iconRecommendationsList: Recommendation[] = [
    {
        icon: Power(),
        header: 'Restart your router',
        content: `Restarting your router is a good short-term fix for many issues.

Just like your computer, your router can occasionally “freeze”. Restarting it clears its memory and refreshes its connection with your internet service provider.

We recommend restarting your router every 30-60 days to keep things running smoothly. `,
        tipFeedback: true,
    },
    {
        icon: Cable(),
        header: 'Connect with a cable',
        content: `If you want to speed up your Wi-Fi, reduce the number of devices that connect wirelessly to your internet.

Use ethernet cables to connect nearby devices directly to your router.

Devices that use a lot of data — like laptops, smart TVs, and gaming consoles — are great candidates for wired connections. 

Wired connections are also significantly faster and more stable than wireless ones.



`,
        tipFeedback: true,
    },
    {
        icon: TripPins(),
        header: 'Reposition your router',
        content: `To maximize your router’s coverage:
        
• Move it as close as possible to the center of your home.

• Place it out in the open. Cabinet or closet doors can dramatically dampen the signal.

• Don’t place it near thick walls or metal appliances, like refrigerators or microwaves. These can obstruct or interfere with the signal.

• Lift it off the ground. Routers cast signal in all directions, including downwards. Some materials that make up floors — like concrete – will block that downward signal.`,
        tipFeedback: true,
    },
    {
        icon: CellSignal(),
        header: 'Try a Wi-Fi range extender',
        content: `Since you’re experiencing coverage issues, consider buying a Wi-Fi range extender.

Most extenders wirelessly connect to your router, then broadcast a new Wi-Fi signal into the hard-to-reach areas of your home.

Because the extender needs to “talk” to the router, the extender needs to be placed in an area where the Wi-Fi signal is still strong. Halfway in between your router and the dead zone is a generally a good bet.`,
        tipFeedback: true,
    },
    {
        icon: Clock(),
        header: 'Wait it out',
        content: `If your connection suffers at a specific time of day, the answer might be waiting it out.
There may be nothing wrong with your connection or your Wi-Fi and you are experiencing network congestion. Busy periods can be evenings, weekends and school holidays. 

Tips to try:

• Try to download content when there is less network traffic, such as overnight or during work hours.

• Test your speed again in a couple hours to see if the numbers go up, down or stay the same to rule out other issues.`,
        tipFeedback: true,
    },
    {
        icon: MiniLaptop(),
        header: 'Check your device',
        content: `If your problem seems to be happening with a specific device, it could be the device and not your network.

Try these steps:

• Turn off apps and devices when not in use.

• Close unused apps and turn off connected devices when not in use to ensure they aren’t using up bandwidth in the background.

• Reboot the device

• Reset network settings on the phone`,
        tipFeedback: true,
    },
];
