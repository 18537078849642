import { SpeedTestStatus } from '@soluto-private/wixi-web-sdk';

interface SpeedTestProgressData {
    speed: number;
    status: SpeedTestStatus | string;
}

export const formatSpeed = (speed: string): number => {
    let numberSpeed = Math.trunc(parseFloat(speed.split(' ')[0]));
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(numberSpeed)) {
        numberSpeed = 0;
    }

    return numberSpeed;
};

export const determineSpeed = (downloadSpeed: number, minimumSpeed?: number): number =>
    Math.max(minimumSpeed ?? downloadSpeed, downloadSpeed);

export const getProgressData = (status: string, uploadSpeed: string, downloadSpeed: string): SpeedTestProgressData => {
    if (status === 'inProgressDownload') {
        return {
            speed: formatSpeed(downloadSpeed),
            status: SpeedTestStatus.inProgressDownload,
        };
    }
    if (status === 'inProgressUpload') {
        return {
            speed: formatSpeed(uploadSpeed),
            status: SpeedTestStatus.inProgressUpload,
        };
    }
    return { speed: 0, status: 'initializing' };
};

export const ordinalToWord = (cardinal: number): string | undefined => {
    const ordinals = [
        'zeroth',
        'first',
        'second',
        'third',
        'fourth',
        'fifth',
        'sixth',
        'seventh',
        'eighth',
        'ninth',
        'tenth',
        'eleventh',
        'twelfth',
        'thirteenth',
        'fourteenth',
        'fifteenth',
        'sixteenth',
        'seventeenth',
        'eighteenth',
        'nineteenth',
    ];
    return ordinals[cardinal];
};
