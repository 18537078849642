/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import { DiamondStars, LinkButton } from '@home-mgmt-shared/common-ui';
import { MessageAlert } from '@home-mgmt-shared/message-alert';
import anime from 'animejs';
import React, { ComponentType, forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Answer, QuestionAnswerActivity } from '../models';

const Progress = styled.div`
    font-family: ${(props) => props.theme.font?.bold};
    font-size: 0.875rem;
    font-weight: bold;
    color: ${(props) => props.theme.button?.defaultTextColor};
    margin-bottom: 1rem;
`;

const MessageContainer = styled.div`
    margin: 0rem 0 2rem 0;
`;

const FlowContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
`;

const QuestionContainer = styled.div`
    width: 100%;
    max-width: 600px;
`;

interface ActivityWrapperProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentMap: Record<string, ComponentType<any>>;
    imageMap: Record<string, () => JSX.Element>;
    currentActivityIndex: number;
    totalActivities: number;
    currentActivitySelectedAnswers: Answer[];
    onAnswerSelection: (selectedAnswers: Answer[]) => void;
    executeResponse: () => void;
    previousQuestion: () => void;
    hidePreviousButton?: boolean;
    onFlowSkip?: () => void;
    activities: QuestionAnswerActivity[];
    linkButtonUnderlined?: boolean;
    flowAnalyticEventPrefix: string;
    numberProgress?: boolean;
}
const ActivityWrapper = ({
    componentMap,
    imageMap,
    currentActivityIndex,
    totalActivities,
    currentActivitySelectedAnswers,
    onAnswerSelection,
    executeResponse,
    previousQuestion,
    hidePreviousButton,
    onFlowSkip,
    activities,
    linkButtonUnderlined,
    flowAnalyticEventPrefix,
    numberProgress = true,
}: ActivityWrapperProps) => {
    const activity = activities[currentActivityIndex];
    if (!activity) {
        return <div>Oops! Something went wrong</div>;
    }
    const QuestionComponent = componentMap[activity.meta.content.questionComponentName];
    const AnswerComponent = componentMap[activity.meta.content.answerComponentName];
    const ResponseComponent = componentMap[activity.responses[0].meta.componentName];

    const questionProps = {
        question: activity.meta.content.question,
        questionSubtext: activity.meta.content.questionSubtext,
        imageMap,
    };
    const answerProps = { answerOptions: activity.meta.content.answersOptions, activityId: activity.id, imageMap };

    const responseAnalyticEvent = `${flowAnalyticEventPrefix}${activity.responses[0].id}_${activity.responses[0].meta.displayName}`;

    const selectedAnswersTextList = currentActivitySelectedAnswers?.map((r) => r.option.text) ?? [];
    const responseProps = {
        analyticEventName: responseAnalyticEvent,
        analyticsExtras: { selectedAnswers: selectedAnswersTextList, activityId: activity.id },
    };
    const responseText = activity.responses[0].meta.displayName;

    const isResponseEnabled = currentActivitySelectedAnswers?.length > 0;
    const isPreviousEnabled = currentActivityIndex > 0 && !hidePreviousButton;
    const isSkipEnabled = currentActivityIndex === 0 && onFlowSkip !== undefined;

    if (!QuestionComponent || !AnswerComponent || !ResponseComponent) {
        return <div>Oops! Something went wrong</div>;
    }

    const skipText = `Skip. I don't want to know my ideal speed.`;

    const alertHtml = activity.meta.content.alertMessage;
    return (
        <QuestionContainer>
            {alertHtml && (
                <MessageContainer>
                    <MessageAlert
                        // eslint-disable-next-line react/no-danger
                        messageText={<div dangerouslySetInnerHTML={{ __html: alertHtml }} />}
                        icon={imageMap?.MessageAlert ? imageMap.MessageAlert() : DiamondStars()}
                    />
                </MessageContainer>
            )}
            {numberProgress && (
                <Progress>
                    Question {currentActivityIndex + 1}/{totalActivities}
                </Progress>
            )}

            <div>
                <QuestionComponent {...questionProps} />
                <AnswerComponent
                    data-test-cy={`question-answer-answer-${AnswerComponent.displayName ?? 'unknown'}`}
                    onAnswerSelection={onAnswerSelection}
                    initialState={currentActivitySelectedAnswers}
                    {...answerProps}
                />
                <ResponseComponent
                    data-test-cy={`question-answer-response-${responseText}`}
                    disabled={!isResponseEnabled}
                    onClick={executeResponse}
                    {...responseProps}
                >
                    {responseText}
                </ResponseComponent>
                {isPreviousEnabled && (
                    <LinkButton
                        analyticEventName={`${flowAnalyticEventPrefix}previous`}
                        analyticsExtras={{ activityId: activity.id }}
                        onClick={previousQuestion}
                        underlined={linkButtonUnderlined}
                    >
                        Previous Question
                    </LinkButton>
                )}

                {isSkipEnabled && (
                    <LinkButton
                        analyticEventName={`${flowAnalyticEventPrefix}skip`}
                        analyticsExtras={{ activityId: activity.id }}
                        onClick={onFlowSkip}
                        underlined={linkButtonUnderlined}
                    >
                        {skipText}
                    </LinkButton>
                )}
            </div>
        </QuestionContainer>
    );
};

interface QuestionAnswerFlowProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentMap: Record<string, ComponentType<any>>;
    activities: QuestionAnswerActivity[];
    imageMap: Record<string, () => JSX.Element>;
    onFlowStateChange?: (flowState: Record<string, Answer[]>) => void;
    onFlowComplete?: (finalState: Record<string, Answer[]>) => void;
    onFlowSkip?: () => void;
    onStepChange?: (_: number) => void;
    linkButtonUnderlined?: boolean;
    flowAnalyticEventPrefix: string;
    onPreviousPageState?: () => void;
    numberProgress?: boolean;
    hidePreviousButton?: boolean;
    initialFlowState?: Record<string, Answer[]>;
    initialActivityIndex?: number;
    initialSelectedAnswerList?: Answer[];
    onCurrentAnswerListChange?: (_: Answer[]) => void;
}

export interface QuestionAnswerRef {
    previous: () => void;
}
export const QuestionAnswerFlow = forwardRef((props: QuestionAnswerFlowProps, ref: Ref<QuestionAnswerRef>) => {
    const {
        componentMap,
        activities,
        imageMap,
        onFlowStateChange,
        onFlowComplete,
        onFlowSkip,
        onStepChange,
        linkButtonUnderlined,
        flowAnalyticEventPrefix,
        onPreviousPageState,
        numberProgress = true,
        hidePreviousButton = false,
        initialFlowState,
        initialActivityIndex,
        initialSelectedAnswerList,
        onCurrentAnswerListChange,
    } = props;
    const [currentActivitySelectedAnswers, setCurrentActivitySelectedAnswers] = useState<Answer[]>(
        initialSelectedAnswerList ?? [],
    );
    const [currentActivityIndex, setCurrentActivityIndex] = useState(initialActivityIndex ?? 0);
    const [flowState, setFlowState] = useState<Record<string, Answer[]>>(initialFlowState ?? {});

    useEffect(() => {
        onFlowStateChange?.(flowState);
    }, [flowState, onFlowStateChange]);

    const executeResponse = useCallback(() => {
        const activityId = activities[currentActivityIndex].id;
        const updatedFlowState = { ...flowState, [activityId]: currentActivitySelectedAnswers };

        setFlowState(updatedFlowState);

        const nextActivity = activities[currentActivityIndex + 1];

        if (nextActivity) {
            const nextActivityAnswers = updatedFlowState[nextActivity.id];
            setCurrentActivitySelectedAnswers(nextActivityAnswers);
        } else {
            setCurrentActivitySelectedAnswers([]);
        }

        if (currentActivityIndex + 1 < activities.length) {
            setCurrentActivityIndex(currentActivityIndex + 1);
            window.scrollTo(0, 0);
        } else {
            onFlowComplete?.(updatedFlowState);
        }

        const tl = anime.timeline({
            loop: false,
        });

        tl.add({
            targets: '.page-transition',
            opacity: ['100%', '0%'],
            easing: 'easeInOutSine',
            duration: 500,
        });

        const tl2 = anime.timeline({
            loop: false,
        });

        tl2.add({
            targets: '.page-transition',
            opacity: ['0%', '100%'],
            easing: 'easeInOutSine',
            duration: 500,
        });
    }, [activities, currentActivityIndex, flowState, currentActivitySelectedAnswers, onFlowComplete]);

    const previousQuestion = useCallback(() => {
        if (currentActivityIndex - 1 >= 0) {
            const activityId = activities[currentActivityIndex - 1].id;
            const answers = flowState[activityId];
            setCurrentActivitySelectedAnswers(answers);
            setCurrentActivityIndex(currentActivityIndex - 1);
        } else {
            onPreviousPageState?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activities, currentActivityIndex, flowState]);

    useImperativeHandle(ref, () => ({
        previous: () => {
            previousQuestion();
        },
    }));

    useEffect(() => {
        onStepChange?.(currentActivityIndex);
    }, [currentActivityIndex, onStepChange]);

    useEffect(() => {
        onCurrentAnswerListChange?.(currentActivitySelectedAnswers);
    }, [currentActivitySelectedAnswers, onCurrentAnswerListChange]);

    const onAnswerSelection = useCallback((selectedAnswers: Answer[]) => {
        setCurrentActivitySelectedAnswers(selectedAnswers);
    }, []);

    return (
        <FlowContainer className="page-transition" data-test-cy="question-answer-flow-container">
            <ActivityWrapper
                key={currentActivityIndex}
                activities={activities}
                componentMap={componentMap}
                imageMap={imageMap}
                currentActivityIndex={currentActivityIndex}
                totalActivities={activities.length}
                currentActivitySelectedAnswers={currentActivitySelectedAnswers}
                onAnswerSelection={onAnswerSelection}
                executeResponse={executeResponse}
                previousQuestion={previousQuestion}
                hidePreviousButton={hidePreviousButton}
                onFlowSkip={onFlowSkip}
                linkButtonUnderlined={linkButtonUnderlined}
                flowAnalyticEventPrefix={flowAnalyticEventPrefix}
                numberProgress={numberProgress}
            />
        </FlowContainer>
    );
});
