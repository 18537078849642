/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import styled from '@emotion/styled';
import { usePageAnalytics } from '@home-mgmt-shared/analytics';
import React, { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { CSSTransition } from 'react-transition-group';
import { Theme } from '@emotion/react';
import { SpeedTestLegal } from './SpeedTestLegal';
import SpeedTestStartButton from './SpeedTestStartButton';

const SpeedTestFont = styled.h1`
    font-family: ${(props) => props.theme.font?.type};
`;

const SpeedTestStartHeader = styled(SpeedTestFont)`
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 2rem;

    @media all and (max-width: 450px) {
        margin: 2rem 1rem;
    }
`;

const SpeedTestStartSubHeader = styled(SpeedTestFont)`
    font-size: 20px;
    margin-bottom: 40px;
    text-align: center;

    @media all and (max-width: 450px) {
        font-size: 16px;
    }
`;

const SpeedTestProgress = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 290px;
    margin-bottom: 75px;
`;

const SpeedTestProgressCircle = styled.div`
    max-width: 450px;

    width: 350px;
    height: 190px;

    path {
        stroke-width: 4;
    }

    @media all and (max-width: 450px) {
        width: 300px;
    }
`;

const LegalContainer = styled.div`
    margin: 0 1rem;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styles = (pathColor: any, textColor: any) => ({
    rotation: 1 / 2 + 1 / 5,
    strokeLinecap: 'butt',
    trailColor: '#eee',
    pathColor,
    textColor,
});

interface SpeedTestIntroProps {
    startSpeedTest: () => void;
    introHeader?: string;
    introSubheader?: string;
}

export const SpeedTestIntro = ({ startSpeedTest, introHeader, introSubheader }: SpeedTestIntroProps) => {
    usePageAnalytics('Web Scan Page');
    const [render, setRender] = useState(false);
    useEffect(() => {
        setRender(true);
    }, []);
    return (
        <>
            {introHeader && (
                <CSSTransition
                    in={render}
                    timeout={{
                        appear: 1000,
                        enter: 1000,
                    }}
                    appear
                    unmountOnExit
                    classNames="fade"
                >
                    <SpeedTestStartHeader>{introHeader}</SpeedTestStartHeader>
                </CSSTransition>
            )}
            {introSubheader && (
                <CSSTransition
                    in={render}
                    timeout={{
                        appear: 1000,
                        enter: 1000,
                    }}
                    appear
                    unmountOnExit
                    classNames="fade"
                >
                    <SpeedTestStartSubHeader aria-label={introSubheader.replaceAll('...', '')}>
                        {introSubheader}
                    </SpeedTestStartSubHeader>
                </CSSTransition>
            )}
            <SpeedTestProgress>
                <SpeedTestProgressCircle>
                    <CircularProgressbar
                        value={0}
                        circleRatio={0.6}
                        styles={buildStyles(
                            styles(
                                (props: Theme) => props.special?.primaryColor,
                                (props: Theme) => props.special?.primaryColor,
                            ),
                        )}
                    />
                </SpeedTestProgressCircle>
            </SpeedTestProgress>
            <CSSTransition
                in={render}
                timeout={{
                    appear: 1000,
                    enter: 1000,
                }}
                appear
                unmountOnExit
                classNames="fade"
            >
                <SpeedTestStartButton startSpeedTest={startSpeedTest} />
            </CSSTransition>
            <LegalContainer>
                <SpeedTestLegal textAlign="left" />
            </LegalContainer>
        </>
    );
};
