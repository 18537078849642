import React from 'react';
import styled from '@emotion/styled';
import { AnalyticEvents } from '@home-mgmt-shared/analytics';
import { ISiteConnectivity } from './ISiteConnectivity';
import { StreamingItem } from './StreamingItem';
import { Accordion } from '../Accordion';
import { StreamingAccordionInfo } from './StreamingAccordionInfo';

const SpeedTestResults = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    width: 100%;
`;

const StreamingConnectivityContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
`;

const ItemDivider = styled.div`
    border: 0.5px solid #e6e6eb;
    height: 0px;
    width: calc(100% + 2.5rem);
    margin-left: -1.25rem;
`;

interface IStreamingConnectivityProps {
    sites: ISiteConnectivity[];
    analyticsCb?: (eventName: string, props: Record<string, unknown>) => void;
}

export const StreamingConnectivity = ({ sites, analyticsCb }: IStreamingConnectivityProps) => (
    <StreamingConnectivityContainer>
        <SpeedTestResults>
            {sites.map((site, index) => (
                <div key={index}>
                    <StreamingItem siteIcon={site.siteIcon} scoreIcon={site.scoreIcon} label={site.label} />
                    <ItemDivider />
                </div>
            ))}
        </SpeedTestResults>
        <Accordion
            header="What does this mean?"
            analyticEventName={AnalyticEvents.CONNECTION_EXPLANATION_ACCORDION}
            analyticsCb={analyticsCb}
        >
            <StreamingAccordionInfo />
        </Accordion>
    </StreamingConnectivityContainer>
);
