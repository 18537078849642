import * as React from 'react';

export const GoogleLogo = () => (
    <svg width={82} height={29} viewBox="0 0 82 29" fill="none" role="img" aria-label="google logo">
        <g clipPath="url(#googleClip0)">
            <path fill="#fff" d="M.304.997h81v27.397h-81z" />
            <path
                d="M34.773 15.046c0 3.803-2.975 6.606-6.626 6.606-3.65 0-6.625-2.803-6.625-6.606 0-3.83 2.974-6.605 6.625-6.605s6.626 2.776 6.626 6.605zm-2.9 0c0-2.376-1.724-4.002-3.726-4.002-2 0-3.725 1.626-3.725 4.002 0 2.353 1.724 4.003 3.725 4.003 2.002 0 3.726-1.653 3.726-4.002z"
                fill="#EA4335"
            />
            <path
                d="M49.067 15.046c0 3.803-2.975 6.606-6.626 6.606-3.65 0-6.626-2.803-6.626-6.606 0-3.826 2.975-6.605 6.626-6.605 3.651 0 6.626 2.776 6.626 6.605zm-2.9 0c0-2.376-1.724-4.002-3.726-4.002-2 0-3.725 1.626-3.725 4.002 0 2.353 1.724 4.003 3.725 4.003 2.002 0 3.726-1.653 3.726-4.002z"
                fill="#FBBC05"
            />
            <path
                d="M62.766 8.84V20.7c0 4.877-2.877 6.87-6.278 6.87-3.201 0-5.128-2.141-5.854-3.892l2.525-1.052c.45 1.075 1.551 2.344 3.326 2.344 2.177 0 3.526-1.343 3.526-3.871v-.95h-.101c-.65.8-1.9 1.5-3.478 1.5-3.303 0-6.329-2.876-6.329-6.578 0-3.728 3.026-6.629 6.329-6.629 1.575 0 2.826.7 3.478 1.477h.101V8.843h2.755V8.84zm-2.55 6.23c0-2.325-1.55-4.026-3.525-4.026-2.002 0-3.678 1.7-3.678 4.026 0 2.302 1.677 3.979 3.678 3.979 1.974 0 3.526-1.677 3.526-3.979z"
                fill="#4285F4"
            />
            <path d="M67.308 1.89v19.357h-2.83V1.89h2.83z" fill="#34A853" />
            <path
                d="M78.332 17.22l2.251 1.501c-.727 1.075-2.478 2.928-5.503 2.928-3.752 0-6.555-2.9-6.555-6.606 0-3.927 2.826-6.605 6.23-6.605 3.428 0 5.104 2.728 5.652 4.202l.301.75-8.83 3.658c.677 1.325 1.728 2 3.202 2 1.477 0 2.501-.726 3.252-1.828zm-6.93-2.376l5.902-2.45c-.324-.826-1.301-1.4-2.45-1.4-1.475 0-3.526 1.3-3.452 3.85z"
                fill="#EA4335"
            />
            <path
                d="M10.813 13.328v-2.802h9.443c.092.488.14 1.066.14 1.691 0 2.103-.575 4.703-2.427 6.555-1.802 1.876-4.104 2.877-7.153 2.877-5.652 0-10.405-4.604-10.405-10.257 0-5.652 4.753-10.256 10.405-10.256 3.127 0 5.354 1.227 7.028 2.827L15.867 5.94c-1.2-1.126-2.826-2.001-5.054-2.001-4.127 0-7.355 3.326-7.355 7.454 0 4.127 3.228 7.453 7.355 7.453 2.677 0 4.202-1.075 5.179-2.052.792-.792 1.313-1.923 1.518-3.469l-6.697.003z"
                fill="#4285F4"
            />
        </g>
        <defs>
            <clipPath id="googleClip0">
                <path fill="#fff" transform="translate(.304 .997)" d="M0 0h81v27.397H0z" />
            </clipPath>
        </defs>
    </svg>
);
