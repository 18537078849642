import * as React from 'react';

export const HuluLogo = () => (
    <svg width={70} height={24} viewBox="0 0 70 24" fill="none" role="img" aria-label="hulu logo">
        <g clipPath="url(#huluClip0)">
            <path fill="#fff" d="M.304.406h69.003v23.001H.304z" />
            <path
                d="M42.568 23.183h5.495V.438h-5.495v22.745zm-9.648-6.485c0 .799-.67 1.47-1.47 1.47h-3.194c-.798 0-1.47-.671-1.47-1.47V7.53h-5.494v9.616c0 3.929 2.524 6.005 6.23 6.005h5.366c3.419 0 5.495-2.46 5.495-6.005V7.53h-5.495c.032 0 .032 8.88.032 9.168zM63.812 7.53v9.168c0 .799-.67 1.47-1.47 1.47h-3.194c-.799 0-1.47-.671-1.47-1.47V7.53h-5.494v9.616c0 3.929 2.523 6.005 6.23 6.005h5.366c3.418 0 5.495-2.46 5.495-6.005V7.53h-5.463zm-52.647 0H7.651c-1.246 0-1.853.32-1.853.32V.437H.304v22.713h5.462v-9.136c0-.799.671-1.47 1.47-1.47h3.195c.798 0 1.469.671 1.469 1.47v9.168h5.495V13.28c0-4.153-2.78-5.75-6.23-5.75z"
                fill="#1CE783"
            />
        </g>
        <defs>
            <clipPath id="huluClip0">
                <path fill="#fff" transform="translate(.304 .406)" d="M0 0h69.003v23.001H0z" />
            </clipPath>
        </defs>
    </svg>
);
