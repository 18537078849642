import styled from '@emotion/styled';
import { MXLoadingSpinner } from '@home-mgmt-shared/common-ui';
import {
  initializeWixiSdk,
  initializeWixiSdkWithAuth,
} from '@home-mgmt-shared/wixi-helpers';
import initAnalytics from '@soluto-asurion/one-service-analytics-react';
import { ConnectedRouter } from 'connected-react-router';
import React, { useCallback, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { RoutePrefix } from '../models';
import { wixiPartnerConfig } from '../utils/initializeWixiSdk';
import { App } from './App';

interface RootProps {
  store: any;
  history: any;
  routePrefix?: RoutePrefix;
}

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Spinner = styled.div`
  height: calc(100vh - 144px);
  align-self: center;
  width: 5rem;
`;

export default function Root({ store, history, routePrefix }: RootProps) {
  const [sdkInitialized, setSdkInitialized] = useState(false);

  initAnalytics('ATT', 'prod', {
    appName: 'StreamingSupport',
    disableWriters: [],
  });

  const initializeWixi = useCallback(async () => {
    if (!IS_MX_APP) {
      initializeWixiSdk(wixiPartnerConfig);
      setSdkInitialized(true);
      return;
    }
    await initializeWixiSdkWithAuth(wixiPartnerConfig);
    setSdkInitialized(true);
  }, []);

  useEffect(() => {
    initializeWixi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        {sdkInitialized ? (
          <App prefix={routePrefix?.prefix || ''} />
        ) : (
          <SpinnerContainer>
            <Spinner>
              <MXLoadingSpinner />
            </Spinner>
          </SpinnerContainer>
        )}
      </ConnectedRouter>
    </Provider>
  );
}
