import { logger } from '@home-mgmt-shared/logger';

export const initializeLogger = (): void => {
  const appName = 'att-dashboard';
  const newContext = {
    url: `${process.env?.LOGGING_URL ?? ''}${appName}/`,
    appName,
    env: process.env?.NODE_ENV,
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logger.updateContext((_) => newContext);
};
