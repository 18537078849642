/* eslint-disable consistent-return */
import styled from '@emotion/styled';
import React from 'react';
import { SpeedPoint } from '../models';

const ChartLabelContainer = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.7rem;
`;

type ChartLabelProps = {
    isLast?: boolean;
    isBlank?: boolean;
};

const ChartLabel = styled.div<ChartLabelProps>`
    color: ${(props) =>
        props.isLast
            ? props.theme.components?.overviewSpeedChart?.textColor ?? '#000'
            : props.theme.components?.overviewSpeedChart?.lightTextColor ?? '#A5AAAF'};
    font-size: 0.875rem;
    font-weight: ${(props: ChartLabelProps) => (props.isLast ? '700' : '400')};
    opacity: ${(props) => (props.isBlank ? '0%' : '100%')};
`;

const getLabels = (data: SpeedPoint[]): (JSX.Element | undefined)[] => {
    const labels = data.map((d: SpeedPoint, index: number) => {
        if (index === 0 && data.length >= 5) {
            return;
        }
        if (index !== data.length - 1) {
            return <ChartLabel key={`${d.date}${index}`}>{d.date}</ChartLabel>;
        }
        return (
            <ChartLabel key={`${d.date}${index}`} isLast>
                {d.date}
            </ChartLabel>
        );
    });

    if (data.length < 5) {
        for (let i = 0; i < 4 - data.length; i++) {
            labels.push(
                // Use underscore characters to add proper spacing to labels and
                // use isBlank prop to hide characters with background color
                <ChartLabel isBlank key={`label-${i}`}>
                    _____
                </ChartLabel>,
            );
        }
    }

    return labels;
};

interface SpeedChartLabelsProps {
    data: SpeedPoint[];
}

export const SpeedChartLabels = ({ data }: SpeedChartLabelsProps): JSX.Element => (
    <ChartLabelContainer>{getLabels(data)}</ChartLabelContainer>
);
