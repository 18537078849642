import * as React from 'react';

export const ScanningPhone = () => (
    <svg
        width={63}
        height={80}
        viewBox="0 0 63 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-label="Scanning phone"
        role="img"
    >
        <path
            d="M43.86 21.346c-2.797-.79-20.698.035-21.209 0-1.537-.088-3.433.284-4.433.76-4.053 1.926-3.547 7.7-3.955 12.374-.799 9.138-.148 18.729-.148 27.694 0 3.888-1.09 9.784 2.809 12.824 3.32 2.577 9.168 2.553 12.743 2.53 8.97-.035 13.787-.244 16.305-3.586 1.904-2.518 1.962-6.731 2.229-9.656.696-7.625.777-27.876-.023-34.642-.557-4.584-1.347-7.462-4.318-8.298zM1.586 27.384c.137-.89 1.299.807 1.982 1.169 1.55.807 3.1 1.53 4.718 2.143M11.51 10.06c.045-.056.63 1.258.687 1.404.844 1.788 1.7 3.54 2.521 5.345M33.576 1.89c-.178-.592-.29 1.187-.347 1.753-.113 1.24-.055 1.077-.227 2.29-.344 2.75-.688 5.471-.914 8.22M49.4 16.81c.03 0 1.704-1.834 1.856-1.98 2.253-2.24 4.476-4.482 6.576-6.869M56.182 28.49c1.593-.775 3.119-1.733 4.579-2.723"
            stroke="#000"
            strokeWidth={3.143}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M26.326 21.9c-.116.162.255.893.313 1.056.372 1.16.465 2.24 1.683 2.739 1.73.708 4.004.464 5.803.197.487-.07 1.253.012 1.648-.29.743-.58 1.08-1.625 1.45-2.472.175-.406.57-.998.57-1.45l-11.467.22z"
            fill="#000"
            stroke="#000"
            strokeWidth={3.143}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M25.169 72.125H36.56"
            stroke="#000"
            strokeWidth={3.143}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
