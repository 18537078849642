import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

type SpeedBannerIsIdeal = {
    isIdeal?: boolean;
};

const SpeedBannerContainer = styled.div<SpeedBannerIsIdeal>`
    width: calc(100% - 2rem - 1.5rem);
    max-width: 34rem;
    align-self: center;
    padding: 0.625rem 0.75rem;
    background: ${(props) =>
        props.isIdeal
            ? props.theme.components?.overviewSpeedChart?.increasedColor
            : props.theme.components?.overviewSpeedChart?.decreasedColor};
    margin-bottom: 1rem;
    border-radius: 8px;
    line-height: 150%;
`;

interface OverviewSpeedBannerProps {
    isSpeedIdeal?: boolean;
    idealContent: ReactNode;
    nonIdealContent: ReactNode;
}

export const OverviewSpeedBanner = ({ isSpeedIdeal, idealContent, nonIdealContent }: OverviewSpeedBannerProps) => (
    <SpeedBannerContainer isIdeal={isSpeedIdeal}>{isSpeedIdeal ? idealContent : nonIdealContent}</SpeedBannerContainer>
);
