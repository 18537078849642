import * as React from 'react';

export const HboMax = () => (
    <svg width={104} height={18} viewBox="0 0 116 20" fill="none" role="img" aria-label="hbo max logo">
        <g clipPath="url(#hbo_prefix_clip0)">
            <path
                d="M14.218 19.916h-5.32V12.33H5.456v7.587H0V.346h5.446V7.65h3.442V.346h5.32v19.57h.01zM38.604 20C44.05 20 48.5 15.467 48.5 9.948 48.5 4.344 44.05.01 38.605.01c-5.519 0-8.31 4.008-8.982 5.645 0-2.424-2.424-5.32-5.236-5.32H15.3v19.58h8.457c3.442 0 5.866-2.938 5.866-5.52.766 1.596 3.474 5.604 8.982 5.604zm-15.519-7.954c.787 0 1.417.766 1.417 1.637 0 .913-.63 1.679-1.417 1.679h-2.707v-3.316h2.707zm0-7.345c.787 0 1.417.766 1.417 1.637 0 .87-.63 1.637-1.417 1.637h-2.707V4.7h2.707zm3.578 5.247c.63-.042 1.658-.766 2.046-1.197-.147.525-.147 2.183 0 2.708-.43-.64-1.416-1.396-2.046-1.511zm6.978 0a4.974 4.974 0 119.948.073c-.042 2.728-2.246 4.921-4.974 4.921a4.975 4.975 0 01-4.974-4.994zm4.963 3.924c2.12 0 3.904-1.742 3.904-3.924 0-2.183-1.784-3.904-3.904-3.904-2.182 0-3.924 1.721-3.924 3.904a3.905 3.905 0 003.924 3.924z"
                fill="#000"
            />
            <path
                d="M76.936 7.272C76.831 2.529 73.473.02 69.36.02c-2.256 0-4.292.755-5.677 2.225C62.298.776 60.263.02 58.006.02c-4.113 0-7.47 2.518-7.576 7.261v10.85c0 .997.808 1.794 1.794 1.794h1.7a.305.305 0 00.305-.304V7.303c.073-2.34 1.731-3.578 3.777-3.578 2.047 0 3.704 1.238 3.778 3.578v10.819c0 .997.808 1.794 1.794 1.794h1.7a.305.305 0 00.304-.304V7.292c.074-2.34 1.732-3.577 3.778-3.577 2.046 0 3.704 1.238 3.778 3.578V18.11c0 .997.807 1.795 1.794 1.795h1.7a.305.305 0 00.304-.305V7.282c.01.01 0 0 0-.01zM115.205.325h-2.886c-.798 0-1.543.399-1.994 1.06l-3.19 4.722a1.05 1.05 0 01-1.742 0l-3.189-4.722a2.405 2.405 0 00-1.994-1.06h-2.886c-.178 0-.283.2-.189.347l5.688 8.436a1.807 1.807 0 010 2.015l-5.688 8.436c-.105.147.01.347.19.347h2.885a2.42 2.42 0 001.994-1.06l3.189-4.722a1.05 1.05 0 011.742 0l3.19 4.722a2.403 2.403 0 001.994 1.06h2.886c.178 0 .283-.2.188-.347l-5.697-8.426a1.804 1.804 0 010-2.014l5.687-8.437c.105-.147 0-.357-.178-.357zm-19.37 0h-1.7a1.81 1.81 0 00-1.764 1.417C90.861.588 88.962 0 86.915 0c-4.974 0-9.014 3.463-9.014 10s4.03 10 9.014 10c2.036 0 3.914-.577 5.425-1.71.084.913.85 1.637 1.794 1.637h1.7a.305.305 0 00.304-.305V.63c-.01-.168-.136-.305-.304-.305zm-8.92 15.96c-2.97 0-5.383-2.172-5.383-6.274 0-4.103 2.413-6.275 5.383-6.275s5.383 2.172 5.383 6.274c0 4.103-2.413 6.275-5.383 6.275z"
                fill="url(#hbo_prefix__paint0_linear)"
            />
        </g>
        <defs>
            <linearGradient
                id="hbo_prefix__paint0_linear"
                x1={50.434}
                y1={10.006}
                x2={115.425}
                y2={10.006}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#991EEB" />
                <stop offset={0.399} stopColor="#5822B4" />
                <stop offset={0.727} stopColor="#5822B4" />
                <stop offset={1} stopColor="#991EEB" />
            </linearGradient>
            <clipPath id="hbo_prefix_clip0">
                <path fill="#fff" d="M0 0h115.429v20H0z" />
            </clipPath>
        </defs>
    </svg>
);
