import * as C from '../constants';

const initialState = {
  items: {},
  loading: false,
  error: null,
  id: 0,
};

export default function QuickTipsReducer(state = initialState, action) {
  switch (action.type) {
    case C.FETCH_QUICK_TIPS_BEGIN:
      return {
        ...initialState,
        loading: true,
      };

    case C.FETCH_QUICK_TIPS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        items: action.payload.data,
      };

    case C.FETCH_QUICK_TIPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error.response
          ? action.payload.error.response.status
          : true,
        items: [],
      };

    default:
      return state;
  }
}
