import styled from '@emotion/styled';
import { MultiPointEvents, usePageAnalytics } from '@home-mgmt-shared/analytics';
import { FilledButton, GuyWalkingOnPhone, LinkButton } from '@home-mgmt-shared/common-ui';
import React from 'react';

const MultiRoomDesktopIntroContent = styled.div`
    max-width: 37.5rem;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

const ImageContainer = styled.div`
    width: 11.4rem;
    height: auto;
    align-self: center;
    transform: scaleX(-1);
    margin-bottom: 2rem;
`;

const HeaderContainer = styled.h1`
    font-size: 2rem;
    line-height: 120%;
    font-weight: 400;
    margin-bottom: 0.5rem;
`;

const SubheaderContainer = styled.div`
    font-size: 1.25rem;
    line-height: 150%;
    font-weight: 400;
    margin-bottom: 1rem;
`;

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const StyledButton = styled(FilledButton)`
    padding: 0.75rem 2.5625rem;
    margin-right: 1rem;
    white-space: nowrap;
    width: calc(50% - 1.5rem);
`;

const StyledLink = styled(LinkButton)`
    font-size: 1rem;
    line-height: 150%;
    text-align: left;
    text-decoration: underline;
    margin: 0;
    padding: 0;
`;

interface MultiRoomDesktopIntroProps {
    showRemindLink: boolean;
    setShowRemindLink: (_: boolean) => void;
    setShowShortLink: (_: boolean) => void;
    onContinue: () => void;
}

export const MultiRoomDesktopIntro = ({
    showRemindLink,
    setShowRemindLink,
    setShowShortLink,
    onContinue,
}: MultiRoomDesktopIntroProps) => {
    usePageAnalytics(MultiPointEvents.CanYouMovePage);

    return (
        <MultiRoomDesktopIntroContent>
            <ImageContainer>
                <GuyWalkingOnPhone />
            </ImageContainer>
            <HeaderContainer>Can you move around your home while holding this device?</HeaderContainer>
            <SubheaderContainer>
                We’ll need you to test for weak Wi-Fi signal in two different areas of your home.
            </SubheaderContainer>
            <ButtonRow>
                <StyledButton
                    data-test-cy="help-switch-devices-button"
                    analyticEventName="Multi_Point_No_Switch_Devices"
                    onClick={() => setShowShortLink(true)}
                >
                    No, help me switch devices
                </StyledButton>
                <StyledButton
                    data-test-cy="can-move-around-button"
                    analyticEventName="Multi_Point_Yes_I_Can_Move"
                    onClick={onContinue}
                >
                    Yes, I can move around
                </StyledButton>
            </ButtonRow>
            {showRemindLink && <StyledLink onClick={() => setShowRemindLink(false)}>Remind me later</StyledLink>}
        </MultiRoomDesktopIntroContent>
    );
};
