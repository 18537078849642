import styled from '@emotion/styled';
import { AnalyticEvents } from '@home-mgmt-shared/analytics';
import React, { useCallback } from 'react';
import ExpertIcon from '../CallExpert/ExpertIcon.png';
import { ICTAData } from './ICTAData';

const CTABlockContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 365px;
`;

const Header = styled.h1`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 20px;
    text-align: center;
    margin: 0px;
    text-transform: none;
    font-weight: bold;
`;

const Description = styled.p`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 16px;
    text-align: center;
    margin: 5px 0px;
`;

const Button = styled.button`
    background: ${(props) => props.theme.button?.primaryColor};
    border-radius: 25px;
    color: white;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    margin-top: 15px;
    border: none;
    height: 46px;
    width: 100%;
    button&:hover,
    button&:focus:active {
        outline: none;
    }
`;

const A = styled.a`
    width: 100%;
    outline: none;
`;

const ImgContainer = styled.div`
    display: flex;
    margin: 0px;
`;

const ExpertImage = styled.img`
    height: 150px;
    width: auto;
`;

type ICTABlockProps = ICTAData & {
    analyticsCb?: (eventName: string, props: Record<string, unknown>) => void;
};

export const CTABlock = ({
    title,
    description,
    buttonText,
    link,
    withExpertImage = false,
    analytics,
    analyticsCb,
}: ICTABlockProps) => {
    const onClick = useCallback(() => {
        if (analytics) {
            analyticsCb?.(AnalyticEvents.CTA_BUTTON, analytics.props);
        }
    }, [analytics, analyticsCb]);
    return (
        <CTABlockContainer>
            {withExpertImage && (
                <ImgContainer>
                    <ExpertImage src={ExpertIcon} alt="expert icon" />
                </ImgContainer>
            )}
            <Header>{title}</Header>
            <Description>{description}</Description>
            <A data-test-cy="cta-block-link" href={link}>
                <Button onClick={onClick}>{buttonText}</Button>
            </A>
        </CTABlockContainer>
    );
};
