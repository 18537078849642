import styled from '@emotion/styled';
import { BreakPoints, IconButton } from '@home-mgmt-shared/common-ui';
import React, { useCallback, useEffect, useState } from 'react';
import { Answer, AnswerOption, BaseAnswerProps } from '../models';

interface AnswerGridButtonProps {
    option: AnswerOption;
    onAnswerSelect: (option: AnswerOption) => void;
    imageMap: Record<string, () => JSX.Element>;
    selected: boolean;
    activityId: string;
}

const AnswerGridButton = ({ option, imageMap, onAnswerSelect, selected, activityId }: AnswerGridButtonProps) => {
    const onSelection = useCallback(() => {
        onAnswerSelect(option);
    }, [onAnswerSelect, option]);

    if (!option.text || !option.icon) {
        return null;
    }

    return (
        <IconButton
            data-test-cy={`question-answer-response-${option.text ?? 'undef'}`}
            selected={selected}
            analyticEventName={`option_${option.text}`}
            analyticsExtras={{ activityId }}
            key={option.text}
            label={option.text}
            Icon={imageMap[option.icon] ?? null}
            onClick={onSelection}
        />
    );
};

interface AnswerGridProps extends BaseAnswerProps {
    imageMap: Record<string, () => JSX.Element>;
}

const GridContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: center;
    margin: auto;
`;

const AnswerGridItem = styled.div`
    margin: 0.5rem;
    width: 45%;
    @media ${BreakPoints.mobileBig} {
        margin: 0.5rem;
        width: 45%;
    }
    @media all and (max-width: 352px) {
        width: 100%;
    }
`;

const Directions = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    color: ${(props) => props.theme.font?.accentColor};
    margin: 1rem 0 1rem 0;
`;

export const AnswerGrid = ({
    answerOptions,
    imageMap,
    onAnswerSelection,
    initialState,
    activityId,
}: AnswerGridProps) => {
    const [selectedOptions, setSelectedOptions] = useState<AnswerOption[]>([]);

    useEffect(() => {
        if (initialState?.length) {
            const options = initialState.map((a) => a.option);
            setSelectedOptions(options);
        }
    }, [initialState]);

    const onSelection = useCallback(
        (option: AnswerOption) => {
            let updatedSelections: AnswerOption[] = [];
            if (selectedOptions.includes(option)) {
                updatedSelections = selectedOptions.filter((o) => o.text !== option.text);
            } else {
                updatedSelections = [...selectedOptions, option];
            }

            setSelectedOptions(updatedSelections);

            const answers: Answer[] = updatedSelections.map((selection) => ({ option: selection }));
            onAnswerSelection(answers);
        },
        [onAnswerSelection, selectedOptions],
    );

    return (
        <>
            <Directions>Select all that apply.</Directions>
            <GridContainer>
                {answerOptions.map((option) => {
                    if (!option.text || !option.icon) {
                        return null;
                    }
                    const isSelected = selectedOptions.includes(option);
                    return (
                        <AnswerGridItem key={option.text}>
                            <AnswerGridButton
                                data-test-cy={`question-answer-response-${option.text ?? 'undef'}`}
                                selected={isSelected}
                                option={option}
                                imageMap={imageMap}
                                onAnswerSelect={onSelection}
                                activityId={activityId}
                            />
                        </AnswerGridItem>
                    );
                })}
            </GridContainer>
        </>
    );
};
