import styled from '@emotion/styled';
import { WebscanEvents } from '@home-mgmt-shared/analytics';
import { IAuthClient } from '@home-mgmt-shared/auth';
import {
    DownArrow,
    IconLink,
    IStreamingQualityGuideline,
    LinkButton,
    Redo,
    SpeedDisplay,
    SpeedTestResults,
    UpArrow,
    Wifi,
} from '@home-mgmt-shared/common-ui';
import { SaveResults, useSaveResults } from '@home-mgmt-shared/save-results';
import React from 'react';

let AuthClient: IAuthClient;
if (IS_MX_APP) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,global-require
    AuthClient = require('@home-mgmt-shared/auth').AuthClient;
}
const IncompleteViewContainer = styled.div`
    max-width: 365px;
    margin: 0 auto;
`;

const SpeedTestResultsItem = styled.div`
    margin-bottom: 1rem;
`;

const ResultsHeader = styled.div`
    color: ${(props) => props.theme?.button?.primaryColor};
    font-family: ${(props) => props.theme?.font?.type};
    display: flex;
    font-size: 2rem;
    text-align: left;
    margin-bottom: 1.25rem;
    padding-bottom: 0.5rem;
    max-width: 365px;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
`;

const SpeedContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-self: left;
`;

const SpeedItem = styled.div`
    margin: 1rem;
`;

const CheckSpeedContainer = styled.div`
    margin-bottom: 2rem;
`;

const IconLinkWrapper = styled.div`
    margin-bottom: 1.5rem;
    z-index: 999;
`;

const CheckSpeedAgainContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
`;

const RedoIconContainer = styled.div`
    max-height: 1rem;
    max-width: 1.5rem;
    margin-right: 0.5rem;
`;

const RedoTextContainer = styled.div`
    font-size: 1rem;
`;

interface BandwidthCalculatorResultsIncompleteViewProps {
    downloadSpeed: number;
    uploadSpeed: number;
    streamingGuidelines: IStreamingQualityGuideline[];
    onCheckSpeedsAgain: () => void;
    scanId: string;
}

export const BandwidthCalculatorResultsIncompleteView = ({
    downloadSpeed,
    uploadSpeed,
    streamingGuidelines,
    onCheckSpeedsAgain,
    scanId,
}: BandwidthCalculatorResultsIncompleteViewProps) => {
    const isLoggedIn = AuthClient ? AuthClient.isLoggedIn : false;

    useSaveResults({
        flowData: {
            scanId,
            downloadSpeed,
            uploadSpeed,
            timestamp: new Date(Date.now()).toUTCString(),
        },
    });

    return (
        <IncompleteViewContainer>
            {isLoggedIn && (
                <IconLinkWrapper>
                    <IconLink Icon={<Wifi />} msg="Go to Wi-Fi Overview" route="/overview" />
                </IconLinkWrapper>
            )}
            <ResultsHeader>Your results</ResultsHeader>
            <SpeedContainer>
                <SpeedItem>
                    <SpeedDisplay
                        label="Your download speed"
                        icon={<DownArrow />}
                        speed={downloadSpeed.toFixed(0)}
                        units="Mbps"
                    />
                </SpeedItem>
                <SpeedItem>
                    <SpeedDisplay
                        label="Your upload speed"
                        icon={<UpArrow />}
                        speed={uploadSpeed.toFixed(0)}
                        units="Mbps"
                    />
                </SpeedItem>
            </SpeedContainer>
            <SpeedTestResultsItem>
                <SpeedTestResults
                    downloadSpeed={downloadSpeed}
                    uploadSpeed={uploadSpeed}
                    streamingGuidelines={streamingGuidelines}
                />
            </SpeedTestResultsItem>

            <CheckSpeedContainer>
                <LinkButton onClick={onCheckSpeedsAgain} underlined analyticEventName={WebscanEvents.ScanAgain}>
                    <CheckSpeedAgainContainer>
                        <RedoIconContainer>
                            <Redo />
                        </RedoIconContainer>
                        <RedoTextContainer>Check speed again</RedoTextContainer>
                    </CheckSpeedAgainContainer>
                </LinkButton>
            </CheckSpeedContainer>

            {IS_MX_APP && <SaveResults />}
        </IncompleteViewContainer>
    );
};
