import React from 'react';
import styled from '@emotion/styled';

type MessageAlertProps = {
    messageText?: JSX.Element;
    icon?: JSX.Element;
};

const MessageAlertContainer = styled.div`
    display: flex;
    justify-content: space-around;
    border-radius: 40px;
    font-family: ${(props) => props.theme.messageAlert?.font.type};
    background-color: ${(props) => props.theme.messageAlert?.backgroundColor};
    padding: 0.65rem;
`;

const MessageTextContainer = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 0.875rem;
    text-align: center;
    text-align: left;
    line-height: 130%;
    padding: 0.5rem;
`;
const IconContainer = styled.div`
    display: flex;
    max-width: 2.5rem;
    min-width: 2.5rem;
    margin-bottom: 0.3125rem;
    margin-right: 0.25rem;
`;

export const MessageAlert = ({ messageText, icon }: MessageAlertProps) => (
    <MessageAlertContainer>
        <MessageTextContainer>{messageText} </MessageTextContainer>
        <IconContainer> {icon} </IconContainer>
    </MessageAlertContainer>
);
