/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from '@emotion/styled';
import { FlagIcon } from '@home-mgmt-shared/common-ui';
import { useTheme } from '@emotion/react';
import { useSpring, animated } from 'react-spring';
import { SpeedResultsCopy } from './utils';

type StrokeColor = {
    color: string;
};

type AnimationFlags = {
    is_anim_done?: string;
    anim_dial?: string;
};

const hideElementColor = 'none';

const Label = styled(animated.div)<AnimationFlags>`
    font-family: ${(props) => props.theme.font?.type};
    display: flex;
    font-size: 1rem;
    color: ${(props) => (props.is_anim_done ? props.theme.button?.defaultTextColor : hideElementColor)};
    margin-top: -15rem;
    margin-bottom: 0.25rem;
`;

const SpeedData = styled(animated.div)<AnimationFlags>`
    font-family: ${(props) => props.theme.font?.type};
    display: flex;
    font-size: 3rem;
    color: ${(props) => (props.is_anim_done ? props.theme.button?.defaultTextColor : hideElementColor)};
    max-height: 3.25rem;
`;

const Units = styled(animated.div)<AnimationFlags>`
    font-family: ${(props) => props.theme.font?.type};
    display: flex;
    font-size: 1rem;
    color: ${(props) => (props.is_anim_done ? '#000000' : hideElementColor)};
`;

const IdealSpeed = styled(animated.div)<AnimationFlags & StrokeColor>`
    font-family: ${(props) => props.theme.font?.type};
    font-weight: bold;
    display: flex;
    text-align: center;
    color: ${(props) => (!props.anim_dial ? props.theme.button?.defaultTextColor : hideElementColor)};
    background: ${(props) => (!props.anim_dial ? props.color : hideElementColor)} !important;
    margin-top: 1rem;
    font-size: 1rem;
    padding: 0.45rem;
    border-radius: 4px;
`;

const SpeedIndicatorCircle = styled.div`
    .CircularProgressbar-path {
        stroke: ${(props: StrokeColor) => props.color} !important;
    }

    .CircularProgressbar {
        max-width: 300px;
    }

    .CircularProgressbar .CircularProgressbar-text {
        fill: ${(props) => props.theme.special?.primaryColor};
        font-weight: normal;
        font-size: 1rem;
    }
`;

const FlagIconContainer = styled(animated.div)`
    width: 27px;
    height: 27px;
`;

const CircleLabels = styled(animated.div)<AnimationFlags>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: visible;
    height: 27px;
    width: 303px;
    color: ${(props) => (!props.anim_dial ? '#999999' : hideElementColor)};
    margin-top: -1.2rem;
    padding-bottom: 15px;

    @media all and (max-width: 335px) {
        width: 285px;
    }
`;

const ANIMATION_DURATION = 1;
const ANIMATION_DELAY = 100;
const BOUNCE_DURATION = 500;

interface SpeedIndicatorProps {
    speed: number;
    idealSpeed: number;
    isSpeedSufficient: boolean;
    dialColor?: string;
    speedResultsCopy: SpeedResultsCopy;
}

export const SpeedIndicator = ({
    speed,
    idealSpeed,
    isSpeedSufficient,
    dialColor,
    speedResultsCopy,
}: SpeedIndicatorProps) => {
    const [firstAnimProps, setFirstAnimProps] = useSpring(() => ({ opacity: 0 }));
    const [secondAnimProps, setSecondAnimProps] = useSpring(() => ({ opacity: 0 }));
    const [flagAnimProps, setFlagAnimProps] = useSpring(() => ({ transform: 'scale(1)' }));

    const theme = useTheme();
    const [value, setValue] = useState(0);
    const [animValue, setAnimValue] = useState(0);
    const [animDial, setAnimDial] = useState(true);
    const [isAnimDone, setAnimDone] = useState(false);

    const animStyles = {
        rotation: 1 / 2 + 1 / 5,
        strokeLinecap: 'butt',
        trailColor: '#fff',
        pathTransitionDuration: ANIMATION_DURATION,
    };

    const styles = {
        rotation: 1 / 2 + 1 / 5,
        strokeLinecap: 'butt',
        trailColor: '#eee',
        pathTransitionDuration: ANIMATION_DURATION,
    };

    const flagAnimation = () => {
        setTimeout(() => {
            setFlagAnimProps({ transform: 'scale(1)' });
        }, BOUNCE_DURATION);
    };

    const finishAnimation = () => {
        setTimeout(() => {
            setSecondAnimProps({ opacity: 1 });
            setAnimDone(true);
            if (isSpeedSufficient) {
                setFlagAnimProps({ transform: 'scale(1.3)' });
                flagAnimation();
            }
        }, ANIMATION_DURATION * 1000);
    };

    const changeValue = () => {
        if (speed !== 0 && idealSpeed !== 0) {
            if (isSpeedSufficient) setValue(100);
            else setValue((speed / idealSpeed) * 100);
        }
        finishAnimation();
    };

    const animateValue = () => {
        setTimeout(() => {
            setFirstAnimProps({ opacity: 1 });
            setAnimDial(false);
            setTimeout(() => {
                changeValue();
            }, ANIMATION_DELAY);
        }, ANIMATION_DURATION * 1000);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setTimeout(() => {
            setAnimValue(100);
            animateValue();
        }, ANIMATION_DELAY);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <SpeedIndicatorCircle color={animDial ? '#eee' : dialColor ?? '#000'}>
                {animDial && (
                    <CircularProgressbar
                        value={animValue}
                        text=""
                        circleRatio={0.6}
                        strokeWidth={3}
                        styles={buildStyles(animStyles)}
                    />
                )}
                {!animDial && (
                    <CircularProgressbar
                        value={value}
                        text=""
                        circleRatio={0.6}
                        strokeWidth={3}
                        styles={buildStyles(styles)}
                    />
                )}
            </SpeedIndicatorCircle>
            <Label
                data-test-cy="speed-indicator-speed"
                style={secondAnimProps}
                anim_dial={animDial ? 'true' : undefined}
                is_anim_done={isAnimDone ? 'true' : undefined}
            >
                {speedResultsCopy.innerCircleLabel}
            </Label>
            <SpeedData
                style={secondAnimProps}
                anim_dial={animDial ? 'true' : undefined}
                is_anim_done={isAnimDone ? 'true' : undefined}
            >
                {speed?.toFixed(0)}
            </SpeedData>
            <Units
                style={secondAnimProps}
                anim_dial={animDial ? 'true' : undefined}
                is_anim_done={isAnimDone ? 'true' : undefined}
            >
                Mbps
            </Units>
            <IdealSpeed
                data-test-cy="speed-indicator-ideal-speed"
                style={firstAnimProps}
                color={theme.messageAlert?.backgroundColor ?? '#F0F0F5'}
                anim_dial={animDial ? 'true' : undefined}
                is_anim_done={isAnimDone ? 'true' : undefined}
            >
                Ideal speed: {idealSpeed} Mbps
            </IdealSpeed>
            <CircleLabels
                style={firstAnimProps}
                className="speed-results-circle-labels"
                anim_dial={animDial ? 'true' : undefined}
                is_anim_done={isAnimDone ? 'true' : undefined}
            >
                <div> </div>
                <FlagIconContainer style={flagAnimProps}>
                    <FlagIcon
                        fillColor={
                            animDial ? hideElementColor : isSpeedSufficient && isAnimDone ? dialColor : '#F0F0F5'
                        }
                        flagColor={animDial ? hideElementColor : isSpeedSufficient && isAnimDone ? '#fff' : '#000'}
                        width="100%"
                        height="100%"
                    />
                </FlagIconContainer>
            </CircleLabels>
        </>
    );
};
