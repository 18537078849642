import * as React from 'react';

export const Streaming4k = () => (
  <svg
    width="49"
    height="45"
    viewBox="0 0 49 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Streaming 4k"
    role="img"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.391 28.0074V16.4611H26.6285V22.5092L32.1285 16.4611H33.7785L29.516 21.2721L33.916 28.0074H32.4035L28.5535 22.0968L26.6285 24.1587V28.0074H25.391ZM20.4417 16.5996L14.6667 24.1596V25.2593H20.3042V28.0084H21.5417V25.2593H23.1917V24.1596H21.5417V16.5996H20.4417ZM20.3039 24.1593H15.9039L20.3039 18.5236V24.1593Z"
      fill="#009FDB"
    />
    <path
      d="M46.8344 4.29633H2.82214C1.59471 4.29633 0.542969 5.34828 0.542969 6.57571V37.6122C0.542969 38.8397 1.59471 39.8916 2.82214 39.8916H24.2148V43.9247H14.7458C14.3951 43.9247 14.0446 44.2754 14.0446 44.6261C14.0446 44.9768 14.3951 45.3275 14.7458 45.3275H34.9107C35.2614 45.5029 35.6121 45.3274 35.7874 44.9767C35.9628 44.626 35.7875 44.2753 35.4368 44.1C35.2615 43.9246 34.911 43.9246 34.7356 44.1H25.2666V40.0671H46.6589C47.8863 40.0671 48.9384 39.015 48.9384 37.7875V6.75122C49.1138 5.5238 48.0618 4.47167 46.8344 4.29633C47.0097 4.29633 46.8344 4.29633 46.8344 4.29633ZM47.8866 37.6122C47.8866 38.1383 47.3604 38.6643 46.8344 38.6643H2.82214C2.29609 38.6643 1.77031 38.1383 1.77031 37.6122V6.57571C1.77031 6.04967 2.29609 5.52367 2.82214 5.52367H46.8344C47.3604 5.52367 47.8866 6.04967 47.8866 6.57571V37.6122ZM45.607 9.55673V34.2806C45.607 35.1573 44.9054 35.8588 44.0286 35.8588H9.13479C8.7841 35.6834 8.60884 35.1574 8.78419 34.982C8.78419 34.8067 8.95945 34.8066 9.13479 34.6312H44.2042V9.90734H5.45234V26.2147C5.27699 26.5654 4.75095 26.7406 4.5756 26.5653C4.40026 26.5653 4.40034 26.39 4.225 26.2147V9.90734C4.225 9.03061 4.92664 8.32917 5.80337 8.32917H44.2042C44.9056 8.50452 45.2562 8.85534 45.4315 9.55673H45.607Z"
      fill="#009FDB"
    />
  </svg>
);
