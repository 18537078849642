import * as React from 'react';

export const AmazonLogo = () => (
    <svg width={71} height={22} viewBox="0 0 71 22" fill="none" role="img" aria-label="amazon logo">
        <g clipPath="url(#amazonLogoClip0)">
            <rect width={70} height={21.0948} transform="translate(0.303711 0.0129395)" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M43.7305 16.5097C39.6627 19.5081 33.7667 21.1078 28.6903 21.1078C21.5724 21.1078 15.1644 18.4751 10.3165 14.0965C9.93565 13.7522 10.2769 13.2829 10.734 13.5511C15.9658 16.5951 22.4347 18.4263 29.1169 18.4263C33.6235 18.4263 38.581 17.4939 43.1394 15.5591C43.8281 15.2665 44.4039 16.01 43.7305 16.5097"
                fill="#FF9900"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45.4213 14.5748C44.9033 13.9106 41.9842 14.261 40.6739 14.4164C40.2748 14.4651 40.2138 14.1178 40.5734 13.8679C42.8983 12.2316 46.7132 12.7039 47.1581 13.2524C47.6029 13.8039 47.0423 17.628 44.8575 19.4532C44.5224 19.7335 44.2024 19.5842 44.3517 19.2124C44.8423 17.9875 45.9423 15.2421 45.4213 14.5748"
                fill="#FF9900"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.7656 2.3165V0.725941C40.7656 0.485224 40.9484 0.32373 41.1678 0.32373H48.2888C48.5173 0.32373 48.7001 0.488272 48.7001 0.725941V2.08798C48.6971 2.3165 48.5051 2.61512 48.1639 3.08741L44.4739 8.35577C45.845 8.32225 47.2924 8.5264 48.5356 9.22723C48.8159 9.38567 48.8921 9.61725 48.9134 9.84578V11.543C48.9134 11.7746 48.6575 12.0458 48.3893 11.9056C46.1985 10.7568 43.2886 10.6319 40.8662 11.9178C40.6193 12.0518 40.3604 11.7837 40.3604 11.5521V9.94024C40.3604 9.68124 40.3634 9.23941 40.6224 8.84634L44.8974 2.71567H41.177C40.9484 2.71567 40.7656 2.55418 40.7656 2.3165"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.7898 12.2408H12.6233C12.4161 12.2256 12.2516 12.0702 12.2363 11.8721V0.753418C12.2363 0.530983 12.4222 0.354253 12.6538 0.354253H14.674C14.8842 0.363388 15.0518 0.524887 15.067 0.725993V2.17944H15.1067C15.6338 0.774746 16.6241 0.119629 17.9587 0.119629C19.3146 0.119629 20.1617 0.774746 20.7711 2.17944C21.2952 0.774746 22.4866 0.119629 23.7633 0.119629C24.6714 0.119629 25.6647 0.494417 26.2711 1.3354C26.9567 2.27085 26.8165 3.62984 26.8165 4.82124L26.8134 11.8386C26.8134 12.061 26.6276 12.2408 26.396 12.2408H24.2326C24.0162 12.2256 23.8426 12.0519 23.8426 11.8386V5.9456C23.8426 5.47635 23.8852 4.30628 23.7816 3.86141C23.6201 3.11489 23.1356 2.90464 22.508 2.90464C21.9839 2.90464 21.4354 3.25505 21.213 3.81571C20.9905 4.37637 21.0118 5.31486 21.0118 5.9456V11.8386C21.0118 12.061 20.826 12.2408 20.5944 12.2408H18.431C18.2116 12.2256 18.041 12.0519 18.041 11.8386L18.0379 5.9456C18.0379 4.70545 18.2421 2.88026 16.7033 2.88026C15.1463 2.88026 15.2072 4.65974 15.2072 5.9456V11.8386C15.2072 12.061 15.0213 12.2408 14.7898 12.2408"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M54.8304 0.119629C58.045 0.119629 59.7849 2.88026 59.7849 6.39047C59.7849 9.78184 57.8622 12.4724 54.8304 12.4724C51.6736 12.4724 49.9551 9.71176 49.9551 6.27163C49.9551 2.81018 51.6949 0.119629 54.8304 0.119629V0.119629ZM54.8486 2.38969C53.252 2.38969 53.1514 4.56528 53.1514 5.92122C53.1514 7.28021 53.1301 10.181 54.8304 10.181C56.5093 10.181 56.5885 7.84087 56.5885 6.41484C56.5885 5.47635 56.5489 4.35504 56.2655 3.4653C56.0218 2.69135 55.5373 2.38969 54.8486 2.38969"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M63.9537 12.2408H61.7964C61.58 12.2256 61.4064 12.0519 61.4064 11.8386L61.4033 0.716852C61.4216 0.512699 61.6014 0.354253 61.8208 0.354253H63.8288C64.0177 0.363388 64.1731 0.49137 64.2158 0.665053V2.36531H64.2554C64.8617 0.844828 65.7119 0.119629 67.208 0.119629C68.18 0.119629 69.1276 0.470041 69.737 1.42986C70.3038 2.3196 70.3038 3.81571 70.3038 4.89132V11.8904C70.2794 12.0854 70.0996 12.2408 69.8863 12.2408H67.7138C67.5157 12.2256 67.3512 12.0793 67.3298 11.8904V5.85114C67.3298 4.63537 67.47 2.85588 65.9739 2.85588C65.4468 2.85588 64.9623 3.20934 64.7216 3.74562C64.4169 4.42512 64.3772 5.10157 64.3772 5.85114V11.8386C64.3742 12.061 64.1853 12.2408 63.9537 12.2408"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.0887 6.92976C35.0887 7.77379 35.11 8.47766 34.6834 9.22724C34.3391 9.83665 33.7906 10.2114 33.1843 10.2114C32.3524 10.2114 31.8649 9.57765 31.8649 8.6422C31.8649 6.79569 33.5194 6.46051 35.0887 6.46051V6.92976V6.92976ZM37.2734 12.2103C37.1302 12.3383 36.923 12.3474 36.7615 12.2621C36.0424 11.6649 35.9114 11.3876 35.5183 10.8178C34.3299 12.0305 33.4859 12.3931 31.9471 12.3931C30.122 12.3931 28.7051 11.2688 28.7051 9.01699C28.7051 7.25884 29.6558 6.06135 31.0147 5.47631C32.1909 4.95831 33.8333 4.8669 35.0887 4.72369V4.44336C35.0887 3.92841 35.1283 3.31899 34.8236 2.87413C34.5615 2.47496 34.0557 2.31042 33.6078 2.31042C32.782 2.31042 32.0477 2.73396 31.8679 3.61151C31.8314 3.80653 31.6881 3.99849 31.4901 4.00763L29.3907 3.78215C29.2139 3.74254 29.0159 3.59932 29.0677 3.32814C29.5491 0.7808 31.8527 0.0129395 33.9125 0.0129395C34.9668 0.0129395 36.344 0.293269 37.1759 1.0916C38.2302 2.0758 38.1296 3.38908 38.1296 4.81815V8.19428C38.1296 9.20896 38.5501 9.65382 38.9462 10.2023C39.0833 10.3973 39.1138 10.6319 38.9371 10.7782C38.4953 11.1469 37.7091 11.8325 37.2764 12.2164L37.2734 12.2103"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.68729 6.93C6.68729 7.77403 6.70862 8.4779 6.28203 9.22748C5.93772 9.83689 5.39229 10.2117 4.78288 10.2117C3.95104 10.2117 3.46655 9.57789 3.46655 8.64245C3.46655 6.79593 5.1211 6.46075 6.68729 6.46075V6.93V6.93ZM8.87203 12.2105C8.72882 12.3385 8.52162 12.3477 8.36012 12.2623C7.64102 11.6651 7.51304 11.3878 7.11693 10.818C5.92857 12.0308 5.08759 12.3934 3.54578 12.3934C1.72364 12.3934 0.303711 11.269 0.303711 9.01724C0.303711 7.25908 1.25744 6.06159 2.61338 5.47656C3.78954 4.95855 5.4319 4.86714 6.68729 4.72393V4.4436C6.68729 3.92865 6.7269 3.31924 6.42525 2.87437C6.16015 2.4752 5.65434 2.31066 5.20947 2.31066C4.38372 2.31066 3.64633 2.7342 3.46655 3.61176C3.42999 3.80677 3.28678 3.99873 3.09177 4.00787L0.989298 3.78239C0.812569 3.74278 0.617557 3.59957 0.66631 3.32838C1.15079 0.781044 3.45132 0.0131836 5.51113 0.0131836C6.56541 0.0131836 7.94268 0.293513 8.77452 1.09184C9.82881 2.07604 9.72825 3.38932 9.72825 4.81839V8.19453C9.72825 9.2092 10.1487 9.65407 10.5449 10.2025C10.685 10.3975 10.7155 10.6322 10.5388 10.7784C10.0969 11.1471 9.31081 11.8327 8.87812 12.2166L8.87203 12.2105"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="amazonLogoClip0">
                <rect width={70} height={21.0948} fill="white" transform="translate(0.303711 0.0129395)" />
            </clipPath>
        </defs>
    </svg>
);
