import styled from '@emotion/styled';
import { CloseX, OutlineButton } from '@home-mgmt-shared/common-ui';
import React, { useCallback } from 'react';

type InlinePopupProps = {
    buttonText?: string;
    buttonAnalyticName: string;
    headerText?: string;
    messageText?: string;
    onButtonClick: () => void;
    onClose: () => void;
};

const InlinePopupContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${(props) => props.theme.messageAlert?.font.type};
    background-color: ${(props) => props.theme.messageAlert?.backgroundColor};
    padding: 1rem;
    border-radius: 8px;
`;

const HeaderText = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 150%;
    text-align: left;
`;

const MessageTextContainer = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    text-align: left;
    line-height: 150%;
`;

const ClosePopup = styled.div`
    margin-top: -0.35rem;
    margin-right: -0.35rem;
    max-height: 1.5625rem;
    max-width: 1.5625rem;
    cursor: pointer;
`;

const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const BottomRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Button = styled(OutlineButton)`
    border-radius: 1.5625rem;
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1rem;
    margin-top: 1.125rem;
    width: fit-content;
    min-height: 2rem;
    max-height: 2rem;
    transition: transform 0.2s ease-in;
    user-select: none;
    padding: 0 0.8125rem;

    button&:active {
        transform: scale(0.96);
    }
`;

export const InlinePopup = ({
    buttonText,
    buttonAnalyticName,
    headerText,
    messageText,
    onButtonClick,
    onClose,
}: InlinePopupProps) => {
    const onCloseClicked = useCallback(() => {
        onClose?.();
    }, [onClose]);

    return (
        <InlinePopupContainer>
            <TopRow>
                <HeaderText>{headerText}</HeaderText>
                <ClosePopup onClick={onCloseClicked}>
                    <CloseX />
                </ClosePopup>
            </TopRow>
            <MessageTextContainer>{messageText} </MessageTextContainer>
            <BottomRow>
                <Button analyticEventName={buttonAnalyticName} onClick={onButtonClick}>
                    {buttonText}
                </Button>
            </BottomRow>
        </InlinePopupContainer>
    );
};
