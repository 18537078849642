import * as React from 'react';

export const Checkmark = () => (
    <svg
        width={14}
        height={11}
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="triangle"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.037 1.055l-8.083 9.88L.969 6.95l1.179-1.178L4.83 8.454 11.747 0l1.29 1.055z"
            fill="#fff"
        />
    </svg>
);
