import React from 'react';

export const HomeWifi = () => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 387.82 386.45"
        xmlSpace="preserve"
        aria-label="Wifi symbol"
        role="img"
    >
        <g id="Wifi-Symbol">
            <g>
                <path
                    d="M194.27,24.05c4.24,0,8.37-0.01,12.49,0c2.72,0.01,5.44-0.03,8.16,0.11c3.44,0.17,6.89,0.38,10.31,0.77
           c5.58,0.63,11.16,1.33,16.72,2.15c3.09,0.46,6.13,1.18,9.19,1.84c3.54,0.76,7.1,1.48,10.6,2.41c6.84,1.81,13.62,3.8,20.21,6.37
           c4.47,1.74,8.97,3.43,13.38,5.32c3.29,1.41,6.45,3.09,9.65,4.7c2.59,1.3,5.21,2.57,7.72,4.02c3.53,2.04,7.01,4.17,10.46,6.36
           c3.06,1.94,6.09,3.94,9.05,6.04c2.58,1.84,5.05,3.85,7.56,5.8c1.25,0.97,2.49,1.96,3.71,2.97c1.6,1.34,3.25,2.63,4.74,4.08
           c4.25,4.16,8.46,8.37,12.64,12.6c1.94,1.97,3.82,3.99,5.65,6.07c1.1,1.25,2.08,2.63,3.05,3.99c2.28,3.22,4.64,6.39,6.72,9.74
           c1.11,1.78,1.76,3.86,2.52,5.84c0.92,2.39,1.19,4.87,1.28,7.44c0.08,2.54,0.18,5.06-0.15,7.59c-0.58,4.41-2.37,8.29-5.34,11.55
           c-1.92,2.1-3.85,4.24-6.37,5.7c-1.99,1.15-4.05,2.01-6.43,1.63c-0.18-0.03-0.38-0.07-0.54-0.01c-2.13,0.7-4.26,0.24-6.32-0.19
           c-7.65-1.56-13.36-7.35-18.12-13.17c-5.29-6.46-9.84-12.83-16.36-18.16c-3.97-3.24-7.96-6.47-12.02-9.58
           c-2.7-2.07-5.53-3.96-8.33-5.88c-2.39-1.64-4.78-3.28-7.25-4.79c-2.2-1.34-4.49-2.52-6.76-3.74c-1.67-0.9-3.36-1.78-5.05-2.64
           c-1.33-0.68-2.66-1.37-4.03-1.96c-3.12-1.33-6.26-2.63-9.42-3.88c-2.86-1.13-5.73-2.28-8.65-3.25c-3.69-1.23-7.39-2.45-11.16-3.38
           c-5.01-1.24-10-2.58-15.15-3.21c-4.09-0.5-8.16-1.2-12.26-1.61c-3.83-0.38-7.69-0.62-11.54-0.71c-3.71-0.09-7.43,0.01-11.14,0.19
           c-2.75,0.13-5.49,0.45-8.23,0.78c-3.91,0.47-7.83,0.92-11.72,1.57c-3.16,0.53-6.28,1.34-9.42,2.03c-1.02,0.23-2.03,0.49-3.05,0.73
           c-6.5,1.52-12.8,3.71-19.01,6.11c-3.93,1.52-7.78,3.23-11.61,4.99c-2.63,1.21-5.18,2.61-7.73,3.98c-2.83,1.52-5.7,2.99-8.44,4.67
           c-3.13,1.92-6.13,4.03-9.18,6.08c-8.93,6-17.43,12.87-24.65,20.87c-7.02,7.78-14.48,20.33-24.46,24.47
           c-9.41,3.9-20.06-1.48-25.41-9.47c-1.79-2.67-2.81-5.58-3.31-8.69c-1.25-7.8,0.49-15.48,4.41-22.28
           c3.42-5.95,7.91-11.65,12.31-16.89c2.24-2.68,4.48-5.37,6.85-7.93c2.19-2.36,4.52-4.59,6.84-6.82c2.06-1.98,4.15-3.93,6.32-5.79
           c2.24-1.91,4.58-3.7,6.89-5.54c1.7-1.35,3.38-2.75,5.13-4.03c1.76-1.28,3.58-2.49,5.41-3.66c3.66-2.35,7.3-4.72,11.03-6.95
           c2.33-1.39,4.8-2.54,7.21-3.79c2.37-1.23,4.71-2.54,7.14-3.65c3.34-1.53,6.72-2.97,10.14-4.32c3.91-1.55,7.86-3.01,11.83-4.4
           c2.99-1.05,6.02-2.01,9.07-2.84c4.06-1.11,8.14-2.15,12.26-3.05c4.42-0.96,8.87-1.82,13.33-2.55c4.64-0.76,9.31-1.42,13.99-1.9
           C185.85,24.44,190.12,24.31,194.27,24.05z M189.23,28.28c-8.35,0.52-16.71,1.51-25.02,2.99c-12.16,2.17-24.08,5.31-35.62,9.69
           c-2.24,0.85-4.52,1.62-6.72,2.58c-16.69,7.28-32.57,16.53-46.78,27.95c-7.12,5.72-13.8,11.98-19.94,18.74
           c-5.96,6.57-12.88,14.06-16.91,22.03c-1.01,2-1.62,4.38-1.93,6.6c-0.37,2.66-0.81,5.31-0.68,8.07c0.5,11.14,7.2,20.86,19.34,19.05
           c1.29-0.19,2.66-0.37,3.8-0.94c2.82-1.39,5.36-3.2,7.34-5.72c1.54-1.96,3.16-3.86,4.83-5.71c2.31-2.56,4.67-5.07,7.05-7.56
           c15.84-16.58,34.82-30.11,55.4-40.15c23.45-11.44,50.24-17.39,76.35-16.36c32.11,1.27,61.77,11.28,89.24,27.63
           c13.53,8.06,25.96,18.03,36.56,29.69c7.3,8.04,18.25,24.86,31.09,16.45c4.59-3,7.43-7.29,8.55-12.59
           c0.53-2.49,0.72-5.14,0.55-7.68c-0.51-7.73-3.01-12.59-7.6-18.63c-1.76-2.31-3.4-4.73-5.31-6.9
           C345.14,77.45,324,61.62,300.13,49.4c-2.14-1.09-4.37-1.99-6.57-2.96c-2.53-1.11-5.02-2.32-7.6-3.28
           C254.94,31.53,222.07,26.25,189.23,28.28z"
                />
                <path
                    d="M201.67,137.14c-23.7,0.18-46.27,10.84-64.92,24.84c-3.55,2.66-7.85,5.63-12.04,4.17c20.09-17.25,44.61-30.53,71.01-32.48
           c25.4-1.88,49.89,6.78,71.65,19.15c1.72,0.98,3.44,1.97,5.09,3.06c3.82,2.52,7.53,5.23,11.17,8.02
           c6.84,5.25,12.87,11.48,18.48,18.01c2.41,2.8,4.62,6.59,7.74,8.56c3.52,2.22,7.41,2.53,11.33,1.19c0.58-0.2,1.08-0.64,1.59-1.01
           c3.42-2.46,5.39-5.89,6.12-9.94c0.72-4.02,0.53-8.72-1.03-12.54c-1.58-3.89-5.11-7.56-7.68-10.89c-1.35-1.75-2.87-3.39-4.38-5.01
           c-2.32-2.49-4.68-4.95-7.07-7.37c-1.34-1.35-2.74-2.65-4.19-3.87c-3.33-2.8-6.69-5.57-10.07-8.29c-1.23-0.99-2.57-1.85-3.88-2.74
           c-25.93-17.53-56.81-28.21-88.24-28.35c-28.05-0.13-56.29,8.33-78.88,24.96c-0.73-1.51-1.46-3.03-2.19-4.54
           c-0.54-1.11,18.91-10.57,20.83-11.44c4.64-2.09,9.34-4,14.16-5.61c3.48-1.16,7-2.23,10.55-3.17c13.72-3.67,27.47-5.01,41.63-4.41
           c13.71,0.58,27.31,3.54,40.34,7.77c5.45,1.77,10.81,3.78,16.09,6c3.01,1.26,6.02,2.53,8.93,4c3.52,1.78,6.95,3.73,10.37,5.7
           c2.28,1.31,4.54,2.68,6.68,4.21c3.91,2.8,7.76,5.68,11.56,8.62c2.14,1.66,4.23,3.4,6.21,5.26c2.48,2.33,4.86,4.77,7.21,7.24
           c1.78,1.87,3.38,3.92,5.17,5.78c3.39,3.52,6.14,7.55,8.53,11.74c2.1,3.68,3.47,7.73,3.59,12.14c0.08,2.93,0.03,5.79-0.66,8.64
           c-2.06,8.44-10.52,14.03-19.03,12.05c-2.37-0.55-4.59-1.33-6.44-2.98c-6.93-6.2-11.69-14.23-18.79-20.27
           c-8.28-7.03-16.88-13.85-26.31-19.46c-10.34-6.15-22.5-10.48-34.12-13.41C219.24,138.31,210.45,137.08,201.67,137.14z"
                />
                <path
                    d="M201.34,169.93c-12.16,0.54-24.21,4.3-34.45,10.91c-0.82-0.47-0.95-1.67-0.52-2.52s1.24-1.41,2.04-1.92
           c10.97-7.01,24.13-10.42,37.14-10.03c12.79,0.38,25.34,4.35,36.55,10.53s21.13,14.52,29.91,23.82
           c5.48,5.8,10.67,12.24,12.82,19.93s0.57,16.88-5.66,21.87c-4.35,3.48-10.61,4.41-15.79,2.35c-5.3-2.12-9.01-6.85-12.77-11.14
           c-10.71-12.21-24.76-22.63-40.92-24.27c-8.05-0.82-16.24,0.68-23.63,3.93c-3.8,1.67-6.92,4.08-10.45,6.15
           c-5.03,2.95-1.98-3.11,0.43-4.9c12.05-8.96,28.34-11.46,42.7-7.39c15.74,4.46,26.72,16.86,38,27.91c3.2,3.14,6.85,6.32,11.32,6.74
           c5.86,0.54,11.3-4.26,12.9-9.93c1.59-5.67,0.02-11.82-2.74-17.02c-4.23-7.97-11.03-14.23-17.93-20.03
           c-10.86-9.13-22.59-17.71-36.1-22.02C216.81,170.54,209.06,169.59,201.34,169.93z"
                />
                <path
                    d="M232.02,274.32c0.04-7.02-2.46-14.12-7.87-19.72c-3.02-3.13-6.78-5.53-10.85-7.05c-2.15-0.8-4.39-1.36-6.67-1.64
           c-1.57-0.19-5.01,0.49-6.31-0.36c-0.55-0.36-2.19-3.48-1.23-3.61c0.4-0.05,0.8-0.19,1.2-0.21c5.46-0.27,10.75,0.29,15.84,2.55
           c16.68,7.41,24.25,27.88,16,44.25c-1.17,2.31-2.54,4.49-4.25,6.44c-7.83,8.96-19.01,12.83-30.72,11.57
           c-0.6-0.06-0.9-0.31-0.81-0.97c0.27-1.92-0.29-3.1,1.98-2.94c2.37,0.16,4.6,0.57,7,0.38c4.53-0.36,8.93-1.88,12.77-4.3
           c7.73-4.86,13.13-13.27,13.84-22.43C231.99,275.63,232.02,274.98,232.02,274.32z"
                />
                <path
                    d="M81.7,168.04c-2.09,0.09-1.93,0.14-1.32-1.89c0.7-2.34,2.35-4.06,3.56-6.05c1.34-2.2,2.86-4.3,4.3-6.43
           c0.18-0.26,0.4-0.49,0.59-0.75c0.44-0.62,0.86-0.66,1.41-0.09c0.43,0.45,0.96,0.83,1.47,1.19c0.94,0.65,1.02,0.71,0.33,1.71
           c-1.34,1.93-2.85,3.75-4.08,5.74c-1.19,1.91-2.86,3.53-3.53,5.76c-0.18,0.61-0.62,0.85-1.24,0.82
           C82.7,168.03,82.2,168.04,81.7,168.04z"
                />
            </g>
        </g>
        <g id="Chair">
            <g>
                <path
                    d="M41.55,305.52c0.18,1.78,0.36,3.57,0.53,5.36c0.26,2.79,0.45,5.58,0.78,8.36c0.37,3.14,0.9,6.26,1.26,9.4
           c0.38,3.33,0.65,6.66,0.95,10c0.08,0.9,0.12,1.8,0.19,2.85c2.13,0,4.24,0,6.35,0c3.86,0.01,7.72-0.03,11.58,0.03
           c4.35,0.07,8.7,0.29,13.05,0.31c3.48,0.01,6.97-0.23,10.45-0.3c3.68-0.06,7.36-0.05,11.03-0.03c0.23,0,0.6,0.34,0.65,0.57
           c0.39,1.78,0.22,2.32-1.87,2.39c-3.7,0.12-7.41,0.04-11.11,0.05c-2.85,0.01-5.71,0.03-8.56,0.02c-6.31-0.03-12.63-0.14-18.94-0.09
           c-2.9,0.02-5.79,0.38-8.68,0.52c-1.9,0.09-3.81,0.07-5.72,0.04c-0.76-0.01-1.03,0.19-1.24,0.99c-0.98,3.7-2.14,7.36-3.13,11.06
           c-0.29,1.08-0.56,2.19-1.74,2.29c-1.45,0.12-2.93-0.11-4.39-0.27c-0.21-0.02-0.53-0.53-0.53-0.81c-0.04-3.85-0.02-7.7-0.02-11.55
           c0-0.41,0.01-0.82,0-1.22c-0.06-1.88-0.07-1.84-1.94-2.1c-1.83-0.26-3.73-0.4-5.46-0.99c-2.12-0.72-4.2-1.68-6.12-2.84
           c-1.72-1.04-1.95-3.06-2.25-4.9c-0.6-3.75-1.16-7.5-1.75-11.25c-0.35-2.19-0.74-4.36-1.1-6.55c-0.53-3.26-1.1-6.51-1.53-9.78
           c-0.41-3.14-0.6-6.31-1.02-9.45c-0.58-4.39-1.3-8.77-1.9-13.16c-0.44-3.19-0.77-6.38-1.15-9.58c-0.5-4.15-0.65-8.31-0.29-12.47
           c0.46-5.31,0.82-10.67,3.02-15.65c0.96-2.17,2.34-4.26,4.15-5.82c1.78-1.54,3.96-1.54,6.17-1.72c2.43-0.2,4.88-0.54,7.32-0.77
           c3.02-0.29,6.03-0.57,9.05-0.79c0.65-0.05,0.83-0.25,0.85-0.88c0.11-2.8,0.27-5.6,0.43-8.41c0.22-3.92,0.31-7.85,0.7-11.75
           c0.32-3.18,1.03-6.33,1.55-9.5c0.13-0.8,0.15-1.62,0.26-2.43c0.5-3.72,1.04-7.44,1.51-11.16c0.75-5.94,2.36-11.68,4.1-17.38
           c0.63-2.05,1.82-3.74,4.09-4.58c3.74-1.39,7.53-2.51,11.45-3.2c2.75-0.48,5.5-1.01,8.26-1.46c1.29-0.21,2.6-0.33,3.91-0.41
           c5.3-0.3,10.6-0.57,15.9-0.85c1.27-0.07,2.53-0.21,3.8-0.24c0.56-0.02,1.29-0.28,1.37,0.79c0.07,0.97-0.51,1.03-1.15,1.09
           c-2.17,0.2-4.33,0.46-6.5,0.56c-6.34,0.3-12.67,0.69-18.97,1.51c-5.48,0.72-10.89,1.82-16.19,3.43c-0.52,0.16-1.03,0.37-1.51,0.62
           c-1.12,0.57-1.96,1.32-2.24,2.68c-0.8,3.97-1.73,7.92-2.57,11.89c-0.54,2.55-1.09,5.11-1.5,7.69c-0.64,4.05-1.28,8.11-1.71,12.19
           c-0.37,3.54-0.4,7.11-0.68,10.67c-0.28,3.56-0.72,7.1-1.03,10.66c-0.25,2.83-0.41,5.67-0.57,8.51c-0.01,0.27,0.22,0.65,0.46,0.82
           c3.55,2.55,5.67,6.1,7.17,10.09c0.22,0.58,0.29,1.23-0.38,1.53c-0.71,0.32-1.25-0.15-1.57-0.71c-0.63-1.09-1.13-2.27-1.77-3.35
           c-0.92-1.56-1.86-3.11-2.9-4.58c-0.79-1.11-1.83-1.71-3.37-1.56c-2.75,0.27-5.52,0.28-8.28,0.43c-0.93,0.05-1.86,0.2-2.82,0.31
           c1.39,1.58,2.8,3.09,4.1,4.69c0.68,0.83,1.26,1.78,1.71,2.75c1.48,3.2,2.72,6.46,3.06,10.05c0.34,3.54,1.09,7.05,1.5,10.59
           c0.35,2.97,0.48,5.96,0.72,9.05c1.04-0.33,1.49,0.32,1.79,1.1c-0.55,0.57-1.32,1.05-1.54,1.71c-0.22,0.66,0.1,1.51,0.19,2.27
           c0.34,2.91,0.7,5.83,1.01,8.74c0.24,2.25,0.4,4.51,0.63,6.76c0.14,1.44,0.31,2.87,0.55,4.29c0.05,0.29,0.4,0.65,0.69,0.74
           c1.62,0.53,3.23,0.86,5.02,0.79c4.71-0.18,9.43-0.11,14.14-0.04c3.4,0.06,6.8,0.35,10.2,0.42c3.03,0.06,6.06-0.05,9.1-0.05
           c1.63,0,3.26,0.11,4.89,0.08c1.81-0.03,3.62-0.18,5.43-0.23c3.13-0.09,6.26-0.19,9.39-0.22c3.3-0.04,6.6-0.01,9.92-0.01
           c-0.7,2.03-0.82,2.12-2.87,2.13c-3.54,0.02-7.09,0.02-10.63,0.08c-4.49,0.07-8.98,0.27-13.47,0.28c-4.12,0.01-8.25-0.2-12.37-0.25
           c-1.77-0.02-3.54,0.15-5.3,0.14c-6.39-0.04-12.77-0.08-19.16-0.23C44.82,306.29,43.24,305.82,41.55,305.52z M21.97,240.92
           c-1.62-0.02-3.09-0.03-4.44,1.05c-3.06,2.46-4.51,5.91-5.48,9.46c-0.79,2.88-0.87,5.97-1.12,8.98c-0.16,1.94-0.1,3.91-0.02,5.85
           c0.14,3.26,0.34,6.51,0.59,9.77c0.2,2.57,0.5,5.13,0.76,7.69c0.25,2.43,0.48,4.85,0.75,7.28c0.28,2.56,0.61,5.12,0.9,7.68
           c0.23,2.02,0.39,4.05,0.63,6.06c0.31,2.55,0.67,5.1,1,7.65c0.33,2.5,0.61,5.01,0.99,7.51c0.45,2.94,0.91,5.88,1.52,8.78
           c0.61,2.92,1.4,5.79,2.11,8.69c0.18,0.72,0.61,1.16,1.31,1.39c1.54,0.5,3.06,1.07,4.62,1.54c5,1.49,10.12,1.15,15.23,1.03
           c0.76-0.02,0.99-0.31,0.86-1.1c-0.32-2.1-0.58-4.21-0.79-6.32c-0.3-2.93-0.5-5.86-0.81-8.79c-0.49-4.5-1.17-8.99-1.53-13.5
           c-0.56-6.95-0.89-13.92-1.35-20.87c-0.18-2.66-0.44-5.32-0.66-7.98c-0.11-1.31-0.22-2.62-0.34-3.92
           c-0.31-3.28-0.62-6.56-0.96-9.84c-0.23-2.25-0.44-4.5-0.8-6.73c-0.37-2.23-0.84-4.45-1.41-6.64c-1.15-4.43-2.37-8.81-5.85-12.17
           C26.02,241.87,24.36,240.56,21.97,240.92z"
                />
                <path
                    d="M115.89,341.66c15.78-0.39,31.4-0.78,46.92-1.17c0.26-2.74,0.43-5.3,0.75-7.83c0.65-5.21,1.41-10.4,2.05-15.61
           c0.57-4.62,1.06-9.25,1.6-14c-1.57,0.21-2.89,0.4-4.21,0.57c-3.82,0.5-7.63,1-11.45,1.48c-0.43,0.05-1.01,0.43-1.19-0.39
           c-0.21-1,0.07-1.71,0.82-1.85c0.71-0.14,1.44-0.18,2.16-0.27c1.7-0.21,3.41-0.43,5.11-0.64c2.2-0.27,4.42-0.45,6.6-0.82
           c2.5-0.43,2.29,0.17,2.42-2.68c0.27-5.52,0.69-11.03,1.05-16.54c0.08-1.27,0.24-2.53,0.23-3.8c-0.01-0.99-0.78-1.54-1.65-1.73
           c-0.78-0.18-1.62-0.14-2.43-0.13c-0.9,0.02-1.8,0.12-2.67,0.18c-0.04-0.21-0.1-0.31-0.07-0.37c0.84-2.08,0.85-2.11,3.08-1.89
           c1.12,0.11,2.24,0.29,3.35,0.5c0.7,0.13,0.96-0.03,1.03-0.78c0.21-2.16,0.46-4.31,0.78-6.46c0.34-2.27,0.74-4.54,1.19-6.8
           c0.75-3.77,1.41-7.56,2.36-11.27c1.08-4.22,2.77-8.16,6.66-10.67c0.19-0.12,0.43-0.15,0.65-0.23c-0.1-0.09-0.2-0.19-0.29-0.28
           c-2.47,0-4.96,0.21-7.4-0.06c-2.41-0.26-4.3,0.87-6.31,1.71c-1.37,0.57-2.28,1.75-2.84,3.15c-0.22,0.54-0.39,1.13-0.72,1.6
           c-0.25,0.35-0.73,0.53-1.11,0.79c-0.12-0.45-0.37-0.92-0.32-1.34c0.08-0.57,0.33-1.14,0.62-1.64c1.33-2.24,2.75-4.39,5.47-5.16
           c0.82-0.23,1.64-0.51,2.48-0.61c0.66-0.08,0.97-0.21,0.94-0.95c-0.03-1.12,0.05-2.25,0.07-3.37c0.06-4.58,0.26-9.17,0.12-13.75
           c-0.09-2.91-0.62-5.82-1.08-8.72c-0.58-3.66-1.3-7.31-1.92-10.97c-0.4-2.36-0.71-4.74-1.09-7.1c-0.26-1.6-0.59-3.19-0.85-4.8
           c-0.27-1.64-0.46-3.3-0.75-4.94c-0.2-1.15-0.51-2.28-0.77-3.42c-0.32-1.41-0.58-2.83-0.96-4.23c-0.28-1.05-0.94-1.8-2.05-2.15
           c-5.22-1.61-10.57-2.5-15.98-3.11c-2.65-0.3-5.3-0.66-7.95-0.99c-0.14-0.02-0.27-0.06-0.4-0.07c-3.71-0.24-7.41-0.51-11.12-0.7
           c-2.89-0.15-5.79-0.22-8.68-0.32c-0.07-1.72,0.07-1.9,1.6-1.88c2.31,0.04,4.63,0.15,6.94,0.17c1.8,0.02,3.6-0.13,5.4-0.07
           c1.99,0.07,3.97,0.27,5.95,0.47c3.06,0.31,6.13,0.61,9.17,1.04c2.5,0.35,4.98,0.86,7.46,1.35c2.53,0.5,5.04,1.14,7.57,1.59
           c2.16,0.39,3.43,1.9,3.96,3.68c1.01,3.41,1.59,6.94,2.36,10.42c0.16,0.75,0.37,1.49,0.52,2.24c1.08,5.4,2.22,10.79,3.18,16.2
           c0.62,3.51,1.1,7.06,1.4,10.61c0.43,5.19,0.65,10.4,0.94,15.6c0.13,2.39,0.21,4.78,0.31,6.92c-0.02-0.4,15.21-0.06,16.64,0.24
           c7.19,1.51,10.42,7.55,10.84,14.62c0.15,2.62,0.48,5.24,0.45,7.86c-0.02,2.25-0.43,4.5-0.68,6.75c-0.36,3.24-0.78,6.47-1.09,9.71
           c-0.49,5-0.88,10-1.37,14.99c-0.34,3.5-0.74,7.01-1.18,10.5c-0.23,1.79-0.66,3.54-0.94,5.32c-0.43,2.72-0.8,5.45-1.23,8.17
           c-0.32,2.05-0.67,4.1-1.07,6.14c-0.61,3.19-1.21,6.38-1.93,9.55c-0.54,2.37-1.21,4.72-1.92,7.04c-0.27,0.89-0.76,1.73-1.23,2.55
           c-0.44,0.77-1.08,1.29-1.99,1.53c-0.65,0.17-1.26,0.51-1.85,0.83c-2.45,1.32-5.15,1.63-7.85,1.85c-0.84,0.07-1.05,0.4-1.04,1.18
           c0.04,2.8,0.03,5.59,0.04,8.39c0,0.64,0.05,1.28-0.01,1.91c-0.22,2.65,0.18,2.89-3.23,2.98c-0.67,0.02-1.39-0.06-2-0.3
           c-0.44-0.17-0.93-0.62-1.07-1.05c-0.89-2.7-1.68-5.44-2.52-8.16c-0.36-1.16-0.82-2.3-1.15-3.47c-0.17-0.59-0.5-0.66-1-0.64
           c-1.18,0.06-2.36,0.17-3.54,0.15c-3.51-0.07-7.03-0.19-10.54-0.3c-1.9-0.06-3.81-0.24-5.71-0.2c-6.03,0.11-12.07,0.34-18.1,0.44
           c-3.89,0.07-7.78-0.03-11.68-0.01c-0.72,0-0.99-0.26-0.97-0.95C115.99,342.58,115.93,342.13,115.89,341.66z M165.23,340.12
           c1.12,0,2.43,0.03,3.73-0.01c2.62-0.09,5.25-0.15,7.86-0.34c3.06-0.22,6.13-0.3,9.16-0.97c0.92-0.2,1.4-0.42,1.64-1.41
           c0.57-2.42,1.32-4.79,1.95-7.2c0.7-2.67,1.44-5.32,1.98-8.02c0.94-4.7,1.74-9.43,2.59-14.14c0.57-3.16,1.23-6.3,1.71-9.48
           c0.63-4.2,1.16-8.42,1.65-12.64c0.41-3.5,0.72-7.01,1.06-10.51c0.23-2.34,0.36-4.69,0.68-7.01c0.49-3.63,0.7-7.27,0.59-10.94
           c-0.09-2.99-0.02-5.98-0.16-8.97c-0.07-1.37-0.28-2.81-0.78-4.08c-0.46-1.18-1.33-2.23-2.11-3.27c-1.15-1.54-2.82-2.64-4.58-2.92
           c-2.47-0.39-5.05-0.16-7.58-0.16c-0.3,0-0.65,0.12-0.89,0.29c-1.97,1.37-4.05,2.69-5.19,4.88c-1.01,1.94-1.93,3.97-2.58,6.06
           c-0.68,2.18-0.99,4.47-1.45,6.71c-0.71,3.49-1.65,6.96-2.06,10.48c-0.52,4.49-0.6,9.03-0.9,13.55c-0.31,4.69-0.66,9.38-1.01,14.07
           c-0.19,2.53-0.42,5.05-0.64,7.58c-0.2,2.34-0.32,4.69-0.6,7.03c-0.39,3.19-0.93,6.36-1.34,9.54c-0.46,3.5-0.84,7.01-1.26,10.51
           c-0.23,1.93-0.44,3.86-0.69,5.79C165.76,336.46,165.48,338.37,165.23,340.12z"
                />
                <path
                    d="M110.78,308.23c0.31-0.58,0.59-1.63,0.95-1.66c1.07-0.09,0.89,0.98,1.04,1.7c0.25,1.19,0.4,2.43,0.82,3.56
           c0.32,0.84,0.99,1.55,1.54,2.3c0.53,0.73,1.13,1.42,1.61,2.18c0.14,0.22,0.12,0.76-0.05,0.92c-0.19,0.18-0.67,0.19-0.95,0.08
           c-0.31-0.13-0.54-0.46-0.78-0.73c-0.33-0.37-0.58-0.85-0.98-1.11c-1.26-0.83-1.88-2.01-2.19-3.44
           C111.52,310.8,111.15,309.6,110.78,308.23z"
                />
            </g>
        </g>
        <g id="Person">
            <g>
                <path
                    d="M52.85,248.43c-0.28-1.3-0.53-2.5-0.82-3.68c-0.06-0.24-0.22-0.51-0.42-0.66c-0.95-0.7-1.17-1.7-1.13-2.78
           c0.07-2.18,0.19-4.35,0.29-6.52c0.01-0.32,0.07-0.64,0.02-0.95c-0.24-1.59,0.19-2.95,1.26-4.15c0.62-0.69,0.88-1.48,0.91-2.47
           c0.03-1.25,0.31-2.51,0.56-3.75c0.77-3.77,1.6-7.52,2.35-11.29c0.53-2.66,0.87-5.36,1.46-8c0.55-2.47,1.25-4.89,2.34-7.21
           c1.87-3.99,3.96-7.84,6.79-11.23c3.28-3.93,7.06-7.25,11.82-9.39c3.58-1.61,7.03-3.52,10.57-5.21c1.38-0.66,2.86-1.15,4.32-1.64
           c0.84-0.28,1.24-0.66,1.2-1.64c-0.08-2.22-0.02-4.45-0.04-6.67c0-0.35-0.04-0.73-0.17-1.06c-0.65-1.59-1.36-3.15-2-4.75
           c-0.22-0.54-0.45-0.8-1.09-0.74c-1.41,0.13-2.43-0.62-3.21-1.7c-1.83-2.55-2.87-5.34-2.49-8.54c0.2-1.71,0.76-3.24,2.08-4.45
           c0.28-0.26,0.47-0.78,0.47-1.17c-0.01-2.45-0.18-4.9-0.12-7.35c0.16-6.57,2.52-12.2,9.29-15.14c3.18-1.38,6.46-2.47,9.9-2.89
           c3.18-0.39,6.27,0.21,9.1,1.73c3.44,1.84,5.87,4.58,7.32,8.25c0.94,2.38,1.26,4.82,1.38,7.31c0.16,3.21,0.33,6.43,0.27,9.64
           c-0.09,4.68-0.64,9.31-1.78,13.87c-0.56,2.24-0.9,4.54-1.55,6.75c-0.48,1.63-1.14,3.26-2.01,4.71c-0.93,1.54-1.18,3.1-1,4.81
           c0.07,0.67,0.4,0.89,1.04,0.99c1.2,0.2,2.38,0.49,3.56,0.79c3.85,1.01,7.68,2.07,11.53,3.05c3.1,0.79,6.09,1.69,8.5,4.02
           c1.48,1.43,2.51,3.12,3.39,4.89c1.29,2.58,2.19,5.37,3.64,7.84c1.36,2.31,1.71,4.84,2.46,7.29c1.15,3.75,2.31,7.5,3.3,11.29
           c0.92,3.5,1.58,7.05,2.45,10.56c0.19,0.75,0.67,1.49,1.2,2.08c0.94,1.05,1.29,2.21,1.38,3.59c0.13,2.03,0.43,4.05,0.69,6.08
           c0.2,1.53,0.54,3.07-0.59,4.43c-0.1,0.12-0.1,0.34-0.12,0.52c-0.15,1.66-0.28,3.32-0.42,4.94c1.24,0.76,2.59,1.57,3.91,2.41
           c2.59,1.64,3.72,4.12,3.91,7.07c0.15,2.43,0.32,4.87,0.3,7.3c-0.01,1.48-0.32,2.96-0.59,4.42c-0.72,3.93-1.83,7.74-3.77,11.26
           c-0.83,1.5-1.8,2.92-2.7,4.38c-0.38,0.62-0.69,1.27-1.08,1.88c-2.02,3.16-4.05,6.31-6.11,9.45c-0.34,0.51-0.46,0.91-0.31,1.59
           c0.77,3.65,0.71,7.25-0.89,10.75c-0.61,1.34-1.02,2.77-0.97,4.32c0.11,2.98,0.14,5.96,0.08,8.94c-0.07,3.5-0.82,6.74-3.38,9.45
           c-1.49,1.59-2.72,3.44-4.76,4.37c-3.61,1.64-7.1,1.06-8.49-2.67c-0.72-1.93-0.89-4.15-0.48-6.28c0.37-1.9,0.82-3.79,1.18-5.69
           c0.11-0.61,0.02-1.25,0.02-2.12c-1.74,2.07-3.35,4.01-4.99,5.91c-1.83,2.13-3.71,4.22-5.52,6.36c-1.15,1.36-2.48,2.19-4.36,1.93
           c-0.25-0.03-0.68,0.35-0.82,0.64c-0.67,1.32-1.17,2.74-1.92,4.02c-1.76,3.01-1.8,6.22-1.59,9.58c0.17,2.64,1.02,5.03,1.78,7.48
           c0.07,0.21,0.08,0.46,0.2,0.64c1.67,2.5,2.27,5.43,2.93,8.25c0.52,2.23,1.13,4.62,0.19,6.95c-1.07,2.67-2.29,5.24-4.9,6.81
           c-1.7,1.03-3.55,0.81-5.29,0.42c-3.33-0.75-5.9-2.76-7.76-5.58c-1.19-1.81-2.21-3.74-3.17-5.68c-1.08-2.19-1.7-4.52-1.89-7
           c-0.18-2.38-0.44-4.8-1.08-7.09c-0.57-2.02-1.72-3.87-2.61-5.8c-0.29-0.62-0.6-1.22-0.86-1.85c-0.74-1.77-0.6-3.56,0.17-5.25
           c0.95-2.09,2-4.13,3.1-6.15c0.43-0.78,1.07-1.46,1.66-2.13c1.44-1.62,2.92-3.2,4.36-4.83c0.77-0.88,1.45-1.84,2.22-2.72
           c0.51-0.59,0.39-1.12,0.04-1.71c-0.51-0.85-0.99-1.73-1.5-2.58c-0.72-1.22-0.83-2.36-0.08-3.69c1.95-3.46,3.73-7.01,5.64-10.49
           c1.65-3,3.42-5.94,5.07-8.94c1.33-2.42,2.49-4.93,3.85-7.33c2.04-3.62,4.16-7.19,6.31-10.75c2.59-4.29,5.21-8.56,7.87-12.81
           c1.7-2.71,3.47-5.38,5.25-8.05c0.47-0.7,1.07-1.32,1.64-1.96c0.41-0.46,0.53-0.87,0.13-1.44c-0.45-0.63-0.77-1.34-1.15-2.02
           c-0.13,0.04-0.26,0.07-0.39,0.11c0,0.65-0.16,1.35,0.03,1.94c0.48,1.47-0.3,2.38-1.26,3.36c-0.87-3.32-1.03-6.62-0.84-9.95
           c0.11-2.01-1.04-3.61-1.73-5.36c-0.07-0.19-0.52-0.32-0.8-0.33c-3.18-0.01-6.35,0-9.53,0.01c-4.35,0.02-8.7,0.08-13.04,0.08
           c-2.99,0.01-5.98-0.01-8.98-0.07c-2.36-0.05-3.3-0.96-3.72-3.26c-0.07-0.38-0.53-0.77-0.91-0.98c-0.13-0.07-0.68,0.32-0.83,0.61
           c-1.57,2.97-3.09,5.93-5.32,8.52c-1.77,2.05-3.21,4.39-4.88,6.54c-1.28,1.64-2.64,3.23-4.06,4.75c-1.06,1.14-2.27,2.14-3.42,3.2
           c-0.4,0.37-0.46,0.8-0.1,1.19c0.33,0.36,0.72,0.68,1.13,0.96c3.27,2.2,6.98,3.45,10.62,4.86c9.04,3.51,18.34,3.69,27.78,2.25
           c3.03-0.46,5.99-1.08,8.74-2.47c1.53-0.78,3-1.67,4.49-2.51c-3.64,6.01-7.34,12.09-11.06,18.22c-1.44-0.66-2.91-1.34-4.39-2.01
           c-2.18-0.98-4.37-1.95-6.55-2.93c-4.98-2.25-9.96-4.52-14.94-6.77c-1.15-0.52-2.36-0.94-3.5-1.49c-3.06-1.45-6.1-2.94-9.14-4.43
           c-1.17-0.58-2.31-1.23-3.48-1.8c-0.17-0.08-0.46,0.06-0.69,0.1c0.07,0.22,0.08,0.52,0.24,0.65c0.41,0.36,0.85,0.7,1.33,0.94
           c6.96,3.48,13.87,7.08,20.93,10.37c6.43,3,13.01,5.65,19.66,8.51c-4.08,7.39-8.1,14.67-12.25,22.18c-1.65-0.27-3.38-0.51-5.1-0.83
           c-3.51-0.66-7.02-1.32-10.52-2.05c-1.54-0.32-3.04-0.85-4.56-1.26c-2.23-0.6-4.47-1.14-6.68-1.78c-1.52-0.44-3.01-0.98-4.5-1.49
           c-2.3-0.79-4.66-1.45-6.89-2.41c-3.84-1.65-7.92-2.54-11.85-3.87c-2.17-0.73-4.45-1.14-6.67-1.74c-1.18-0.32-2.36-0.65-3.48-1.12
           c-2.74-1.15-5.68-1.94-7.92-4.06c-2.31-2.19-3.45-4.89-3.9-8.05c-0.62-4.34,0.5-8.35,2.31-12.09c1.44-2.98,3.01-6.02,5.58-8.29
           c1.12-0.99,2.31-1.91,3.57-2.7c0.78-0.49,1.74-0.7,2.63-1C51.34,248.85,52.07,248.66,52.85,248.43z M148.33,192.52
           c-0.1-0.41-0.14-0.68-0.23-0.93c-1.34-3.48-2.94-6.85-5.17-9.83c-1.24-1.67-3.01-3-5.05-3.56c-5.06-1.4-10.18-2.59-15.27-3.89
           c-0.56-0.14-0.8,0.03-1.08,0.48c-0.67,1.07-1.42,2.1-2.14,3.15c-1.33,1.93-3.22,3-5.47,3.44c-3.5,0.68-6.98,0.2-10.39-0.6
           c-2.45-0.57-4.82-1.53-7.23-2.3c-2.29-0.73-4.11-2.1-5.51-4.02c-0.94-1.29-1.07-1.42-2.51-0.74c-2.49,1.18-4.97,2.38-7.43,3.63
           c-1.93,0.98-3.93,1.89-5.71,3.1c-3.02,2.06-5.4,4.8-7.56,7.75c-3.14,4.3-5.92,8.76-7.29,13.96c-0.38,1.43-0.83,2.85-1.08,4.3
           c-0.54,3.16-0.88,6.35-1.47,9.5c-0.78,4.13-1.7,8.24-2.6,12.34c-0.11,0.51-0.33,1.1-0.69,1.45c-0.83,0.79-1.1,1.8-1.15,2.82
           c-0.12,2.58-0.02,5.17-0.19,7.75c-0.09,1.47,0.58,2.19,1.81,2.56c1.95,0.58,3.92,1.12,5.9,1.62c0.22,0.06,0.57-0.17,0.79-0.34
           c3.11-2.5,6.17-5.05,9.33-7.49c1.13-0.87,1.87-1.77,1.84-3.26c-0.01-0.62,0.24-1.24,0.36-1.86c0.34-1.73,0.72-3.45,1-5.18
           c0.27-1.69,0.36-3.42,0.66-5.11c0.69-3.92,1.34-7.85,2.24-11.72c0.51-2.19,0.36-4.19-0.5-6.2c-0.31-0.73-0.07-1.17,0.72-1.61
           c0.36,0.85,0.68,1.59,1.08,2.51c0.23-0.88,0.34-1.55,0.59-2.17c0.13-0.31,0.51-0.53,0.77-0.79c0.17,0.34,0.38,0.66,0.48,1.02
           c0.07,0.25-0.01,0.54-0.05,0.81c-0.38,2.46-0.78,4.91-1.17,7.36c-0.42,2.68-0.84,5.35-1.26,8.03c-0.4,2.55-0.76,5.1-1.2,7.64
           c-0.33,1.87-0.77,3.72-1.13,5.59c-0.07,0.38-0.01,0.79-0.01,1.28c0.27-0.13,0.49-0.19,0.66-0.32c3.59-2.69,7.1-5.51,10.8-8.04
           c2.45-1.68,3.73-3.77,4.22-6.69c0.75-4.49,3.69-7.01,8.12-7.81c0.73-0.13,0.84-0.59,0.88-1.16c0.24-3.85,0.5-7.69,0.71-11.54
           c0.07-1.39,0.37-2.69,1.65-3.38c1.06-0.57,2.24-1.17,3.4-1.23c3.9-0.19,7.8-0.15,11.71-0.17c4.49-0.03,8.98-0.02,13.47-0.04
           c2-0.01,4-0.08,5.99-0.09C140.7,192.51,144.45,192.52,148.33,192.52z M109.09,234.69c0.4,0.03,0.88,0.09,1.37,0.08
           c3.27-0.03,6.53-0.08,9.8-0.12c2.77-0.03,5.53-0.04,8.3-0.08c2.08-0.03,4.16-0.13,6.25-0.15c3.84-0.03,7.68,0.01,11.53-0.04
           c1.9-0.03,2.54-0.81,2.55-2.72c0.02-2.85,0.05-5.7,0.15-8.54c0.07-1.95,0.33-3.88,0.41-5.83c0.23-5.39,0.41-10.77,0.61-16.16
           c0.01-0.36,0.13-0.72,0.14-1.08c0.05-1.4,0.19-2.81,0.07-4.2c-0.1-1.18-0.71-1.46-1.9-1.33c-1.13,0.12-2.26,0.21-3.39,0.22
           c-1.67,0.02-3.34-0.04-5.01-0.04c-3.98,0-7.97,0.03-11.95,0.01c-2.36-0.01-4.71-0.12-7.07-0.14c-5.12-0.05-10.25-0.06-15.37-0.11
           c-1.82-0.01-3.22,1.39-3.3,3.24c-0.14,3.3-0.37,6.6-0.5,9.89c-0.1,2.44-0.08,4.89-0.14,7.33c-0.03,1.36,0.04,1.53,1.26,1.68
           c3.78,0.46,7.57,0.83,11.39,0.45c1.66-0.16,3.35-0.16,5.02-0.11c0.87,0.02,1.51,0.47,1.62,1.51c0.05,0.43,0.46,0.83,0.75,1.21
           c0.9,1.16,1.61,2.34,0.68,3.83c-0.11,0.17-0.07,0.43-0.14,0.63c-0.27,0.74-0.4,1.6-0.87,2.19c-0.64,0.8-1.55,1.38-2.34,2.05
           c-0.23,0.2-0.64,0.36-0.68,0.59c-0.22,1.33-1.28,1.82-2.28,2.32C113.75,232.46,111.42,233.56,109.09,234.69z M123.14,131.33
           c-0.15-0.92-0.27-2.04-0.55-3.13c-0.11-0.42-0.51-0.94-0.9-1.09c-2.75-1.09-5.59-1.9-8.58-1.94c-4.46-0.07-8.87,0.4-13.23,1.37
           c-1.54,0.34-3.09,0.68-4.36,1.78c-0.95,0.83-1.33,1.74-1,2.99c0.27,1.05,0.44,2.13,0.64,3.2c0.19,1.01-0.07,1.79-1.14,2.16
           c-0.86,0.29-0.94,0.83-0.78,1.7c0.29,1.59,0.45,3.23,0.49,4.85c0.02,1.2-1.02,1.78-2.06,1.17c-0.67-0.4-1.21-1.05-1.74-1.65
           c-1.02-1.15-1.83-1.04-2.38,0.38c-0.1,0.25-0.11,0.53-0.2,0.79c-0.57,1.71-0.04,3.34,0.57,4.88c0.48,1.2,1.27,2.27,1.96,3.37
           c0.37,0.59,0.89,0.78,1.64,0.57c1.36-0.37,1.51-0.26,1.93,1.11c0.96,3.19,2.19,6.27,4.08,9.01c1.19,1.73,2.65,3.32,4.18,4.76
           c1.77,1.68,4.2,2.15,6.48,2.66c3.05,0.67,5.87-0.09,7.79-2.65c1.46-1.94,2.89-3.99,3.47-6.42c0.97-4.04,2.07-8.07,2.76-12.17
           c0.58-3.43,0.71-6.94,0.9-10.43C123.24,136.26,123.14,133.9,123.14,131.33z M54.63,246.12c0.3,1.32,0.57,2.78,0.98,4.19
           c0.79,2.71,2.39,5,4.12,7.15c1.65,2.05,3.69,3.68,6.31,4.54c2.22,0.73,3.9,0.34,5.48-1.39c1.68-1.84,3.55-3.5,5.31-5.27
           c2.08-2.08,4.23-4.1,6.18-6.31c2.31-2.62,4.52-5.33,6.61-8.13c2.04-2.72,3.87-5.59,5.8-8.38c0.49-0.71,0.95-1.44,1.52-2.08
           c0.21-0.23,0.73-0.37,1.04-0.29c0.92,0.23,1.78,0.68,2.7,0.89c2.07,0.47,4.14,0.97,6.25,1.22c2.03,0.23,3.78-0.73,5.42-1.85
           c-0.8-0.05-1.56,0.06-2.32,0.11c-0.76,0.06-1.52,0.07-2.08-0.98c3.1-0.76,6.11-1.33,8.89-2.61c-2.91,0.13-5.8,0.55-8.68-0.08
           c-0.35-0.08-0.66-0.34-0.99-0.51c0.3-0.29,0.55-0.75,0.9-0.83c0.65-0.15,1.35-0.13,2.03-0.09c3.68,0.24,7.12-0.46,10.26-2.46
           c0.45-0.29,0.87-0.55,0.5-1.17c-0.43-0.71-0.79-0.21-1.21,0.03c-1.91,1.11-4,1.66-6.19,1.86c-1.25,0.12-2.51,0.16-3.77,0.23
           c-0.54,0.03-1.11,0.12-1.62,0c-0.48-0.1-0.9-0.44-1.35-0.67c0.39-0.24,0.76-0.62,1.19-0.7c2.23-0.42,4.49-0.68,6.7-1.18
           c1.35-0.3,2.65-0.88,3.94-1.42c0.27-0.11,0.38-0.61,0.56-0.93c-0.32-0.11-0.64-0.32-0.94-0.3c-0.86,0.06-1.7,0.25-2.56,0.34
           c-4.86,0.48-9.7,0.39-14.43-0.92c-1.23-0.34-2.36-1.08-3.51-1.68c-0.17-0.09-0.2-0.43-0.29-0.66c0.2-0.08,0.39-0.21,0.6-0.22
           c0.61-0.03,1.23-0.01,1.95-0.01c-0.09-1.29-0.18-2.48-0.28-3.84c-1.23,0.6-2.31,1-3.24,1.63c-1.11,0.74-2.46,1.33-2.9,2.76
           c-0.59,1.9-1.27,3.8-1.57,5.76c-0.19,1.24-0.78,1.92-1.69,2.59c-2.29,1.7-4.55,3.44-6.81,5.19c-1.97,1.52-3.91,3.1-5.9,4.6
           c-3.19,2.39-6.42,4.74-9.62,7.11c-1.53,1.13-3.06,2.27-4.58,3.41c-0.2,0.15-0.47,0.37-0.51,0.59c-0.2,1.21-1.05,1.4-2.02,1.32
           C58.77,246.53,56.76,246.32,54.63,246.12z M108.06,319.44c-0.12,0.08-0.23,0.1-0.26,0.17c-1.34,2.61-3.13,4.85-5.34,6.78
           c-0.6,0.53-1.06,1.24-1.53,1.9c-0.12,0.17-0.15,0.51-0.07,0.71c0.6,1.4,0.56,2.89,0.74,4.37c0.31,2.55-0.28,4.76-1.75,6.77
           c-0.71,0.98-0.88,1.78-0.19,2.9c0.75,1.22,1.21,2.63,1.74,3.97c0.18,0.45,0.27,0.96,0.3,1.45c0.19,3.07,0.4,6.14,1.27,9.09
           c0.42,1.44,1.19,2.78,1.78,4.12c4.91-2.31,9.69-3.93,14.99-1.4c-0.12-0.59-0.19-1.07-0.3-1.54c-0.41-1.66-0.51-3.51-1.33-4.94
           c-1.94-3.39-2.49-7.15-3.31-10.83c-0.35-1.57-0.13-3.25-0.26-4.88c-0.21-2.62,0.79-4.85,2.1-7c0.77-1.26,1.55-2.51,2.33-3.77
           C114.86,325.21,110.8,323.22,108.06,319.44z M150.62,225.14c0,2.73,0.02,5.16-0.01,7.6c-0.02,1.78-1.55,3.62-3.14,3.69
           c-1.81,0.08-3.62,0.02-5.43,0.04c-1.6,0.02-3.2,0.06-5.14,0.09c1.8,3.62,3.4,6.91,5.07,10.16c0.62,1.21,1.29,2.42,2.15,3.46
           c1.79,2.17,4.32,2.68,6.98,2.75c0.85,0.02,1.55-0.3,2.19-0.9c2.11-2,3.31-4.51,4.04-7.25c0.7-2.65,1.25-5.33,1.78-7.64
           C156.23,233.09,153.5,229.22,150.62,225.14z M152.66,293.68c-0.51,0.63-0.85,1.01-1.14,1.43c-0.8,1.15-1.48,2.39-2.37,3.47
           c-3.09,3.78-6.24,7.52-9.4,11.24c-0.52,0.61-0.7,1.15-0.57,1.95c0.37,2.17,0.23,4.32-0.38,6.45c-0.33,1.16-0.54,2.36-0.83,3.64
           c3.71-2.23,7.26-4.53,11.78-2.42c0.1-0.41,0.23-0.7,0.23-1c0-1.48-0.05-2.97-0.06-4.45c-0.01-2.52,0.01-5.04,0.02-7.57
           c0.01-1.42,0.37-2.73,1.02-4.03C152.28,299.78,152.71,296.95,152.66,293.68z M96.09,164.66c0,0.46-0.1,1.01,0.02,1.51
           c0.52,2.26,0.16,4.52,0,6.77c-0.21,2.98,0.05,3.53,2.98,4.35c2.56,0.71,5.16,1.3,7.77,1.79c3.03,0.56,6.04,0.39,8.93-0.88
           c1.04-0.46,1.76-1.2,2.34-2.18c-1.68-0.97-0.49-3.09-1.68-4.11c-1.63,0.65-3.1,1.46-4.67,1.82c-3.65,0.83-6.83-0.37-9.49-2.87
           C100.18,168.9,98.2,166.79,96.09,164.66z M158.95,234.19c0.1-0.03,0.2-0.06,0.3-0.09c0.02-0.36,0.08-0.72,0.04-1.07
           c-0.2-2.08-0.38-4.16-0.66-6.22c-0.17-1.29-0.41-2.59-1.51-3.54c-0.23-0.2-0.25-0.65-0.33-1c-0.45-1.93-0.88-3.87-1.35-5.8
           c-0.44-1.85-1.13-3.67-1.31-5.54c-0.23-2.44-1.24-4.61-1.91-6.89c-0.03-0.12-0.14-0.21-0.19-0.28c-0.36,5.5-0.73,10.97-1.07,16.43
           c-0.04,0.63-0.04,1.28,0.11,1.89c0.31,1.23,0.79,1.39,1.97,1.03c0.62-0.19,1.31-0.25,1.97-0.22c0.25,0.01,0.49,0.42,0.74,0.64
           c-0.21,0.2-0.39,0.46-0.64,0.58c-0.68,0.32-1.39,0.57-2.19,0.89C155,228.15,156.98,231.17,158.95,234.19z M105.87,363.49
           c1.59,2.44,3.34,4.67,6.09,5.83c1.31,0.56,2.79,0.93,4.05,0.25c2.25-1.21,3.2-3.46,3.92-5.79c0.13-0.42,0.02-0.66-0.5-0.89
           c-2.35-1.04-4.66-1.78-7.34-1.31C109.95,361.95,107.76,361.97,105.87,363.49z M140.98,328.41c1.68-0.01,2.68-0.42,3.89-1.58
           c0.62-0.6,1.33-1.12,1.87-1.78c0.62-0.76,1.14-1.61,1.59-2.48c0.12-0.23-0.07-0.84-0.31-1c-0.38-0.26-0.91-0.39-1.38-0.41
           c-3.07-0.09-5.68,1.31-8.3,2.64c-0.14,0.07-0.28,0.26-0.3,0.4C137.74,326.31,138.89,328.89,140.98,328.41z M97.92,339.62
           c1.55-2.8,2.41-5.67,1.58-8.94C97.01,334.27,96.44,337.47,97.92,339.62z M91.85,172.59c0.96,0.96,1.75,1.74,2.53,2.53
           c0.1-0.06,0.19-0.12,0.29-0.19c-0.06-1.23-0.13-2.46-0.21-3.91C93.52,171.59,92.83,172,91.85,172.59z M101.64,233.5
           c-0.1,0.16-0.2,0.32-0.3,0.49c0.34,0.35,0.66,0.99,1.02,1.01c1.17,0.06,2.35-0.1,3.52-0.18c0.01-0.14,0.01-0.29,0.02-0.43
           C104.48,234.09,103.06,233.79,101.64,233.5z"
                />
                <path
                    d="M70.27,234.19c-2.62-0.61-5.19-1.13-7.72-1.82c-2.05-0.56-4.04-1.3-6.05-2c-0.3-0.1-0.52-0.4-0.78-0.61
           c0.25-0.21,0.48-0.54,0.76-0.59c0.55-0.1,1.17-0.16,1.7-0.02c1.27,0.33,2.49,0.84,3.75,1.18c2.14,0.56,4.31,1.01,6.45,1.57
           c0.78,0.2,1.53,0.54,2.25,0.92c0.25,0.13,0.52,0.58,0.48,0.84C71.08,233.85,70.6,233.99,70.27,234.19z"
                />
                <path
                    d="M148.68,198.3c-0.02,1.06-0.57,1.7-1.49,1.72c-0.92,0.02-1.64-0.7-1.61-1.63c0.02-0.87,0.73-1.71,1.44-1.7
           C147.82,196.69,148.69,197.54,148.68,198.3z"
                />
                <path
                    d="M144.58,198.42c-0.07,0.94-0.57,1.39-1.49,1.33c-0.89-0.06-1.27-0.45-1.22-1.25c0.05-0.77,0.84-1.5,1.5-1.42
           C144.14,197.19,144.63,197.74,144.58,198.42z"
                />
                <path
                    d="M109.28,158.48c-0.84-0.3-1.67-0.62-2.51-0.88c-0.63-0.2-1.27-0.4-1.93-0.48c-0.95-0.12-1.77-0.45-2.27-1.28
           c-0.15-0.25-0.24-0.56,0-0.78c0.2-0.19,0.63-0.3,0.9-0.25c0.89,0.17,1.78,0.33,2.66,0.58c0.92,0.27,1.84,0.58,2.77,0.83
           c1.41,0.38,3-0.3,4.15-1.51c0.7-0.74,1.37-0.14,1.33,0.77c-0.02,0.32-0.26,0.71-0.53,0.92
           C112.54,157.44,111.19,158.46,109.28,158.48z"
                />
                <path
                    d="M113.13,138.12c0.27,0.38,0.12,1.06,0.09,1.47c-0.19,2.93-0.45,5.87-0.56,8.8c-0.04,1.02,0.31,2.05,0.48,3.08
           c0.07,0.4,0.19,0.8,0.18,1.2c0,0.23-0.18,0.61-0.35,0.65c-0.25,0.07-0.73-0.01-0.82-0.18c-0.37-0.66-0.8-1.35-0.91-2.08
           c-0.59-4.08-0.38-8.13,0.88-12.08c0.13-0.4,0.47-0.72,0.72-1.08C112.97,137.95,113.06,138.03,113.13,138.12z"
                />
                <path
                    d="M102.27,137.87c-1.06,0-1.99-0.13-3.04,0.18c-0.73,0.21-1.31,0.76-2.02,0.99c-0.22,0.07-0.77,0.24-0.96,0.03
           c-0.17-0.19-0.04-0.9,0.14-1.06c1.18-1.06,2.4-2.11,4.07-2.24c1.11-0.08,2.22-0.06,3.34-0.07c0.7-0.01,2.91,0.17,2.61,1.35
           c-0.25,0.95-1.07,0.81-1.78,0.82C103.95,137.88,103.27,137.87,102.27,137.87z"
                />
                <path
                    d="M106.97,163.55c-2.59-0.01-5.25-1.96-6.83-3.77c-0.27-0.31-0.26-0.82,0.07-1.08c0.57-0.45,1.19,0.36,1.62,0.67
           c0.59,0.43,1.24,0.79,1.92,1.08c1.36,0.57,2.83,0.83,4.29,0.96c0.51,0.04,1.27,0.37,1.37,0.95c0.1,0.57-0.55,0.84-0.99,0.98
           C107.95,163.49,107.47,163.56,106.97,163.55z"
                />
                <path
                    d="M115.28,135.58c1.71-0.01,4.07,0.51,4.59,0.74c0.87,0.39,1.53,1.21,2.23,1.83c0.44,0.39,0.18,0.8-0.36,0.78
           c-0.48-0.01-0.99-0.14-1.43-0.35c-0.97-0.45-2.02-0.7-3.08-0.84c-0.81-0.1-1.65,0.06-2.45-0.11c-0.6-0.12-1.8-0.86-1.24-1.62
           C113.89,135.56,114.79,135.58,115.28,135.58z"
                />
                <path
                    d="M117.71,144.61c-0.09,0.5-0.36,0.9-0.82,0.86c-0.36-0.03-0.58-0.45-0.62-0.77c-0.06-0.46-0.04-1.79,0.6-1.79
           C117.57,142.91,117.85,143.87,117.71,144.61z"
                />
                <path
                    d="M102.9,144.68c-0.1,0.58-0.4,1.05-0.9,1.01c-0.39-0.04-0.63-0.53-0.67-0.9c-0.06-0.54-0.05-2.08,0.66-2.08
           C102.74,142.7,103.04,143.81,102.9,144.68z"
                />
                <path
                    d="M117.78,144.89c-0.1,0.58-0.4,1.05-0.9,1.01c-0.39-0.04-0.63-0.53-0.67-0.9c-0.06-0.54-0.05-2.08,0.66-2.08
           C117.62,142.91,117.92,144.02,117.78,144.89z"
                />
            </g>
        </g>
        <g id="Stripes">
            <g>
                <path
                    d="M148.96,190.35c-2.13,0-4.12-0.01-6.11,0c-6.94,0.04-13.89,0.1-20.83,0.14c-2.58,0.01-5.17-0.06-7.75-0.03
           c-2.27,0.03-4.53,0.19-6.8,0.22c-5.31,0.06-10.61,0.07-15.92,0.12c-2.99,0.03-5.98,0.12-8.97,0.11c-4.21-0.01-8.42-0.1-12.64-0.14
           c-1.67-0.02-3.34,0.03-5.01,0.02c-0.42,0-0.84-0.12-1.26-0.18c-0.01-0.12-0.02-0.24-0.03-0.36c0.34-0.11,0.69-0.31,1.03-0.32
           c1.76-0.06,3.53-0.04,5.3-0.1c1.68-0.05,3.35-0.19,5.03-0.23c3.63-0.08,7.26-0.13,10.9-0.17c4.26-0.04,8.52-0.04,12.78-0.08
           c4.4-0.04,8.8-0.12,13.19-0.17c6.62-0.07,13.25-0.1,19.87-0.18c5.13-0.06,10.25-0.17,15.38-0.27
           C148.42,188.72,148.56,188.8,148.96,190.35z"
                />
                <path
                    d="M68.82,184.53c0.54-0.89,1.04-1.36,1.93-1.38c3.17-0.05,6.34-0.18,9.51-0.22c4.4-0.06,8.8-0.08,13.2-0.12
           c3.26-0.03,6.52-0.03,9.77-0.09c4.35-0.07,8.71-0.21,13.06-0.27c4.53-0.07,9.06-0.07,13.59-0.13c1.44-0.02,2.88-0.19,4.32-0.21
           c2.86-0.05,5.72-0.05,8.58-0.06c0.45,0,0.91,0.01,1.36,0c1.23-0.04,1.23-0.04,1.69,1.41c-2.58,0-5.12,0-7.65,0
           c-2.54,0-5.07-0.05-7.6-0.02c-5.49,0.07-10.97,0.19-16.46,0.29c-5.07,0.09-10.15,0.15-15.22,0.26c-3.26,0.07-6.52,0.22-9.79,0.29
           c-2.58,0.05-5.15,0.02-7.73,0.05c-3.81,0.05-7.62,0.14-11.43,0.2C69.66,184.53,69.35,184.53,68.82,184.53z"
                />
                <path
                    d="M81.58,252.65c0.42-0.09,0.83-0.26,1.25-0.27c3.53-0.1,7.05-0.19,10.58-0.25c4.36-0.08,8.71-0.16,13.07-0.19
           c7.11-0.05,14.23-0.06,21.34-0.08c2.86-0.01,5.72-0.02,8.58-0.02c0.43,0,0.87,0.04,1.32,0.06c-0.27,1.43-0.35,1.47-1.55,1.43
           c-0.86-0.03-1.72,0.06-2.58,0.04c-1.85-0.03-3.71-0.13-5.56-0.12c-1.81,0.01-3.62,0.18-5.43,0.19c-3.72,0.03-7.45,0.01-11.17,0.01
           c-3.12,0-6.25-0.01-9.37-0.01c-6.13,0-12.26,0-18.39-0.01C82.96,253.44,82.22,253.5,81.58,252.65z"
                />
                <path
                    d="M133.96,257.51c-0.17,1.21-0.73,1.47-1.57,1.46c-5.03-0.04-10.07-0.07-15.1-0.09c-2.63-0.01-5.26,0.03-7.89,0
           c-2.04-0.03-4.08-0.18-6.12-0.2c-3.81-0.05-7.62-0.05-11.43-0.06c-2.58-0.01-5.15,0.03-7.73,0c-1.31-0.02-2.63-0.13-3.93-0.26
           c-0.29-0.03-0.56-0.32-0.84-0.49c0.31-0.19,0.6-0.53,0.92-0.56c1.49-0.12,2.98-0.23,4.47-0.22c5.25,0.03,10.5,0.11,15.76,0.16
           c5.26,0.05,10.53,0.08,15.79,0.12c2.99,0.03,5.98,0.06,8.97,0.1c1.85,0.02,3.7,0.07,5.55,0.08c0.4,0,0.81-0.11,1.22-0.11
           C132.64,257.43,133.26,257.48,133.96,257.51z"
                />
                <path
                    d="M138.73,247.34c-17.78,0.12-35.46,0.25-53.26,0.37c0.35-1.01,0.9-1.4,1.86-1.4c5.67-0.01,11.34-0.04,17.01-0.1
           c4.04-0.04,8.07-0.16,12.11-0.19c3.81-0.04,7.62-0.01,11.44-0.02c3.22-0.01,6.44-0.04,9.67-0.05
           C138.67,245.95,138.67,245.98,138.73,247.34z"
                />
                <path
                    d="M90.13,240.99c0.31-1.25,1.12-1.38,2.04-1.38c2.72,0,5.45-0.01,8.17-0.04c3.91-0.04,7.81-0.13,11.72-0.17
           c6.08-0.05,12.16-0.05,18.25-0.11c2.04-0.02,4.07-0.17,6.11-0.24c1.2-0.04,1.2-0.02,1.77,1.4c-0.98,0.03-1.91,0.09-2.83,0.1
           c-3.72,0.04-7.44,0.07-11.15,0.09c-5,0.02-9.99,0-14.99,0.05c-5.54,0.06-11.07,0.19-16.61,0.28
           C91.79,241,90.99,240.99,90.13,240.99z"
                />
                <path
                    d="M101.64,196.51c0.17,0.88-0.09,1.33-0.93,1.33c-0.23,0-0.45,0.02-0.68,0.03c-6.31,0.11-12.62,0.24-18.92,0.32
           c-6.71,0.09-13.43,0.13-20.14,0.18c-0.22,0-0.44-0.06-0.65-0.09c0.08-1.26,0.08-1.23,1.07-1.25c2.9-0.05,5.81-0.15,8.71-0.19
           c5.22-0.08,10.44-0.14,15.65-0.21c2.77-0.04,5.54-0.09,8.31-0.13c2.04-0.03,4.08-0.06,6.11-0.08
           C100.66,196.43,101.15,196.48,101.64,196.51z"
                />
                <path
                    d="M98.92,210.8c-0.12,0.39-0.24,0.76-0.38,1.23c-13.98,0.21-27.92,0.42-41.87,0.64c0-1.33,0.02-1.4,1-1.41
           c2.72-0.04,5.44-0.04,8.16-0.07c1.9-0.02,3.8-0.07,5.7-0.1c5.81-0.1,11.61-0.22,17.42-0.31c3.17-0.05,6.33-0.06,9.5-0.08
           C98.58,210.69,98.71,210.75,98.92,210.8z"
                />
                <path
                    d="M101.33,203.96c-0.05,0.43-0.1,0.78-0.17,1.32c-3.6,0-7.18-0.02-10.75,0c-3.58,0.02-7.16,0.1-10.73,0.13
           c-3.58,0.03-7.16,0.09-10.74,0.04c-3.51-0.05-7.02,0.05-10.52-0.23c-0.01-0.16-0.02-0.32-0.03-0.48c0.44-0.09,0.88-0.24,1.32-0.26
           c1.81-0.06,3.62-0.05,5.44-0.1c2.13-0.06,4.26-0.18,6.39-0.21c3.58-0.05,7.17-0.02,10.75-0.06c4.62-0.06,9.24-0.17,13.86-0.24
           c1.4-0.02,2.81-0.01,4.21,0C100.67,203.86,100.97,203.92,101.33,203.96z"
                />
                <path
                    d="M90.11,262.54c0.75-0.1,1.5-0.29,2.24-0.29c1.67,0,3.34,0.12,5.01,0.15c0.81,0.02,1.62-0.05,2.43-0.04
           c3.08,0.03,6.15,0.06,9.23,0.12c3.12,0.06,6.24,0.16,9.36,0.25c2.62,0.08,5.25,0.16,7.87,0.25c0.45,0.02,0.91,0.05,1.34,0.17
           c0.2,0.06,0.47,0.36,0.46,0.54c-0.01,0.2-0.28,0.53-0.48,0.56c-0.71,0.13-1.44,0.22-2.15,0.21c-2.39-0.03-4.79-0.1-7.18-0.17
           c-2.3-0.06-4.61-0.15-6.91-0.2c-3.4-0.07-6.8-0.08-10.2-0.18c-3.03-0.09-6.06-0.25-9.09-0.43c-0.68-0.04-1.35-0.31-2.02-0.48
           C90.06,262.86,90.09,262.7,90.11,262.54z"
                />
                <path
                    d="M88.12,224.87c-0.83,0.85-1.68,1.19-2.76,1.19c-4.31,0.02-8.62,0.12-12.93,0.17c-5.71,0.07-11.42,0.12-17.13,0.17
           c-0.22,0-0.44-0.03-0.74-0.05c0-0.43,0-0.8,0-1.08C55.06,224.78,86.85,224.44,88.12,224.87z"
                />
                <path
                    d="M55.77,218.99c-0.41-1.03,0.13-1.16,0.8-1.18c2-0.06,3.99-0.13,5.99-0.16c4.68-0.06,9.36-0.12,14.04-0.15
           c5.4-0.04,10.8-0.06,16.2-0.09c0.08,0,0.16,0.07,0.26,0.12c-0.08,0.88-0.56,1.21-1.43,1.21c-2.32,0.02-4.63,0.12-6.95,0.14
           c-3.86,0.04-7.72,0.07-11.57,0.06c-4.94-0.02-9.89-0.09-14.83-0.12C57.43,218.82,56.58,218.93,55.77,218.99z"
                />
                <path
                    d="M145.78,254.66c0.38-0.08,0.76-0.15,1.14-0.23c-0.01-0.05-0.01-0.1-0.02-0.15c-1.13,0-2.25,0-3.48,0
           c0.88-0.5,1.64-0.94,2.42-1.36c0.19-0.1,0.44-0.12,0.66-0.12c3.76-0.04,7.52-0.06,11.28-0.1c1.31-0.01,2.63-0.04,3.94-0.12
           c0.73-0.04,1.32,0.04,1.77,0.71c0.21,0.31,0.62,0.48,1.01,0.76c-0.44,0.71-1.14,0.76-1.79,0.77c-2.94,0.03-5.89,0-8.83,0.02
           c-2.13,0.01-4.26,0.07-6.38,0.09c-0.58,0.01-1.15-0.05-1.73-0.07C145.76,254.8,145.77,254.73,145.78,254.66z"
                />
                <path
                    d="M143.29,261.41c-0.26-0.56-0.15-0.95,0.56-0.97c1-0.04,1.99-0.05,2.99-0.06c4.44-0.06,8.88-0.13,13.32-0.18
           c2.08-0.02,4.16,0,6.34,0c0.06,0.46,0.11,0.84,0.18,1.38C158.85,261.8,151.14,262.05,143.29,261.41z"
                />
                <path
                    d="M80.07,231.02c-0.25,0.85-0.95,0.82-1.63,0.83c-2.99,0.03-5.98,0.06-8.97,0.09c-0.07,0-0.15,0.06-0.32,0.14
           c1.29,0.62,2.6,0.58,3.89,0.66c1.29,0.09,2.57,0.21,3.87,0.32c-0.5,1.42-1.55,1.47-3.07,1.16c-0.61-0.12-1.22-0.27-1.83-0.34
           c-1.31-0.14-2.62-0.19-3.92-0.37c-0.53-0.07-1.03-0.39-1.73-0.68c0.77-0.36,1.29-0.61,1.81-0.85c-1.16-0.08-2.23-0.2-3.5-0.59
           c0.94-0.82,1.88-0.73,2.71-0.76c3.67-0.14,7.34-0.19,11-0.27C79.04,230.33,79.67,230.32,80.07,231.02z"
                />
                <path
                    d="M54.59,262.88c-0.02-0.5-0.04-0.9-0.06-1.4c0.39-0.05,0.74-0.16,1.08-0.14c3.99,0.35,7.97-0.14,11.96-0.09
           c2.54,0.04,5.08-0.04,7.63-0.06c0.93-0.01,1.03,0.06,2.21,1.45C69.75,262.72,62.26,262.8,54.59,262.88z"
                />
                <path
                    d="M118.33,176.9c0.4-1.01,1.18-1.38,2.39-1.36c4.07,0.05,8.15,0.05,12.22-0.06c1.37-0.04,2.4,0.53,3.44,1.42
           C130.35,176.9,124.33,176.9,118.33,176.9z"
                />
                <path
                    d="M53.69,233.74c0.74,0.06,1.5,0.04,2.21,0.21c4.16,0.98,8.32,2.01,12.47,3.02c0.52,0.13,1.07,0.2,1.56,0.42
           c0.27,0.12,0.43,0.47,0.64,0.72c-0.3,0.16-0.58,0.36-0.9,0.45c-0.2,0.06-0.45-0.02-0.67-0.07c-3.89-0.99-7.78-1.99-11.67-2.99
           c-0.96-0.25-1.92-0.51-2.87-0.8c-0.32-0.1-0.6-0.31-0.89-0.47C53.62,234.06,53.65,233.9,53.69,233.74z"
                />
                <path
                    d="M94.78,177.3c-5.25,0-10.49,0-15.84,0c0.67-0.74,1.35-1.24,2.4-1.23c3.04,0.02,6.07-0.01,9.11-0.04
           c0.45,0,0.89-0.12,1.34-0.18C93.09,175.68,93.91,176.07,94.78,177.3z"
                />
                <path
                    d="M53.44,238.92c1.7-0.43,10.51,1.82,11.65,3c-0.26,0.65-0.81,0.62-1.35,0.51c-1.5-0.33-3-0.66-4.48-1.04
           c-1.61-0.42-3.21-0.89-4.81-1.36C53.56,239.77,53.57,239.75,53.44,238.92z"
                />
                <path
                    d="M157.44,218.21c-2.47,0-4.76,0-7.01,0c-0.28-0.83-0.08-1.29,0.72-1.31c1.85-0.05,3.7-0.11,5.55-0.08
           C157.47,216.83,157.34,217.52,157.44,218.21z"
                />
                <path
                    d="M153.31,228.5c0.31-0.52,0.43-1.01,0.73-1.18c1.75-0.98,3.69-1.19,5.65-1.34c0.27,0.9-0.29,1.12-0.79,1.24
           C157.17,227.65,155.43,228.02,153.31,228.5z"
                />
                <path
                    d="M94.44,234.03c0.4-0.81,0.85-1.23,1.72-1.18c1.26,0.07,2.53,0.02,3.92,0.02c0.07,0.3,0.15,0.67,0.26,1.17
           C98.36,234.03,96.48,234.03,94.44,234.03z"
                />
                <path
                    d="M150.69,210.01c1.57,0,3.08,0,4.67,0c0.08,0.33,0.18,0.7,0.31,1.2c-1.7,0-3.3,0-4.98,0
           C150.69,210.81,150.69,210.48,150.69,210.01z"
                />
                <path
                    d="M156.57,232.23c1.38,0.21,2.75,0.42,4.13,0.64c-0.85,0.91-1.84,1.04-2.77,0.81c-0.57-0.14-1.03-0.74-1.53-1.14
           C156.45,232.43,156.51,232.33,156.57,232.23z"
                />
                <path d="M149.11,225.29c0.26-1.57,1.26-1.52,2.38-1.45c0.13,0.36,0.28,0.76,0.49,1.33C150.98,225.21,150.1,225.25,149.11,225.29z" />
                <path d="M48.5,262.8c-2.38,0.38-2.7,0.26-3.04-1.1C46.68,261.32,47.67,261.63,48.5,262.8z" />
                <path
                    d="M150.94,204.73c0-0.49,0-0.84,0-1.33c0.47,0,0.91-0.08,1.3,0.03c0.2,0.06,0.49,0.51,0.44,0.67
           C152.4,204.87,151.71,204.71,150.94,204.73z"
                />
            </g>
        </g>
    </svg>
);
