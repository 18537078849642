import React from 'react';

interface TripPinsProps {
    strokeColor?: string;
}

export const TripPins = ({ strokeColor = 'black' }: TripPinsProps = {}) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label="trip pins"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.9455 5.38792C9.9455 7.28104 7.0325 10.9799 5.9585 12.2798C5.86366 12.3947 5.71927 12.4618 5.5665 12.4618C5.41373 12.4618 5.26934 12.3947 5.1745 12.2798C4.1005 10.9761 1.1875 7.28104 1.1875 5.38792C1.1875 3.06621 3.14805 1.18408 5.5665 1.18408C7.98496 1.18408 9.9455 3.06621 9.9455 5.38792Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.20988 5.38784C7.21028 6.02588 6.81023 6.60131 6.19632 6.84574C5.5824 7.09018 4.87556 6.95547 4.40545 6.50445C3.93535 6.05343 3.7946 5.37493 4.04885 4.78543C4.30309 4.19592 4.90225 3.81152 5.56688 3.81152C6.00253 3.81127 6.42044 3.97723 6.72859 4.27288C7.03674 4.56852 7.20988 4.96961 7.20988 5.38784V5.38784Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.5665 11.7441C19.728 11.7439 20.8419 12.1867 21.6632 12.9751C22.4845 13.7636 22.9458 14.833 22.9455 15.948C22.9455 17.8411 20.0325 21.539 18.9585 22.8389C18.8637 22.9538 18.7193 23.0209 18.5665 23.0209C18.4137 23.0209 18.2693 22.9538 18.1745 22.8389C17.1005 21.539 14.1875 17.8401 14.1875 15.947C14.1875 14.8322 14.6489 13.763 15.4701 12.9748C16.2914 12.1866 17.4052 11.7439 18.5665 11.7441Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2099 15.9479C20.2103 16.5859 19.8102 17.1614 19.1963 17.4058C18.5824 17.6502 17.8756 17.5155 17.4055 17.0645C16.9354 16.6135 16.7946 15.935 17.0488 15.3455C17.3031 14.756 17.9023 14.3716 18.5669 14.3716C19.0025 14.3713 19.4204 14.5373 19.7286 14.8329C20.0367 15.1286 20.2099 15.5297 20.2099 15.9479Z"
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M6.56641 23.2642H5.56641V22.3042" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.5664 23.2642H15.5664" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.5664 23.2642H12.5664" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.56641 23.2642H9.56641" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.56641 13.6641V14.6241" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.56641 16.5439V17.5039" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.56641 19.4243V20.3843" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
