import React from 'react';
import styled from '@emotion/styled';
import { InformationAlert } from '@home-mgmt-shared/common-ui';

type SpeedResultsAlertProps = {
    prefix: string;
    idealSpeed: number;
    suffix: string;
};

const AlertContainer = styled.div`
    font-family: ${(props) => props.theme.font?.type};
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 1rem;
    display: flex;
`;

export const SpeedResultsAlert = ({ prefix, idealSpeed, suffix }: SpeedResultsAlertProps) => {
    const getSpeedRecommendation = () => (
        <div>
            {`${prefix} `}
            <span>
                <strong>{`${idealSpeed} Mbps ${suffix}`}</strong>
            </span>
        </div>
    );

    return (
        <AlertContainer>
            <InformationAlert>{getSpeedRecommendation()}</InformationAlert>
        </AlertContainer>
    );
};
