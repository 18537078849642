import { Theme } from '@emotion/react';

export const bandwidthTheme: Theme = {
  font: {
    type: 'ATTAleckSans_Rg',
    bold: 'ATTAleckSans_Bd',
    accentColor: '',
  },
  accentColors: {
    primary: '#0057B8',
    secondary: '#009FDB',
    tertiary: '#6E767D',
  },
  button: {
    primaryColor: '#0057B8',
    selectedColor: '#0057B8',
    disabledColor: '#E6E6EB',
    disabledTextColor: '#A5AAAF',
    defaultTextColor: '#000000',
  },
  messageAlert: {
    backgroundColor: '#F0F0F5',
    font: {
      type: 'ATTAleckSans_Rg',
      bold: 'ATTAleckSans_Bd',
      accentColor: '#000000',
    },
  },
  speedResults: {
    slowColor: '#FF585D',
    okayColor: '#FFB000',
    decentColor: '#91DC00',
    goodColor: '#00B574',
  },
  special: {
    primaryColor: '#000000',
    secondaryColor: '#009FDB',
  },
  spinner: {
    primaryColor: '',
  },
  progressBar: {
    primaryColor: '#009FDB',
  },
};
