import * as React from 'react';

export const StreamingVideo = () => (
  <svg
    width="49"
    height="45"
    viewBox="0 0 49 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Streaming video"
    role="img"
  >
    <path
      d="M46.8344 0.0609741H2.82214C1.59471 0.0609741 0.542969 1.11293 0.542969 2.34035V33.3769C0.542969 34.6043 1.59471 35.6563 2.82214 35.6563H24.2148V39.6893H14.7458C14.3951 39.6893 14.0446 40.0401 14.0446 40.3908C14.0446 40.7414 14.3951 41.0922 14.7458 41.0922H34.9107C35.2614 41.2675 35.6121 41.0921 35.7874 40.7414C35.9628 40.3907 35.7875 40.04 35.4368 39.8646C35.2615 39.6893 34.911 39.6893 34.7356 39.8646H25.2666V35.8318H46.6589C47.8863 35.8318 48.9384 34.7796 48.9384 33.5522V2.51587C49.1138 1.28844 48.0618 0.236321 46.8344 0.0609741C47.0097 0.0609741 46.8344 0.0609741 46.8344 0.0609741ZM47.8866 33.3769C47.8866 33.9029 47.3604 34.4289 46.8344 34.4289H2.82214C2.29609 34.4289 1.77031 33.9029 1.77031 33.3769V2.34035C1.77031 1.81431 2.29609 1.28832 2.82214 1.28832H46.8344C47.3604 1.28832 47.8866 1.81431 47.8866 2.34035V33.3769ZM45.607 5.32138V30.0452C45.607 30.922 44.9054 31.6234 44.0286 31.6234H9.13479C8.7841 31.4481 8.60884 30.922 8.78419 30.7467C8.78419 30.5713 8.95945 30.5712 9.13479 30.3959H44.2042V5.67199H5.45234V21.9793C5.27699 22.33 4.75095 22.5053 4.5756 22.3299C4.40026 22.3299 4.40034 22.1547 4.225 21.9793V5.67199C4.225 4.79525 4.92664 4.09382 5.80337 4.09382H44.2042C44.9056 4.26917 45.2562 4.61999 45.4315 5.32138H45.607Z"
      fill="#009FDB"
    />
    <path
      d="M33.2463 16.7616L21.7969 10.7247C21.45 10.5165 21.103 10.4471 20.7561 10.4471C19.854 10.4471 19.2295 11.2104 19.2295 12.3207V24.0476C19.2295 25.1578 19.854 25.9212 20.7561 25.9212C21.103 25.9212 21.45 25.8518 21.7969 25.6436L33.2463 19.6067C34.0096 19.3291 34.426 18.5657 34.2178 17.8024C34.079 17.3167 33.6627 16.9698 33.2463 16.7616ZM33.0382 19.0515L21.5888 25.0884C21.3112 25.2272 21.0336 25.2966 20.7561 25.2966C20.1316 25.2966 19.7152 24.8109 19.7152 23.9782V12.2512C19.7152 11.4185 20.1316 10.9329 20.7561 10.9329C21.0336 10.9329 21.3112 11.0022 21.5888 11.141L33.0382 17.178C33.5239 17.4556 33.8015 17.8025 33.8015 18.08C33.8015 18.3576 33.4545 18.8433 32.9688 19.0515H33.0382Z"
      fill="#009FDB"
      stroke="#009FDB"
      strokeWidth="0.407646"
    />
  </svg>
);
